const _ = require('lodash');

export const arrayUniqueFilter = (arr: any[], key: string) => {
  return _.uniq(arr, key);
};

export const getArrayTotalByObjKeys = (arr: any[], key: string) => {
  return arr.reduce((a, b) => {
    return a + b[key];
  }, 0);
};

export const orderArrayOfObject = (arr: any[], key: string, order: string) => {
  const sortedArr = _.orderBy(arr, key, order);

  return sortedArr;
};

export const arrayObjectUniqueFilter = (arr: any[], key: string) => {
  return _.uniqBy(arr, key);
};
