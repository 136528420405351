import { Grid, TextField, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { AdsTemplate, DiyError } from '../../../types/IDiy';
import { ChangeEventType } from '../../../types';

interface DiyAdDetailsFormProps {
  template: AdsTemplate;
  setTemplate: Dispatch<SetStateAction<AdsTemplate>>;
  onChange: (e: ChangeEventType) => void;
  onChangeTags: (
    type: string,
    campaignTag?: string,
    adSetTag?: string,
    adTag?: string,
  ) => void;
  diyError: DiyError;
  campaignNameTag: string;
  adSetNameTag: string;
  adNameTag: string;
  isAdmin: boolean;
}

const DiyAdDetailsForm: React.FC<DiyAdDetailsFormProps> = ({
  template,
  setTemplate,
  onChange,
  onChangeTags,
  diyError,
  campaignNameTag,
  adSetNameTag,
  adNameTag,
  isAdmin,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <TextField
          fullWidth
          required
          size="small"
          value={template?.name}
          name="name"
          onChange={onChange}
          InputLabelProps={{ shrink: true }}
          label="Ad Name"
          error={diyError?.nameError ? true : false}
          helperText={diyError?.nameError ? diyError?.nameError : ''}
        />
      </Grid>

      <Grid item xs={8}>
        <TextField
          fullWidth
          required
          size="small"
          value={template?.description}
          name="description"
          label="Description"
          multiline
          onChange={onChange}
          InputLabelProps={{ shrink: true }}
          minRows={4}
          maxRows={100}
          error={diyError?.descriptionError ? true : false}
          helperText={
            diyError?.descriptionError ? diyError?.descriptionError : ''
          }
        />
      </Grid>

      <Grid item xs={8}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Ad Name Tags
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <TextField
          fullWidth
          size="small"
          value={campaignNameTag}
          name="campaignNameTag"
          onChange={(e: ChangeEventType) =>
            onChangeTags('campaign', e.target.value, adSetNameTag, adNameTag)
          }
          InputLabelProps={{ shrink: true }}
          label="Campaign Name Tag"
          helperText={template?.campaignTemplate?.name}
        />
      </Grid>

      <Grid item xs={8}>
        <TextField
          fullWidth
          size="small"
          value={adSetNameTag}
          name="adSetNameTag"
          onChange={(e: ChangeEventType) =>
            onChangeTags('adset', campaignNameTag, e.target.value, adNameTag)
          }
          InputLabelProps={{ shrink: true }}
          label="Adset Name Tag"
          helperText={template?.adSetTemplate?.name}
        />
      </Grid>

      <Grid item xs={8}>
        <TextField
          fullWidth
          size="small"
          value={adNameTag}
          name="adNameTag"
          onChange={(e: ChangeEventType) =>
            onChangeTags('ad', campaignNameTag, adSetNameTag, e.target.value)
          }
          InputLabelProps={{ shrink: true }}
          label="Ad Name Tag"
          helperText={template?.adTemplate?.name}
        />
      </Grid>

      {isAdmin ? (
        <>
          <Grid item xs={8}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Facebook Campaign Configuration
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              required
              size="small"
              value={template?.campaignTemplate?.name}
              name="name"
              onChange={(e: ChangeEventType) =>
                setTemplate({
                  ...template,
                  campaignTemplate: {
                    ...template?.campaignTemplate,
                    name: e.target.value,
                  },
                })
              }
              InputLabelProps={{ shrink: true }}
              label="Campaign Name"
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              required
              size="small"
              value={template?.adSetTemplate?.name}
              name="name"
              onChange={(e: ChangeEventType) =>
                setTemplate({
                  ...template,
                  adSetTemplate: {
                    ...template?.adSetTemplate,
                    name: e.target.value,
                  },
                })
              }
              InputLabelProps={{ shrink: true }}
              label="Adset Name"
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              required
              size="small"
              value={template?.adTemplate?.name}
              name="name"
              onChange={(e: ChangeEventType) =>
                setTemplate({
                  ...template,
                  adTemplate: {
                    ...template?.adTemplate,
                    name: e.target.value,
                  },
                })
              }
              InputLabelProps={{ shrink: true }}
              label="Ad Name"
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default DiyAdDetailsForm;
