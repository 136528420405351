import { Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import styles from '../../../../assets/styles/components/Forms/Form.module.scss';
import {
  ChangeEventType,
  StripeUpdateAccountForm as UpdateForm,
} from '../../../../types';
import CountrySelectMenu from '../../../Select/CountrySelectMenu';
import { PrimaryButton } from '../../../Buttons';
import { MuiTelInput } from 'mui-tel-input';
import FormHeader from '../../FormHeader';

interface StripeUpdateAccountFormProps {
  onClose: () => void;
  onChange: (e: ChangeEventType) => void;
  formValues: UpdateForm;
  onSubmit: () => void;
  loading: boolean;
  onChangeNumber: (newValue: string) => void;
}

const StripeUpdateAccountForm: React.FC<StripeUpdateAccountFormProps> = ({
  onClose,
  onChange,
  formValues,
  onSubmit,
  loading,
  onChangeNumber,
}) => {
  return (
    <Box className={styles.form}>
      <FormHeader title="Business Details" />

      <Grid container spacing={2} sx={{ marginBottom: '20px' }} my={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="name"
            name="name"
            label="Business Name"
            onChange={onChange}
            value={formValues?.business_profile?.name}
            InputLabelProps={{ shrink: true }}
            size="small"
            autoFocus
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="email"
            id="supportEmail"
            name="support_email"
            label="Support Email"
            onChange={onChange}
            value={formValues?.business_profile?.support_email}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <MuiTelInput
            fullWidth
            required
            variant="standard"
            id="supportPhone"
            name="support_phone"
            label="Support Phone"
            onChange={(newValue: string) => onChangeNumber(newValue)}
            value={formValues?.business_profile?.support_phone}
            InputLabelProps={{ shrink: true }}
            size="small"
            onlyCountries={['US']}
            defaultCountry="US"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="standard"
            type="text"
            id="website"
            name="url"
            label="Website"
            onChange={onChange}
            value={formValues?.business_profile?.url}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="standard"
            type="text"
            id="supportWebsite"
            name="support_url"
            label="Support Website"
            onChange={onChange}
            value={formValues?.business_profile?.support_url}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>
      </Grid>

      <FormHeader title="Business Address" />

      <Grid container spacing={2} sx={{ marginBottom: '20px' }} my={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="standard"
            type="text"
            id="line1"
            name="line1"
            label="Company Name, Street, PO Box"
            onChange={onChange}
            value={formValues?.business_profile?.support_address?.line1}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="standard"
            type="text"
            id="line2"
            name="line2"
            label="Apartment, Suite, Unit, Building"
            onChange={onChange}
            value={formValues?.business_profile?.support_address?.line2}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="city"
            name="city"
            label="City"
            onChange={onChange}
            value={formValues?.business_profile?.support_address?.city}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="state"
            name="state"
            label="State"
            onChange={onChange}
            value={formValues?.business_profile?.support_address?.state}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CountrySelectMenu
            value={{
              code: 'US',
              label: 'United States',
              phone: '1',
              suggested: true,
            }}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="postalCode"
            name="postal_code"
            label="Postal Code"
            onChange={onChange}
            value={formValues?.business_profile?.support_address?.postal_code}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>
      </Grid>

      <div className={`${styles.action} ${styles['-unsticky']}`}>
        <PrimaryButton
          title="Save"
          loading={loading}
          marginRight5
          handleOnClick={onSubmit}
          size="large"
          rounded
        />

        <PrimaryButton
          title="Cancel"
          type="button"
          theme="red"
          handleOnClick={onClose}
          variant="text"
          size="large"
        />
      </div>
    </Box>
  );
};

export default StripeUpdateAccountForm;
