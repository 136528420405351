import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@mui/material';
import { Page } from '../../../types';
import { constantStringToHumanized } from '../../../utils/stringModifier';
import logo from '../../../assets/images/aiadz-logo.png';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { IoIosShareAlt } from 'react-icons/io';
import { FaCommentAlt } from 'react-icons/fa';
import { SlOptions } from 'react-icons/sl';
import { FBCampaignObjective } from '../../../utils/helpers/facebookAdsHelper';

interface AdsPreviewProps {
  page: Page;
  body: string;
  isImage?: boolean;
  imageUrl?: string;
  videoUrl?: string;
  websiteUrl?: string;
  headline?: string;
  description?: string;
  objective?: string;
  cta: string;
}

export const AdsPreviewSquare: React.FC<AdsPreviewProps> = ({
  page,
  body,
  isImage = false,
  imageUrl,
  videoUrl,
  websiteUrl,
  headline,
  description,
  cta,
  objective,
}) => {
  const [play, setPlay] = useState<boolean>(false);

  const isLeads = ['Get More Leads', FBCampaignObjective.Leads].includes(
    objective,
  );

  return (
    <Card sx={{ width: '320px' }}>
      <CardHeader
        avatar={<Avatar src={page?.imageUrl} alt={page?.name} />}
        title={page?.name}
        subheader="Sponsored"
        titleTypographyProps={{
          fontWeight: 'bold',
          fontSize: '.875rem',
          fontFamily: 'Helvetica, Arial, sans-serif',
        }}
        subheaderTypographyProps={{
          fontSize: '.75rem',
          fontFamily: 'Helvetica, Arial, sans-serif',
        }}
      />

      <CardContent
        sx={{
          paddingTop: 0,
          paddingBottom: '10px',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: '.875rem',
            fontFamily: 'Helvetica, Arial, sans-serif',
            lineHeight: '18px',
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
          }}
        >
          {body}
        </Typography>
      </CardContent>

      <CardMedia
        component={isImage ? 'img' : 'video'}
        src={(isImage ? imageUrl : videoUrl) || logo}
        sx={{
          marginLeft: 0,
          aspectRatio: !isImage ? 'auto' : '1/1',
          backgroundColor: 'gray',
        }}
        loop
        controls={play}
        onClick={() => setPlay(true)}
      />

      <CardActions
        sx={{
          alignItems: 'center',
          flexDirection: 'row',
          display: 'flex',
          padding: '12px',
          flex: '1 1 auto',
          backgroundColor: '#f7f8fa',
          minHeight: '40px',
        }}
      >
        <Box
          component="div"
          sx={{
            flex: '1 1 auto',
            order: 0,
            overflowX: 'hidden',
            overflowY: 'hidden',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              display: 'block',
              maxWidth: '200px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: 'rgb(70,73,80)',
              fontSize: '.8125rem',
              fontFamily: 'Helvetica, Arial, sans-serif',
            }}
          >
            {isLeads ? 'FORM ON FACEBOOK' : websiteUrl}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              display: 'block',
              color: '#1c2028',
              wordWrap: 'break-word',
              fontSize: '.9375rem',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 'bold',
            }}
          >
            {headline}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              display: 'block',
              maxWidth: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: 'rgb(70, 73, 80)',
              fontSize: '.9375rem',
              fontFamily: 'Helvetica, Arial, sans-serif',
            }}
          >
            {description}
          </Typography>
        </Box>

        <Box
          component="div"
          sx={{
            marginLeft: '12px',
            display: 'flex',
            width: '150px',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            component="div"
            sx={{
              backgroundColor: '#E4E6EB',
              height: '36px',
              margin: 0,
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '5px',
              textAlign: 'left',
              padding: 0,
              flexDirection: 'column',
              boxSizing: 'border-box',
              maxWidth: '120px',
            }}
          >
            <Typography
              component="div"
              variant="body1"
              sx={{
                maxWidth: '100%',
                color: '#050505',
                fontSize: '.8000rem',
                fontFamily:
                  'Segoe UI Historic, Segoe UI, Helvetica, Arial, sans-serif',
                fontWeight: 600,
                wordBreak: 'break-word',
                textAlign: 'center',
                paddingLeft: '12px',
                paddingRight: '12px',
                position: 'relative',
                flexDirection: 'row',
              }}
            >
              {constantStringToHumanized(cta || '')}
            </Typography>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};

export const AdsPreviewVertical: React.FC<AdsPreviewProps> = ({
  page,
  body,
  isImage = false,
  imageUrl,
  videoUrl,
  cta,
}) => {
  const [play, setPlay] = useState<boolean>(false);

  const buildIcons = (option: string) => {
    if (option === 'share') {
      return <IoIosShareAlt fontSize="20px" />;
    }

    if (option === 'comment') {
      return <FaCommentAlt fontSize="18px" />;
    }

    if (option === 'like') {
      return <ThumbUpIcon sx={{ fontSize: '18px' }} />;
    }

    return <SlOptions fontSize="18px" />;
  };
  return (
    <Card sx={{ width: '320px' }}>
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component={isImage ? 'img' : 'video'}
          src={(isImage ? imageUrl : videoUrl) || logo}
          sx={{
            marginLeft: 0,
            aspectRatio: '9/16',
            background: 'gray',
            objectFit: 'contain',
          }}
          loop
          controls={play}
          onClick={() => setPlay(true)}
        />

        <Box
          component="div"
          sx={{
            position: 'absolute',
            bottom: '8px',
            width: '36px',
            zIndex: 2,
            right: '10px',
          }}
        >
          {['like', 'share', 'comment', 'option'].map(
            (option: string, index: number) => {
              return (
                <>
                  <Box
                    component="div"
                    sx={{
                      marginBottom: '2px',
                      position: 'relative',
                      height: '36px',
                      width: '36px',
                      borderRadius: '20px',
                      backgroundColor: 'rgba(228,230,235,.2)',
                    }}
                    key={`${option}-${index}`}
                  >
                    <Box
                      component="div"
                      sx={{
                        display: 'inline-flex',
                        maxWidth: '100%',
                        verticalAlign: 'middle',
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          paddingY: '8px',
                          paddingX: '12px',
                          minWidth: 0,
                          maxWidth: '100%',
                          minHeight: 0,
                          margin: 0,
                          verticalAlign: 'middle',
                          alignItems: 'center',
                          borderRadius: '6px',
                          position: 'relative',
                          backgroundColor: 'rgba(255,255,255,0)',
                          fontWeight: 700,
                          flexDirection: 'row',
                          zIndex: 0,
                        }}
                      >
                        <Box
                          component="span"
                          sx={{
                            width: '100%',
                            minWidth: 0,
                            fontFamily: 'Roboto,Arial,sans-serif !important',
                            wordWrap: 'normal',
                            whiteSpace: 'inherit',
                          }}
                        >
                          <Box component="div" sx={{ display: 'flex' }}>
                            <Box
                              component="div"
                              sx={{
                                width: '100%',
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                flexGrow: 1,
                              }}
                            >
                              <Avatar
                                component="span"
                                sx={{
                                  width: '30px',
                                  backgroundColor: 'transparent',
                                  borderBottomColor: 'rgba(0, 0, 0, 0.4)',
                                  borderTopColor: 'rgba(0, 0, 0, 0.4)',
                                  height: 'unset',
                                }}
                              >
                                {buildIcons(option)}
                              </Avatar>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  {option !== 'option' ? (
                    <Box
                      component="div"
                      sx={{
                        textAlign: 'center',
                        position: 'relative',
                        height: '16px',
                        marginBottom: '8px',
                        zIndex: 2,
                      }}
                    />
                  ) : null}
                </>
              );
            },
          )}
        </Box>

        <Box
          component="div"
          sx={{
            position: 'absolute',
            width: '250px',
            bottom: '10px',
            zIndex: 2,
            left: '16px',
            color: 'white',
          }}
        >
          <Box
            component="div"
            sx={{
              position: 'relative',
              marginBottom: '6px',
              zIndex: 2,
            }}
          >
            <Box
              component="div"
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '250px',
              }}
            >
              <Avatar
                sx={{
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                }}
                src={page?.imageUrl}
                alt={page?.name}
              />

              <Box
                component="div"
                sx={{
                  display: 'block',
                  marginLeft: '5px',
                  lineHeight: '1px',
                }}
              >
                <Typography
                  component="div"
                  variant="body1"
                  sx={{
                    color: '#fff',
                    fontFamily: 'SF Pro Text',
                    fontWeight: 600,
                  }}
                >
                  {page?.name}
                </Typography>

                <Typography
                  component="div"
                  variant="body1"
                  sx={{ color: '#fff', fontFamily: 'SF Pro Text' }}
                >
                  Sponsored
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            component="div"
            sx={{
              position: 'relative',
              marginBottom: '6px',
              zIndex: 2,
            }}
          >
            <Typography
              component="div"
              variant="body1"
              sx={{
                wordWrap: 'break-word',
                marginBottom: '4px',
                fontSize: '.875rem',
                color: '#FFF',
                fontFamily: 'SF Pro Text',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {body || ' '}
            </Typography>
          </Box>

          <Box
            component="div"
            sx={{
              position: 'relative',
              textAlign: 'center',
              zIndex: 4,
              backgroundColor: 'rgb(255, 255, 255)',
              width: '250px',
              height: '34px',
              marginTop: '12px',
              borderRadius: '5px',
            }}
          >
            <Typography
              component="div"
              variant="body1"
              sx={{
                paddingY: '8px',
                fontFamily: 'Roboto,Arial,sans-serif!important',
                fontSize: '.875rem',
                color: 'black',
              }}
            >
              {constantStringToHumanized(cta || '')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
