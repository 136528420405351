import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { Brand } from '../../../types';
import {
  columns,
  adminColumns,
  mobileColumns,
  brandManagerColumns,
} from './BrandListTableColumns';
import { XsOnly } from '../../../utils/breakpoints';
import { CustomLoadingOverlay } from '../../Loading';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';

interface BrandListTableProps {
  brands: Brand[];
  handleOnClickDelete: (id: string) => void;
  loading: boolean;
  isAdmin: boolean;
  isAgency: boolean;
  isSalesperson: boolean;
  page: number;
  setPage: (page: number) => void;
  rowData: number;
  setBrandPage: (pageNum: number, limit: number) => void;
  currentLimit: number;
  pageSize: number;
  setBrandSize: (pageNum: number, limit: number) => void;
  isBrandManager: boolean;
  onSort: (sortedField: string, sortType: string) => void;
  agencyId: string;
  agencyName: string;
}
const BrandListTable: React.FC<BrandListTableProps> = ({
  brands,
  handleOnClickDelete,
  loading,
  isAdmin,
  isAgency,
  isSalesperson,
  page,
  setPage,
  rowData,
  setBrandPage,
  currentLimit,
  pageSize,
  setBrandSize,
  isBrandManager,
  onSort,
  agencyId,
  agencyName,
}) => {
  const renderColumns = () => {
    if (isAdmin || isAgency) {
      if (XsOnly()) {
        return mobileColumns;
      } else {
        return adminColumns;
      }
    } else if (isBrandManager) {
      return brandManagerColumns;
    } else {
      return columns;
    }
  };

  const rows = brands.slice().map((brand: Brand, index: number) => {
    return {
      brand: brand.brand,
      agency: brand.agency,
      investmentRequirement: brand.investmentRequirement,
      allLeads: brand.allLeads,
      zipcodereq: brand.zipcodereq,
      financialreq: brand.financialreq,
      redirect: brand.redirect,
      redirectlink: brand.redirectlink,
      redirecttext: brand.redirecttext,
      industryRequirement: brand.industryRequirement,
      industryQuestion: brand.industryQuestion,
      schedulecall: brand.schedulecall,
      calendly: brand.calendly,
      salesperson: brand.salesperson,
      manager: brand.manager,
      salespersonTitle: brand.salespersonTitle,
      _id: brand._id,
      id: brand.brandId,
      phoneNumReq: brand.phoneNumReq,
      budgetOffset: brand.budgetOffset || 100,
      clientTetherConfig: brand.clientTetherConfig,
      isAdmin,
      isSalesperson,
      isBrandManager,
      isAgency,
      cannedResponses: brand.cannedResponses,
      active: brand.active,
      paused: brand.paused,
      lastPausedAt: brand.lastPausedAt,
      page: {
        _id: brand.page?._id,
        name: brand.page?.name,
        accessToken: brand.page?.accessToken,
        fbPageId: brand.page?.fbPageId,
        imageUrl: brand.page?.imageUrl,
      },
      facebookBusinessIds: brand.facebookBusinessIds,
      googleCustomerIds: brand.googleCustomerIds,
      allowLocations: brand.allowLocations,
      franchisor: brand.franchisor,
      allowSetupDepositAccount: brand.allowSetupDepositAccount,
      allowSetupPayment: brand.allowSetupPayment,
      allowOptions: brand.allowOptions,
      __type: brand.__type,
      stripe: brand.stripe,
      handleOnClickDelete: handleOnClickDelete,
      agencyId: agencyId,
      agencyName: agencyName,
    };
  });

  return (
    <Box className={styles.table}>
      <DataGrid
        density="compact"
        rows={rows}
        columns={renderColumns()}
        autoHeight
        hideFooterSelectedRowCount
        pageSize={pageSize}
        rowCount={rowData}
        rowsPerPageOptions={[25, 50, 100]}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
        }}
        loading={loading}
        page={page}
        onPageChange={(page) => {
          setPage(page);
          setBrandPage(page + 1, currentLimit);
        }}
        onPageSizeChange={(newLimit) => {
          setBrandSize(1, newLimit);
        }}
        onSortModelChange={(e: any) => {
          onSort(e[0]?.field || 'brand', e[0]?.sort || 'asc');
        }}
        sortingMode="server"
      />
    </Box>
  );
};

export default BrandListTable;
