export const phoneNoSpaceFormat = (phoneNumber: string) => {
  let formattedNumber = phoneNumber.replace(/[^\d]/g, '');

  // Check if the number has a country code and remove it
  if (formattedNumber.length > 10) {
    formattedNumber = formattedNumber.substring(formattedNumber.length - 10);
  }

  // Format the number
  if (formattedNumber.length === 10) {
    return formattedNumber;
  }

  // If the number is not 10 digits long, return the original number
  return phoneNumber;
};
