import React, { useContext, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Campaigns.module.scss';
import PageHeader from '../../components/PageHeader';
import {
  AdsAccountFormFields,
  AdsFormFields,
  Brand,
  CampaignFormFields,
  FbAdsAccountData,
  FbCampaign,
} from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCampaign,
  createCampaignAds,
  fetchAdsAccountByBrand,
  fetchBrandCampaigns,
  fetchFbAdsAccount,
  fetchFbCampaigns,
  syncConnectedCampaigns,
  syncFbAdsAccount,
  syncFbBusinessAccount,
} from '../../services/ads';
import { AuthContext } from '../../context';
import {
  ADMIN,
  AGENCY,
  BRAND_MANAGER,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import { setBrand, toggleAlert } from '../../redux/actions';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { adsProvider } from '../../utils/constants/facebookAds';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Tab,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AdAccountForm from '../../components/Forms/Campaigns/AdAccountForm';
import {
  buildAccessToken,
  buildRefreshToken,
} from '../../utils/helpers/DefaultTokenBuilder';
import {
  fetchGoogleAdsAccount,
  fetchGoogleCampaigns,
  syncCustomerIds,
} from '../../services/googleAds';
import { arrayUniqueFilter } from '../../utils/arrayFormatter';
import CampaignForm from '../../components/Forms/Campaigns/CampaignForm';
import CircularLoading from '../../components/CircularLoading';
import { NoData } from '../NoContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PrimaryButton } from '../../components/Buttons';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';

const Campaigns: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const campaignProvider: string = useSelector(
    (state: any) => state?.campaignProvider?.campaignProvider,
  );
  const { state } = useContext(AuthContext);
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAdmin = state.role === ADMIN;
  const isAgency = state.role === AGENCY;
  const isSalesperson = state.role === SALESPERSON;
  const isBrandManager = state.role === BRAND_MANAGER;
  const roleBasedId = state.roleBasedId;
  const accessToken = state.authUser?.accessToken;
  const refreshToken = state.authUser?.refreshToken;
  const tabs = ['Connect Ad Accounts', 'Setup Campaigns'];
  const isFacebook = campaignProvider === adsProvider.FACEBOOK;
  const isGoogle = campaignProvider === adsProvider.GOOGLE;

  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<CampaignFormFields[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<string>('1');
  const [fbAdsAccounts, setFbAdsAccounts] = useState<AdsAccountFormFields[]>(
    [],
  );
  const [adAccounts, setAdAccounts] = useState<AdsAccountFormFields[]>([]);
  const [adsAccountFormValues, setAdsAccountFormValues] = useState<
    AdsAccountFormFields[]
  >([]);
  const [defaultAccessToken, setDefaultAccessToken] = useState<string>(null);
  const [defaultRefreshToken, setDefaultRefreshToken] = useState<string>(null);
  const [connectedAccountIds, setConnectedAccountIds] = useState<string[]>([]);
  const [googleAdAccounts, setGoogleAdAccounts] = useState<
    AdsAccountFormFields[]
  >([]);
  const [campaignFormValues, setCampaignFormValues] = useState<
    CampaignFormFields[]
  >([]);
  const [adsFormValues, setAdsFormValues] = useState<AdsFormFields[]>([]);
  const [defaultCampaigns, setDefaultCampaigns] = useState<
    CampaignFormFields[]
  >([]);
  const [providerCampaigns, setProviderCampaigns] = useState<
    CampaignFormFields[]
  >([]);
  const [selectedBrand, setSelectedBrand] = useState<Brand>(null);
  const [expanded, setExpanded] = useState<string>('panel1');

  useEffect(() => {
    handleBuildProviderTokens();
    if (location) return setSelectedBrand(location);

    if (brand) return setSelectedBrand(brand);
  }, [brand, location]);

  useEffect(() => {
    if (selectedBrand && campaignProvider) {
      getCampaigns();

      if (isFacebook && defaultAccessToken) {
        getAdsAccounts();
        syncBrandCampaigns();
        getProviderCampaigns();
        getFbCampaigns();
        getFbAdsAccounts(selectedBrand?.facebookBusinessIds || []);
      } else if (isGoogle && defaultRefreshToken) {
        getAdsAccounts();
        getProviderCampaigns();
        getGoogleCampaigns();
        getGoogleAdAccounts();
      }
    }
  }, [
    selectedBrand,
    campaignProvider,
    defaultAccessToken,
    defaultRefreshToken,
  ]);

  const syncBrandCampaigns = async () => {
    try {
      const obj = {
        brandId: selectedBrand?._id,
        campaigns,
      };
      syncConnectedCampaigns(obj);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getAdsAccounts = async () => {
    setLoading(true);

    try {
      const response = await fetchAdsAccountByBrand(
        selectedBrand?._id,
        campaignProvider,
      );

      let temp: AdsAccountFormFields[] = [];
      if (isFacebook) {
        temp = await response?.data.filter(
          (data: any) => data.provider === adsProvider.FACEBOOK,
        );
      } else if (isGoogle) {
        temp = await response?.data.filter(
          (data: any) => data.provider === adsProvider.GOOGLE,
        );
      }

      setAdsAccountFormValues(response?.data);
      setAdAccounts(temp);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const getCampaigns = async () => {
    setFetchLoading(true);
    try {
      const campaigns = await fetchBrandCampaigns(
        selectedBrand?._id,
        campaignProvider,
      );

      setCampaigns(campaigns.data);
      setFetchLoading(false);
    } catch (error: any) {
      setFetchLoading(false);
      console.log(error.message);
    }
  };

  const getProviderCampaigns = async () => {
    setLoading(true);
    try {
      const campaigns = await fetchBrandCampaigns(
        selectedBrand?._id,
        campaignProvider,
      );

      const accountIds = campaigns.data.map((obj: any) => obj.accountId);

      const filteredIds = arrayUniqueFilter(accountIds, 'accountId');

      setConnectedAccountIds(filteredIds);
      setCampaignFormValues(campaigns.data);
      setDefaultCampaigns(campaigns.data);
      setLoading(false);
      // Temporary Disabled
      //await getCampaignAds(brandCampaign);
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const getFbCampaigns = async () => {
    setLoading(true);
    try {
      const campaigns = await fetchFbCampaigns(selectedBrand?._id);

      let temp: any = [];
      campaigns.data.forEach(async (campaign: FbCampaign) => {
        const formattedObj: CampaignFormFields = {
          id: campaign.id,
          name: campaign.name,
          objective: campaign.objective,
          status: campaign.effective_status,
          accountId: campaign.account_id,
          provider: adsProvider.FACEBOOK,
        };
        const campaignValue = { ...campaign, ...formattedObj };
        temp = [...temp, campaignValue];
      });

      setProviderCampaigns(temp);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const getFbAdsAccounts = async (fbBusinessIds: string[]) => {
    setLoading(true);
    try {
      const response = await fetchFbAdsAccount(
        fbBusinessIds,
        selectedBrand?._id,
      );

      let newFormValues: any[] = [];

      response.data.forEach((adsAccount: FbAdsAccountData) => {
        const formattedObj: AdsAccountFormFields = {
          accountId: adsAccount.account_id,
          name: adsAccount.name,
          currency: adsAccount.currency,
          provider: adsProvider.FACEBOOK,
        };

        newFormValues = [...newFormValues, formattedObj];
      });

      setFbAdsAccounts(newFormValues);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const getGoogleCampaigns = async () => {
    setLoading(true);
    try {
      const campaigns = await fetchGoogleCampaigns(
        selectedBrand?._id,
        defaultRefreshToken,
      );

      setProviderCampaigns(campaigns?.data || []);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const getGoogleAdAccounts = async () => {
    setLoading(true);
    try {
      const response = await fetchGoogleAdsAccount(
        selectedBrand?._id,
        defaultRefreshToken,
      );

      setGoogleAdAccounts(response?.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSelectAdsAccount = async (
    value: AdsAccountFormFields[],
    brand: Brand,
    selectedId: string,
    reason: string,
    provider: string,
  ) => {
    let newFormValues: AdsAccountFormFields[] = [...adsAccountFormValues];

    let adsAccount = value.find((x: any) => {
      return x.accountId === selectedId;
    });

    if (reason === 'selectOption') {
      const index = newFormValues.findIndex((x: any) => {
        return x.accountId === adsAccount.accountId;
      });

      if (index < 0) {
        adsAccount = { ...adsAccount, brand: brand._id, provider };
        newFormValues = [...newFormValues, adsAccount];
      } else {
        newFormValues[index].deleted = false;
        newFormValues[index].brand = brand._id;
      }
    }

    if (reason === 'removeOption') {
      const index = newFormValues.findIndex((x: any) => {
        return x.accountId === selectedId;
      });

      newFormValues[index].deleted = true;
    }

    setAdsAccountFormValues(newFormValues);
    handleSyncFbAdsAccount(newFormValues);
  };

  const handleSyncFbAdsAccount = async (adAccounts: AdsAccountFormFields[]) => {
    setLoading(true);
    try {
      const response = await syncFbAdsAccount(adAccounts);

      if (response) {
        let temp: AdsAccountFormFields[] = [];
        if (isFacebook) {
          getFbCampaigns();

          temp = response?.data.filter(
            (data: any) => data.provider === adsProvider.FACEBOOK,
          );
        } else if (isGoogle) {
          getGoogleCampaigns();
          temp = response?.data.filter(
            (data: any) => data.provider === adsProvider.GOOGLE,
          );
        }

        setAdAccounts(temp);
        setLoading(false);
        dispatch(
          toggleAlert({
            toggle: true,
            message: response.message,
          }),
        );
      }
    } catch (error: any) {
      setLoading(false);
      const err = error.response.data;
      dispatch(
        toggleAlert({
          toggle: true,
          message: err?.message,
          type: 'error',
        }),
      );
    }
  };

  const handleSyncBusinessIds = async (value: any[], brand: Brand) => {
    try {
      setLoading(true);
      const data = await syncFbBusinessAccount(value, brand._id);

      let temp: Brand = { ...brand };

      temp = {
        ...temp,
        facebookBusinessIds: data?.data.facebookBusinessIds,
      };

      dispatch(setBrand(temp));

      getFbAdsAccounts(data?.data.facebookBusinessIds);
      setLoading(false);
    } catch (error: any) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleSubmitCampaigns = async (formValues: CampaignFormFields[]) => {
    setLoading(true);
    try {
      const response = await createCampaign(formValues);

      const adsAccountIds = response.data.map((obj: any) => obj.accountId);
      const filteredIds = arrayUniqueFilter(adsAccountIds, 'accountId');

      setCampaignFormValues(response.data);

      if (adsFormValues.length > 0) {
        const campaignAdsData = await createCampaignAds(adsFormValues);
        setAdsFormValues(campaignAdsData.campaignAds);
      }

      setConnectedAccountIds(filteredIds);
      getCampaigns();
      setLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: response.message,
        }),
      );
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.message,
          type: 'error',
        }),
      );
    }
  };

  const handleBuildProviderTokens = () => {
    buildAccessToken(
      isSuperAdmin || isAdmin,
      isAgency,
      isBrandManager || isSalesperson,
      accessToken,
      roleBasedId,
      setDefaultAccessToken,
    )
      .then(() => {
        buildRefreshToken(
          isSuperAdmin || isAdmin,
          isAgency,
          isBrandManager || isSalesperson,
          refreshToken,
          roleBasedId,
          setDefaultRefreshToken,
        );
      })
      .catch((error: any) => {
        console.log(error.message);
      });
  };

  const handleSyncCustomerIds = async (value: string[], brand: Brand) => {
    try {
      setLoading(true);
      const data = await syncCustomerIds(brand._id, value);

      let temp: Brand = { ...brand };

      temp = {
        ...temp,
        googleCustomerIds: data?.data.googleCustomerIds,
      };

      dispatch(setBrand(temp));

      getGoogleAdAccounts();
      setLoading(false);
    } catch (error: any) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleOnSelectCampaign = async (
    campaignAds: any[],
    brand: Brand,
    selectedCampaign: string,
    reason: string,
  ) => {
    let newFormValue: CampaignFormFields[] = [...campaignFormValues];
    const campaign = campaignAds.find((x: any) => {
      return x.id === selectedCampaign;
    });
    if (reason === 'selectOption') {
      const existingCampaign = newFormValue.find((x: any) => {
        return x.id === campaign.id;
      });

      if (existingCampaign) {
        const index = newFormValue.findIndex((formValue: any) => {
          return formValue.id === selectedCampaign;
        });

        newFormValue[index].deleted = false;
      } else {
        //const ads = await getFbCampaignAds(campaign?.id);

        const formObj: CampaignFormFields = {
          brand: brand?._id,
          deleted: false,
          status: campaign.status,
        };

        const formattedObj: CampaignFormFields = { ...campaign, ...formObj };
        newFormValue = [...newFormValue, formattedObj];
      }
    }

    if (reason === 'removeOption') {
      const index = newFormValue.findIndex((formValue: any) => {
        return formValue.id === selectedCampaign;
      });

      newFormValue[index].deleted = true;

      /*
      const removedCampaign = newFormValue[index];

      let newAdsFormValues: any[] = [];
      adsFormValues.forEach((ad: any, index: number) => {
        if (ad.campaignId === removedCampaign.id) {
          const formattedAdsObj = { ...ad, deleted: true };
          newAdsFormValues = [...newAdsFormValues, formattedAdsObj];
        }

        if (index + 1 === adsFormValues.length) {
          setAdsFormValues(newAdsFormValues);
        }
      });
      */
    }

    handleSubmitCampaigns(newFormValue);
  };

  const handleChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setTabValue(newValue);
  };

  const handleOnExpand = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };

  const renderContent = (tab: string) => {
    if (tab === 'Connect Ad Accounts') {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AdAccountForm
              fbAdsAccounts={fbAdsAccounts}
              defaultFormValues={adAccounts}
              setDefaultFormValues={setAdAccounts}
              onSelectAdsAccount={handleSelectAdsAccount}
              brand={selectedBrand}
              connectedAccountsIds={connectedAccountIds}
              handleSync={handleSyncBusinessIds}
              handleSyncGoogleCustomerIds={handleSyncCustomerIds}
              googleAdAccounts={googleAdAccounts}
              isFacebook={isFacebook}
              isGoogle={isGoogle}
            />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {adAccounts.filter(
            (obj: AdsAccountFormFields) => !(obj.deleted === true),
          ).length > 0 ? (
            <CampaignForm
              campaigns={providerCampaigns}
              campaignFormValues={campaignFormValues}
              defaultFormValues={defaultCampaigns}
              setDefaultValue={setDefaultCampaigns}
              brand={selectedBrand}
              onSelectCampaign={handleOnSelectCampaign}
              provider={campaignProvider}
            />
          ) : (
            <NoData />
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={styles.campaigns}>
      <div className={styles.base}>
        <CircularLoading loading={loading} />

        <div className={styles.header}>
          <PageHeader title="Campaigns" />

          <div className={styles.controls}>
            <div className={styles.buttons}>
              <PrimaryButton
                type="button"
                title="Ads Manager"
                marginRight5
                startIcon={<SettingsIcon />}
                handleOnClick={() => navigate('/ads-manager')}
              />
            </div>
          </div>
        </div>

        <div>
          {!isMdAndDown ? (
            <TabContext value={tabValue}>
              <TabList
                onChange={handleChangeTabValue}
                variant="scrollable"
                allowScrollButtonsMobile
                scrollButtons
                textColor="secondary"
                TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
                TabScrollButtonProps={{
                  style: { backgroundColor: '#096f4d', color: 'white' },
                }}
                sx={{
                  backgroundColor: '#096f4d',
                  '.MuiTab-root': {
                    top: 5,
                    color: 'white',
                  },
                  '.Mui-selected': {
                    color: '#096f4d !important',
                    backgroundColor: 'white',
                    top: 5,
                    borderRadius: 2,
                  },
                }}
              >
                {tabs.map((tab: string, index: number) => (
                  <Tab label={tab} value={`${index + 1}`} />
                ))}
              </TabList>

              {tabs.map((tab: string, index: number) => (
                <TabPanel
                  value={`${index + 1}`}
                  sx={{
                    backgroundColor: '#d9d9d933',
                    minHeight: 'calc(100vh - 310px)',
                  }}
                >
                  {renderContent(tab)}
                </TabPanel>
              ))}
            </TabContext>
          ) : (
            <Box component="div" sx={{ marginTop: '10px' }}>
              {tabs.map((tab: string, index: number) => {
                return (
                  <Accordion
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleOnExpand(`panel${index + 1}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                      aria-controls={`panel${index + 1}a-content`}
                      id={`panel${index + 1}a-header`}
                      sx={{ backgroundColor: '#096f4d' }}
                    >
                      <Typography sx={{ color: 'white' }}>{tab}</Typography>
                    </AccordionSummary>

                    <AccordionDetails sx={{ backgroundColor: '#d9d9d933' }}>
                      {renderContent(tab)}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
