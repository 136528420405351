import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Brand } from '../../../types';
import { XsOnly } from '../../../utils/breakpoints';
import { CustomLoadingOverlay } from '../../Loading';
import {
  locationAdminColumns,
  locationMobileColumns,
} from './LocationListTableColumn';

const styledDataGrid = () => {
  let baseStyle = {
    '.css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
      backgroundColor: '#096f4d',
      color: 'white',
    },
    width: '100%',
  };

  if (!XsOnly()) {
    return baseStyle;
  }

  return {
    ...baseStyle,
    overflow: 'hidden',
  };
};

interface LocationListTableProps {
  locations: Brand[];
  handleOnClickDelete: (id: string) => void;
  loading: boolean;
  isAdmin: boolean;
  isAgency: boolean;
  isSalesperson: boolean;
  page: number;
  setPage: (page: number) => void;
  rowData: number;
  setLocationPage: (pageNum: number, limit: number) => void;
  currentLimit: number;
  pageSize: number;
  setLocationSize: (pageNum: number, limit: number) => void;
  isBrandManager: boolean;
  onSort: (sortedField: string, sortType: string) => void;
  agencyId: string;
  agencyName: string;
  onClickEdit: (locationId: string) => void;
}

const LocationListTable: React.FC<LocationListTableProps> = ({
  locations,
  handleOnClickDelete,
  loading,
  isAdmin,
  isAgency,
  isSalesperson,
  page,
  setPage,
  rowData,
  setLocationPage,
  currentLimit,
  pageSize,
  setLocationSize,
  isBrandManager,
  onSort,
  agencyId,
  agencyName,
  onClickEdit,
}) => {
  const navigate = useNavigate();

  const renderColumns = () => {
    if (XsOnly()) {
      return locationMobileColumns;
    } else {
      return locationAdminColumns;
    }
  };

  const rows = locations.slice().map((location: Brand, index: number) => {
    return {
      brand: location.brand,
      agency: location.agency,
      investmentRequirement: location.investmentRequirement,
      allLeads: location.allLeads,
      zipcodereq: location.zipcodereq,
      financialreq: location.financialreq,
      redirect: location.redirect,
      redirectlink: location.redirectlink,
      redirecttext: location.redirecttext,
      industryRequirement: location.industryRequirement,
      industryQuestion: location.industryQuestion,
      schedulecall: location.schedulecall,
      calendly: location.calendly,
      salesperson: location.salesperson,
      manager: location.manager,
      salespersonTitle: location.salespersonTitle,
      _id: location._id,
      id: location.brandId,
      phoneNumReq: location.phoneNumReq,
      budgetOffset: location.budgetOffset || 100,
      clientTetherConfig: location.clientTetherConfig,
      isAdmin,
      isSalesperson,
      isBrandManager,
      isAgency,
      cannedResponses: location.cannedResponses,
      active: location.active,
      paused: location.paused,
      lastPausedAt: location.lastPausedAt,
      page: {
        _id: location.page?._id,
        name: location.page?.name,
        accessToken: location.page?.accessToken,
        fbPageId: location.page?.fbPageId,
        imageUrl: location.page?.imageUrl,
      },
      facebookBusinessIds: location.facebookBusinessIds,
      googleCustomerIds: location.googleCustomerIds,
      allowLocations: location.allowLocations,
      franchisor: location.franchisor,
      allowSetupDepositAccount: location.allowSetupDepositAccount,
      allowSetupPayment: location.allowSetupPayment,
      allowOptions: location.allowOptions,
      __type: location.__type,
      stripe: location.stripe,
      handleOnClickDelete: handleOnClickDelete,
      navigate: navigate,
      agencyId: agencyId,
      agencyName: agencyName,
      onClickEdit,
    };
  });

  return (
    <div>
      <Box>
        <DataGrid
          density="compact"
          rows={rows}
          columns={renderColumns()}
          autoHeight
          hideFooterSelectedRowCount
          pageSize={pageSize}
          rowCount={rowData}
          rowsPerPageOptions={[25, 50, 100]}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
          }}
          loading={loading}
          page={page}
          onPageChange={(page) => {
            setPage(page);
            setLocationPage(page + 1, currentLimit);
          }}
          onPageSizeChange={(newLimit) => {
            setLocationSize(1, newLimit);
          }}
          onSortModelChange={(e: any) => {
            onSort(e[0]?.field || 'brand', e[0]?.sort || 'asc');
          }}
          sortingMode="server"
          sx={styledDataGrid()}
        />
      </Box>
    </div>
  );
};

export default LocationListTable;
