import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";
import { ChatUser } from "../../../types";
import { SALESPERSON } from "../../../utils";
import {
  columns,
  mobileColumns,
  salespersonColumn,
} from "./ChatsListTableColumns";
import { XsOnly } from "../../../utils/breakpoints";
import { CustomLoadingOverlay } from "../../Loading";
import styles from "../../../assets/styles/components/Tables/Tables.module.scss";

interface ChatListTableProps {
  chats: ChatUser[];
  loading: boolean;
  currentLimit: number;
  setPage: (page: number) => void;
  rowData: number;
  page: number;
  setChatPage: (page: any, limit: any) => void;
  setChatSize: (pageNum: any, limit: any) => void;
}

const ChatsListTable: React.FC<ChatListTableProps> = ({
  chats,
  loading,
  currentLimit,
  setPage,
  rowData,
  page,
  setChatPage,
  setChatSize,
}) => {
  const { state } = useContext(AuthContext);
  const isSalesperson = state.role === SALESPERSON;
  const navigate = useNavigate();
  const rows = chats.slice().map((chat: ChatUser, index: number) => {
    return {
      brand: chat.brand,
      brandId: chat.brandId,
      broker: chat.broker,
      calendlyeventdate: chat.calendlyeventdate,
      candidateemail: chat.candidateemail,
      candidatephone: chat.candidatephone,
      chatId: chat.chatId,
      chatuserId: chat.chatuserId,
      createdAt: chat.createdAt,
      email: chat.email,
      firstname: chat.firstname,
      gender: chat.gender,
      industryexperience: chat.industryexperience,
      investment: chat.investment,
      isFollowupEnabled: chat.isFollowupEnabled,
      language: chat.language,
      lastInteraction: chat.lastInteraction,
      lastSeen: chat.lastSeen,
      lastname: chat.lastname,
      name: chat.name,
      pageId: chat.pageId,
      profilePic: chat.profilePic,
      result: chat.result,
      salesid: chat.salesid,
      status: chat.status,
      subscribed: chat.subscribed,
      tellusaboutyourself: chat.tellusaboutyourself,
      territory: chat.territory,
      timestamp: chat.timestamp,
      timezone: chat.timezone,
      updatedAt: chat.updatedAt,
      whentocall: chat.whentocall,
      chatSeenAt: chat.chatSeenAt,
      partialEngagement: chat.partialEngagement,
      completedEngagement: chat.completedEngagement,
      isSalesperson: isSalesperson,
      lastEngagedAt: chat.lastEngagedAt,
      __type: chat.__type,
      __v: chat.__v,
      _id: chat._id,
      id: chat._id,
      navigate: navigate,
    };
  });

  const renderColumns = () => {
    if (isSalesperson) {
      if (XsOnly()) {
        return mobileColumns;
      } else {
        return salespersonColumn;
      }
    } else {
      if (XsOnly()) {
        return mobileColumns;
      }

      return columns;
    }
  };

  return (
    <Box className={styles.table}>
      <DataGrid
        density="compact"
        rows={rows}
        onPageChange={(newPage) => {
          setPage(newPage);
          setChatPage(newPage + 1, currentLimit);
        }}
        onPageSizeChange={(newLimit) => {
          setChatSize(1, newLimit);
        }}
        columns={renderColumns()}
        autoHeight
        hideFooterSelectedRowCount
        pageSize={currentLimit}
        rowsPerPageOptions={[25, 50, 100]}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
        }}
        rowCount={rowData}
        loading={loading}
        page={page}
        rowHeight={XsOnly() ? 70 : 50}
      />
    </Box>
  );
};

export default ChatsListTable;
