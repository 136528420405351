import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DeleteModal } from '../../components/Modal';
import { Brand, ChangeEventType } from '../../types';
import {
  deleteBrand,
  getBrandsAssociatedLocations,
} from '../../services/brand';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context';
import {
  ADMIN,
  AGENCY,
  BRAND_MANAGER,
  IS_DIY_ADZ,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import { PrimaryButton } from '../../components/Buttons';
import styles from '../../assets/styles/pages/Brands.module.scss';
import PageHeader from '../../components/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import LocationListTable from '../../components/Tables/BrandList/LocationListTable';
import { toggleAlert } from '../../redux/actions';

const Locations: React.FC = () => {
  const dispatch = useDispatch();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const { state } = useContext(AuthContext);
  const mountedRef = useRef(true);
  const [searchParams] = useSearchParams();
  const agencyId: string = searchParams.get('agencyId');
  const agencyName: string = searchParams.get('agency');
  const [rowData, setRowData] = useState<number>(0);
  const isAdmin = state.role === ADMIN;
  const isSalesperson = state.role === SALESPERSON;
  const isAgency = state.role === AGENCY;
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isBrandManager = state.role === BRAND_MANAGER;
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [locations, setLocations] = useState<Brand[]>([]);
  const [locationToDelete, setLocationToDelete] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentLimit, setCurrentLimit] = useState<number>(25);
  const [page, setPage] = useState(0);
  const [locationFilter, setLocationFilter] = useState<string>('active');
  const [sortedFields, setSortedField] = useState<string>('brand');
  const [sortType, setSortType] = useState<string>('asc');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (brand) {
      setLocations([]);
      getAllLocations(
        currentPage,
        currentLimit,
        locationFilter,
        sortedFields,
        sortType,
        false,
      );
    }

    return () => {
      mountedRef.current = false;
    };
  }, [brand]);

  const resetLocations = async (
    response: any,
    page: number,
    limit: number,
    filter: string,
    fromSort: boolean,
  ) => {
    if (currentLimit !== limit || locationFilter !== filter || fromSort) {
      setLocations(response.data);
    } else {
      if (currentPage === page) {
        setLocations(response.data);
      } else {
        setLocations([...locations, ...response.data]);
      }
    }

    setRowData(response.count);
  };

  const getAllLocations = async (
    pageNum: number,
    limit: number,
    filter: string,
    sortedField: string,
    sortType: string,
    fromSort: boolean,
  ) => {
    setLoading(true);

    const response = await getBrandsAssociatedLocations(
      brand?._id,
      pageNum,
      limit,
      filter,
      sortedField,
      sortType,
    );

    resetLocations(response, pageNum, limit, filter, fromSort);

    setLoading(false);
  };

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleOnClickDelete = (id: string) => {
    setOpenDeleteModal(true);
    setLocationToDelete(id);
  };

  const handleDeleteLocation = async () => {
    setDeleteLoading(true);
    const { id } = await deleteBrand(locationToDelete);
    if (id) {
      setLocations(locations.filter((s) => s._id !== id));
      setOpenDeleteModal(false);
      setDeleteLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Brand deleted successfully',
        }),
      );
    }
  };

  const setLocationPage = async (page: any, limit: any) => {
    if (page > currentPage) setCurrentPage(page);
    if (currentPage < page) {
      await getAllLocations(
        page,
        limit,
        locationFilter,
        sortedFields,
        sortType,
        false,
      );
    }
  };

  const setLocationSize = async (pageNum: any, limit: any) => {
    setPage(0);
    setCurrentPage(1);
    await getAllLocations(
      pageNum,
      limit,
      locationFilter,
      sortedFields,
      sortType,
      false,
    );

    setCurrentLimit(limit);
  };

  const setFilter = async (filter: string) => {
    setLocationFilter(filter);
    setPage(0);
    setCurrentPage(1);
    setCurrentLimit(25);
    await getAllLocations(1, 25, filter, sortedFields, sortType, false);
  };

  const setSort = async (sortedField: string, sortType: string) => {
    setSortedField(sortedField);
    setSortType(sortType);
    await getAllLocations(
      currentPage,
      currentLimit,
      locationFilter,
      sortedField,
      sortType,
      true,
    );
  };

  const handleClickEdit = (locationId: string) => {
    navigate(
      `/${
        IS_DIY_ADZ ? 'locationz' : 'locations'
      }/edit?locationId=${locationId}`,
    );
  };

  return (
    <div className={styles.brand}>
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Locations" />

          <div className={styles.controls}>
            <RadioGroup
              row
              value={locationFilter}
              onChange={(e: ChangeEventType) => setFilter(e.target.value)}
            >
              <FormControlLabel
                value="active"
                control={<Radio size="small" />}
                label="Active"
              />

              <FormControlLabel
                value="inactive"
                control={<Radio size="small" />}
                label="Inactive"
              />
            </RadioGroup>

            <div
              className={`${
                !isSuperAdmin && !isAdmin ? styles['buttons'] : ''
              }`}
            >
              <PrimaryButton
                type="button"
                handleOnClick={() =>
                  navigate(`/${IS_DIY_ADZ ? 'locationz' : 'locations'}/create`)
                }
                title="Add New Location"
                startIcon={<AddIcon />}
              />
            </div>
          </div>
        </div>

        <div>
          <LocationListTable
            locations={locations}
            isAdmin={isAdmin || isSuperAdmin}
            isAgency={isAgency}
            isSalesperson={isSalesperson}
            isBrandManager={isBrandManager}
            handleOnClickDelete={handleOnClickDelete}
            loading={loading}
            page={page}
            setPage={setPage}
            rowData={rowData}
            setLocationPage={setLocationPage}
            currentLimit={currentLimit}
            pageSize={currentLimit}
            setLocationSize={setLocationSize}
            onSort={setSort}
            agencyId={agencyId}
            agencyName={agencyName}
            onClickEdit={handleClickEdit}
          />
        </div>

        <DeleteModal
          title="Delete Location"
          subtitle="Are you want to delete this location, It'll remove all data belongs to it permanently!"
          open={openDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={handleDeleteLocation}
          loading={deleteLoading}
        />
      </div>
    </div>
  );
};

export default Locations;
