import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  leadColumns,
  messengerAdsColumns,
  salesColumns,
  trafficColumns,
} from './CampaignRegionOverviewColumns';
import { Breakdown } from '../../../types';
import { styledDataGrid } from '../../../utils/helpers/DataGridHelper';

interface CampaignOverviewProps {
  data?: Breakdown;
  campaignType: string;
  breakdownType: string;
}

const CampaignRegionOverview: React.FC<CampaignOverviewProps> = ({
  data,
  campaignType,
  breakdownType,
}) => {
  const isLeadAds = campaignType === 'lead';
  const isTrafficAds = campaignType === 'traffic';
  const isSalesAds = campaignType === 'sales';
  const isRegion = breakdownType === 'region';
  const isDma = breakdownType === 'dma';
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));

  const rowData = (
    isRegion
      ? data?.region || []
      : isDma
      ? data?.dma || []
      : data?.platform || []
  ).map((row: any, index: number) => {
    return {
      id: index,
      region: row?.region,
      clicks: row.clicks,
      impressions: row.impressions,
      cpm: row.impressions > 0 ? (row.spend / row.impressions) * 1000 : 0,
      leads: row.leads,
      cpl: row.leads > 0 ? row.spend / row.leads : 0,
      spend: row.spend,
      objective: row.objective,
      cpc: row.clicks > 0 ? row.spend / row.clicks : 0,
      platform: row?.platform,
      dma: row?.dma,
    };
  });

  const renderColumns = () => {
    if (isLeadAds) {
      return leadColumns(breakdownType, isMdAndDown);
    }

    if (isTrafficAds) {
      return trafficColumns(breakdownType, isMdAndDown);
    }

    if (isSalesAds) {
      return salesColumns(breakdownType, isMdAndDown);
    }

    return messengerAdsColumns(breakdownType, isMdAndDown);
  };

  return (
    <div>
      <Box>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'leads', sort: 'desc' }],
            },
          }}
          sx={styledDataGrid()}
          density="compact"
          rows={rowData}
          columns={renderColumns()}
          autoHeight
          hideFooter
        />
      </Box>
    </div>
  );
};

export default CampaignRegionOverview;
