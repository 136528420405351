import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { Agency, InviteInfo } from '../../../types';
import { adminColumns, columns } from './AgencyListTableColumns';
import { useNavigate } from 'react-router-dom';
import { CustomLoadingOverlay } from '../../Loading';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { useMediaQuery, useTheme } from '@mui/material';
import { styledDataGrid } from '../../../utils/helpers/DataGridHelper';

interface AgencyListTableProps {
  agencies: Agency[];
  handleOnClickEdit: (agency: Agency) => void;
  handleOnClickDelete: (id: string) => void;
  handleOnClickInviteBtn: (info: InviteInfo) => void;
  loading: boolean;
  handleBanAgency: (id: string) => void;
  handleUnbanAgency: (id: string) => void;
  setPage: (page: number) => void;
  rowData: number;
  setAgencyPage: (pageNum: number, limit: number) => void;
  currentLimit: number;
  pageSize: number;
  setAgencySize: (pageNum: number, limit: number) => void;
  page: number;
  isAdmin: boolean;
  onSort: (sortedField: string, sortType: string) => void;
}

const AgencyListTable: React.FC<AgencyListTableProps> = ({
  agencies,
  handleOnClickEdit,
  handleOnClickDelete,
  handleOnClickInviteBtn,
  loading,
  handleBanAgency,
  handleUnbanAgency,
  setPage,
  rowData,
  setAgencyPage,
  currentLimit,
  pageSize,
  setAgencySize,
  page,
  isAdmin,
  onSort,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));

  const rows = agencies.slice().map((agency: Agency, index: number) => {
    return {
      agency: agency.agency,
      name: agency.name,
      email: agency.email,
      cell: agency.cell,
      url: agency.url,
      logo: agency.logo,
      user: agency.user,
      theme: {
        theme: agency.theme.theme,
        properties: { ...agency.theme.properties },
      },
      _id: agency._id,
      id: agency.agencyId,
      fbLoginEmails: agency.fbLoginEmails,
      page: {
        _id: agency.page?._id,
        name: agency.page?.name,
        accessToken: agency.page?.accessToken,
        fbPageId: agency.page?.fbPageId,
        imageUrl: agency.page?.imageUrl,
      },
      allowSetupPayment: agency.allowSetupPayment || false,
      handleOnClickEdit: handleOnClickEdit,
      handleOnClickDelete: handleOnClickDelete,
      handleOnClickInviteBtn: handleOnClickInviteBtn,
      navigate: navigate,
      handleBanAgency,
      handleUnbanAgency,
      withUser: agency.user !== undefined && agency.user !== null,
    };
  });

  const renderColumns = () => {
    if (isAdmin) {
      return adminColumns(isMdAndDown);
    }

    return columns(isMdAndDown);
  };

  return (
    <Box className={styles.table}>
      <DataGrid
        density="compact"
        rows={rows}
        columns={renderColumns()}
        autoHeight
        hideFooterSelectedRowCount
        pageSize={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
        }}
        loading={loading}
        page={page}
        onPageChange={(page) => {
          setPage(page);
          setAgencyPage(page + 1, currentLimit);
        }}
        onPageSizeChange={(newLimit) => {
          setAgencySize(1, newLimit);
        }}
        rowCount={rowData}
        onSortModelChange={(e: any) => {
          onSort(e[0]?.field || 'agency', e[0]?.sort || 'asc');
        }}
        sortingMode="server"
        sx={styledDataGrid()}
      />
    </Box>
  );
};

export default AgencyListTable;
