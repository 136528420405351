import { DateRangePicker as Picker } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Moment } from 'moment';
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setEndRange } from '../../redux/actions';

interface DateRangePickerProps {
  fromDate: Moment | null;
  toDate: Moment | null;
  onChange: (newValue: Moment[] | []) => void;
  maxDate?: Moment | null;
  separator?: string;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  fromDate,
  toDate,
  onChange,
  maxDate,
  separator = 'to',
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOnOpen = (start: boolean) => {
    if (start) {
      dispatch(setEndRange(null));
    }

    setOpen(true);
  };

  /**
   * React MUI Date Range Picker
   * @author Angelo David <angelod@codev.com>
   * @since 1.12.6
   * @reference https://v5-0-6.mui.com/components/date-range-picker/
   */
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Picker
        open={open}
        startText="Start Range"
        endText="End Range"
        value={[fromDate, toDate]}
        onChange={onChange}
        onClose={handleOnClose}
        maxDate={maxDate}
        onError={(e) => toast.error(e)}
        allowSameDateSelection
        calendars={2}
        renderInput={(startProps, endProps) => (
          <Fragment>
            <TextField
              {...startProps}
              size="small"
              variant="standard"
              onClick={() => handleOnOpen(true)}
            />

            <Box sx={{ mx: 2 }}>{separator}</Box>

            <TextField
              {...endProps}
              size="small"
              variant="standard"
              onClick={() => handleOnOpen(false)}
            />
          </Fragment>
        )}
      />
    </LocalizationProvider>
  );
};

export default DateRangePicker;
