import { GridOverlay } from '@mui/x-data-grid';
import LinearProgressBar from './LinearProgressBar';
import styles from '../assets/styles/components/Loading.module.scss';

export function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div className={styles.loading}>
        <LinearProgressBar />
      </div>
    </GridOverlay>
  );
}
