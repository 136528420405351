import { Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { AdVideo, Brand, Campaign, CampaignAd, FacebookAd } from '../../types';
import ModalHeader from './ModalHeader';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  constantStringToHumanized,
  copyToClipboard,
} from '../../utils/stringModifier';
import parse from 'html-react-parser';
import CircularLoading from '../CircularLoading';
import styles from '../../assets/styles/components/Modal/Modal.module.scss';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';
import { AdsTemplate, DiySettings } from '../../types/IDiy';
import {
  fetchSingleCampaignTemplate,
  getSingleDiySetting,
} from '../../services/diy';
import { IS_DIY_ADZ } from '../../utils';
import {
  fetchBrandCampaigns,
  fetchSingleFbAdAccountVideo,
} from '../../services/ads';
import { AdsPreviewSquare } from '../Forms/Diy/AdsPreview';

interface FacebookAdPreviewModalProps {
  open: boolean;
  onClose: () => void;
  fbAd: FacebookAd;
  previewIframe: string;
  loading: boolean;
  brand: Brand;
}
const FacebookAdPreviewModal: React.FC<FacebookAdPreviewModalProps> = ({
  open,
  onClose,
  fbAd,
  previewIframe,
  loading,
  brand,
}) => {
  const dispatch = useDispatch();
  const [diySettings, setDiySettings] = useState<DiySettings>(null);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [template, setTemplate] = useState<AdsTemplate>(null);
  const [adVideo, setAdVideo] = useState<AdVideo>(null);

  useEffect(() => {
    if (open && IS_DIY_ADZ) {
      getDiySetting();
      getCampaigns();
    }
  }, [open, IS_DIY_ADZ]);

  useEffect(() => {
    if (!open) {
      setTemplate(null);
      setDiySettings(null);
    }
  }, [open]);

  const getDiySetting = async () => {
    setFetchLoading(true);
    try {
      const response = await getSingleDiySetting(brand?._id);

      setDiySettings(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const getCampaigns = async () => {
    setFetchLoading(true);
    try {
      const campaigns = await fetchBrandCampaigns(brand?._id, 'facebook');

      let ads: any[] = [];
      campaigns.data.forEach((campaign: Campaign) => {
        ads = [...ads, ...(campaign?.ads || [])];
      });

      const ad = ads.find((ad: CampaignAd) => {
        return ad?.id === fbAd?.id;
      });

      const template = await fetchSingleCampaignTemplate(ad?.template);

      if (ad?.media === 'video') {
        getVideo(
          template?.data?.creativeTemplate?.asset_feed_spec?.videos[0]
            ?.video_id,
        );
      }

      setTemplate(template.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const getVideo = async (videoId: string) => {
    setFetchLoading(true);
    try {
      const response = await fetchSingleFbAdAccountVideo(brand?._id, videoId);

      setAdVideo(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleOpenToast = () => {
    dispatch(
      toggleAlert({
        toggle: true,
        message:
          'Copied to clipboard. Open a new tab and paste the link to see the preview.',
        vertical: 'top',
        horizontal: 'center',
      }),
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '600px',
          overflowY: 'auto',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-track': {
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#096f4d',
            borderRadius: '18px',
            '&:hover': {
              background: '#096f4d',
            },
          },
        }}
      >
        <div className={styles.modal}>
          <CircularLoading loading={loading || fetchLoading} />

          <div>
            <ModalHeader title={`${fbAd?.name} Ad Preview`} isCenter isLarge />
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={fbAd?.preview_shareable_link}
                disabled
                sx={{
                  width: '100%',
                  '& input.Mui-disabled': {
                    color: 'black',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        copyToClipboard(fbAd?.preview_shareable_link);
                        handleOpenToast();
                      }}
                    >
                      <ContentCopyOutlinedIcon sx={{ color: '#1976d2' }} />
                    </IconButton>
                  ),
                }}
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ textAlign: 'center', fontWeight: 'bold' }}
              >
                Preview
              </Typography>
            </Grid>

            <Grid item xs={12} mb={2}>
              <Typography
                variant="body2"
                sx={{ textAlign: 'center', color: 'gray' }}
              >
                * This is only a mock preview. Click the copy button above to
                view the live ad.
              </Typography>
            </Grid>
          </Grid>

          {IS_DIY_ADZ ? (
            <>
              {!fetchLoading ? (
                <AdsPreviewSquare
                  page={diySettings?.facebookPage}
                  body={
                    template?.creativeTemplate?.asset_feed_spec?.bodies[0]?.text
                  }
                  isImage={template?.media === 'image'}
                  imageUrl={template?.images?.square}
                  websiteUrl={
                    template?.creativeTemplate?.asset_feed_spec?.link_urls[0]
                      ?.website_url
                  }
                  headline={
                    template?.creativeTemplate?.asset_feed_spec?.titles[0]?.text
                  }
                  description={
                    template?.creativeTemplate?.asset_feed_spec?.descriptions[0]
                      ?.text
                  }
                  cta={constantStringToHumanized(
                    template?.creativeTemplate?.asset_feed_spec
                      ?.call_to_action_types[0] || '',
                  )}
                  videoUrl={adVideo?.source}
                  objective={template?.campaignTemplate?.objective}
                />
              ) : null}
            </>
          ) : (
            <div className={styles.iframe}>{parse(previewIframe)}</div>
          )}
        </div>
      </Box>
    </Modal>
  );
};
export default FacebookAdPreviewModal;
