import { Box, Card } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { ScoreboardTemplateData } from '../../../types';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { CustomLoadingOverlay } from '../../Loading';
import { styledDataGrid } from '../../../utils/helpers/DataGridHelper';
import { templateOveviewColumns } from './TemplateOverviewTableColumn';

interface TemplateOverviewTableProps {
  templateAnalytics: ScoreboardTemplateData[];
}

const TemplateOverviewTable: React.FC<TemplateOverviewTableProps> = ({
  templateAnalytics,
}) => {
  const rows = templateAnalytics.map((data: ScoreboardTemplateData) => {
    return {
      id: data.templateId,
      template: data.template,
      spend: data.spend,
      leads: data.leads || 0,
      visitors: data.visitors || 0,
      cpl: data.cpl,
      cpv: data.cpv,
      usageCount: data.usageCount,
      averageSpend: data.averageSpend,
    };
  });

  return (
    <Card sx={{ padding: '20px' }}>
      <Box className={styles.table}>
        <DataGrid
          density="compact"
          rows={rows.sort((a, b) => b.usageCount - a.usageCount)}
          columns={templateOveviewColumns()}
          autoHeight
          hideFooterSelectedRowCount
          components={{ LoadingOverlay: CustomLoadingOverlay }}
          sx={styledDataGrid()}
        />
      </Box>
    </Card>
  );
};

export default TemplateOverviewTable;
