import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import { buildRowData } from '../../../utils/helpers/BrandAnalyticsHelper';
import { toCurrency } from '../../../utils/numberFormatter';
import { IconButton, TableBody } from '@mui/material';
import { styledPaper } from '../../../utils/helpers/Modal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#096f4d',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.common.white,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface CampaignOverviewProps {
  rows: any[];
  campaigns?: any[];
  isSummary?: boolean;
  lead?: number;
  ads?: any[];
  adsets?: any[];
  isCampaign?: boolean;
  isAds?: boolean;
  isAdset?: boolean;
  leadAds?: any;
  messengerAds?: any;
  trafficAds?: any;
  onGeneratePreview?: (adId: string) => void;
  salesAds?: any;
  showQL?: boolean;
}

const CampaignOverview: React.FC<CampaignOverviewProps> = ({
  rows,
  lead = 0,
  campaigns = [],
  ads = [],
  isSummary = false,
  isCampaign = false,
  isAds = false,
  leadAds,
  messengerAds,
  trafficAds,
  isAdset = false,
  adsets = [],
  onGeneratePreview,
  salesAds,
  showQL = false,
}) => {
  const rowData = () => {
    return buildRowData(
      isCampaign,
      lead,
      isAds,
      isSummary,
      isAdset,
      campaigns,
      ads,
      adsets,
      leadAds,
      messengerAds,
      trafficAds,
      salesAds,
    );
  };

  return (
    <div>
      <Paper sx={styledPaper()}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {rows.map((row: any, index: number) => (
                  <StyledTableCell key={index} align="center">
                    {row}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rowData().length > 0 ? (
                rowData().map((row: any, index: any) => (
                  <StyledTableRow key={index}>
                    {!isSummary ? (
                      <StyledTableCell align="center">
                        {row.name}
                      </StyledTableCell>
                    ) : null}

                    <StyledTableCell align="center">
                      {row.clicks}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {toCurrency('USD', row.cpc)}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {row.impressions}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {toCurrency('USD', row.cpm)}
                    </StyledTableCell>

                    <StyledTableCell align="center">{row.lead}</StyledTableCell>

                    <StyledTableCell align="center">
                      {`$${row.cpl}`}
                    </StyledTableCell>

                    {showQL ? (
                      <>
                        <StyledTableCell align="center">
                          {row.ql}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {`$${row.cpql}`}
                        </StyledTableCell>
                      </>
                    ) : null}

                    <StyledTableCell align="center">
                      {toCurrency('USD', row.spend)}
                    </StyledTableCell>

                    {isAds ? (
                      <StyledTableCell align="center">
                        <IconButton
                          onClick={() => {
                            onGeneratePreview(row.id);
                          }}
                          sx={{ fontSize: '13px', color: '#1976d2' }}
                        >
                          Preview Ad
                        </IconButton>
                      </StyledTableCell>
                    ) : null}
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default CampaignOverview;
