import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from '../../../assets/styles/components/Forms/Form.module.scss';
import PageHeader from '../../PageHeader';
import logo from '../../../assets/images/aiadz-logo.png';
import LinearProgressBarWithLabel from '../../ProgressBar/LinearProgressBarWithLabel';
import { PrimaryButton } from '../../Buttons';
import {
  Brand,
  Country,
  FBLeadGenForm,
  FBLeadGenQuestionForm,
} from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { BusinessType } from '../../../utils/helpers/BrandHelpers';
import { DiySettings } from '../../../types/IDiy';
import { getSingleDiySetting } from '../../../services/diy';
import LeadFormQuestionFieldsForm from './Leads/LeadFormQuestionFieldsForm';
import {
  DEFAULT_BACKGROUND_QUESTION,
  DEFAULT_BEST_NUMBER_QUESTION,
  DEFAULT_INVESTMENT_QUESTION,
  ThankYouPageButtonType,
} from '../../../utils/helpers/LeadHelpers';
import LeadFormPrivacyPolicyForm from './Leads/LeadFormPrivacyPolicyForm';
import LeadFormThankYouPageForm from './Leads/LeadFormThankYouPageForm';
import LeadFormDetailsForm from './Leads/LeadFormDetailsForm';
import { createLeadForms } from '../../../services/lead';
import { phoneNoSpaceFormat } from '../../../utils/phoneNumberFormatter';
import { errorMessageParser } from '../../../utils/helpers/ToastHelper';
import { toggleAlert } from '../../../redux/actions';
import { useNavigate } from 'react-router-dom';
import LeadFormCustomQuestionForm from './Leads/LeadFormCustomQuestionForm';
import LeadFormPrefillQuestionForm from './Leads/LeadFormPrefillQuestionForm';
import { useForm } from 'react-hook-form';

const STEPS: string[] = [
  'Custom Questions',
  'Prefill Questions',
  //'Custom Fields',
  //'Qualified Lead Fields',
  'Privacy Policy',
  'Thank You Page',
  'Form Details',
];

const FacebookLeadForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const {
    formState: { errors },
    handleSubmit,
    register,
    clearErrors,
    unregister,
  } = useForm({
    mode: 'onSubmit',
  });

  const [activeStep, setActiveStep] = useState<string>('');
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [diySettings, setDiySettings] = useState<DiySettings>(null);
  const [formValues, setFormValues] = useState<FBLeadGenForm>({
    brandId: (location || brand)?._id,
    allow_organic_lead: true,
    block_display_for_non_targeted_viewer: false,
    name: '',
    privacy_policy: {
      url: '',
      link_text: 'Privacy Policy',
    },
    question_page_custom_headline:
      'A representative will reach out to you soon',
    questions: [
      ...((location || brand)?.businessType === BusinessType.FRANDEV
        ? [
            {
              key: 'Investment',
              label: DEFAULT_INVESTMENT_QUESTION,
              type: 'CUSTOM',
              options: [
                { value: 'Yes', key: 'yes' },
                { value: 'No', key: 'no' },
              ],
            },
            {
              key: 'Background',
              label: DEFAULT_BACKGROUND_QUESTION,
              type: 'CUSTOM',
            },
            {
              key: 'BestNumber',
              label: DEFAULT_BEST_NUMBER_QUESTION,
              type: 'CUSTOM',
            },
          ]
        : []),
      { type: 'EMAIL' },
      { type: 'PHONE' },
      { type: 'FIRST_NAME' },
      { type: 'LAST_NAME' },
      { type: 'CITY' },
      { type: 'STATE' },
    ],
    locale: 'EN_US',
    is_optimized_for_quality: false,
    thank_you_page: {
      title: `Thanks, you're all set.`,
      body: 'You can visit our website or exit the form now.',
      button_type: 'VIEW_WEBSITE',
      button_text: 'Visit Website',
    },
  });
  const [selectedCountry, setSelectedCountry] = useState<Country>({
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
  });

  useEffect(() => {
    if (location || brand) getDiySettings();
  }, [location, brand]);

  useEffect(() => {
    if ((location || brand)?.businessType === BusinessType.FRANDEV) {
      setDefaultFranDevQuestions();
    }
  }, []);

  useEffect(() => {
    const step = STEPS.find((value: string, index: number) => {
      return index === activeStepIndex;
    });

    setActiveStep(step);
  }, [activeStepIndex]);

  useEffect(() => {
    if (STEPS.length > 0) {
      const step = STEPS.find((value: string, index: number) => {
        return index === activeStepIndex;
      });

      setActiveStep(step);
    }
  }, [STEPS, activeStepIndex]);

  const getDiySettings = async () => {
    try {
      const response = await getSingleDiySetting((location || brand)?._id);

      setFormValues({
        ...formValues,
        thank_you_page: {
          ...formValues?.thank_you_page,
          website_url: response?.data?.defaultUrl,
          business_phone_number: phoneNoSpaceFormat(
            (location || brand)?.phoneNumber,
          ),
          country_code: '+1',
        },
      });

      setDiySettings(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    try {
      let temp: FBLeadGenForm = { ...formValues };

      if (
        temp?.thank_you_page?.button_type ===
        ThankYouPageButtonType.VIEW_WEBSITE
      ) {
        temp = {
          ...temp,
          thank_you_page: {
            ...temp?.thank_you_page,
            country_code: null,
            business_phone_number: null,
          },
        };
      }

      if (
        temp?.thank_you_page?.button_type ===
        ThankYouPageButtonType.SCHEDULE_CALL
      ) {
        temp = {
          ...temp,
          thank_you_page: {
            ...temp?.thank_you_page,
            country_code: null,
            business_phone_number: null,
            button_type: ThankYouPageButtonType.VIEW_WEBSITE,
          },
        };
      }

      if (
        temp?.thank_you_page?.button_type ===
        ThankYouPageButtonType.CALL_BUSINESS
      ) {
        temp = {
          ...temp,
          thank_you_page: {
            ...temp?.thank_you_page,
            website_url: null,
            business_phone_number: `+${selectedCountry?.phone}${temp?.thank_you_page?.business_phone_number}`,
            country_code: selectedCountry?.code,
          },
        };
      }

      const response = await createLeadForms(temp);

      console.log(response);

      navigate('/assetz');
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);

      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    setActiveStepIndex((prev) => prev + 1);
    setProgress((prevProgress) => prevProgress + (1 / STEPS.length) * 100);
  };

  const handleBack = () => {
    setActiveStepIndex((prev) => prev - 1);
    setProgress((prevProgress) => prevProgress - (1 / STEPS.length) * 100);
  };

  const setDefaultFranDevQuestions = () => {
    setFormValues({
      ...formValues,
      questions: [
        {
          key: 'Investment',
          label:
            'Do you have $100K of Liquid Capital to invest? (from 401K, Retirement, etc.)',
          type: 'CUSTOM',
          options: [
            { value: 'Yes', key: 'option1' },
            { value: 'No', key: 'option2' },
          ],
        },
        {
          key: 'Background',
          label: 'Tell us about your professional background',
          type: 'CUSTOM',
        },
        {
          key: 'BestNumber',
          label: `What's the best number to reach you?`,
          type: 'CUSTOM',
        },
      ],
    });
  };

  const buildPageheader = () => {
    let title: string = '';
    switch (activeStep) {
      case 'Custom Questions':
        title = 'PLEASE SETUP YOUR CUSTOM QUESTIONS';
        break;
      case 'Prefill Questions':
        title = 'PLEASE SETUP YOUR PREFILL QUESTIONS';
        break;
      case 'Custom Fields':
        title = `ADD QUESTION'S CUSTOM FIELD NAMES`;
        break;
      case 'Qualified Lead Fields':
        title = 'SET YOUR QUALIFIED LEAD FIELDS';
        break;
      case 'Privacy Policy':
        title = 'ENTER YOUR PRIVACY POLICY LINK';
        break;
      case 'Thank You Page':
        title = 'SET YOUR ENDING ACTIONS';
        break;
      default:
        title = 'FORM DETAILS';
        break;
    }

    return title;
  };

  const renderContent = () => {
    if (activeStep === 'Custom Questions') {
      const customQuestions = formValues?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type === 'CUSTOM',
      );

      const prefilledQuestions = formValues?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type !== 'CUSTOM',
      );

      return (
        <LeadFormCustomQuestionForm
          customQuestions={customQuestions}
          prefilledQuestions={prefilledQuestions}
          formValues={formValues}
          setFormValues={setFormValues}
          register={register}
          errors={errors}
          unregister={unregister}
          clearErrors={clearErrors}
          onValidated={handleSubmit}
        />
      );
    }

    if (activeStep === 'Prefill Questions') {
      const prefilledQuestions = formValues?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type !== 'CUSTOM',
      );

      const customQuestions = formValues?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type === 'CUSTOM',
      );

      return (
        <LeadFormPrefillQuestionForm
          prefillQuestions={prefilledQuestions}
          customQuestions={customQuestions}
          formValues={formValues}
          setFormValues={setFormValues}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
        />
      );
    }

    if (activeStep === 'Custom Fields') {
      const customQuestions = formValues?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type === 'CUSTOM',
      );

      const prefillQuestions = formValues?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type !== 'CUSTOM',
      );

      return (
        <LeadFormQuestionFieldsForm
          customQuestions={customQuestions}
          prefillQuestions={prefillQuestions}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      );
    }

    if (activeStep === 'Privacy Policy') {
      return (
        <LeadFormPrivacyPolicyForm
          formValues={formValues}
          setFormValues={setFormValues}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
        />
      );
    }

    if (activeStep === 'Thank You Page') {
      return (
        <LeadFormThankYouPageForm
          formValues={formValues}
          setFormValues={setFormValues}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          diySettings={diySettings}
          errors={errors}
          register={register}
          unregister={unregister}
          clearErrors={clearErrors}
          brand={location || brand}
        />
      );
    }

    if (activeStep === 'Form Details') {
      return (
        <LeadFormDetailsForm
          formValues={formValues}
          setFormValues={setFormValues}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
        />
      );
    }
  };

  return (
    <Box className={`${styles.form} ${xsOnly ? `${styles['-mobile']}` : ''}`}>
      <Box
        component="div"
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          margin: 0,
        }}
      >
        <Box component="div" sx={{ paddingBottom: 0 }}>
          <PageHeader
            title={buildPageheader()}
            textAlign="left"
            marginBottom="0"
          />
        </Box>

        <div>
          <Box component="img" src={logo} alt="" sx={{ width: '90px' }} />
        </div>
      </Box>

      <Grid container spacing={12}>
        <Grid item xs={12} sx={{ minHeight: 'calc(100vh - 222px)' }}>
          {renderContent()}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        mt={4}
        sx={{
          position: 'sticky',
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: '#FFF',
          paddingBottom: '20px',
          zIndex: 999,
          alignItems: 'flex-end',
        }}
      >
        <Grid item xs={12} sm={6}>
          <LinearProgressBarWithLabel value={progress} />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            justifyContent: 'flex-end',
            display: 'flex',
            paddingY: '20px',
          }}
        >
          {activeStepIndex > 0 ? (
            <PrimaryButton
              title="Back"
              type="button"
              handleOnClick={handleBack}
              marginRight5
              variant="text"
              color="red"
            />
          ) : null}

          <PrimaryButton
            loading={activeStepIndex + 1 === STEPS.length && loading}
            title={activeStepIndex + 1 === STEPS.length ? 'Submit' : 'Next'}
            type="button"
            handleOnClick={
              activeStepIndex + 1 === STEPS.length
                ? handleSubmit(handleOnSubmit)
                : handleSubmit(handleNext)
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FacebookLeadForm;
