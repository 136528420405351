import React from 'react';
import { AdLead, Brand, CampaignAd } from '../../../types';
import { Box } from '@mui/material';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { DataGrid } from '@mui/x-data-grid';
import { CustomLoadingOverlay } from '../../Loading';
import { leadColumns } from './LeadListTableColumn';
import { BusinessType } from '../../../utils/helpers/BrandHelpers';
import { styledDataGrid } from '../../../utils/helpers/DataGridHelper';

interface LeadListTableProps {
  leads: AdLead[];
  loading: boolean;
  onOpenLeadDetails: (lead: AdLead) => void;
  ads: CampaignAd[];
  brand: Brand;
}

const LeadListTable: React.FC<LeadListTableProps> = ({
  leads,
  loading,
  onOpenLeadDetails,
  ads,
  brand,
}) => {
  const isFrandev: boolean = brand?.businessType === BusinessType.FRANDEV;

  const getFirstName = (lead: AdLead) => {
    const data = lead?.fieldData?.find(
      (item: any) => item.name === 'first_name',
    );

    return data?.values[0] || '';
  };

  const getLastName = (lead: AdLead) => {
    const data = lead?.fieldData?.find(
      (item: any) => item.name === 'last_name',
    );

    return data?.values[0] || '';
  };

  const getEmail = (lead: AdLead) => {
    const data = lead?.fieldData?.find((item: any) => item.name === 'email');

    return data?.values[0] || '';
  };

  const getBackground = (lead: AdLead) => {
    const data = lead?.fieldData?.find(
      (item: any) => item.name === 'Background',
    );

    return data?.values[0] || '';
  };

  const getPhoneNumber = (lead: AdLead) => {
    const data = lead?.fieldData?.find(
      (item: any) => item.name === 'phone_number',
    );

    return data?.values[0] || '';
  };

  const rows = leads.map((lead: AdLead) => {
    const ad = ads.find((ad: CampaignAd) => ad.id === lead.adId);

    return {
      id: lead.leadId,
      adName: ad?.name || '',
      email: getEmail(lead),
      firstName: getFirstName(lead),
      lastName: getLastName(lead),
      background: getBackground(lead),
      investmentQualified: lead.investmentQualifiedLead,
      backgroundQualified: lead.backgroundQualifiedLead,
      createdAt: lead.leadCreatedAt,
      phoneNumber: getPhoneNumber(lead),
      lead,
      onOpen: onOpenLeadDetails,
    };
  });

  return (
    <Box className={styles.table}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
        }}
        density="compact"
        rows={rows}
        autoHeight
        hideFooterSelectedRowCount
        rowsPerPageOptions={[25, 50, 100]}
        components={{ LoadingOverlay: CustomLoadingOverlay }}
        loading={loading}
        columns={leadColumns(isFrandev)}
        sx={styledDataGrid()}
      />
    </Box>
  );
};

export default LeadListTable;
