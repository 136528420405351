import { Autocomplete, Grid, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  AdsAccountFormFields,
  Brand,
  FbAdsAccountData,
  IFacebookUser,
} from '../../../types';
import { fetchFbAdsAccount } from '../../../services/ads';
import { adsProvider } from '../../../utils/constants/facebookAds';
import { DiySettings } from '../../../types/IDiy';
import Spinner from '../../Spinner';

interface DiyAdsAccountFormProps {
  brand: Brand;
  isEdit?: boolean;
  formValues: Brand;
  setFormValues: Dispatch<SetStateAction<Brand>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
  facebookAccount: IFacebookUser;
  diySettings: DiySettings;
  setDiySettings: Dispatch<SetStateAction<DiySettings>>;
  userId: string;
  loading: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

const DiyAdsAccountForm: React.FC<DiyAdsAccountFormProps> = ({
  brand,
  isEdit = false,
  formValues,
  setFormValues,
  setLoading,
  disabled,
  facebookAccount,
  diySettings,
  setDiySettings,
  userId,
  loading,
  setIsDirty,
}) => {
  const [fbAdsAccounts, setFbAdsAccounts] = useState<AdsAccountFormFields[]>(
    [],
  );

  useEffect(() => {
    if (formValues?.facebookBusinessIds?.length > 0) {
      getFbAdsAccounts(brand, userId);
    }
  }, [formValues?.facebookBusinessIds, facebookAccount]);

  const getFbAdsAccounts = async (brand: Brand, userId: string) => {
    setLoading(true);
    try {
      const response = await fetchFbAdsAccount(
        formValues?.facebookBusinessIds,
        formValues?._id,
        userId,
      );

      if (response?.data) {
        const newFormvalues = response.data.map(
          (adsAccount: FbAdsAccountData) => {
            return {
              accountId: adsAccount.account_id,
              name: adsAccount.name,
              currency: adsAccount.currency,
              provider: adsProvider.FACEBOOK,
            };
          },
        );

        setFbAdsAccounts(newFormvalues);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnAddBusinessId = (value: string[]) => {
    setIsDirty(true);
    setFormValues({ ...formValues, facebookBusinessIds: value });
  };

  const handleOnSelectAdAccount = (value: any) => {
    setIsDirty(true);
    setDiySettings({ ...diySettings, fbAdAccount: value });
  };

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="facebookBusinessIds"
            multiple
            disableClearable
            freeSolo
            options={[]}
            value={formValues?.facebookBusinessIds}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Facebook Business Ids"
                placeholder="Enter Facebook Businesss ID"
                name="facebookBusinessIds"
              />
            )}
            onChange={(e: any, value: any[]) => {
              handleOnAddBusinessId(value);
            }}
            disabled={disabled}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {fbAdsAccounts?.length > 0 &&
          formValues?.facebookBusinessIds.length > 0 ? (
            <Autocomplete
              id="adsAccount"
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.accountId}>
                    {option.name}
                  </li>
                );
              }}
              options={fbAdsAccounts.sort(
                (a, b) => -b.name.localeCompare(a.name),
              )}
              value={fbAdsAccounts?.find((account: AdsAccountFormFields) => {
                return (
                  account.accountId === diySettings?.fbAdAccount?.accountId
                );
              })}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Ad Accounts"
                  placeholder="Select Your Ads Accounts"
                />
              )}
              onChange={async (e: any, value: any, reason: string) => {
                let selectedAccount: any = null;
                if (reason !== 'clear') {
                  selectedAccount = value;
                }

                handleOnSelectAdAccount(selectedAccount);
              }}
              disabled={disabled}
            />
          ) : isEdit && loading ? (
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ justifyContent: 'center', display: 'flex' }}
            >
              <Spinner />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default DiyAdsAccountForm;
