import React from 'react';
import { Button } from '@mui/material';
import Spinner from '../Spinner';

interface BtnProps {
  loading?: boolean;
  title: string;
  variant?: 'text' | 'outlined' | 'contained';
  type?: 'button' | 'submit' | 'reset';
  color?: string;
  handleOnClick?: () => void;
  startIcon?: React.ReactNode | null;
  isMr?: boolean;
  theme?: string;
  marginTop?: string | null;
  disabled?: boolean;
  width?: string | null;
  marginBot20?: boolean;
  marginRight5?: boolean;
  marginRight10?: boolean;
  endIcon?: React.ReactNode | null;
  rounded?: boolean;
  fontSize?: string;
  size?: string;
  flexDirection?: string;
  height?: string | null;
  bold?: boolean | false;
}

export const PrimaryButton: React.FC<BtnProps> = ({
  loading = false,
  title,
  variant,
  type,
  handleOnClick,
  startIcon,
  isMr = false,
  theme = '#096f4d',
  marginTop,
  disabled = false,
  width,
  marginBot20 = false,
  marginRight5 = false,
  marginRight10 = false,
  endIcon,
  rounded = false,
  fontSize = '',
  size = 'small',
  flexDirection = 'row',
  height,
  bold = false,
}) => {
  return (
    <Button
      type={type ? type : 'submit'}
      variant={variant ? variant : 'contained'}
      size={size as any}
      onClick={handleOnClick}
      startIcon={startIcon ? startIcon : null}
      endIcon={endIcon ? endIcon : null}
      sx={{
        mr: isMr ? 1 : 0,
        backgroundColor: ['outlined', 'text'].includes(variant)
          ? 'transparent'
          : theme,
        color: ['outlined', 'text'].includes(variant) ? theme : '#FFFFFF',
        border: variant !== 'outlined' ? 'none' : `1px solid ${theme}`,
        boxShadow: '0',
        flexDirection: flexDirection as any,
        '&:hover': {
          backgroundColor: ['outlined', 'text'].includes(variant)
            ? 'transparent'
            : theme,
          color: ['outlined', 'text'].includes(variant) ? theme : '#FFFFFF',
          boxShadow: '0',
        },
        marginTop: marginTop ? marginTop : '',
        width: width !== null ? width : '',
        marginBottom: marginBot20 ? '20px' : '',
        marginRight: marginRight5 ? '5px' : marginRight10 ? '10px' : '',
        borderRadius: rounded ? '49px' : '',
        fontSize: fontSize,
        height: height !== null ? height : '',
        fontWeight: bold ? 'bold' : '',
      }}
      disabled={disabled ? true : false}
    >
      {loading ? <Spinner /> : title}
    </Button>
  );
};
