import { diyAdzUrl } from '../services/api';
import { Objectives } from './constants/facebookAds';

const baseUrl = window.location.host;

export const LOGGED_IN_USER: string = 'LOGGED_IN_USER';
export const ADMIN: string = 'admin';
export const SUPER_ADMIN: string = 'super-admin';
export const SALESPERSON: string = 'salesperson';
export const AGENCY: string = 'agency';
export const BRAND_MANAGER: string = 'brand-manager';
export const MARKETING_MANAGER: string = 'marketing-manager';
export const GET_THEME: string = 'GET_THEME';
export const CREATE_THEME: string = 'CREATE_THEME';
export const EDIT_THEME: string = 'EDIT_THEME';
export const AUTH_TOKEN: string = 'auth_token';
export const BRAND_SESSION: string = 'brand_session';
export const BRAND_FILTER_SESSION: string = 'brand_filter_session';
export const CAMPAIGN_PROVIDER_SESSION: string = 'campaign_provider_session';
export const BUDGET_PROVIDER_SESSION: string = 'budget_provider_session';
export const AGENCY_SESSION: string = 'agency_session';
export const LOCATION_SESSION: string = 'location_session';
export const PROFILE_SCOPES = 'email,public_profile';
export const PAGE_MANAGEMENT_SCOPES =
  'pages_messaging,pages_manage_metadata,page_events,pages_show_list,pages_manage_ads,pages_read_engagement';
export const ADS_MANAGEMENT_SCOPES = 'ads_read,ads_management';
export const BUSINESS_MANAGEMENT_SCOPES = 'business_management';
export const USER_MANAGEMENT_SCOPES = 'leads_retrieval';
export const SET_PAGE: string = 'SET_PAGE';
export const CANNED_RESPONSE_FIELDS = [
  '<<lead>>',
  '<<salesperson>>',
  '<<brand>>',
  '<<calendly_link>>',
];
export const CUSTOM_FIELDS = ['address', 'phone_number', 'custom'];
export const CUSTOM_FIELD_REGEX = /\{\{(.*?)\}\}/g;
export const IS_MESSENGER_ADS = [
  Objectives.OUTCOME_ENGAGEMENT,
  Objectives.MESSAGES,
];
export const IS_LEAD_ADS = [
  Objectives.OUTCOME_LEADS,
  Objectives.LEAD_GENERATION,
];
export const IS_TRAFFIC_ADS = [
  Objectives.OUTCOME_TRAFFIC,
  Objectives.LINK_CLICKS,
];
export const IS_SALES_ADS = [Objectives.OUTCOME_SALES];
export const GOOGLE_LOGIN_SCOPES: string =
  'email profile https://www.googleapis.com/auth/adwords openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';
export const IS_DEVELOPMENT =
  process.env.REACT_APP_ENVIRONMENT === 'development';
export const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === 'production';
export const IS_STAGING = process.env.REACT_APP_ENVIRONMENT === 'staging';
export const IS_DIY_ADZ = baseUrl === diyAdzUrl;
// Default lat and lng for Google Maps
// Pointed to the center of USA
export const DEFAULT_LAT: number = 39.8097343;
export const DEFAULT_LNG: number = -98.5556199;
