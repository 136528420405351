import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { XsOnly } from '../../utils/breakpoints';
import CloseIcon from '@mui/icons-material/Close';
import ModalHeader from '../Modal/ModalHeader';
import { AdsTemplate, DiySettings } from '../../types/IDiy';
import {
  fetchSingleCampaignTemplate,
  getSingleDiySetting,
} from '../../services/diy';
import { AdVideo, Brand } from '../../types';
import { PrimaryButton } from '../Buttons';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArchiveIcon from '@mui/icons-material/Archive';
import { AdsPreviewSquare } from '../Forms/Diy/AdsPreview';
import { constantStringToHumanized } from '../../utils/stringModifier';
import { fetchSingleFbAdAccountVideo } from '../../services/ads';
import Spinner from '../Spinner';

interface TemplatePreviewProps {
  onClose: () => void;
  templateId: string;
  brand: Brand;
  isDiy?: boolean;
  onSelect?: (template: AdsTemplate) => void;
  onArchive?: () => void;
  archiveLoading?: boolean;
}

const TemplatePreview: React.FC<TemplatePreviewProps> = ({
  onClose,
  templateId,
  brand,
  isDiy = false,
  onSelect,
  onArchive,
  archiveLoading = false,
}) => {
  const navigate = useNavigate();
  const [template, setTemplate] = useState<AdsTemplate>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [diySettings, setDiySettings] = useState<DiySettings>(null);
  const [brandDiySettings, setBrandDiySettings] = useState<DiySettings>(null);
  const [confirmArchive, setConfirmArchive] = useState<boolean>(false);
  const [adVideo, setAdVideo] = useState<AdVideo>(null);

  useEffect(() => {
    if (templateId) {
      getSingleTemplate();
    }
  }, [templateId]);

  useEffect(() => {
    getDiySettings(brand);
    if (brand?.__type === 'location') {
      getBrandDiySettings(brand?.franchisor);
    }
  }, [brand]);

  useEffect(() => {
    if (
      template?.creativeTemplate?.asset_feed_spec?.videos &&
      template?.media === 'video'
    ) {
      getSingleAdVideo();
    }
  }, [template]);

  const getSingleAdVideo = async () => {
    setLoading(true);
    try {
      const video = template?.creativeTemplate?.asset_feed_spec?.videos?.find(
        (video: any) => {
          return video.adlabels[0]?.name === 'video_square';
        },
      );
      const response = await fetchSingleFbAdAccountVideo(
        brand?._id,
        video?.video_id,
      );

      setAdVideo(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getSingleTemplate = async () => {
    setLoading(true);
    try {
      const response = await fetchSingleCampaignTemplate(templateId);

      setTemplate(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getDiySettings = async (brand: Brand) => {
    try {
      const response = await getSingleDiySetting(brand?._id);

      setDiySettings(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getBrandDiySettings = async (brand: Brand) => {
    try {
      const response = await getSingleDiySetting(brand?._id);

      setBrandDiySettings(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <Box
      sx={{
        width: XsOnly() ? '100%' : '400px',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          // background: "#F1F0F0",
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },

        '&::-webkit-scrollbar-thumb': {
          background: '#096f4d',
          borderRadius: '18px',

          '&:hover': {
            background: '#096f4d',
          },
        },
        padding: '20px',
      }}
    >
      <Grid container spacing={1}>
        {XsOnly() ? (
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={onClose} type="button">
              <CloseIcon />
            </IconButton>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <ModalHeader title="Template Preview" isCenter />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
          }}
        >
          <Typography variant="body2" mr={1} sx={{ fontWeight: 'bold' }}>
            Template ID:
          </Typography>

          <Typography variant="body2">{template?.adsTemplateId}</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            minWidth: '100%',
            mb: 2,
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          {loading ? (
            <Spinner />
          ) : (
            <div>
              <AdsPreviewSquare
                page={diySettings?.facebookPage}
                body={
                  template?.creativeTemplate?.asset_feed_spec?.bodies[0]?.text
                }
                isImage={template?.media === 'image'}
                imageUrl={template?.images?.square}
                websiteUrl={
                  template?.creativeTemplate?.asset_feed_spec?.link_urls[0]
                    ?.website_url
                }
                headline={
                  template?.creativeTemplate?.asset_feed_spec?.titles[0]?.text
                }
                description={
                  template?.creativeTemplate?.asset_feed_spec?.descriptions[0]
                    ?.text
                }
                cta={constantStringToHumanized(
                  template?.creativeTemplate?.asset_feed_spec
                    ?.call_to_action_types[0] || '',
                )}
                videoUrl={adVideo?.source}
                objective={template?.campaignTemplate?.objective}
              />
            </div>
          )}
        </Grid>

        {!loading && !isDiy ? (
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              textAlign: 'center',
              position: 'sticky',
              bottom: 0,
            }}
          >
            <PrimaryButton
              title="Edit"
              type="button"
              handleOnClick={() => navigate(`/templatez/edit/${template?._id}`)}
              marginRight5
              startIcon={<EditIcon />}
            />

            <PrimaryButton
              title="Duplicate"
              type="button"
              handleOnClick={() =>
                navigate(`/templatez/duplicate/${template?._id}`)
              }
              marginRight5
              startIcon={<ContentCopyIcon />}
            />

            <PrimaryButton
              title={`${confirmArchive ? 'Really archive?' : 'Archive'}`}
              type="button"
              handleOnClick={() =>
                confirmArchive ? onArchive() : setConfirmArchive(true)
              }
              startIcon={<ArchiveIcon />}
              loading={archiveLoading}
              theme={confirmArchive ? 'red' : '#096f4d'}
            />
          </Grid>
        ) : null}

        {!loading && isDiy ? (
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              textAlign: 'center',
              position: 'sticky',
              bottom: 0,
            }}
          >
            <PrimaryButton
              title="Select"
              type="button"
              handleOnClick={() => onSelect(template)}
              marginRight5
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default TemplatePreview;
