/**
 * React Hook Form Custom Validations
 * @author Angelo David
 * @reference https://www.react-hook-form.com/
 */
export const securedUrlPattern = {
  value: /^https:\/\/([a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+.*)$/,
  message: 'Invalid URL format, only https URLs are allowed',
};

export const rawPhoneNumberPattern = {
  value: /^\d{10,}$/,
  message:
    'Invalid phone number format, only digits are allowed and atleast 10-digit number: 1234567890',
};
