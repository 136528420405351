import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { AdsAccount, AdsAccountFormFields, Brand } from '../../../types';
import styles from '../../../assets/styles/components/Modal/CampaignModal.module.scss';
import { humanizeString } from '../../../utils/stringModifier';
import FormHeader from '../FormHeader';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../redux/actions';

interface FacebookAdsAccountFormProps {
  brand: Brand;
  handleSync: (value: any[], brand: Brand) => void;
  fbAdsAccounts: AdsAccount[];
  defaultFormValues: AdsAccountFormFields[];
  connectedAccountsIds: string[];
  setDefaultFormValues: Dispatch<SetStateAction<AdsAccountFormFields[]>>;
  onSelectAdsAccount: (
    value: AdsAccountFormFields[],
    brand: Brand,
    selectedId: string,
    reason: string,
    provider: string,
  ) => void;
  provider: string;
}

const FacebookAdsAccountForm: React.FC<FacebookAdsAccountFormProps> = ({
  brand,
  handleSync,
  fbAdsAccounts,
  defaultFormValues,
  connectedAccountsIds,
  setDefaultFormValues,
  onSelectAdsAccount,
  provider,
}) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.accounts}>
      <FormHeader title={`Connect ${humanizeString(provider)} Ad Accounts`} />

      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            id="facebookBusinessIds"
            multiple
            disableClearable
            freeSolo
            options={[]}
            value={brand?.facebookBusinessIds}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Facebook Business Ids"
                placeholder="Enter Facebook Businesss ID"
                name="facebookBusinessIds"
              />
            )}
            onChange={(e: any, value: any[]) => {
              handleSync(value, brand);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            id="adsAccount"
            multiple
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.accountId}>
                  {option.name}
                </li>
              );
            }}
            options={fbAdsAccounts.sort(
              (a, b) => -b.name.localeCompare(a.name),
            )}
            getOptionDisabled={(option) =>
              !!defaultFormValues.find(
                (element: any) =>
                  element.accountId === option.accountId && !element.deleted,
              )
            }
            value={defaultFormValues}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Ad Accounts"
                placeholder="Select Your Ads Accounts"
              />
            )}
            disableClearable
            onChange={async (e: any, value: any[], reason, detail) => {
              if (
                reason === 'removeOption' &&
                connectedAccountsIds.includes(detail.option.accountId)
              ) {
                dispatch(
                  toggleAlert({
                    toggle: true,
                    message: `Cannot remove ${detail.option.name} Ad Account. Kindly disconnect the connected ${detail.option.name}'s campaigns first.`,
                    type: 'error',
                  }),
                );

                return;
              }
              setDefaultFormValues(value);
              onSelectAdsAccount(
                value,
                brand,
                detail.option.accountId,
                reason,
                provider,
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            {defaultFormValues.map((adsAccount: AdsAccountFormFields) => {
              return adsAccount.deleted ? null : (
                <Grid item xs={12} sm={4} lg={3} key={adsAccount.accountId}>
                  <Card sx={{ height: '100%' }}>
                    <CardHeader
                      subheader={adsAccount.name}
                      subheaderTypographyProps={{
                        sx: {
                          fontWeight: 'bold',
                          color: 'black',
                          textAlign: 'center',
                        },
                      }}
                    />

                    <CardContent sx={{ paddingTop: 0 }}>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        component="div"
                      >
                        Account ID:
                        <Typography variant="body2" display="inline" ml={1}>
                          {adsAccount.accountId}
                        </Typography>
                      </Typography>

                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        component="div"
                      >
                        Status:
                        <Typography variant="body2" display="inline" ml={1}>
                          {humanizeString(adsAccount.currency)}
                        </Typography>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FacebookAdsAccountForm;
