import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  useState,
} from 'react';
import { humanizeString } from '../../../utils/stringModifier';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import {
  IMAGE_FILTER_OPTIONS,
  VIDEO_FILTER_OPTIONS,
} from '../../../utils/helpers/facebookAdsHelper';
import {
  AdImage,
  AdVideo,
  ChangeEventType,
  ImageFilters,
  VideoFilters,
} from '../../../types';
import TuneIcon from '@mui/icons-material/Tune';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FiUpload } from 'react-icons/fi';
import { RiVideoUploadLine } from 'react-icons/ri';
import { HtmlTooltip } from '../../Tooltip';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

interface MediaListProps {
  onFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  isImage: boolean;
  isVideo: boolean;
  format: string;
  mediaType: string;
  images: AdImage[];
  videos: AdVideo[];
  selectedImages: string[];
  selectedVideos: string[];
  onSelectMedia: (image?: AdImage, video?: AdVideo) => void;
  imageFilters: ImageFilters;
  videoFilters: VideoFilters;
  onImageFilter: (name: string, checked: boolean) => void;
  onVideoFilter: (name: string, checked: boolean) => void;
  search: string;
  onSearch: (e: ChangeEventType) => void;
  replace?: boolean;
  ratio?: string | null;
  setReplaceImage: Dispatch<SetStateAction<AdImage>>;
  replacedImage: AdImage;
  replacedVideo: AdVideo;
  setReplacedVideo: Dispatch<SetStateAction<AdVideo>>;
}

const Input = styled('input')({
  display: 'none',
  '&:disabled': {
    cursor: 'not-allowed',
  },
});

const MediaList: React.FC<MediaListProps> = ({
  onFileUpload,
  isImage,
  isVideo,
  format,
  mediaType,
  images,
  videos,
  selectedImages,
  selectedVideos,
  onSelectMedia,
  imageFilters,
  videoFilters,
  search,
  onSearch,
  onImageFilter,
  onVideoFilter,
  replace = false,
  ratio = null,
  setReplaceImage,
  replacedImage,
  replacedVideo,
  setReplacedVideo,
}) => {
  const [play, setPlay] = useState<boolean>(false);

  return (
    <>
      <Grid item xs={12} sx={{ position: 'sticky', top: -15, zIndex: 999 }}>
        <label htmlFor="agency-logo">
          <Input
            //disabled={logo.url !== ''}
            onChange={onFileUpload}
            accept={isImage ? 'image/*' : 'video/*'}
            id="agency-logo"
            multiple={format === 'carousel'}
            type="file"
          />

          <Button
            component="span"
            variant="contained"
            sx={{
              backgroundColor: '#096f4d',
              '&:hover': {
                backgroundColor: '#096f4d',
              },
              marginRight: '10px',
              position: 'sticky',
              top: 0,
            }}
            startIcon={
              mediaType === 'image' ? <FiUpload /> : <RiVideoUploadLine />
            }
            endIcon={
              <HtmlTooltip
                title={
                  <Fragment>
                    {' '}
                    <Box component="div" sx={{ flexDirection: 'column' }}>
                      <Typography
                        component="div"
                        variant="caption"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {`Square ${humanizeString(mediaType)}s (1:1)`}
                      </Typography>

                      <Typography component="div" variant="caption">
                        {`We recommend square 1:1 ${mediaType}s for feed, search results,
                        marketplace placements.`}
                      </Typography>

                      <Box component="div" sx={{ marginBottom: '20px' }}>
                        <Typography
                          component="a"
                          variant="caption"
                          target="_blank"
                          href="https://scontent.fmnl4-2.fna.fbcdn.net/v/t39.2365-6/67263086_2415275025419868_2215144582801260544_n.pdf?_nc_cat=106&ccb=1-7&_nc_sid=e280be&_nc_ohc=1QNsunkrTG0AX81pmqh&_nc_ht=scontent.fmnl4-2.fna&oh=00_AfBt43t1oQlkFVqT84K8Miv4BLyN-1Pa8g0ZCrKx5Qgn3A&oe=656EC58F"
                        >
                          Download Template
                        </Typography>
                      </Box>

                      <Typography
                        component="div"
                        variant="caption"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {`Vertical ${humanizeString(mediaType)}s (9:16)`}
                      </Typography>

                      <Typography component="div" variant="caption">
                        {`We recommend vertical 9:16 ${mediaType}s for Facebook, Instagram and Messenger stories placements.`}
                      </Typography>

                      <Box component="div" sx={{ marginBottom: '20px' }}>
                        <Typography
                          component="a"
                          variant="caption"
                          target="_blank"
                          href="https://scontent.fmnl4-4.fna.fbcdn.net/v/t39.2365-6/67163785_386669378629012_3380213509051645952_n.pdf?_nc_cat=102&ccb=1-7&_nc_sid=e280be&_nc_ohc=jyGSHyA34JYAX_7F9JK&_nc_ht=scontent.fmnl4-4.fna&oh=00_AfB-Ghn2Krm0pTKxgEHN8jw9cEc4KQ7yU0LC239ymMbuRg&oe=656EE3F9"
                        >
                          Download Template
                        </Typography>
                      </Box>

                      <Box component="div">
                        <Typography
                          component="a"
                          variant="caption"
                          target="_blank"
                          href="https://www.facebook.com/business/help/103816146375741?id=271710926837064"
                        >
                          Learn More
                        </Typography>
                      </Box>
                    </Box>
                  </Fragment>
                }
              >
                <InfoTwoToneIcon sx={{ fontSize: '18px' }} />
              </HtmlTooltip>
            }
            //disabled={logo.url !== ''}
          >
            {`Upload ${humanizeString(mediaType)}`}
          </Button>
        </label>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic-email"
              label="Search"
              variant="outlined"
              onChange={onSearch}
              value={search}
              autoFocus
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={{
                '& .MuiInputBase-root': {
                  '&:has(> input:-webkit-autofill)': {
                    backgroundColor: 'rgb(232, 240, 254)',
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PopupState variant="popover" popupId="filter-menu">
                      {(popupState) => (
                        <Fragment>
                          <IconButton edge="end" {...bindTrigger(popupState)}>
                            <TuneIcon />
                          </IconButton>

                          <Menu
                            {...bindMenu(popupState)}
                            sx={{ textAlign: 'left' }}
                          >
                            {(isImage
                              ? IMAGE_FILTER_OPTIONS
                              : VIDEO_FILTER_OPTIONS
                            ).map((filter: any, index: number) => (
                              <MenuItem
                                key={index + 1}
                                onClickCapture={popupState.close}
                                onClick={null}
                                sx={{
                                  flexDirection: 'column',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  component="div"
                                  sx={{ textAlign: 'left' }}
                                >
                                  {filter.type}
                                </Typography>

                                <FormGroup>
                                  {filter.options.map(
                                    (option: any, index: number) => {
                                      const checked = isImage
                                        ? imageFilters[option.option]
                                        : videoFilters[option.option];

                                      return (
                                        <FormControlLabel
                                          id={`filter-${index + 1}`}
                                          control={<Checkbox />}
                                          label={option.label}
                                          onClick={(e: any) => {
                                            if (isImage) {
                                              onImageFilter(
                                                option.option,
                                                !checked,
                                              );
                                            } else if (isVideo) {
                                              onVideoFilter(
                                                option.option,
                                                !checked,
                                              );
                                            }
                                          }}
                                          checked={checked}
                                          name={option.option}
                                          value={checked}
                                        />
                                      );
                                    },
                                  )}
                                </FormGroup>
                              </MenuItem>
                            ))}
                          </Menu>
                        </Fragment>
                      )}
                    </PopupState>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {isImage ? (
          images.length > 0 ? (
            <Grid container spacing={2}>
              {images.map((image: AdImage, index: number) => {
                const selected = replace
                  ? image.id === replacedImage?.id
                  : selectedImages.includes(image.hash);

                return (
                  <>
                    {image ? (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        sx={{
                          position: 'relative',
                        }}
                        key={`image-${index + 1}`}
                      >
                        {selected ? (
                          <Box
                            component="div"
                            sx={{
                              position: 'absolute',
                              top: '8px',
                              zIndex: 1,
                              right: '-10px',
                              float: 'right',
                              margin: '1px',
                            }}
                          >
                            <CheckCircleIcon
                              sx={{
                                color: '#096f4d',
                                bgcolor: 'InfoBackground',
                                borderRadius: '50%',
                              }}
                            />
                          </Box>
                        ) : null}

                        <Card
                          sx={{
                            height: '100%',
                            border: selected ? 'solid' : '',
                            borderColor: selected ? '#096f4d' : '',
                            borderWidth: 'medium',
                            zIndex: 0,
                          }}
                        >
                          <CardActionArea
                            onClick={() => {
                              if (replace) {
                                setReplaceImage(image);
                              } else {
                                onSelectMedia(image);
                              }
                            }}
                          >
                            <CardMedia
                              component="img"
                              src={`${image.permalink_url}`}
                              height="100px"
                              sx={{
                                objectFit: 'contain',
                              }}
                            />

                            <CardContent
                              sx={{ paddingBottom: '5px !important' }}
                            >
                              <Typography
                                gutterBottom
                                variant="caption"
                                component="div"
                                fontWeight="bold"
                                color="#092C4C"
                                marginBottom="0"
                              >
                                {image.name}
                              </Typography>

                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {`${image.width} x ${image.height}`}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ) : null}
                  </>
                );
              })}
            </Grid>
          ) : (
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{ textAlign: 'center' }}
              mt={4}
            >
              No Image to Show
            </Typography>
          )
        ) : null}

        {isVideo ? (
          videos.length > 0 ? (
            <Grid container spacing={1}>
              {videos.map((video: AdVideo, index: number) => {
                const selected = replace
                  ? video.id === replacedVideo?.id
                  : selectedVideos.includes(video.id);

                return (
                  <>
                    {video ? (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        sx={{
                          position: 'relative',
                        }}
                        key={`video-${index + 1}`}
                      >
                        {selected ? (
                          <Box
                            component="div"
                            sx={{
                              position: 'absolute',
                              top: '-1px',
                              zIndex: 1,
                              right: '-10px',
                              float: 'right',
                              margin: '1px',
                            }}
                          >
                            <CheckCircleIcon
                              sx={{
                                color: '#096f4d',
                                bgcolor: 'InfoBackground',
                                borderRadius: '50%',
                              }}
                            />
                          </Box>
                        ) : null}

                        <Card
                          sx={{
                            height: '100%',
                            border: selected ? 'solid' : '',
                            borderColor: selected ? '#096f4d' : '',
                            borderWidth: 'medium',
                            zIndex: 0,
                          }}
                        >
                          <CardActionArea
                            onClick={() => {
                              if (replace) {
                                setReplacedVideo(video);
                              } else {
                                onSelectMedia(null, video);
                              }
                            }}
                          >
                            <CardMedia
                              component="video"
                              src={video.source}
                              height="100px"
                              sx={{
                                objectFit: 'contain',
                              }}
                              controls={play}
                              onClick={() => setPlay(true)}
                            />

                            <CardContent
                              sx={{ paddingBottom: '5px !important' }}
                            >
                              <Typography
                                gutterBottom
                                variant="caption"
                                component="div"
                                fontWeight="bold"
                                color="#092C4C"
                                marginBottom="0"
                              >
                                {video.title || video.id}
                              </Typography>

                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {`${video.thumbnails.data[0].width} x ${video.thumbnails.data[0].height}`}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ) : null}
                  </>
                );
              })}
            </Grid>
          ) : (
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{ textAlign: 'center' }}
              mt={4}
            >
              No Video to Show
            </Typography>
          )
        ) : null}
      </Grid>
    </>
  );
};

export default MediaList;
