import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Brand, FBLeadGenData, FbAssetFeedSpec } from '../../../types';
import { fetchFacebookGenForms } from '../../../services/ads';
import { AdsTemplate, DiyError, TemplateError } from '../../../types/IDiy';
import Spinner from '../../Spinner';

interface LeadGenProps {
  brand: Brand;
  accountId: string;
  assetFeedSpec: FbAssetFeedSpec;
  setAssetFeedSpec?: Dispatch<SetStateAction<FbAssetFeedSpec>>;
  selectedTemplate?: AdsTemplate;
  setSelectedTemplate?: Dispatch<SetStateAction<AdsTemplate>>;
  isDiy?: boolean;
  error?: TemplateError;
  diyError?: DiyError;
  setIsTemplateChanged?: Dispatch<SetStateAction<boolean>>;
  isEditAds?: boolean;
}

const LeadGen: React.FC<LeadGenProps> = ({
  brand,
  accountId,
  assetFeedSpec,
  setAssetFeedSpec,
  isDiy = false,
  selectedTemplate,
  setSelectedTemplate,
  error,
  diyError,
  setIsTemplateChanged,
  isEditAds = false,
}) => {
  const [leadGenForms, setLeadGenForms] = useState<FBLeadGenData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (brand && accountId) {
      getLeadGenForms();
    }
  }, [brand, accountId]);

  const handleSelectLeadForm = (value: FBLeadGenData) => {
    if (isDiy) {
      let temp: AdsTemplate = { ...selectedTemplate, isTemplateChanged: true };
      temp.creativeTemplate.asset_feed_spec.call_to_actions[0] = {
        ...temp.creativeTemplate.asset_feed_spec.call_to_actions[0],
        value: {
          lead_gen_form_id: value.id,
        },
      };

      setSelectedTemplate(temp);
      if (isEditAds) setIsTemplateChanged(true);
    } else {
      let temp: FbAssetFeedSpec = { ...assetFeedSpec };
      temp.call_to_actions[0] = {
        ...(temp.call_to_actions[0] || {
          type: '',
          adlabels: [{ name: 'cta_1' }],
        }),
        value: { lead_gen_form_id: value.id },
      };

      setAssetFeedSpec(temp);
    }
  };

  const getLeadGenForms = async () => {
    setLoading(true);
    try {
      const response = await fetchFacebookGenForms(brand?._id);

      setLeadGenForms(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  /**
   * TODO: Re-enable if we allowing users to create form
  const handleOpenLeadGenForm = () => {
    setOpenCreateLeadForm(true);
  };

  const handleCloseLeadGenForm = () => {
    setOpenCreateLeadForm(false);
  };
   */

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight="bold">
          Instant form
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="caption">
          Make connections with people by letting them send contact information
          and other details to you through a form.
        </Typography>
      </Grid>

      {/**
        * TODO: Re-enable if we allowing users to create form 
      <Grid item xs={12}>
        <PrimaryButton
          title="Create From"
          type="button"
          handleOnClick={handleOpenLeadGenForm}
        />
      </Grid>
      */}

      {loading ? (
        <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
          <Spinner />
        </Grid>
      ) : null}

      {!loading ? (
        <Grid item xs={12}>
          <Autocomplete
            id="leadGenForm"
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {`${option.name} (${option.id})`}
                </li>
              );
            }}
            options={leadGenForms.sort(
              (a, b) => -b.name?.localeCompare(a.name),
            )}
            value={
              assetFeedSpec?.call_to_actions?.length > 0
                ? leadGenForms.find(
                    (form: FBLeadGenData) =>
                      form.id ===
                      assetFeedSpec?.call_to_actions[0]?.value
                        ?.lead_gen_form_id,
                  )
                : null
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Lead Gen Form"
                fullWidth
                size="small"
                placeholder="Select Form Lead"
                required
                InputLabelProps={{ shrink: true }}
                error={
                  error?.leadFormError || diyError?.leadFormError ? true : false
                }
                helperText={
                  error?.leadFormError
                    ? error?.leadFormError
                    : diyError?.leadFormError
                    ? diyError?.leadFormError
                    : ''
                }
              />
            )}
            disableClearable
            onChange={(e: any, value: any) => handleSelectLeadForm(value)}
          />
        </Grid>
      ) : null}

      {/**
        * TODO: Re-enable if we allowing users to create form 
      <PopupModal
        open={openCreateLeadForm}
        handleClose={handleCloseLeadGenForm}
      >
        <LeadGenForm
          brand={brand}
          accountId={accountId}
          accessToken={accessToken}
          onClose={handleCloseLeadGenForm}
          onSelectForm={handleSelectLeadForm}
        />
      </PopupModal>
        */}
    </Grid>
  );
};

export default LeadGen;
