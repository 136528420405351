import React, { useContext, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Pages.module.scss';
import PageHeader from '../../components/PageHeader';
import { PrimaryButton } from '../../components/Buttons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Brand, Campaign, CampaignAd, FbCampaignAdSetForm } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBrandCampaigns,
  getSingleFbAdCreative,
  syncFbAds,
} from '../../services/ads';
import { adsProvider } from '../../utils/constants/facebookAds';
import { DrawerMenu } from '../../components/Menu';
import { AdPreviewData, DiySettings } from '../../types/IDiy';
import {
  ADMIN,
  MARKETING_MANAGER,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import { AuthContext } from '../../context';
import { Box, Grid, Typography } from '@mui/material';
import { XsOnly } from '../../utils/breakpoints';
import ModalHeader from '../../components/Modal/ModalHeader';
import { AdsPreviewSquare } from '../../components/Forms/Diy/AdsPreview';
import { constantStringToHumanized } from '../../utils/stringModifier';
import Spinner from '../../components/Spinner';
import AdListTable from '../../components/Tables/AdsList/AdListTable';
import { getSingleDiySetting, stopFbAds } from '../../services/diy';
import { toggleAlert } from '../../redux/actions';
import { FbAdStatus } from '../../utils/helpers/facebookAdsHelper';
import moment from 'moment';
import { AiOutlineStop } from 'react-icons/ai';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { toCurrency } from '../../utils/numberFormatter';
import { GiReceiveMoney } from 'react-icons/gi';
import { BsPlusSlashMinus } from 'react-icons/bs';
import PopupModal, { ConfirmationModal } from '../../components/Modal';
import CreditPreview from '../../components/Modal/CreditPreview';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const Adz: React.FC = () => {
  const [searchParams] = useSearchParams();
  const fromUpdateBudget = searchParams.get('fromUpdateBudget') === 'true';
  const totalCredit = parseFloat(searchParams.get('creditAmount') || '0');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const { state } = useContext(AuthContext);
  const isAdmin = state.role === ADMIN;
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isSalesperson = state.role === SALESPERSON;
  const isMarketingManager = state.role === MARKETING_MANAGER;
  const capabilities = state.capabilities;
  const [loading, setLoading] = useState<boolean>(false);
  const [stopLoading, setStopLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState<AdPreviewData>(null);
  const [ads, setAds] = useState<CampaignAd[]>([]);
  const [selectedAd, setSelectedAd] = useState<CampaignAd>(null);
  const [diySettings, setDiySettings] = useState<DiySettings>(null);
  const [confirmStop, setConfirmStop] = useState<boolean>(false);
  const [confirmCredit, setConfirmCredit] = useState<boolean>(false);
  const [openCreditPreview, setOpenCreditPreview] = useState<boolean>(false);
  const [creditedAmount, setCreditedAmount] = useState<number>(0);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    if (location || brand) {
      const delayDebounceFn = setTimeout(() => {
        getCampaigns();
        getDiySettings();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [location, brand]);

  useEffect(() => {
    if (selectedAd && brand) {
      getSingleCreative();
    }
  }, [selectedAd, brand]);

  useEffect(() => {
    if (fromUpdateBudget && totalCredit > 0) {
      setCreditedAmount(totalCredit);
      if (totalCredit > 0) setOpenCreditPreview(true);
    }
  }, [fromUpdateBudget, totalCredit]);

  const getCampaigns = async () => {
    setFetchLoading(true);
    try {
      //await syncFbAds((location || brand)?._id);

      const response = await fetchBrandCampaigns(
        (location || brand)?._id,
        adsProvider.FACEBOOK,
        true,
      );

      let tempAds: CampaignAd[] = [];

      response.data.forEach((campaign: Campaign) => {
        campaign?.ads.forEach((ad: CampaignAd) => {
          let params: CampaignAd = { ...ad };
          if (ad.archived) return;

          const adAdSet = campaign?.adSets?.find(
            (adset: FbCampaignAdSetForm) => {
              return adset.id === ad.adset_id;
            },
          );

          const ads = campaign.ads.filter(
            (adObj: CampaignAd) => adObj.adset_id === ad.adset_id,
          );

          const adStatuses = ads.map((adObj: CampaignAd) => adObj.status);

          const creditable = adStatuses.every(
            (status: string) =>
              [
                FbAdStatus.WITH_ISSUES,
                FbAdStatus.DISAPPROVED,
                FbAdStatus.ADSET_PAUSED,
                FbAdStatus.CAMPAIGN_PAUSED,
                FbAdStatus.PAUSED,
              ].includes(status) &&
              ![
                FbAdStatus.PROCESSING_PAYMENT,
                FbAdStatus.PAYMENT_FAILED,
              ].includes(status),
          );

          if (adAdSet) {
            params = {
              ...params,
              start_time: adAdSet?.start_time,
              end_time: adAdSet?.end_time,
              budget: (location || brand)?.allowSetupPayment
                ? (adAdSet?.totalBudget || 0) /
                  (1 - (location || brand)?.budgetPercentageFee / 100)
                : adAdSet?.totalBudget || 0,
              creditable,
              isResumable: campaign?.isResumable,
            };
          }

          tempAds = [...tempAds, params];
        });
      });

      setAds(tempAds);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getSingleCreative = async () => {
    setLoading(true);
    try {
      const response = await getSingleFbAdCreative(
        brand?._id,
        selectedAd?.creative?.creative_id,
      );

      setPreviewData(response?.data?.previewData);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getDiySettings = async () => {
    try {
      const response = await getSingleDiySetting((location || brand)?._id);

      setDiySettings(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleStopAds = async (adId: string, refund?: boolean) => {
    setStopLoading(true);
    try {
      const response = await stopFbAds(adId, (location || brand)?._id, refund);

      let temp: CampaignAd[] = [];
      const adsObj = ads.find((ad: CampaignAd) => ad.id === adId);
      ads.forEach((ad: CampaignAd) => {
        if (refund) {
          if (ad.adset_id === adsObj?.adset_id) {
            ad.refunded = true;
          }
        } else {
          if (ad.id === adId) {
            ad.status = 'PAUSED';
            ad.refunded = true;
          }
        }

        temp = [...temp, ad];
      });

      setAds(temp);
      setOpenPreview(false);

      if ((location || brand)?.allowSetupPayment) {
        setCreditedAmount(response?.creditedAmount || 0);
        if ((response?.creditedAmount || 0) > 0) setOpenCreditPreview(true);
      }
    } catch (error: any) {
      console.log(error);
      const err = error?.response?.data;
      if (err?.message) {
        dispatch(
          toggleAlert({ toggle: true, type: 'error', message: err?.message }),
        );
      }
    } finally {
      setStopLoading(false);
      if (refund) {
        setConfirmCredit(false);
      } else {
        setConfirmStop(false);
      }
    }
  };

  const handleOpenPreview = (ad: CampaignAd) => {
    setSelectedAd(ad);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setSelectedAd(null);
    setOpenPreview(false);
  };

  const handleCloseCreditPreview = () => {
    setOpenCreditPreview(false);
  };

  const handleConfirmChangeTemplate = () => {
    handleCloseConfirmModal();
    navigate(`/adz/edit/${selectedAd?.id}?changeTemplate=true`);
  };

  const handleRejectChangeTemplate = () => {
    handleCloseConfirmModal();
    navigate(`/adz/edit/${selectedAd?.id}?changeTemplate=false`);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  return (
    <div className={styles.page}>
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Adz" />

          <div className={styles.controls}>
            <div className={styles.buttons}>
              {isAdmin ||
              isSuperAdmin ||
              isMarketingManager ||
              (isSalesperson && capabilities?.createAds) ? (
                <PrimaryButton
                  title="Create Adz"
                  type="button"
                  handleOnClick={() => navigate('/adz/diy')}
                />
              ) : null}
            </div>
          </div>
        </div>

        <div>
          <AdListTable
            ads={ads}
            loading={fetchLoading}
            onOpenPreview={handleOpenPreview}
          />
        </div>
      </div>

      <DrawerMenu
        anchor="right"
        open={openPreview}
        onClose={handleClosePreview}
      >
        <Box
          sx={{
            width: XsOnly() ? '100%' : '400px',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-track': {
              // background: "#F1F0F0",
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
            },

            '&::-webkit-scrollbar-thumb': {
              background: '#096f4d',
              borderRadius: '18px',

              '&:hover': {
                background: '#096f4d',
              },
            },
            padding: '20px',
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ModalHeader title="Ad Preview" isCenter />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                minWidth: '100%',
                mb: 2,
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <div>
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    <Box component="div" mb={2}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            flexDirection: 'row',
                            display: 'flex',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="body2"
                            mr={2}
                            sx={{ fontWeight: 'bold' }}
                          >
                            Ad ID
                          </Typography>

                          <Typography variant="body2">
                            {selectedAd?.id}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sx={{
                            flexDirection: 'row',
                            display: 'flex',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="body2"
                            mr={2}
                            sx={{ fontWeight: 'bold' }}
                          >
                            Objective
                          </Typography>

                          <Typography variant="body2">
                            {selectedAd?.objective}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sx={{
                            flexDirection: 'row',
                            display: 'flex',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="body2"
                            mr={2}
                            sx={{ fontWeight: 'bold' }}
                          >
                            Start Time
                          </Typography>

                          <Typography variant="body2">
                            {moment
                              .unix(selectedAd?.start_time as number)
                              .local()
                              .format('LL')}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sx={{
                            flexDirection: 'row',
                            display: 'flex',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="body2"
                            mr={2}
                            sx={{ fontWeight: 'bold' }}
                          >
                            End Time
                          </Typography>

                          <Typography variant="body2">
                            {selectedAd?.end_time
                              ? moment
                                  .unix(selectedAd?.end_time as number)
                                  .local()
                                  .format('LL')
                              : 'On-going'}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sx={{
                            flexDirection: 'row',
                            display: 'flex',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="body2"
                            mr={2}
                            sx={{ fontWeight: 'bold' }}
                          >
                            Budget
                          </Typography>

                          <Typography variant="body2">
                            {toCurrency('USD', selectedAd?.budget || 0)}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sx={{
                            minWidth: '100%',
                            mb: 2,
                            justifyContent: 'center',
                            display: 'flex',
                          }}
                        >
                          <AdsPreviewSquare
                            page={diySettings?.facebookPage}
                            body={previewData?.body}
                            isImage={previewData?.format === 'SINGLE_IMAGE'}
                            imageUrl={previewData?.imageUrl}
                            websiteUrl={previewData?.url}
                            headline={previewData?.headline}
                            description={previewData?.description}
                            cta={constantStringToHumanized(
                              previewData?.cta || '',
                            )}
                            videoUrl={previewData?.videoUrl}
                            objective={selectedAd?.objective}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sx={{
                            width: '100%',
                            textAlign: 'center',
                            position: 'sticky',
                            bottom: 0,
                          }}
                        >
                          {!(selectedAd?.status === FbAdStatus.WITH_ISSUES) ? (
                            <PrimaryButton
                              title="A/B Test"
                              type="button"
                              handleOnClick={() =>
                                navigate(`/adz/ab-test/${selectedAd?.id}`)
                              }
                              marginRight5
                              startIcon={<AddOutlinedIcon />}
                              loading={stopLoading}
                            />
                          ) : null}

                          {/** TODO: Only show for Active campaigns */}
                          <PrimaryButton
                            title="Edit"
                            type="button"
                            handleOnClick={() => setOpenConfirmModal(true)}
                            marginRight5
                            startIcon={<EditOutlinedIcon />}
                            loading={stopLoading}
                          />

                          {selectedAd?.creditable &&
                          !selectedAd?.refunded &&
                          (location || brand)?.allowSetupPayment ? (
                            <PrimaryButton
                              title={
                                confirmCredit
                                  ? 'Really request credit?'
                                  : 'Request Credit'
                              }
                              type="button"
                              handleOnClick={() =>
                                confirmCredit
                                  ? handleStopAds(selectedAd?.id, true)
                                  : setConfirmCredit(true)
                              }
                              marginRight5
                              startIcon={<GiReceiveMoney />}
                              loading={stopLoading}
                              theme={confirmCredit ? 'red' : '#096f4d'}
                            />
                          ) : null}

                          {selectedAd?.status === FbAdStatus.ACTIVE ? (
                            <PrimaryButton
                              title="Budget"
                              type="button"
                              handleOnClick={() =>
                                navigate(`/adz/budget/${selectedAd?.id}`)
                              }
                              marginRight5
                              startIcon={<BsPlusSlashMinus />}
                              loading={stopLoading}
                            />
                          ) : null}

                          {selectedAd?.status === FbAdStatus.ACTIVE ? (
                            <PrimaryButton
                              title={confirmStop ? 'Really stop?' : 'Stop Ads'}
                              type="button"
                              handleOnClick={() =>
                                confirmStop
                                  ? handleStopAds(selectedAd?.id)
                                  : setConfirmStop(true)
                              }
                              marginRight5
                              startIcon={<AiOutlineStop />}
                              loading={stopLoading}
                              theme={confirmStop ? 'red' : '#096f4d'}
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </DrawerMenu>

      <PopupModal
        open={openCreditPreview}
        handleClose={handleCloseCreditPreview}
      >
        <CreditPreview amount={creditedAmount} />
      </PopupModal>

      <ConfirmationModal
        open={openConfirmModal}
        onConfirm={handleConfirmChangeTemplate}
        onReject={handleRejectChangeTemplate}
        onClose={handleCloseConfirmModal}
        title="Change Template"
        subtitle="Do you want to change your template?"
      />
    </div>
  );
};

export default Adz;
