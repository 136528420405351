import { Box } from '@mui/material';
import React from 'react';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { CustomLoadingOverlay } from '../../Loading';
import { DataGrid } from '@mui/x-data-grid';
import { styledDataGrid } from '../../../utils/helpers/DataGridHelper';
import { leadFormColumns } from './LeadFormListTableColumn';
import { LeadFormData } from '../../../types';
import moment from 'moment';

interface LeadFormListTableProps {
  leadForms: LeadFormData[];
  loading: boolean;
}

const LeadFormListTable: React.FC<LeadFormListTableProps> = ({
  leadForms,
  loading,
}) => {
  const rows = leadForms.map((form: LeadFormData) => {
    return {
      id: form?.id,
      name: form?.name || '',
      formId: form?.id,
      createdAt: moment(form?.createdAt).format('MM-DD-YYYY hh:mm A'),
      description: form?.description,
    };
  });

  return (
    <Box className={styles.table}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
        }}
        density="compact"
        rows={rows}
        autoHeight
        hideFooterSelectedRowCount
        rowsPerPageOptions={[25, 50, 100]}
        components={{ LoadingOverlay: CustomLoadingOverlay }}
        loading={loading}
        columns={leadFormColumns}
        sx={styledDataGrid()}
      />
    </Box>
  );
};

export default LeadFormListTable;
