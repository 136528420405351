import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Agency, Brand, ChangeEventType } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import {
  setAgencies,
  setAgency,
  setBrand,
  setBrandFilter,
  setBrands,
  setBudgetReportProvider,
  setCampaignProvider,
  setLocation,
  setLocations,
} from '../../redux/actions';
import {
  ADMIN,
  AGENCY,
  AGENCY_SESSION,
  BRAND_FILTER_SESSION,
  BRAND_MANAGER,
  BRAND_SESSION,
  BUDGET_PROVIDER_SESSION,
  CAMPAIGN_PROVIDER_SESSION,
  IS_DIY_ADZ,
  LOCATION_SESSION,
  MARKETING_MANAGER,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import { AuthContext } from '../../context';
import { humanizeString } from '../../utils/stringModifier';
import {
  getBrands,
  getBrandsAssociatedLocations,
  getBrandsAssociatedWithAgency,
  getBrandsAssociatedWithManager,
  getBrandsAssociatedWithMarketingManager,
  getBrandsAssociatedWithSalesperson,
} from '../../services/brand';
import { getAgencies } from '../../services/agency';
import { fetchBrandCampaigns } from '../../services/ads';
import { arrayUniqueFilter } from '../../utils/arrayFormatter';
import { adsProvider } from '../../utils/constants/facebookAds';

interface MobileFiltersProps {
  open: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const MobileFilters: React.FC<MobileFiltersProps> = ({ open, setLoading }) => {
  const pathLocation = useLocation();
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const { state } = useContext(AuthContext);

  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAdmin = state.role === ADMIN;
  const isAgency = state.role === AGENCY;
  const isBrandManager = state.role === BRAND_MANAGER;
  const isSalesperson = state.role === SALESPERSON;
  const isMarketingManager = state.role === MARKETING_MANAGER;
  const locationBrand = state.authUser?.brand;
  const isFranchisee = locationBrand !== null && locationBrand !== undefined;
  const capabilities = state.capabilities;
  const pathNames = pathLocation.pathname.split('/');
  const isBudget = pathNames.includes('budgets');
  const isCampaign = pathNames.includes('campaigns');

  const brands: Brand[] = useSelector((state: any) => state?.brands?.brands);
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const locations: Brand[] = useSelector(
    (state: any) => state?.locations?.locations,
  );
  const campaignProvider: string = useSelector(
    (state: any) => state?.campaignProvider?.campaignProvider,
  );
  const budgetProvider =
    useSelector((state: any) => state?.budgetReportProvider?.provider) ||
    'facebook';
  const agencies: Agency[] = useSelector(
    (state: any) => state?.agencies?.agencies,
  );
  const agency: Agency = useSelector((state: any) => state?.agency?.agency);
  const brandFilter: string = useSelector(
    (state: any) => state?.brandFilter?.filter?.filter,
  );
  const budgetProviderSession = localStorage.getItem(BUDGET_PROVIDER_SESSION);
  const sessionFilter = localStorage.getItem(BRAND_FILTER_SESSION);

  const [selectedBrand, setSelectedBrand] = useState<Brand>(null);
  const [selectedLocation, setSelectedLocation] = useState<Brand>(null);
  const [campaignProviders, setCampaignProviders] = useState<string[]>([]);
  const [selectedAgency, setSelectedAgency] = useState<Agency>(null);

  useEffect(() => {
    if (budgetProviderSession) {
      reduxDispatch(setBudgetReportProvider(budgetProviderSession));
    } else {
      reduxDispatch(setBudgetReportProvider('facebook'));
    }
  }, [budgetProviderSession]);

  useEffect(() => {
    if (isSuperAdmin || isAdmin) {
      handleSelectFilter(sessionFilter ? sessionFilter : 'active');
      getAllAgencies(sessionFilter ? sessionFilter : 'active');
    }
  }, [isSuperAdmin, isAdmin]);

  useEffect(() => {
    if (agency) {
      setSelectedAgency(agency);
    }
  }, [agency, brandFilter]);

  useEffect(() => {
    if (!(isSuperAdmin || isAdmin)) {
      getAllBrands();
    }
  }, [isSuperAdmin, isAdmin]);

  useEffect(() => {
    if (brand) {
      setSelectedBrand(brand);
      getCampaigns(brand);
      if (brand?.allowLocations) {
        getAllLocations(brand);
      }
    }
  }, [brand, isCampaign]);

  const getAllBrands = async (agency?: Agency, brandFilter?: string) => {
    setLoading(true);
    try {
      let data: Brand[] = [];
      if (isAgency) {
        const response = await getBrandsAssociatedWithAgency(
          state.roleBasedId,
          1,
          1000,
          brandFilter ? brandFilter : 'active',
          undefined,
          undefined,
          IS_DIY_ADZ,
        );

        data = [...response.data];

        setLoading(false);
      } else if (isBrandManager) {
        const response = await getBrandsAssociatedWithManager(
          state.roleBasedId,
          1,
          1000,
          brandFilter ? brandFilter : 'active',
          isFranchisee,
          undefined,
          undefined,
          IS_DIY_ADZ,
        );

        data = [...response.data];

        setLoading(false);
      } else if (isSalesperson) {
        //let response: any;
        const response = await getBrandsAssociatedWithSalesperson(
          state.roleBasedId,
          1,
          1000,
          brandFilter ? brandFilter : 'active',
          isFranchisee,
          undefined,
          undefined,
          IS_DIY_ADZ,
        );

        data = [...response.data];

        setLoading(false);
      } else if (isMarketingManager) {
        const response = await getBrandsAssociatedWithMarketingManager(
          state.roleBasedId,
          1,
          1000,
          brandFilter ? brandFilter : 'active',
          isFranchisee,
          null,
          null,
          IS_DIY_ADZ,
        );

        data = [...response.data];

        setLoading(false);
      } else {
        if (agency) {
          const response = await getBrandsAssociatedWithAgency(
            agency?._id,
            1,
            1000,
            brandFilter ? brandFilter : 'active',
            undefined,
            undefined,
            IS_DIY_ADZ,
          );

          data = [...response.data];
        } else {
          const response = await getBrands(
            1,
            1000,
            brandFilter ? brandFilter : 'active',
            undefined,
            undefined,
            undefined,
            IS_DIY_ADZ,
          );

          data = [...response.data];
        }
      }

      reduxDispatch(setBrands(data));
      const sessionBrand = localStorage.getItem(BRAND_SESSION);

      if (sessionBrand) {
        const brand = data.find((brand: Brand) => {
          return brand?._id === sessionBrand;
        });

        reduxDispatch(setBrand(brand));
      } else {
        reduxDispatch(setBrand(data[0]));
        localStorage.setItem(BRAND_SESSION, data[0]?._id);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const getAllAgencies = async (brandFilter: string) => {
    try {
      setLoading(true);
      const response = await getAgencies(
        1,
        1000,
        undefined,
        undefined,
        undefined,
        IS_DIY_ADZ,
      );
      reduxDispatch(setAgencies(response.data));

      const agencySession = localStorage.getItem(AGENCY_SESSION);

      if (agencySession) {
        const agencyData = response.data.find((agency: Agency) => {
          return agency._id === agencySession;
        });

        reduxDispatch(setAgency(agencyData));
        getAllBrands(agencyData, brandFilter);
      } else {
        reduxDispatch(setAgency(null));
        getAllBrands(null, brandFilter);
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const getCampaigns = async (brand: Brand) => {
    setLoading(true);
    try {
      const campaigns = await fetchBrandCampaigns(brand?._id);

      const providers = campaigns?.data.map(
        (campaign: any) => campaign.provider,
      );

      const filteredProviders = arrayUniqueFilter(providers, 'provider');
      const providerSession = localStorage.getItem(CAMPAIGN_PROVIDER_SESSION);

      if (isCampaign) {
        if (providerSession) {
          reduxDispatch(setCampaignProvider(providerSession));
        } else {
          reduxDispatch(setCampaignProvider(adsProvider.FACEBOOK));
          localStorage.setItem(CAMPAIGN_PROVIDER_SESSION, adsProvider.FACEBOOK);
        }
      } else {
        setCampaignProviders(filteredProviders);

        if (filteredProviders.length > 1) {
          if (providerSession) {
            reduxDispatch(setCampaignProvider(providerSession));
          } else {
            reduxDispatch(setCampaignProvider(adsProvider.FACEBOOK));
            localStorage.setItem(
              CAMPAIGN_PROVIDER_SESSION,
              adsProvider.FACEBOOK,
            );
          }
        } else if (filteredProviders.length > 0) {
          reduxDispatch(setCampaignProvider(filteredProviders[0]));
          localStorage.setItem(CAMPAIGN_PROVIDER_SESSION, filteredProviders[0]);
        } else {
          localStorage.removeItem(CAMPAIGN_PROVIDER_SESSION);
        }
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const getAllLocations = async (brand?: Brand, brandFilter?: string) => {
    setLoading(true);
    try {
      const response = await getBrandsAssociatedLocations(
        brand?._id,
        1,
        1000,
        brandFilter ? brandFilter : 'active',
      );

      setLoading(false);

      reduxDispatch(setLocations(response.data));
      const sessionLocation = localStorage.getItem(LOCATION_SESSION);

      if (sessionLocation) {
        const locationData = response.data.find((location: Brand) => {
          return location._id === sessionLocation;
        });

        reduxDispatch(setLocation(locationData));
      } else {
        reduxDispatch(setLocation(null));
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSelectBrand = (brand: Brand) => {
    reduxDispatch(setLocation(null));
    reduxDispatch(setLocations([]));
    reduxDispatch(setBrand(brand));
    localStorage.setItem(BRAND_SESSION, brand?._id);
  };

  const handleSelectLocation = (location: Brand, reason: string) => {
    if (reason === 'clear') {
      setSelectedLocation(null);
      reduxDispatch(setLocation(null));
      localStorage.removeItem(LOCATION_SESSION);
    } else {
      setSelectedLocation(location);
      reduxDispatch(setLocation(location));
      localStorage.setItem(LOCATION_SESSION, location?._id);
    }
  };

  const handleOnSelectBudgetProvider = (value: string) => {
    reduxDispatch(setBudgetReportProvider(value));
    localStorage.setItem(BUDGET_PROVIDER_SESSION, value);
  };

  const handleOnSelectProvider = (value: string) => {
    reduxDispatch(setCampaignProvider(value));
    localStorage.setItem(CAMPAIGN_PROVIDER_SESSION, value);
  };

  const handleSelectAgency = (agency: Agency, reason: string) => {
    if (reason === 'clear') {
      setSelectedAgency(null);
      reduxDispatch(setAgency(null));
      localStorage.removeItem(AGENCY_SESSION);
      localStorage.removeItem(BRAND_SESSION);
      getAllBrands(null, brandFilter);
    } else {
      setSelectedAgency(agency);
      reduxDispatch(setAgency(agency));
      localStorage.setItem(AGENCY_SESSION, agency?._id);
      localStorage.removeItem(BRAND_SESSION);
      getAllBrands(agency, brandFilter);
    }
  };

  const handleOnSelectBrandFilter = (value: string) => {
    reduxDispatch(setBrandFilter({ filter: value }));
    localStorage.setItem(BRAND_FILTER_SESSION, value);
    localStorage.removeItem(BRAND_SESSION);
    getAllBrands(selectedAgency, value);
  };

  const handleSelectFilter = (filter: string) => {
    reduxDispatch(setBrandFilter({ filter }));
  };

  return (
    <Slide direction="down" in={open} mountOnEnter unmountOnExit>
      <Grid
        container
        spacing={1}
        mb={2}
        sx={{ justifyContent: 'center', display: 'flex', width: '100%' }}
      >
        {brands?.length > 1 && !isBudget ? (
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="brands"
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option._id}>
                    {option.brand}
                  </li>
                );
              }}
              options={brands?.sort((a, b) => -b.brand?.localeCompare(a.brand))}
              value={selectedBrand}
              getOptionLabel={(option) => option?.brand}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Brands"
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '50px',
                      backgroundColor: 'white',
                      legend: {
                        marginLeft: '10px',
                      },
                    },
                    '& .MuiAutocomplete-inputRoot': {
                      paddingLeft: '20px !important',
                      borderRadius: '50px',
                    },
                    '& .MuiInputLabel-outlined': {
                      backgroundColor: 'none',
                    },
                    '& .MuiInputLabel-shrink': {
                      paddingRight: 0,
                      marginLeft: '10px',
                    },
                    input: {
                      '&::placeholder': {
                        textOverflow: 'ellipsis !important',
                        color: 'black',
                        fontWeight: 'bold',
                      },
                    },
                  }}
                />
              )}
              disableClearable
              onChange={async (e: any, value: any, reason, detail) => {
                handleSelectBrand(value);
                navigate(IS_DIY_ADZ ? '/reportz' : '/reports');
              }}
              PaperComponent={({ children }) => {
                return (
                  <Paper>
                    {children}
                    <Box component="div" sx={{ textAlign: 'center' }}>
                      <Button
                        type="button"
                        startIcon={<AddIcon sx={{ fontWeight: 'bold' }} />}
                        onMouseDown={() =>
                          navigate(
                            `/${IS_DIY_ADZ ? 'brandz' : 'brands'}/create`,
                          )
                        }
                        variant="text"
                        sx={{ color: '#096f4d', fontWeight: 'bold' }}
                      >
                        Add New Brand
                      </Button>
                    </Box>
                  </Paper>
                );
              }}
            />
          </Grid>
        ) : null}

        {brand?.allowLocations && !isBudget ? (
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="locations"
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option._id}>
                    {option.brand}
                  </li>
                );
              }}
              options={locations?.sort(
                (a, b) => -b.brand?.localeCompare(a.brand),
              )}
              value={selectedLocation}
              getOptionLabel={(option) => option?.brand}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select Location"
                  label={humanizeString(
                    `${brand?.locationLabel || 'location'}s`,
                  )}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '50px',
                      backgroundColor: 'white',
                      legend: {
                        marginLeft: '10px',
                      },
                    },
                    '& .MuiAutocomplete-inputRoot': {
                      paddingLeft: '20px !important',
                      borderRadius: '50px',
                    },
                    '& .MuiInputLabel-outlined': {
                      backgroundColor: 'none',
                    },
                    '& .MuiInputLabel-shrink': {
                      paddingRight: 0,
                      marginLeft: '10px',
                    },
                    input: {
                      '&::placeholder': {
                        textOverflow: 'ellipsis !important',
                        color: 'black',
                        fontWeight: 'bold',
                      },
                    },
                  }}
                />
              )}
              onChange={async (e: any, value: any, reason, detail) => {
                handleSelectLocation(value, reason);
                navigate(IS_DIY_ADZ ? '/reportz' : '/reports');
              }}
              PaperComponent={({ children }) => {
                return (
                  <Paper>
                    {children}

                    {isSuperAdmin ||
                    isAdmin ||
                    (isMarketingManager && capabilities?.addLocations) ? (
                      <Box component="div" sx={{ textAlign: 'center' }}>
                        <Button
                          type="button"
                          startIcon={<AddIcon sx={{ fontWeight: 'bold' }} />}
                          onMouseDown={() =>
                            navigate(
                              `/${
                                IS_DIY_ADZ ? 'locationz' : 'locations'
                              }/create`,
                            )
                          }
                          variant="text"
                          sx={{ color: '#096f4d', fontWeight: 'bold' }}
                        >
                          {`Add New ${humanizeString(
                            brand?.locationLabel || 'location',
                          )}`}
                        </Button>
                      </Box>
                    ) : null}
                  </Paper>
                );
              }}
            />
          </Grid>
        ) : locations?.length === 1 && !isBudget ? (
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h6"
              color="#484848"
              fontWeight="bold"
              sx={{ marginRight: '5px' }}
            >
              {locations[0].brand}
            </Typography>
          </Grid>
        ) : null}

        {(campaignProviders.length > 1 && campaignProvider) ||
        isCampaign ||
        (isBudget && (isSuperAdmin || isAdmin || isAgency)) ? (
          <Grid item xs={12} sm={6}>
            <TextField
              id="campaignProviders"
              select
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '50px',
                  backgroundColor: 'white',
                  legend: {
                    marginLeft: '10px',
                  },
                },
                '& .MuiSelect-select': {
                  marginLeft: '10px',
                },
                '& .MuiInputLabel-outlined': {
                  backgroundColor: 'none',
                },
                '& .MuiInputLabel-shrink': {
                  paddingRight: 0,
                  marginLeft: '10px',
                },
                input: {
                  '&::placeholder': {
                    textOverflow: 'ellipsis !important',
                    color: 'black',
                    fontWeight: 'bold',
                  },
                },
              }}
              label="Provider"
              value={isBudget ? budgetProvider : campaignProvider}
              InputLabelProps={{ shrink: true }}
              size="small"
              onChange={(e: ChangeEventType) => {
                isBudget
                  ? handleOnSelectBudgetProvider(e.target.value)
                  : handleOnSelectProvider(e.target.value);
              }}
            >
              {['facebook', 'google'].map((option: string) => (
                <MenuItem key={option} value={option}>
                  {humanizeString(option)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        ) : null}

        {(isAdmin || isSuperAdmin) && agencies?.length > 0 ? (
          <>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="agencies"
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option._id}>
                      {option.agency}
                    </li>
                  );
                }}
                options={agencies?.sort(
                  (a, b) => -b.agency?.localeCompare(a.agency),
                )}
                value={selectedAgency}
                getOptionLabel={(option) => option?.agency}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="All Agencies"
                    label="Agencies"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '50px',
                        backgroundColor: 'white',
                        legend: {
                          marginLeft: '10px',
                        },
                      },
                      '& .MuiAutocomplete-inputRoot': {
                        paddingLeft: '20px !important',
                        borderRadius: '50px',
                      },
                      '& .MuiInputLabel-outlined': {
                        backgroundColor: 'none',
                      },
                      '& .MuiInputLabel-shrink': {
                        paddingRight: 0,
                        marginLeft: '10px',
                      },
                      input: {
                        '&::placeholder': {
                          textOverflow: 'ellipsis !important',
                          color: 'black',
                          fontWeight: 'bold',
                        },
                      },
                    }}
                  />
                )}
                onChange={async (e: any, value: any, reason, detail) => {
                  handleSelectAgency(value, reason);
                  navigate(IS_DIY_ADZ ? '/reportz' : '/reports');
                }}
              />
            </Grid>

            {brandFilter && !isBudget ? (
              <Grid item xs={12} sm={6}>
                <TextField
                  id="filters"
                  select
                  sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '50px',
                      backgroundColor: 'white',
                      legend: {
                        marginLeft: '10px',
                      },
                    },
                    '& .MuiInputLabel-outlined': {
                      backgroundColor: 'none',
                    },
                    '& .MuiInputLabel-shrink': {
                      paddingRight: 0,
                      marginLeft: '10px',
                    },
                    '& .MuiSelect-select': {
                      marginLeft: '10px',
                    },
                    input: {
                      '&::placeholder': {
                        textOverflow: 'ellipsis !important',
                        color: 'black',
                        fontWeight: 'bold',
                      },
                    },
                  }}
                  label="Brand Filter"
                  value={brandFilter}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  onChange={(e: ChangeEventType) => {
                    handleOnSelectBrandFilter(e.target.value);
                  }}
                >
                  {['active', 'inactive'].map((option: string) => (
                    <MenuItem key={option} value={option}>
                      {humanizeString(option)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : null}
          </>
        ) : null}
      </Grid>
    </Slide>
  );
};

export default MobileFilters;
