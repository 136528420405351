import axios from 'axios';
import { Brand } from '../types';
import { apiBaseUrl } from './api';

export const createBrand = async (obj: Brand) => {
  const data = await axios.post(`${apiBaseUrl}/brand/create`, obj);

  return data?.data;
};

export const editBrand = async (obj: Brand, brandId: string) => {
  try {
    const data = await axios.put(`${apiBaseUrl}/brand/edit/${brandId}`, obj);

    return data?.data;
  } catch (error: any) {
    console.log(error);
  }
};

export const deleteBrand = async (brandId: string) => {
  try {
    const data = await axios.delete(`${apiBaseUrl}/brand/remove/${brandId}`);

    return data?.data;
  } catch (error: any) {
    console.log(error);
  }
};

export const getBrands = async (
  pageNum?: number,
  limit?: number,
  filter?: string,
  sortedField?: string,
  sortType?: string,
  fromSort?: boolean,
  diy?: boolean,
) => {
  try {
    const data = await axios.get(
      `${apiBaseUrl}/brand/get${
        pageNum !== undefined && limit !== undefined
          ? `?pageNum=${pageNum}&limit=${limit}`
          : ''
      }${filter !== undefined ? `&filter=${filter}` : ''}${
        sortedField !== undefined
          ? `&sortedField=${sortedField}`
          : '&sortedField=brand'
      }${sortType !== undefined ? `&sortType=${sortType}` : '&sortType=asc'}${
        fromSort ? `&fromSort=${true}` : ''
      }${diy !== undefined ? `&diy=${diy}` : ''}`,
    );

    return data?.data.results;
  } catch (error) {
    console.log(error);
  }
};

export const getBrandsAssociatedWithAgency = async (
  agencyId: string,
  pageNum?: number,
  limit?: number,
  filter?: string,
  sortedField?: string,
  sortType?: string,
  diy?: boolean,
) => {
  try {
    const data = await axios.get(
      `${apiBaseUrl}/brand/get?agencyId=${agencyId}${
        pageNum !== undefined && limit !== undefined
          ? `&pageNum=${pageNum}&limit=${limit}`
          : ''
      }${filter !== undefined ? `&filter=${filter}` : ''}${
        sortedField !== undefined
          ? `&sortedField=${sortedField}`
          : '&sortedField=brand'
      }${sortType !== undefined ? `&sortType=${sortType}` : '&sortType=asc'}${
        diy !== undefined ? `&diy=${diy}` : ''
      }`,
    );

    return data?.data.results;
  } catch (error) {
    console.log(error);
  }
};

export const getBrandsAssociatedWithSalesperson = async (
  salespersonId: string,
  pageNum?: number,
  limit?: number,
  filter?: string,
  isLocation?: boolean,
  sortedField?: string,
  sortType?: string,
  diy?: boolean,
) => {
  try {
    const data = await axios.get(
      `${apiBaseUrl}/brand/get?salespersonId=${salespersonId}${
        pageNum !== undefined && limit !== undefined
          ? `&pageNum=${pageNum}&limit=${limit}`
          : ''
      }${filter !== undefined ? `&filter=${filter}` : ''}${
        isLocation ? `&type=location` : ''
      }${
        sortedField !== undefined
          ? `&sortedField=${sortedField}`
          : '&sortedField=brand'
      }${sortType !== undefined ? `&sortType=${sortType}` : '&sortType=asc'}${
        diy !== undefined ? `&diy=${diy}` : ''
      }`,
    );

    return data?.data.results;
  } catch (error) {
    console.log(error);
  }
};

export const getBrandsAssociatedWithMarketingManager = async (
  marketingManagerId: string,
  pageNum?: number,
  limit?: number,
  filter?: string,
  isLocation?: boolean,
  sortedField?: string,
  sortType?: string,
  diy?: boolean,
) => {
  try {
    const data = await axios.get(
      `${apiBaseUrl}/brand/get?marketingManagerId=${marketingManagerId}${
        pageNum !== undefined && limit !== undefined
          ? `&pageNum=${pageNum}&limit=${limit}`
          : ''
      }${filter !== undefined ? `&filter=${filter}` : ''}${
        isLocation ? `&type=location` : ''
      }${
        sortedField !== undefined
          ? `&sortedField=${sortedField}`
          : '&sortedField=brand'
      }${sortType !== undefined ? `&sortType=${sortType}` : '&sortType=asc'}${
        diy !== undefined ? `&diy=${diy}` : ''
      }`,
    );

    return data?.data.results;
  } catch (error) {
    console.log(error);
  }
};

export const getBrandsAssociatedWithManager = async (
  managerId: string,
  pageNum?: number,
  limit?: number,
  filter?: string,
  isLocation?: boolean,
  sortedField?: string,
  sortType?: string,
  diy?: boolean,
) => {
  try {
    const data = await axios.get(
      `${apiBaseUrl}/brand/get?managerId=${managerId}${
        pageNum !== undefined && limit !== undefined
          ? `&pageNum=${pageNum}&limit=${limit}`
          : ''
      }${filter !== undefined ? `&filter=${filter}` : ''}${
        isLocation ? `&type=location` : ''
      }${
        sortedField !== undefined
          ? `&sortedField=${sortedField}`
          : '&sortedField=brand'
      }${sortType !== undefined ? `&sortType=${sortType}` : '&sortType=asc'}${
        diy !== undefined ? `&diy=${diy}` : ''
      }`,
    );

    return data?.data.results;
  } catch (error) {
    console.log(error);
  }
};

export const getBrandsAssociatedWithPage = async (
  pageId: string,
  pageNum?: number,
  limit?: number,
  filter?: string,
  sortedField?: string,
  sortType?: string,
) => {
  try {
    const data = await axios.get(
      `${apiBaseUrl}/brand/get?pageId=${pageId}${
        pageNum !== undefined && limit !== undefined
          ? `&pageNum=${pageNum}&limit=${limit}`
          : ''
      }${filter !== undefined ? `&filter=${filter}` : ''}${
        sortedField !== undefined
          ? `&sortedField=${sortedField}`
          : '&sortedField=brand'
      }${sortType !== undefined ? `&sortType=${sortType}` : '&sortType=asc'}`,
    );

    return data?.data.results;
  } catch (error) {
    console.log(error);
  }
};

export const getSingleBrand = async (brand: string) => {
  const data = await axios.get(`${apiBaseUrl}/brand/get/single?brand=${brand}`);

  return data?.data;
};

export const getBrandsAssociatedLocations = async (
  franchisorId: string,
  pageNum?: number,
  limit?: number,
  filter?: string,
  sortedField?: string,
  sortType?: string,
) => {
  try {
    const data = await axios.get(
      `${apiBaseUrl}/brand/get?franchisorId=${franchisorId}${
        pageNum !== undefined && limit !== undefined
          ? `&pageNum=${pageNum}&limit=${limit}`
          : ''
      }${filter !== undefined ? `&filter=${filter}` : ''}${
        sortedField !== undefined
          ? `&sortedField=${sortedField}`
          : '&sortedField=brand'
      }${sortType !== undefined ? `&sortType=${sortType}` : '&sortType=asc'}`,
    );

    return data?.data.results;
  } catch (error) {
    console.log(error);
  }
};

export const connectFbAccountToBrand = async (token: string) => {
  const data = await axios.post(`${apiBaseUrl}/brand/connect/facebook`, {
    token,
  });

  return data?.data;
};
