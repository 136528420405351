import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { InviteInfo, Salesperson } from '../../../types';
import { columns, mobileColumns } from './UserListTableColumns';
import { useNavigate } from 'react-router-dom';
import { CustomLoadingOverlay } from '../../Loading';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { useMediaQuery, useTheme } from '@mui/material';
import { styledDataGrid } from '../../../utils/helpers/DataGridHelper';

interface UserListTableProps {
  salespersons: Salesperson[];
  handleOnClickEdit: (salesperson: Salesperson) => void;
  handleOnClickDelete: (id: string) => void;
  handleOnClickInviteBtn: (info: InviteInfo) => void;
  loading: boolean;
  handleBanSalesPerson: (id: string) => void;
  handleUnbanSalesPerson: (id: string) => void;
  setPage: (page: number) => void;
  rowData: number;
  setUserPage: (pageNum: number, limit: number) => void;
  currentLimit: number;
  pageSize: number;
  setUserSize: (pageNum: number, limit: number) => void;
  page: number;
  onSort: (sortedField: string, sortType: string) => void;
}

const UserListTable: React.FC<UserListTableProps> = ({
  salespersons,
  handleOnClickEdit,
  handleOnClickDelete,
  handleOnClickInviteBtn,
  loading,
  handleBanSalesPerson,
  handleUnbanSalesPerson,
  setPage,
  rowData,
  setUserPage,
  currentLimit,
  pageSize,
  setUserSize,
  page,
  onSort,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isXsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  const renderColumns = () => {
    if (isXsOnly) {
      return mobileColumns(isXsOnly);
    }

    return columns;
  };

  const rows = salespersons
    ?.slice()
    .map((salesperson: Salesperson, index: number) => {
      return {
        name: salesperson.name,
        firstname: salesperson.firstname,
        lastname: salesperson.lastname,
        email: salesperson.email,
        cell: salesperson.cell,
        broker: salesperson.broker,
        investment: salesperson.investment,
        role: salesperson.role,
        user: salesperson?.user,
        _id: salesperson._id,
        id: salesperson.salespersonId,
        active: salesperson.active,
        handleOnClickEdit: handleOnClickEdit,
        handleOnClickDelete: handleOnClickDelete,
        handleOnClickInviteBtn: handleOnClickInviteBtn,
        navigate: navigate,
        handleBanSalesPerson,
        handleUnbanSalesPerson,
        withUser: salesperson?.user !== undefined && salesperson?.user !== null,
      };
    });

  return (
    <Box className={styles.table}>
      <DataGrid
        density="compact"
        rows={rows}
        columns={renderColumns()}
        autoHeight
        hideFooterSelectedRowCount
        pageSize={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
        }}
        loading={loading}
        page={page}
        onPageChange={(page) => {
          setPage(page);
          setUserPage(page + 1, currentLimit);
        }}
        onPageSizeChange={(newLimit) => {
          setUserSize(1, newLimit);
        }}
        rowCount={rowData}
        sortingMode="server"
        onSortModelChange={(e: any) => {
          onSort(e[0]?.field || 'name', e[0]?.sort || 'asc');
        }}
        sx={styledDataGrid()}
      />
    </Box>
  );
};

export default UserListTable;
