import React from 'react';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Box } from '@mui/material';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { CustomLoadingOverlay } from '../../Loading';
import { columns } from './AccoutPayoutListTableColumns';
import { StripePayout } from '../../../types';

interface AccountPayoutListTableProps {
  payouts: StripePayout[];
  loading: boolean;
}

const AccountPayoutListTable: React.FC<AccountPayoutListTableProps> = ({
  payouts,
  loading,
}) => {
  const rows = payouts.map((payout, index) => {
    return {
      id: payout.id || `${index}`,
      amount: payout.amount / 100,
      arrivalDate: payout.arrival_date,
      formattedArrivalDate: moment.unix(payout.arrival_date).format('LL'),
      status: payout.status,
      type: payout.type,
      createdAt: payout.created,
      formattedDate: moment.unix(payout.created).format('LL'),
    };
  });

  return (
    <Box className={styles.table}>
      <DataGrid
        density="compact"
        rows={rows}
        columns={columns}
        autoHeight
        getRowHeight={() => 50}
        hideFooterSelectedRowCount
        components={{
          LoadingOverlay: CustomLoadingOverlay,
        }}
        loading={loading}
      />
    </Box>
  );
};

export default AccountPayoutListTable;
