import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { chatStatus } from "../../../utils/constants/chatStatus";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import MarkChatUnreadOutlinedIcon from "@mui/icons-material/MarkChatUnreadOutlined";
import { Brand } from "../../../types";
import {
  chatResult,
  isCompletedEngagement,
  isNewMessage,
  isPartialEngagement,
  isUnengagedMessage,
  isWaitingForResponse,
} from "../../../utils/chatDialogHelper";
import styles from "../../../assets/styles/components/Tables/Tables.module.scss";

const status = (result: string, brand: Brand, investment: string) => {
  if (brand?.phoneNumReq === "Yes") {
    if (result === "LeadSentToBrand") {
      return chatStatus.Lead;
    }
    if (result === "CallScheduled") {
      return chatStatus.Scheduled;
    }

    return chatStatus.NotQualified;
  } else {
    if (result === "LeadSentToBrand") {
      return chatStatus.Lead;
    }
    if (result === "CallScheduled") {
      return chatStatus.Scheduled;
    }
    if (investment) {
      if (parseFloat(investment) >= brand?.investmentRequirement?.num || 0) {
        return chatStatus.Lead;
      }
    }

    return chatStatus.NotQualified;
  }
};

export const mobileColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: (params) => {
      const {
        brand,
        navigate,
        name,
        id,
        investment,
        result,
        lastInteraction,
        chatSeenAt,
        partialEngagement,
        completedEngagement,
        lastEngagedAt,
      } = params.row;

      return (
        <div
          className={`${styles.chat} ${
            isNewMessage(lastInteraction, chatSeenAt)
              ? styles["-new-message"]
              : ""
          }`}
          onClick={() => {
            navigate(`/chats/dialog?chat=${id}&brandId=${brand?.brandId}`);
          }}
        >
          <p className={styles.user}>
            {name}
            <span className={styles.icon}>
              {(!isWaitingForResponse(
                partialEngagement,
                completedEngagement,
                `${lastInteraction}`,
                lastEngagedAt
              ) &&
                isCompletedEngagement(
                  partialEngagement,
                  completedEngagement
                )) ||
              isUnengagedMessage(partialEngagement, completedEngagement) ? (
                <MarkChatUnreadIcon
                  htmlColor={`${
                    isUnengagedMessage(
                      partialEngagement,
                      completedEngagement
                    ) ||
                    isPartialEngagement(partialEngagement, completedEngagement)
                      ? "#1976d2"
                      : "#198754"
                  }`}
                  fontSize="small"
                />
              ) : (
                <MarkChatUnreadOutlinedIcon
                  htmlColor={`${
                    isPartialEngagement(partialEngagement, completedEngagement)
                      ? "#1976d2"
                      : "#198754"
                  }`}
                  fontSize="small"
                />
              )}
            </span>
          </p>

          <p className={styles.status}>
            {brand?.brand} | {status(result, brand, investment)}
          </p>
        </div>
      );
    },
  },
  {
    field: "last chat",
    valueGetter: (params) => params.row.lastInteraction,
    headerName: "Last Chat",
    sortingOrder: ["desc", "asc", null],
    align: "center",
    headerAlign: "center",
    sortComparator: (a, b) => {
      if (
        new Date(a as string | Date | number).getTime() >
        new Date(b as string | Date | number).getTime()
      ) {
        return 1;
      } else {
        return -1;
      }
    },
    renderCell: (params) => {
      const { lastInteraction, chatSeenAt } = params.row;
      const formattedDate = moment
        .utc(lastInteraction)
        .local()
        .format("YYYY-MM-DDTHH:mm:ss");
      return (
        <div
          className={`${styles.duration} ${
            isNewMessage(lastInteraction, chatSeenAt)
              ? styles["-new-message"]
              : ""
          }`}
        >
          <span>{moment(formattedDate).fromNow(true)}</span>
        </div>
      );
    },
  },
];

export const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: (params) => {
      const {
        brand,
        navigate,
        name,
        id,
        lastInteraction,
        chatSeenAt,
        partialEngagement,
        completedEngagement,
        lastEngagedAt,
      } = params.row;

      return (
        <div
          className={`${styles.chat} ${styles["-desktop"]} ${
            isNewMessage(lastInteraction, chatSeenAt)
              ? styles["-new-message"]
              : ""
          }`}
          onClick={() => {
            navigate(`/chats/dialog?chat=${id}&brandId=${brand?.brandId}`);
          }}
        >
          {name}

          <span className={`${styles.user} ${styles["-icon"]}`}>
            {(!isWaitingForResponse(
              partialEngagement,
              completedEngagement,
              `${lastInteraction}`,
              lastEngagedAt
            ) &&
              isCompletedEngagement(partialEngagement, completedEngagement)) ||
            isUnengagedMessage(partialEngagement, completedEngagement) ? (
              <MarkChatUnreadIcon
                htmlColor={`${
                  isUnengagedMessage(partialEngagement, completedEngagement) ||
                  isPartialEngagement(partialEngagement, completedEngagement)
                    ? "#1976d2"
                    : "#198754"
                }`}
                fontSize="small"
              />
            ) : (
              <MarkChatUnreadOutlinedIcon
                htmlColor={`${
                  isPartialEngagement(partialEngagement, completedEngagement)
                    ? "#1976d2"
                    : "#198754"
                }`}
                fontSize="small"
              />
            )}
          </span>
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    valueGetter: (params) => {
      return chatResult(
        params.row.result,
        params.row.investment,
        params.row.brand
      );
    },
    flex: 1,
    renderCell: (params) => {
      const {
        result,
        investment,
        brand,
        navigate,
        id,
        lastInteraction,
        chatSeenAt,
      } = params.row;

      return (
        <div
          className={`${styles.chat} ${styles["-desktop"]} ${
            isNewMessage(lastInteraction, chatSeenAt)
              ? styles["-new-message"]
              : ""
          }`}
          onClick={() => {
            navigate(`/chats/dialog?chat=${id}&brandId=${brand?.brandId}`);
          }}
        >
          {status(result, brand, investment)}
        </div>
      );
    },
  },
  {
    field: "brand",
    valueGetter: (params) => params.row.brand?.brand,
    headerName: "Brand",
    flex: 1,
    renderCell: (params) => {
      const { brand, navigate, id, lastInteraction, chatSeenAt } = params.row;
      return (
        <div
          className={`${styles.chat} ${styles["-desktop"]} ${
            isNewMessage(lastInteraction, chatSeenAt)
              ? styles["-new-message"]
              : ""
          }`}
          onClick={() => {
            navigate(`/chats/dialog?chat=${id}&brandId=${brand?.brandId}`);
          }}
        >
          {brand?.brand}
        </div>
      );
    },
  },
  {
    field: "agency",
    valueGetter: (params) => params.row.brand?.agency?.agency,
    headerName: "Agency",
    flex: 1,
    renderCell: (params) => {
      const { brand, navigate, id, lastInteraction, chatSeenAt } = params.row;
      return (
        <div
          className={`${styles.chat} ${styles["-desktop"]} ${
            isNewMessage(lastInteraction, chatSeenAt)
              ? styles["-new-message"]
              : ""
          }`}
          onClick={() => {
            navigate(`/chats/dialog?chat=${id}&brandId=${brand?.brandId}`);
          }}
        >
          {brand?.agency?.agency}
        </div>
      );
    },
  },
  {
    field: "salesperson",
    headerName: "Salesperson",
    valueGetter: (params) => params.row.brand?.salesperson?.name,
    flex: 1,
    renderCell: (params) => {
      const { brand, navigate, id, lastInteraction, chatSeenAt } = params.row;
      return (
        <div
          className={`${styles.chat} ${styles["-desktop"]} ${
            isNewMessage(lastInteraction, chatSeenAt)
              ? styles["-new-message"]
              : ""
          }`}
          onClick={() => {
            navigate(`/chats/dialog?chat=${id}&brandId=${brand?.brandId}`);
          }}
        >
          {brand?.salesperson?.name}
        </div>
      );
    },
  },
  {
    field: "lastChat",
    valueGetter: (params) => params.row.lastInteraction,
    headerName: "Last Chat",
    sortingOrder: ["desc", "asc", null],
    align: "center",
    headerAlign: "center",
    sortComparator: (a, b) => {
      if (
        new Date(a as string | Date | number).getTime() >
        new Date(b as string | Date | number).getTime()
      ) {
        return 1;
      } else {
        return -1;
      }
    },

    renderCell: (params) => {
      const { lastInteraction, chatSeenAt } = params.row;
      /**
       * Update Moment fromNow Threshold
       * @description "m" = 60 minutes === 1 hour
       *              "h" = 24 hours === 1 day
       * @author Angelo David <angelod@codev.com>
       * @since 1.1.6
       * @reference https://stackoverflow.com/a/35394048
       */
      moment.relativeTimeThreshold("m", 60);
      moment.relativeTimeThreshold("h", 24);
      moment.updateLocale("en", {
        relativeTime: {
          m: "%d minute",
          d: "%d day",
          M: "%d month",
        },
      });
      const formattedDate = moment
        .utc(lastInteraction)
        .local()
        .format("YYYY-MM-DDTHH:mm:ss");
      return (
        <div
          className={`${styles.duration} ${
            isNewMessage(lastInteraction, chatSeenAt)
              ? styles["-new-message"]
              : ""
          }`}
        >
          <span>{moment(formattedDate).fromNow(true)}</span>
        </div>
      );
    },
  },
];

/**
 * Salesperson Column Ommiter
 * Omit salesperson column when the logged in user role is salesperson
 * @author Angelo David <angelod@codev.com>
 * @return Array[{}]
 */
export const salespersonColumn: GridColDef[] = columns.filter((result) => {
  return result.field !== "salesperson";
});
