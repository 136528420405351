export const toCurrency = (
  currency: string,
  value: number,
  decimal?: number,
) => {
  const decimalPlaces = decimal !== null || decimal !== undefined ? decimal : 2;

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

  return currencyFormatter.format(value);
};

export const numberShortener = (value: number) => {
  const formatter = Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumSignificantDigits: 3,
  });

  return formatter.format(value);
};
