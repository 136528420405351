import { GridColDef } from '@mui/x-data-grid';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Tooltip, Typography } from '@mui/material';
import { humanizeString } from '../../../utils/stringModifier';

export const adTemplatesColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      flex: 1,
      field: 'name',
      headerName: 'Name',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        const { name, openPreview, template } = params.row;

        return (
          <Tooltip
            title={<Typography variant="body2">{name}</Typography>}
            disableInteractive
          >
            <div className={styles.name} onClick={() => openPreview(template)}>
              {name}
            </div>
          </Tooltip>
        );
      },
    },
    {
      ...(isMobile ? { width: 250 } : { flex: 1 }),
      field: 'description',
      headerName: 'Description',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        const { description, openPreview, template } = params.row;

        return (
          <Tooltip
            title={<Typography variant="body2">{description}</Typography>}
            disableInteractive
          >
            <div className={styles.name} onClick={() => openPreview(template)}>
              {description}
            </div>
          </Tooltip>
        );
      },
    },
    {
      width: 150,
      field: 'objective',
      headerName: 'Objective',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        const { objective, openPreview, template } = params.row;

        return (
          <Tooltip
            title={<Typography variant="body2">{objective}</Typography>}
            disableInteractive
          >
            <div className={styles.name} onClick={() => openPreview(template)}>
              {objective}
            </div>
          </Tooltip>
        );
      },
    },
    {
      width: 150,
      field: 'category',
      headerName: 'Category',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        const { category, openPreview, template } = params.row;

        return (
          <Tooltip
            title={<Typography variant="body2">{category}</Typography>}
            disableInteractive
          >
            <div className={styles.name} onClick={() => openPreview(template)}>
              {category}
            </div>
          </Tooltip>
        );
      },
    },
    {
      width: 150,
      field: 'media',
      headerName: 'Media',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        const { media, openPreview, template } = params.row;

        return (
          <Tooltip
            title={
              <Typography variant="body2">{humanizeString(media)}</Typography>
            }
            disableInteractive
          >
            <div className={styles.name} onClick={() => openPreview(template)}>
              {humanizeString(media)}
            </div>
          </Tooltip>
        );
      },
    },
  ];

  return columns;
};
