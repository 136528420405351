import { XsOnly } from '../breakpoints';

export const styledDataGrid = () => {
  let baseStyle = {
    '.MuiDataGrid-columnHeadersInner': {
      backgroundColor: '#096f4d',
      color: 'white',
    },
    width: '100%',
  };

  if (!XsOnly()) {
    return baseStyle;
  }

  return {
    ...baseStyle,
    overflow: 'hidden',
  };
};
