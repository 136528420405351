import { GridColDef } from '@mui/x-data-grid';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { toCurrency } from '../../../utils/numberFormatter';
import { humanizeString } from '../../../utils/stringModifier';
import { IconButton, Typography } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { renderCCIcon } from '../../Cards/Billing/PaymentMethodHelpers';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import moment from 'moment';

const defaultProps = (isMobile: boolean, width: number) => {
  if (isMobile) return { width };

  return { flex: 1 };
};

export const invoicesColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 130),
      field: 'invoiceNumber',
      headerName: 'Invoice #',
      disableColumnMenu: true,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        const { invoiceNumber, openBreakdown, breakdown } = params.row;

        return (
          <div className={styles.name} onClick={() => openBreakdown(breakdown)}>
            {invoiceNumber}
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 150),
      field: 'createdAt',
      headerName: 'Paid/Due',
      valueGetter: (params) => params.row.createdAt,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { formattedDate, breakdown, openBreakdown } = params.row;

        return (
          <div
            className={`${styles.name} ${styles['-break']}`}
            onClick={() => openBreakdown(breakdown)}
          >
            {formattedDate}
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 150),
      field: 'invoicePeriod',
      headerName: 'Invoice Period',
      valueGetter: (params) => params.row.createdAt,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { periodStart, periodEnd, breakdown, openBreakdown, items } =
          params.row;
        let start: string = periodStart;
        let end: string = periodEnd;
        const setupFee = items.find((item: any) => {
          return item.description === 'Setup Fee';
        });

        if (!setupFee) {
          const period = items[0].period;
          start = moment.unix(period.start).utc().format('MMM DD, YYYY');
          end = moment.unix(period.end).utc().format('MMM DD, YYYY');
        }

        return (
          <div
            className={`${styles.name} ${styles['-break']}`}
            onClick={() => openBreakdown(breakdown)}
          >
            {`${start} - ${end}`}
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 160),
      field: 'paymentMethod',
      headerName: 'Payment Method',
      disableColumnMenu: true,
      renderCell: (params) => {
        const { paymentMethod, breakdown, openBreakdown } = params.row;

        return (
          <div
            className={styles.image}
            onClick={() => openBreakdown(breakdown)}
          >
            {paymentMethod?.card
              ? renderCCIcon(paymentMethod?.card?.brand, 20)
              : null}{' '}
            <div className={styles.label}>{`${
              paymentMethod?.card?.last4
                ? `●●●● ${paymentMethod?.card?.last4}`
                : ''
            }`}</div>
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'amount',
      headerName: 'Amount',
      disableColumnMenu: true,
      renderCell: (params) => {
        const { amount, breakdown, openBreakdown } = params.row;

        return (
          <div className={styles.name} onClick={() => openBreakdown(breakdown)}>
            {toCurrency('USD', amount)}
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'status',
      headerName: 'Status',
      disableColumnMenu: true,
      renderCell: (params) => {
        const {
          status,
          breakdown,
          openBreakdown,
          paymentIntent,
          invoicePortal,
        } = params.row;

        const isRequiresAuthentication =
          paymentIntent?.status === 'requires_action';

        return (
          <div
            className={styles.name}
            onClick={() =>
              isRequiresAuthentication
                ? window.open(invoicePortal, '_blank')
                : openBreakdown(breakdown)
            }
          >
            {isRequiresAuthentication ? (
              <Typography
                variant="body2"
                sx={{ color: 'red', fontWeight: 'bold' }}
              >
                Requires Authentication
              </Typography>
            ) : (
              humanizeString(status)
            )}
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'action',
      headerName: 'Action',
      hideSortIcons: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const { invoiceFile, paymentIntent } = params.row;

        const downloadFile = () => {
          window.open(invoiceFile, '_blank');
        };

        const isRequiresAuthentication =
          paymentIntent?.status === 'requires_action';

        return (
          <div>
            {isRequiresAuthentication ? (
              <IconButton
                onClick={() =>
                  window.open(
                    paymentIntent.next_action.use_stripe_sdk.stripe_js,
                    '_blank',
                  )
                }
              >
                <CreditScoreIcon
                  sx={{
                    fontSize: '18px',
                    color: '#1976d2',
                  }}
                />
              </IconButton>
            ) : null}

            {invoiceFile ? (
              <IconButton onClick={() => downloadFile()}>
                <FileDownloadOutlinedIcon
                  sx={{
                    fontSize: '18px',
                    color: '#1976d2',
                  }}
                />
              </IconButton>
            ) : null}
          </div>
        );
      },
    },
  ];

  return columns;
};
