import { GridColDef } from '@mui/x-data-grid';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { snakeCaseToHumanized } from '../../../utils/stringModifier';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import { Chip, IconButton, Typography } from '@mui/material';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import { renderCCIcon } from '../../Cards/Billing/PaymentMethodHelpers';
import CreditScoreIcon from '@mui/icons-material/CreditScore';

const renderActions = (
  status: string,
  clientSecret: string,
  subscriptionId: string,
  cancelAt: number,
  isRequiresAction: boolean,
  invoicePageUrl: string,
  onOpenPayment: (clientSecret: string, transactionType: string) => void,
  onOpenCancel: (subscriptionId: string) => void,
  onOpenRenew: (subscriptionId: string) => void,
) => {
  if (status === 'past_due' && isRequiresAction) {
    return (
      <IconButton onClick={() => window.open(invoicePageUrl, '_blank')}>
        <CreditScoreIcon
          sx={{
            fontSize: '18px',
            color: '#1976d2',
          }}
        />
      </IconButton>
    );
  }

  if (
    [
      'requires_payment_method',
      'incomplete',
      'incomplete_expired',
      'past_due',
    ].includes(status)
  ) {
    return (
      <IconButton onClick={() => onOpenPayment(clientSecret, 'payment')}>
        <AddCardOutlinedIcon
          sx={{
            fontSize: '18px',
            color: '#1976d2',
          }}
        />
      </IconButton>
    );
  }
  if (cancelAt > 0) {
    return (
      <IconButton onClick={() => onOpenRenew(subscriptionId)}>
        <ReplayOutlinedIcon
          sx={{
            fontSize: '18px',
            color: '#1976d2',
          }}
        />
      </IconButton>
    );
  }

  return (
    <IconButton onClick={() => onOpenCancel(subscriptionId)}>
      <DoDisturbAltOutlinedIcon
        sx={{
          fontSize: '18px',
          color: '#1976d2',
        }}
      />
    </IconButton>
  );
};

export const columns: GridColDef[] = [
  {
    field: 'startDate',
    headerName: 'Start Date',
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { startDate } = params.row;

      return <div className={styles.name}>{startDate}</div>;
    },
  },
  {
    field: 'subscriptionPeriod',
    headerName: 'Subscription Period',
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { periodStart, periodEnd } = params.row;

      return (
        <div
          className={`${styles.name} ${styles['-break']}`}
        >{`${periodStart} - ${periodEnd}`}</div>
      );
    },
  },
  {
    field: 'paymentMethod',
    headerName: 'Payment Method',
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { paymentMethod } = params.row;

      return (
        <div className={styles.image}>
          {paymentMethod?.card
            ? renderCCIcon(paymentMethod?.card?.brand, 20)
            : null}{' '}
          <div className={styles.label}>{`${
            paymentMethod?.card?.last4
              ? `●●●● ${paymentMethod?.card?.last4}`
              : ''
          }`}</div>
        </div>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      const { status, formattedCancelAt, paymentIntent, invoicePageUrl } =
        params.row;

      const requiresAction = paymentIntent?.status === 'requires_action';

      return (
        <div
          className={styles.name}
          onClick={() =>
            requiresAction ? window.open(invoicePageUrl, '_blank') : null
          }
        >
          {requiresAction ? (
            <Typography
              variant="body2"
              sx={{ color: 'red', fontWeight: 'bold' }}
            >
              Requires Authentication
            </Typography>
          ) : (
            <>
              {status === 'trialing' ? 'Pending' : snakeCaseToHumanized(status)}
              {formattedCancelAt ? (
                <Chip
                  label={`Cancels ${formattedCancelAt}`}
                  size="small"
                  color="warning"
                  sx={{ marginLeft: '5px' }}
                />
              ) : null}
            </>
          )}
        </div>
      );
    },
  },
  /* TODO: Disable cancel subscription option
  {
    field: 'action',
    headerName: 'Action',
    hideSortIcons: true,
    disableColumnMenu: true,
    align: 'center',
    headerAlign: 'center',
    width: 80,
    renderCell: (params) => {
      const {
        id,
        status,
        clientSecret,
        onOpenPayment,
        onOpenCancel,
        cancelAt,
        onOpenRenew,
        paymentIntent,
        invoicePageUrl,
      } = params.row;
      return (
        <div>
          {renderActions(
            status,
            clientSecret,
            id,
            cancelAt,
            paymentIntent?.status === 'requires_action',
            invoicePageUrl,
            onOpenPayment,
            onOpenCancel,
            onOpenRenew,
          )}
        </div>
      );
    },
  },
  */
];
