import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  Brand,
  ChangeEventType,
  CustomAudience,
  FbCampaignAdSetForm,
  FbTargetSearch,
  GroupedTargetSearchKey,
  TargetSearchKey,
} from '../../../types';
import {
  getFbTargetingBrowse,
  getFbTargetingReachEstimate,
  getFbTargetingSearch,
  getFbTargetingSuggestions,
} from '../../../services/ads';
import { PrimaryButton } from '../../Buttons';
import SelectedTargetCard from '../../Details/Ads/SelectedTargetCard';
import TargetSearchForm from '../Ads/TargetSearchForm';
import {
  AdsTemplate,
  CustomAudienceForm,
  DiySettings,
  TemplateError,
} from '../../../types/IDiy';
import audienceLogo from '../../../assets/images/audience.png';
import DiyAudienceSize from './DiyAudienceSize';
import { constantStringToHumanized } from '../../../utils/stringModifier';
import { getCustomAudiences, getSingleDiySetting } from '../../../services/diy';
import { numberShortener } from '../../../utils/numberFormatter';
import { useSelector } from 'react-redux';
import { HtmlTooltip } from '../../Tooltip';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { BusinessType } from '../../../utils/helpers/BrandHelpers';

interface DiyAudienceFormProps {
  adSetTemplate: FbCampaignAdSetForm;
  brand: Brand;
  setAdSetTemplate: Dispatch<SetStateAction<FbCampaignAdSetForm>>;
  accountId: string;
  isEdit?: boolean;
  formValues: AdsTemplate;
  setFormValues: Dispatch<SetStateAction<AdsTemplate>>;
  isDuplicate?: boolean;
  hasSpecialCategory?: boolean;
  diySettings: DiySettings;
  setDiySettings: Dispatch<SetStateAction<DiySettings>>;
  error: TemplateError;
  showExclusions: boolean;
  setShowExclusions: Dispatch<SetStateAction<boolean>>;
  showNarrowAudience: boolean;
  setShowNarrowAudience: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  isEditAds?: boolean;
  isAdmin: boolean;
  enabledAdvantageLookalike: boolean;
  setEnabledAdvantageLookalike: Dispatch<SetStateAction<boolean>>;
  enabledAdvantageDetailed: boolean;
  setEnabledAdvantageDetailed: Dispatch<SetStateAction<boolean>>;
  isLeads: boolean;
}

const DiyAudienceForm: React.FC<DiyAudienceFormProps> = ({
  adSetTemplate,
  brand,
  setAdSetTemplate,
  accountId,
  isEdit = false,
  formValues,
  setFormValues,
  isDuplicate = false,
  hasSpecialCategory = false,
  diySettings,
  setDiySettings,
  error,
  showExclusions,
  setShowExclusions,
  showNarrowAudience,
  setShowNarrowAudience,
  setLoading,
  isEditAds = false,
  isAdmin,
  enabledAdvantageDetailed,
  setEnabledAdvantageDetailed,
  enabledAdvantageLookalike,
  setEnabledAdvantageLookalike,
  isLeads,
}) => {
  const locations: Brand[] = useSelector(
    (state: any) => state.locations?.locations,
  );
  const [browsedKeys, setBrowsedKeys] = useState<any>(null);
  const [browseLoading, setBrowseLoading] = useState<boolean>(false);
  const [searchedTargetingKeys, setSearchedTargetingKeys] = useState<
    TargetSearchKey[]
  >([]);
  const [searchedExclusionKeys, setSearchedExclusionKeys] = useState<
    TargetSearchKey[]
  >([]);
  const [searchedFlexibleKeys, setSearchedFlexibleKeys] = useState<any[]>([]);
  const [selectedTarget, setSelectedTarget] = useState<TargetSearchKey>(null);
  const [selectedFlexible, setSelectedFlexible] = useState<TargetSearchKey[]>(
    [],
  );
  const [selectedExclusion, setSelectedExclusion] =
    useState<TargetSearchKey>(null);
  const [reachEstimate, setReachEstimate] = useState<any>({
    users_lower_bound: 0,
    users_upper_bound: 0,
  });
  const [targetSearchQuery, setTargetSearchQuery] = useState<string>('');
  const [targetSearchLoading, setTargetSearchLoading] =
    useState<boolean>(false);
  const [exclusionSearchLoading, setExclusionSearchLoading] =
    useState<boolean>(false);
  const [exclusionSearchQuery, setExclusionSearchQuery] = useState<string>('');
  const [flexibleSearchQuery, setFlexibleSearchQuery] = useState<string[]>([]);
  const [flexibleSearchLoading, setFlexibleSearchLoading] = useState<boolean[]>(
    [],
  );
  const [flexibleIndex, setFlexibleIndex] = useState<number>(null);
  const [sizeLoading, setSizeLoading] = useState<boolean>(false);
  const [customAudiences, setCustomAudiences] = useState<CustomAudience[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Brand>(null);
  const [customAudienceForm, setCustomAudienceForm] =
    useState<CustomAudienceForm>({
      name: '',
      description: '',
      subtype:
        formValues?.customAudienceType === 'custom' ? 'CUSTOM' : 'LOOKALIKE',
      customer_file_source: 'USER_PROVIDED_ONLY',
    });
  const [reachError, setReachError] = useState<string>(null);
  const [defaultLocationDiySettings, setDefaultLocationDiySettings] =
    useState<DiySettings>(null);

  useEffect(() => {
    if (locations?.length > 0 && diySettings) {
      const location = locations.find((location: Brand) => {
        return location._id === diySettings?.defaultLocation?._id;
      });

      setSelectedLocation(location);
    }
  }, [locations, diySettings]);

  useEffect(() => {
    if (brand?.__type === 'location') {
      setSelectedLocation(brand);
    }
  }, [brand]);

  useEffect(() => {
    if (accountId && brand && formValues?.audienceType === 'custom') {
      getFbCustomAudiences();
    }
  }, [accountId, brand, formValues?.audienceType]);

  useEffect(() => {
    if (accountId && brand) getFacebookTargetingBrowse();
  }, [accountId, brand]);

  useEffect(() => {
    if (!targetSearchQuery) {
      setSearchedTargetingKeys([]);
    } else {
      const delayDebounceFn = setTimeout(() => {
        getFacebookTargetingSearch('targets');
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [targetSearchQuery]);

  useEffect(() => {
    if (!exclusionSearchQuery) {
      setSearchedExclusionKeys([]);
    } else {
      const delayDebounceFn = setTimeout(() => {
        getFacebookTargetingSearch('exclusion');
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [exclusionSearchQuery]);

  useEffect(() => {
    if (!flexibleSearchQuery) {
      let temp: any[] = [...searchedFlexibleKeys];
      temp[flexibleIndex] = [];

      setSearchedFlexibleKeys(temp);
    } else {
      const delayDebounceFn = setTimeout(() => {
        getFacebookTargetingSearch('flexible', flexibleIndex);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [flexibleSearchQuery]);

  /*
  useEffect(() => {
    switch (formValues?.audienceType) {
      case 'default':
        setAdSetTemplate({
          ...adSetTemplate,
          targeting: {
            ...adSetTemplate?.targeting,
            custom_audiences: [],
            excluded_custom_audiences: [],
          },
        });

        break;
      default:
        setAdSetTemplate({
          ...adSetTemplate,
          targeting: { ...adSetTemplate?.targeting, geo_locations: {} },
        });
        const temp: AdsTemplate = {
          ...formValues,
          targets: {
            targets: [],
            flexible: [],
            exclusions: [],
          },
        };
        setFormValues(temp);

        break;
    }
  }, [formValues?.audienceType]);
  */

  useEffect(() => {
    if (hasSpecialCategory) {
      setFormValues({
        ...formValues,
        audienceType: 'default',
      });
    }
  }, [hasSpecialCategory]);

  useEffect(() => {
    if (selectedLocation) {
      getDefaultLocationDiySettings(selectedLocation);
    }
  }, [selectedLocation]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (brand && diySettings) {
        let temp: FbCampaignAdSetForm = {
          ...adSetTemplate,
          targeting: {
            ...adSetTemplate?.targeting,
          },
        };

        let geolocationTemp: any;

        if (isEditAds) {
          temp = {
            ...temp,
            targeting: {
              ...temp?.targeting,
              geo_locations: {
                ...formValues?.adSetTemplate?.targeting?.geo_locations,
              },
              excluded_geo_locations: {
                ...formValues?.adSetTemplate?.targeting?.excluded_geo_locations,
              },
            },
          };
        } else {
          if (
            (brand?.__type === 'location' &&
              selectedLocation &&
              defaultLocationDiySettings) ||
            (locations?.length > 0 &&
              selectedLocation &&
              defaultLocationDiySettings)
          ) {
            if (defaultLocationDiySettings?.geolocation) {
              geolocationTemp = {
                ...geolocationTemp,
                ...defaultLocationDiySettings?.geolocation,
              };
            } else if (
              selectedLocation?.address?.lat &&
              selectedLocation?.address?.lng
            ) {
              geolocationTemp = {
                custom_locations: [
                  {
                    latitude: selectedLocation?.address?.lat,
                    longitude: selectedLocation?.address?.lng,
                    radius: defaultLocationDiySettings?.maxAddressRadius || 50,
                  },
                ],
              };
            }

            if (defaultLocationDiySettings?.excludedGeolocations) {
              temp = {
                ...temp,
                targeting: {
                  ...temp?.targeting,
                  excluded_geo_locations: {
                    ...defaultLocationDiySettings?.excludedGeolocations,
                  },
                },
              };
            }

            temp = {
              ...temp,
              targeting: {
                ...temp.targeting,
                geo_locations: {
                  ...geolocationTemp,
                },
              },
            };
          } else if (brand?.__type === 'brand') {
            temp = {
              ...temp,
              targeting: {
                ...temp.targeting,
                geo_locations: {
                  ...diySettings?.geolocation,
                },
              },
            };

            if (diySettings?.excludedGeolocations) {
              temp = {
                ...temp,
                targeting: {
                  ...temp?.targeting,
                  excluded_geo_locations: {
                    ...diySettings?.excludedGeolocations,
                  },
                },
              };
            }
          } else {
            temp = {
              ...temp,
              targeting: {
                ...temp.targeting,
                geo_locations: {
                  countries: ['US'],
                },
              },
            };
          }
        }

        getFacebookTargetReach(temp.targeting);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [
    selectedLocation,
    brand,
    diySettings?.geolocation,
    diySettings?.excludedGeolocations,
    formValues?.targets?.targets,
    formValues?.targets?.exclusions,
    adSetTemplate,
    adSetTemplate?.targeting,
    defaultLocationDiySettings,
  ]);

  const getFacebookTargetingSearch = async (type: string, index?: number) => {
    try {
      switch (type) {
        case 'exclusion':
          setExclusionSearchLoading(true);
          break;
        case 'flexible':
          let temp: boolean[] = [...flexibleSearchLoading];
          temp[index] = true;

          setFlexibleSearchLoading(temp);
          break;
        default:
          setTargetSearchLoading(true);
          break;
      }

      let data: TargetSearchKey[] = [];
      const response = await getFbTargetingSearch(
        brand?._id,
        type === 'targets'
          ? targetSearchQuery
          : type === 'exclusion'
          ? exclusionSearchQuery
          : type === 'flexible'
          ? flexibleSearchQuery[index]
          : '',
      );

      data = [...response.data];

      if (type === 'targets' && !hasSpecialCategory) {
        const keyType = formValues?.targets?.targets[0]?.type;
        if (keyType) {
          data = data.filter(
            (target: TargetSearchKey) => target.type === keyType,
          );
        }
      }

      const targetTypeIds = formValues?.targets?.targets.map(
        (target: any) => target.type,
      );

      if (type === 'flexible') {
        if (formValues?.targets?.flexible[index]?.length > 0) {
          const keyType = formValues?.targets?.flexible[index][0]?.type;

          if (keyType) {
            data = data.filter(
              (target: TargetSearchKey) => target.type === keyType,
            );
          }
        } else {
          data = data.filter(
            (target: TargetSearchKey) => !targetTypeIds.includes(target.type),
          );
        }
      }

      if (type === 'exclusion') {
        if (hasSpecialCategory || formValues?.audienceType === 'custom') {
          data = data.filter(
            (target: TargetSearchKey) => target.type === 'interests',
          );
        } else {
          data = data.filter(
            (target: TargetSearchKey) =>
              [
                'life_events',
                'education_majors',
                'education_schools',
                'education_statuses',
                'work_positions',
                'work_employers',
                'interests',
              ] && !targetTypeIds.includes(target.type),
          );
        }
      }

      if (type === 'targets') {
        setSearchedTargetingKeys(data);
        setTargetSearchLoading(false);
      } else if (type === 'exclusion') {
        setSearchedExclusionKeys(data);
        setExclusionSearchLoading(false);
      } else {
        let temp: any[] = [...searchedFlexibleKeys];
        temp[index] = data;

        setSearchedFlexibleKeys(temp);
        let loading: boolean[] = [...flexibleSearchLoading];
        loading[index] = false;

        setFlexibleSearchLoading(loading);
      }
    } catch (error: any) {
      let loading: boolean[] = [...flexibleSearchLoading];
      loading[flexibleIndex] = false;

      setFlexibleSearchLoading(loading);
      setTargetSearchLoading(false);
      setExclusionSearchLoading(false);
      console.log(error);
    }
  };

  const getFacebookTargetingBrowse = async () => {
    setBrowseLoading(true);
    try {
      const response = await getFbTargetingBrowse(brand?._id);

      const data: { data: TargetSearchKey[] } = response;

      const temp: GroupedTargetSearchKey = {};

      data.data.forEach((item: any) => {
        let nestedObj = temp;
        const path = item.path;

        path.forEach((pathElement: string, index: number) => {
          if (!nestedObj[pathElement]) {
            if (index === path.length - 1) {
              nestedObj[pathElement] = [item];
            } else {
              nestedObj[pathElement] = {};
            }
          } else if (index === path.length - 1) {
            if (Array.isArray(nestedObj[pathElement])) {
              (nestedObj[pathElement] as any).push(item);
            } else {
              nestedObj[pathElement] = [
                nestedObj[pathElement] as TargetSearchKey,
                item,
              ];
            }
          }

          nestedObj = nestedObj[pathElement] as GroupedTargetSearchKey;
        });
      });

      setBrowsedKeys(temp);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setBrowseLoading(false);
    }
  };

  const getFbCustomAudiences = async () => {
    setLoading(true);
    try {
      const response = await getCustomAudiences(brand?._id);

      if (response.data?.length === 1) {
        const customAudienceIds: any[] = response.data.map(
          (value: CustomAudience) => {
            return { id: value.id };
          },
        );

        setAdSetTemplate({
          ...adSetTemplate,
          targeting: {
            ...adSetTemplate?.targeting,
            custom_audiences: [...customAudienceIds],
          },
        });
      }

      setCustomAudiences(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getDefaultLocationDiySettings = async (brand: Brand) => {
    setLoading(true);
    try {
      const response = await getSingleDiySetting(brand?._id);

      setDefaultLocationDiySettings(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOnChangeTargeting = (
    value: TargetSearchKey,
    field: string,
    type?: string,
    flexibleIndex?: number,
  ) => {
    let temp: FbCampaignAdSetForm = { ...adSetTemplate };
    let tempArr: any[] = [];

    switch (type) {
      case 'flexible':
        let existingFlexible: any;

        if (temp?.targeting.flexible_spec?.length > 0) {
          existingFlexible = temp?.targeting.flexible_spec[flexibleIndex]?.[
            field
          ]?.find((item: any) => {
            if (
              ['education_statuses', 'relationship_statuses'].includes(field)
            ) {
              return item === value.id;
            }

            return item.id === parseInt(value.id);
          });
        }

        if (!existingFlexible) {
          if (['education_statuses', 'relationship_statuses'].includes(field)) {
            let tempFlexible: any[] = temp?.targeting?.flexible_spec || [];

            tempFlexible[flexibleIndex] = {
              ...tempFlexible[flexibleIndex],
              [field]: [
                ...(tempFlexible[flexibleIndex]?.[field] || []),
                value.id,
              ],
            };

            temp = {
              ...temp,
              targeting: {
                ...temp?.targeting,
                flexible_spec: tempFlexible,
              },
            };
          } else {
            const params = { id: parseInt(value.id), name: value.name };
            let tempFlexible: any[] = temp?.targeting?.flexible_spec || [];

            tempFlexible[flexibleIndex] = {
              ...tempFlexible[flexibleIndex],
              [field]: [
                ...(tempFlexible[flexibleIndex]?.[field] || []),
                params,
              ],
            };

            temp = {
              ...temp,
              targeting: {
                ...temp?.targeting,
                flexible_spec: tempFlexible,
              },
            };
          }

          setAdSetTemplate(temp);
        }
        break;
      case 'exclusion':
        const existingExclusion = temp?.targeting?.exclusions?.[field]?.find(
          (item: any) => {
            if (
              ['education_statuses', 'relationship_statuses'].includes(field)
            ) {
              return item === value.id;
            }

            return item.id === parseInt(value.id);
          },
        );

        if (!existingExclusion) {
          if (['education_statuses', 'relationship_statuses'].includes(field)) {
            tempArr = [
              ...(temp?.targeting.exclusions?.[field] || []),
              value.id,
            ];
          } else {
            const params = { id: parseInt(value.id), name: value.name };
            tempArr = [...(temp?.targeting.exclusions?.[field] || []), params];
          }

          temp = {
            ...temp,
            targeting: {
              ...temp.targeting,
              exclusions: {
                ...temp.targeting.exclusions,
                [field]: tempArr,
              },
            },
          };

          setAdSetTemplate(temp);
        }
        break;
      default:
        const existingTarget = temp?.targeting[field]?.find((item: any) => {
          if (['education_statuses', 'relationship_statuses'].includes(field)) {
            return item === value.id;
          }

          return item.id === parseInt(value.id);
        });

        if (!existingTarget) {
          if (['education_statuses', 'relationship_statuses'].includes(field)) {
            tempArr = [...(temp?.targeting[field] || []), value.id];
          } else {
            const params = { id: parseInt(value.id), name: value.name };
            tempArr = [...(temp?.targeting[field] || []), params];
          }

          temp = {
            ...temp,
            targeting: {
              ...temp?.targeting,
              [field]: tempArr,
            },
          };

          setAdSetTemplate(temp);
        }
        break;
    }
  };

  const handleInputSearch = (value: string, type: string, index?: number) => {
    switch (type) {
      case 'exclusion':
        setExclusionSearchQuery(value);
        break;
      case 'flexible':
        setFlexibleIndex(index);
        let temp: string[] = [...flexibleSearchQuery];
        temp[index] = value;
        setFlexibleSearchQuery(temp);
        break;
      default:
        setTargetSearchQuery(value);
        break;
    }
  };

  const buildSuggestionObject = (value: TargetSearchKey) => {
    let temp: TargetSearchKey[] = [];
    const existingTarget = formValues?.targets?.targets.find(
      (obj: TargetSearchKey) => {
        return obj.id === value.id;
      },
    );

    if (!existingTarget) {
      temp = [...(formValues?.targets?.targets || []), value];

      setFormValues({
        ...formValues,
        targets: { ...formValues.targets, targets: temp },
      });
    }
  };

  const buildExclusionObjects = (value: TargetSearchKey) => {
    let temp: TargetSearchKey[] = [];
    const existingTarget = formValues?.targets?.exclusions?.find(
      (obj: TargetSearchKey) => {
        return obj.id === value.id;
      },
    );

    if (!existingTarget) {
      temp = [...(formValues?.targets?.exclusions || []), value];

      setFormValues({
        ...formValues,
        targets: { ...formValues.targets, exclusions: temp },
      });
    }
  };

  const buildFlexibleSpecs = (value: TargetSearchKey, index: number) => {
    let temp: any[] = [...formValues?.targets?.flexible];

    const existingTarget = temp[index].find((obj: TargetSearchKey) => {
      return obj.id === value.id;
    });

    if (!existingTarget) {
      temp[index] = [...(temp[index] || []), value];

      setFormValues({
        ...formValues,
        targets: { ...formValues.targets, flexible: temp },
      });
    }
  };

  const handleSuggestions = async () => {
    try {
      setTargetSearchLoading(true);
      const params = formValues?.targets?.targets.map(
        (target: TargetSearchKey) => {
          return {
            id: target.id,
            type: target.type,
          };
        },
      );

      const response = await getFbTargetingSuggestions(
        (brand?.__type === 'location' ? brand?.franchisor : brand)?._id,
        accountId,
        params,
      );

      setSearchedTargetingKeys(response.data);
      setTargetSearchLoading(false);
    } catch (error: any) {
      setTargetSearchLoading(false);
      console.log(error);
    }
  };

  const handleRemoveTarget = (target: TargetSearchKey) => {
    const temp = formValues?.targets?.targets.filter((obj: TargetSearchKey) => {
      return obj.id !== target.id;
    });
    let formValuesTemp: FbCampaignAdSetForm = { ...adSetTemplate };

    let targets = [...(adSetTemplate?.targeting[target.type] || [])];

    const filteredTargets = targets.filter((obj: any) => {
      if (
        ['education_statuses', 'relationship_statuses'].includes(target.type)
      ) {
        return target.id !== obj;
      }

      return obj.id !== parseInt(target.id);
    });

    formValuesTemp.targeting[target.type] = filteredTargets;

    setAdSetTemplate(formValuesTemp);
    setFormValues({
      ...formValues,
      targets: { ...formValues.targets, targets: temp },
    });
  };

  const handleRemoveFlexibleTarget = (
    target: TargetSearchKey,
    flexibleIndex: number,
  ) => {
    let tempFlexibleSpecs: any[] = [...(formValues?.targets?.flexible || [])];
    let formValuesTemp: FbCampaignAdSetForm = { ...adSetTemplate };

    const temp = tempFlexibleSpecs[flexibleIndex].filter(
      (obj: TargetSearchKey) => {
        return obj.id !== target.id;
      },
    );

    tempFlexibleSpecs[flexibleIndex] = temp;

    let targets = [
      ...(formValuesTemp?.targeting?.flexible_spec[flexibleIndex]?.[
        target.type
      ] || []),
    ];

    const filteredTargets = targets.filter((obj: any) => {
      if (
        ['education_statuses', 'relationship_statuses'].includes(target.type)
      ) {
        return target.id !== obj;
      }

      return obj.id !== parseInt(target.id);
    });

    formValuesTemp.targeting.flexible_spec[flexibleIndex][target.type] =
      filteredTargets;

    if (filteredTargets.length === 0) {
      formValuesTemp.targeting.flexible_spec.splice(flexibleIndex, 1);
    }

    if (tempFlexibleSpecs[flexibleIndex].length === 0) {
      tempFlexibleSpecs.splice(flexibleIndex, 1);
    }

    if (tempFlexibleSpecs.length === 0) {
      setShowNarrowAudience(false);
    }

    setAdSetTemplate(formValuesTemp);
    setFormValues({
      ...formValues,
      targets: { ...formValues.targets, flexible: tempFlexibleSpecs },
    });
  };

  const handleRemoveExclusion = (target: TargetSearchKey, index: number) => {
    const temp = formValues?.targets?.exclusions?.filter(
      (obj: TargetSearchKey) => {
        return obj.id !== target.id;
      },
    );
    let formValuesTemp: FbCampaignAdSetForm = { ...adSetTemplate };

    let targets = [
      ...(formValuesTemp.targeting.exclusions?.[target.type] || []),
    ];

    const filteredTargets = targets.filter((obj: any) => {
      if (
        ['education_statuses', 'relationship_statuses'].includes(target.type)
      ) {
        return target.id !== obj;
      }

      return obj.id !== parseInt(target.id);
    });

    formValuesTemp.targeting.exclusions[target.type] = filteredTargets;

    if (temp.length === 0) {
      setShowExclusions(false);
    }

    setAdSetTemplate(formValuesTemp);
    setFormValues({
      ...formValues,
      targets: { ...formValues.targets, exclusions: temp },
    });
  };

  const getFacebookTargetReach = async (targeting: FbTargetSearch) => {
    setSizeLoading(true);
    try {
      const response = await getFbTargetingReachEstimate(
        brand?._id,
        targeting,
        adSetTemplate?.optimization_goal,
      );

      setReachEstimate(response.data);
      setReachError(null);
    } catch (error: any) {
      const err = error.response.data;
      setReachEstimate({
        users_lower_bound: 0,
        users_upper_bound: 0,
      });
      setReachError(err?.message);
    } finally {
      setSizeLoading(false);
    }
  };

  const handleShowNarrowAudience = () => {
    setShowNarrowAudience(true);
    let temp: any[] = [...(formValues?.targets?.flexible || [])];

    temp = [...temp, []];

    setFormValues({
      ...formValues,
      targets: { ...formValues.targets, flexible: temp },
    });
  };

  const disableOptions = (
    option: any,
    type: string,
    browse?: boolean,
    flexibleIndex?: number,
  ) => {
    let targetIds: string[] = [];
    switch (type) {
      case 'exclusion':
        targetIds = [...(formValues?.targets?.exclusions || [])].map(
          (option: any) => {
            if (browse) {
              return option.type;
            }

            return option.id;
          },
        );

        break;
      case 'flexible':
        targetIds = [
          ...(formValues?.targets?.flexible[flexibleIndex] || []).flat(),
        ].map((option: any) => {
          if (browse) {
            return option.type;
          }

          return option.id;
        });
        break;
      default:
        targetIds = [...(formValues?.targets?.targets || [])].map(
          (option: any) => {
            if (browse) {
              return option.type;
            }

            return option.id;
          },
        );
        break;
    }

    if (browse) {
      return !targetIds.includes(option.type);
    }

    return targetIds.includes(option.id);
  };

  const handleOnSelectTarget = (
    value: TargetSearchKey,
    type: string,
    flexibleIndex?: number,
  ) => {
    switch (type) {
      case 'exclusion':
        buildExclusionObjects(value);
        handleOnChangeTargeting(value, value.type, 'exclusion');
        setSelectedExclusion(null);
        setExclusionSearchQuery('');
        break;
      case 'flexible':
        buildFlexibleSpecs(value, flexibleIndex);
        handleOnChangeTargeting(value, value.type, 'flexible', flexibleIndex);
        let temp: any[] = [...selectedFlexible];
        temp[flexibleIndex] = null;
        setSelectedFlexible(temp);
        let query: string[] = [...flexibleSearchQuery];
        query[flexibleIndex] = '';
        setFlexibleSearchQuery(query);
        break;
      default:
        buildSuggestionObject(value);
        handleOnChangeTargeting(value, value.type);
        setSelectedTarget(null);
        setTargetSearchQuery('');
        break;
    }
  };

  const buildAge = (start: number, end: number, step: number) => {
    return Array.from(
      Array.from(Array(Math.ceil((end - start) / step)).keys()),
      (x) => start + x * step,
    );
  };

  const handleOnChangeCustomAudience = (e: ChangeEventType) => {
    setCustomAudienceForm({
      ...customAudienceForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnCreateCustomAudience = () => {};

  const handleSelectAudienceType = (e: ChangeEventType) => {
    switch (e.target.value) {
      case 'custom':
        setAdSetTemplate({
          ...adSetTemplate,
          targeting: {
            custom_audiences: [],
            genders: [1, 2],
            age_min:
              (brand?.__type === 'location' ? brand?.franchisor : brand)
                ?.businessType === BusinessType.FRANDEV
                ? 25
                : 18,
            age_max:
              (brand?.__type === 'location' ? brand?.franchisor : brand)
                ?.businessType === BusinessType.FRANDEV
                ? 59
                : 64,
          },
        });

        setFormValues({
          ...formValues,
          [e.target.name]: e.target.value,
          targets: {
            ...formValues?.targets,
            targets: [],
            flexible: [],
            exclusions: [],
          },
        });

        setShowExclusions(false);
        setShowNarrowAudience(false);
        break;
      /* TODO: Reactive once the Advantage+ Audience is stable
       * https://developers.facebook.com/community/threads/922471492846116/
       * https://developers.facebook.com/docs/marketing-api/audiences/reference/targeting-expansion/advantage-audience
      case 'ai':
        setAdSetTemplate({
          ...adSetTemplate,
          targeting: {
            ...adSetTemplate?.targeting,
            targeting_automation: {
              advantage_audience: 1,
            },
          },
        });
        break;
        */
      default:
        setAdSetTemplate({
          ...adSetTemplate,
          targeting: {
            genders: [1, 2],
            age_min:
              (brand?.__type === 'location' ? brand?.franchisor : brand)
                ?.businessType === BusinessType.FRANDEV
                ? 25
                : 18,
            age_max:
              (brand?.__type === 'location' ? brand?.franchisor : brand)
                ?.businessType === BusinessType.FRANDEV
                ? 59
                : 64,
          },
        });

        setFormValues({ ...formValues, [e.target.name]: e.target.value });
        setShowExclusions(false);
        setShowNarrowAudience(false);
        break;
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'bold',
            justifyContent: 'flex-start',
            color: '#096F4D',
            display: 'flex',
            margin: 0,
            alignItems: 'center',
          }}
          mb={1}
          component="div"
        >
          <Box component="span" sx={{ marginRight: '5px' }}>
            Select your audience type
          </Box>

          <HtmlTooltip
            title={
              <Fragment>
                <Box component="div" sx={{ fontWeight: 'bold' }}>
                  Lookalike Audience
                </Box>

                <Box component="div" sx={{ marginBottom: '10px' }}>
                  Lookalike audiences are lists of people to target with
                  advertising who are similar to (or 'look like') the people
                  currently engaging with your business.
                </Box>

                <Box component="div" sx={{ marginBottom: '10px' }}>
                  <a
                    href="https://adsmanager.facebook.com/help/164749007013531/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More
                  </a>
                </Box>

                {/* TODO: Reactive once the Advantage+ Audience is stable
                  * https://developers.facebook.com/community/threads/922471492846116/
                  * https://developers.facebook.com/docs/marketing-api/audiences/reference/targeting-expansion/advantage-audience
                <Box component="div" sx={{ fontWeight: 'bold' }}>
                  ai Audience
                </Box>

                <Box component="div" sx={{ marginBottom: '10px' }}>
                  This option will show your ad to additional people who we
                  believe are likely to get you more or cheaper results, or
                  both, against your optimization goals. Our system implements
                  Advantage detailed targeting when it determines that doing so
                  could improve performance.
                </Box>

                <Box component="div" sx={{ marginBottom: '10px' }}>
                  <a
                    href="https://www.facebook.com/business/help/128066880933676"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More
                  </a>
                </Box>
                */}

                <Box component="div" sx={{ fontWeight: 'bold' }}>
                  Detailed Audience
                </Box>

                <Box component="div" sx={{ marginBottom: '10px' }}>
                  Define your audience by including demographics interests and
                  behaviors.
                </Box>

                <div>
                  <a
                    href="https://www.facebook.com/business/help/182371508761821"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More
                  </a>
                </div>
              </Fragment>
            }
          >
            <InfoTwoToneIcon sx={{ fontSize: '18px', color: '#096F4D' }} />
          </HtmlTooltip>
        </Typography>

        {formValues?.audienceType ? (
          <RadioGroup
            row
            aria-labelledby="audienceType"
            name="audienceType"
            value={formValues?.audienceType}
            onChange={handleSelectAudienceType}
          >
            <FormControlLabel
              value="custom"
              control={<Radio size="small" />}
              label="Lookalike Audience"
              disabled={hasSpecialCategory}
            />

            {/* TODO: Reactive once the Advantage+ Audience is stable
              * https://developers.facebook.com/community/threads/922471492846116/
              * https://developers.facebook.com/docs/marketing-api/audiences/reference/targeting-expansion/advantage-audience
            <FormControlLabel
              value="ai"
              control={<Radio size="small" />}
              label="ai Audience"
            />
            */}

            <FormControlLabel
              value="default"
              control={<Radio size="small" />}
              label="Detailed Audience"
            />
          </RadioGroup>
        ) : null}
      </Grid>

      {formValues?.audienceType === 'default' ? (
        <>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 'bold',
                        justifyContent: 'flex-start',
                        color: '#096F4D',
                      }}
                      mb={1}
                    >
                      Select your target audience
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TargetSearchForm
                      options={searchedTargetingKeys}
                      value={selectedTarget}
                      onChange={handleOnSelectTarget}
                      type="targets"
                      id={`targets-0`}
                      onInput={handleInputSearch}
                      loading={targetSearchLoading}
                      searchQuery={targetSearchQuery}
                      onSuggestion={handleSuggestions}
                      browsedData={browsedKeys}
                      disabledOptions={disableOptions}
                      browseLoading={browseLoading}
                    />
                  </Grid>

                  {isAdmin && isLeads ? (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            defaultChecked={enabledAdvantageDetailed}
                          />
                        }
                        label={
                          <Fragment>
                            <Box
                              sx={{
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{ marginRight: '5px' }}
                              >
                                Enable Advantage Detailed Targeting
                              </Typography>

                              <HtmlTooltip
                                title={
                                  <Fragment>
                                    <Box
                                      component="div"
                                      sx={{ fontWeight: 'bold' }}
                                    >
                                      Advantage detailed targeting
                                    </Box>

                                    <Box
                                      component="div"
                                      sx={{ marginBottom: '10px' }}
                                    >
                                      This expands your audience beyond your
                                      selections to reach more people. We
                                      recommend this option because your
                                      campaign will be using our most advanced
                                      ad technology to help maximize
                                      performance.{' '}
                                      <a
                                        href="https://www.facebook.com/business/help/128066880933676"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Learn more about Advantage lookalike.
                                      </a>
                                    </Box>

                                    <Box
                                      component="div"
                                      sx={{ marginBottom: '10px' }}
                                    >
                                      Because your audience is expanded through
                                      automation, the estimated audience size
                                      will not reflect the total number of
                                      Account Center accounts that meet the
                                      audience criteria.
                                    </Box>
                                  </Fragment>
                                }
                              >
                                <InfoTwoToneIcon
                                  sx={{ fontSize: '18px', color: '#096F4D' }}
                                />
                              </HtmlTooltip>
                            </Box>
                          </Fragment>
                        }
                        value={enabledAdvantageLookalike}
                        onChange={(e: any) => {
                          setEnabledAdvantageDetailed(e.target.checked);
                        }}
                      />
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {formValues?.targets?.targets.map(
                        (target: TargetSearchKey, index: number) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              flexDirection="column"
                              key={`selected-target-${index + 1}`}
                            >
                              <SelectedTargetCard
                                target={target}
                                onRemove={handleRemoveTarget}
                                type="targets"
                              />
                            </Grid>
                          );
                        },
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DiyAudienceSize
                      reachEstimate={reachEstimate}
                      loading={sizeLoading}
                      locations={locations}
                      selectedLocation={selectedLocation}
                      setSelectedLocation={setSelectedLocation}
                      brand={
                        brand?.__type === 'location' ? brand?.franchisor : brand
                      }
                      error={reachError}
                      diySettings={diySettings}
                      setDiySettings={setDiySettings}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {formValues?.targets?.targets.length > 0 &&
            !showExclusions &&
            formValues?.targets?.exclusions.length === 0 &&
            !hasSpecialCategory ? (
              <PrimaryButton
                title="Exclusions"
                marginRight5
                handleOnClick={() => setShowExclusions(true)}
                type="button"
              />
            ) : null}

            {formValues?.targets?.targets.length > 0 &&
            !showNarrowAudience &&
            !hasSpecialCategory ? (
              <PrimaryButton
                title="Narrow Audience"
                handleOnClick={() => handleShowNarrowAudience()}
                type="button"
              />
            ) : null}
          </Grid>

          {/** NARROW AUDIENCE */}
          <Grid item xs={12}>
            {showNarrowAudience ||
            ((isEdit || isDuplicate) &&
              formValues?.targets?.flexible?.length > 0) ? (
              <>
                {formValues?.targets?.flexible?.map(
                  (spec: any, flexibleIndex: number) => {
                    return (
                      <Grid
                        container
                        spacing={1}
                        key={`flexible-target-${flexibleIndex + 1}`}
                      >
                        <Grid item xs={12}>
                          <Divider>
                            <Typography
                              variant="body2"
                              sx={{ color: '#096F4D' }}
                            >
                              and must also match
                            </Typography>
                          </Divider>
                        </Grid>

                        <Grid item xs={12} md={8}>
                          <TargetSearchForm
                            options={searchedFlexibleKeys[flexibleIndex]}
                            value={selectedFlexible[flexibleIndex]}
                            onChange={handleOnSelectTarget}
                            type="flexible"
                            id={`flexible-0-${flexibleIndex}`}
                            onInput={handleInputSearch}
                            loading={flexibleSearchLoading[flexibleIndex]}
                            searchQuery={flexibleSearchQuery[flexibleIndex]}
                            flexibleIndex={flexibleIndex}
                            browsedData={browsedKeys}
                            disabledOptions={disableOptions}
                            browseLoading={browseLoading}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            {formValues?.targets?.flexible[flexibleIndex].map(
                              (target: TargetSearchKey, index: number) => {
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    flexDirection="column"
                                    key={`selected-flexible-target-${
                                      index + 1
                                    }`}
                                  >
                                    <SelectedTargetCard
                                      target={target}
                                      onRemove={handleRemoveFlexibleTarget}
                                      type="flexible"
                                      flexibleIndex={flexibleIndex}
                                    />
                                  </Grid>
                                );
                              },
                            )}
                          </Grid>
                        </Grid>

                        {formValues?.targets?.flexible[flexibleIndex].length >
                          0 &&
                        formValues?.targets?.flexible.length ===
                          flexibleIndex + 1 &&
                        formValues?.targets?.flexible.length < 3 ? (
                          <Grid item xs={12}>
                            <PrimaryButton
                              title="Narrow Further"
                              handleOnClick={() => handleShowNarrowAudience()}
                              type="button"
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    );
                  },
                )}
              </>
            ) : null}
          </Grid>

          {/** EXCLUSIONS */}
          {showExclusions ||
          ((isEdit || isDuplicate) &&
            formValues?.targets?.exclusions?.length > 0) ? (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Divider>
                    {' '}
                    <Typography variant="body2" sx={{ color: '#096F4D' }}>
                      and exclude people who match
                    </Typography>
                  </Divider>
                </Grid>

                <Grid item xs={12} md={8}>
                  <TargetSearchForm
                    options={searchedExclusionKeys}
                    value={selectedExclusion}
                    onChange={handleOnSelectTarget}
                    type="exclusion"
                    id={`exclusion-0`}
                    onInput={handleInputSearch}
                    loading={exclusionSearchLoading}
                    searchQuery={exclusionSearchQuery}
                    browsedData={browsedKeys}
                    disabledOptions={disableOptions}
                    browseLoading={browseLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Grid container spacing={1}>
              {formValues?.targets?.exclusions?.map(
                (target: TargetSearchKey, index: number) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      flexDirection="column"
                      key={`selected-exclusion-${index + 1}`}
                    >
                      <SelectedTargetCard
                        target={target}
                        onRemove={handleRemoveExclusion}
                        type="exclusion"
                      />
                    </Grid>
                  );
                },
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      ) : null}

      {formValues?.audienceType === 'custom' ? (
        <>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              {/* TODO: Use only lookalike audiences for now
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 'bold',
                    justifyContent: 'flex-start',
                    color: '#096F4D',
                  }}
                  mb={1}
                >
                  Select your custom audience type
                </Typography>

                <RadioGroup
                  row
                  aria-labelledby="audienceType"
                  name="customAudienceType"
                  value={formValues?.customAudienceType}
                  onChange={(e: ChangeEventType) => {
                    setFormValues({
                      ...formValues,
                      [e.target.name]: e.target.value,
                    });
                  }}
                >
                  <FormControlLabel
                    value="custom"
                    control={<Radio size="small" />}
                    label="Custom"
                  />

                  <FormControlLabel
                    value="lookalike"
                    control={<Radio size="small" />}
                    label="Lookalike"
                  />
                </RadioGroup>
              </Grid>
                */}

              <Grid item xs={12}>
                <Autocomplete
                  id="customAudiences"
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        <Box
                          component="div"
                          sx={{
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Typography variant="body1" component="div">{`${
                            option.name
                          } (${constantStringToHumanized(
                            option.subtype,
                          )})`}</Typography>

                          <Typography variant="caption" component="div">
                            {option.id}
                          </Typography>

                          <Typography
                            variant="caption"
                            component="div"
                          >{`Estimated Audience Size: ${numberShortener(
                            option.approximate_count_lower_bound,
                          )} - ${numberShortener(
                            option.approximate_count_lower_bound,
                          )}`}</Typography>
                        </Box>
                      </li>
                    );
                  }}
                  options={
                    customAudiences?.sort(
                      (a, b) => -b.name?.localeCompare(a.name),
                    ) || []
                  }
                  value={customAudiences.filter((value: CustomAudience) => {
                    return adSetTemplate?.targeting?.custom_audiences
                      ?.map((customAudience: any) => {
                        return customAudience.id;
                      })
                      .includes(value.id);
                  })}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Custom Audience"
                      fullWidth
                      size="small"
                      placeholder="Select a custom audiences"
                      required
                      InputLabelProps={{ shrink: true }}
                      error={error?.customAudienceError ? true : false}
                      helperText={
                        error?.customAudienceError
                          ? error?.customAudienceError
                          : ''
                      }
                    />
                  )}
                  disableClearable
                  onChange={async (e: any, values: any[]) => {
                    const customAudienceIds: any[] = values.map(
                      (value: CustomAudience) => {
                        return { id: value.id };
                      },
                    );

                    setAdSetTemplate({
                      ...adSetTemplate,
                      targeting: {
                        ...adSetTemplate?.targeting,
                        custom_audiences: [...customAudienceIds],
                      },
                    });
                  }}
                  multiple
                />
              </Grid>
            </Grid>

            {isAdmin ? (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      defaultChecked={enabledAdvantageLookalike}
                    />
                  }
                  label={
                    <Fragment>
                      <Box
                        sx={{
                          flexDirection: 'row',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="body1" sx={{ marginRight: '5px' }}>
                          Enable Advantage Lookalike
                        </Typography>

                        <HtmlTooltip
                          title={
                            <Fragment>
                              <Box component="div" sx={{ fontWeight: 'bold' }}>
                                Advantage lookalike
                              </Box>

                              <Box
                                component="div"
                                sx={{ marginBottom: '10px' }}
                              >
                                This expands your audience beyond your lookalike
                                to reach more people. We recommend this option
                                because your campaign will be using our most
                                advanced ad technology to help maximize
                                performance.{' '}
                                <a
                                  href="https://www.facebook.com/business/help/1212225059146059"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Learn more about Advantage lookalike.
                                </a>
                              </Box>

                              <Box
                                component="div"
                                sx={{ marginBottom: '10px' }}
                              >
                                Because your audience is expanded through
                                automation, the estimated audience size will not
                                reflect the total number of Account Center
                                accounts that meet the audience criteria.
                              </Box>
                            </Fragment>
                          }
                        >
                          <InfoTwoToneIcon
                            sx={{ fontSize: '18px', color: '#096F4D' }}
                          />
                        </HtmlTooltip>
                      </Box>
                    </Fragment>
                  }
                  value={enabledAdvantageLookalike}
                  onChange={(e: any) => {
                    setEnabledAdvantageLookalike(e.target.checked);
                  }}
                />
              </Grid>
            ) : null}
          </Grid>

          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {adSetTemplate?.targeting?.custom_audiences?.length > 0 &&
                !showExclusions &&
                formValues?.targets?.exclusions.length === 0 &&
                !hasSpecialCategory ? (
                  <PrimaryButton
                    title="Exclusions"
                    marginRight5
                    handleOnClick={() => setShowExclusions(true)}
                    type="button"
                  />
                ) : null}

                {adSetTemplate?.targeting?.custom_audiences?.length > 0 &&
                !showNarrowAudience &&
                !hasSpecialCategory ? (
                  <PrimaryButton
                    title="Narrow Audience"
                    handleOnClick={() => handleShowNarrowAudience()}
                    type="button"
                  />
                ) : null}
              </Grid>

              <Grid item xs={12}>
                {showNarrowAudience ||
                ((isEdit || isDuplicate) &&
                  formValues?.targets?.flexible?.length > 0) ? (
                  <>
                    {formValues?.targets?.flexible?.map(
                      (spec: any, flexibleIndex: number) => {
                        return (
                          <Grid
                            container
                            spacing={1}
                            key={`flexible-target-${flexibleIndex + 1}`}
                          >
                            <Grid item xs={12}>
                              <Divider>
                                <Typography
                                  variant="body2"
                                  sx={{ color: '#096F4D' }}
                                >
                                  and must also match
                                </Typography>
                              </Divider>
                            </Grid>

                            <Grid item xs={12} md={8}>
                              <TargetSearchForm
                                options={searchedFlexibleKeys[flexibleIndex]}
                                value={selectedFlexible[flexibleIndex]}
                                onChange={handleOnSelectTarget}
                                type="flexible"
                                id={`flexible-0-${flexibleIndex}`}
                                onInput={handleInputSearch}
                                loading={flexibleSearchLoading[flexibleIndex]}
                                searchQuery={flexibleSearchQuery[flexibleIndex]}
                                flexibleIndex={flexibleIndex}
                                browsedData={browsedKeys}
                                disabledOptions={disableOptions}
                                browseLoading={browseLoading}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                {formValues?.targets?.flexible[
                                  flexibleIndex
                                ].map(
                                  (target: TargetSearchKey, index: number) => {
                                    return (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        flexDirection="column"
                                        key={`selected-flexible-target-${
                                          index + 1
                                        }`}
                                      >
                                        <SelectedTargetCard
                                          target={target}
                                          onRemove={handleRemoveFlexibleTarget}
                                          type="flexible"
                                          flexibleIndex={flexibleIndex}
                                        />
                                      </Grid>
                                    );
                                  },
                                )}
                              </Grid>
                            </Grid>

                            {formValues?.targets?.flexible[flexibleIndex]
                              .length > 0 &&
                            formValues?.targets?.flexible.length ===
                              flexibleIndex + 1 &&
                            formValues?.targets?.flexible.length < 3 ? (
                              <Grid item xs={12}>
                                <PrimaryButton
                                  title="Narrow Further"
                                  handleOnClick={() =>
                                    handleShowNarrowAudience()
                                  }
                                  type="button"
                                />
                              </Grid>
                            ) : null}
                          </Grid>
                        );
                      },
                    )}
                  </>
                ) : null}
              </Grid>

              {showExclusions ||
              ((isEdit || isDuplicate) &&
                formValues?.targets?.exclusions?.length > 0) ? (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Divider>
                        {' '}
                        <Typography variant="body2" sx={{ color: '#096F4D' }}>
                          and exclude people who match
                        </Typography>
                      </Divider>
                    </Grid>

                    <Grid item xs={12}>
                      <TargetSearchForm
                        options={searchedExclusionKeys}
                        value={selectedExclusion}
                        onChange={handleOnSelectTarget}
                        type="exclusion"
                        id={`exclusion-0`}
                        onInput={handleInputSearch}
                        loading={exclusionSearchLoading}
                        searchQuery={exclusionSearchQuery}
                        browsedData={browsedKeys}
                        disabledOptions={disableOptions}
                        browseLoading={browseLoading}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        {formValues?.targets?.exclusions?.map(
                          (target: TargetSearchKey, index: number) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                flexDirection="column"
                                key={`selected-exclusion-${index + 1}`}
                              >
                                <SelectedTargetCard
                                  target={target}
                                  onRemove={handleRemoveExclusion}
                                  type="exclusion"
                                />
                              </Grid>
                            );
                          },
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DiyAudienceSize
                  reachEstimate={reachEstimate}
                  loading={sizeLoading}
                  locations={locations}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  brand={
                    brand?.__type === 'location' ? brand?.franchisor : brand
                  }
                  error={reachError}
                  diySettings={diySettings}
                  setDiySettings={setDiySettings}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}

      {/* TODO: Reactive once the Advantage+ Audience is stable
        * https://developers.facebook.com/community/threads/922471492846116/
        * https://developers.facebook.com/docs/marketing-api/audiences/reference/targeting-expansion/advantage-audience
        formValues?.audienceType === 'ai' ? (
        <>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography variant="body1">
                  ai Audience typically gets better results compared with the
                  original audience options. This is because our AI is not
                  limited to delivering ads to people that match your
                  selections, giving it more flexibility to find new audiences.
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DiyAudienceSize
                      reachEstimate={reachEstimate}
                      loading={sizeLoading}
                      locations={locations}
                      selectedLocation={selectedLocation}
                      setSelectedLocation={setSelectedLocation}
                      brand={
                        brand?.__type === 'location' ? brand?.franchisor : brand
                      }
                      error={reachError}
                      diySettings={diySettings}
                      setDiySettings={setDiySettings}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null
      */}

      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          {!hasSpecialCategory ? (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 'bold',
                  justifyContent: 'flex-start',
                  color: '#096F4D',
                }}
                mb={1}
              >
                Select age limit for your ads
              </Typography>

              <Grid container spacing={1} my={2}>
                <Grid item xs={6}>
                  <TextField
                    id={`minAge-0`}
                    select
                    label="Min Age"
                    value={adSetTemplate?.targeting?.age_min?.toString() || 18}
                    name="age_min"
                    onChange={(e: any) => {
                      const temp: FbCampaignAdSetForm = {
                        ...adSetTemplate,
                      };
                      temp.targeting.age_min = parseInt(e.target.value);

                      setAdSetTemplate(temp);
                    }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    fullWidth
                  >
                    {buildAge(18, 66, 1).map((text: any) => (
                      <MenuItem key={text} value={text}>
                        {text}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id={`maxAge-0`}
                    select
                    label="Max Age"
                    value={adSetTemplate?.targeting?.age_max?.toString() || 64}
                    name="age_max"
                    onChange={(e: any) => {
                      const temp: FbCampaignAdSetForm = {
                        ...adSetTemplate,
                      };
                      temp.targeting.age_max = parseInt(e.target.value);
                      setAdSetTemplate(temp);
                    }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    fullWidth
                  >
                    {buildAge(18, 66, 1).map((text: any) => (
                      <MenuItem key={text} value={text}>
                        {text === 65 ? `${text}+` : text}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} mb={1}>
                <FormControl>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 'bold',
                      justifyContent: 'flex-start',
                      color: '#096F4D',
                    }}
                    mb={1}
                  >
                    Select your target gender
                  </Typography>

                  <RadioGroup
                    row
                    aria-labelledby="genders"
                    name="genders"
                    value={adSetTemplate?.targeting?.genders}
                    onChange={(e: ChangeEventType) => {
                      let value: number[] = [];
                      if (e.target.value === '1,2') {
                        value = e.target.value
                          .split(',')
                          .map((value: string) => {
                            return parseInt(value);
                          });
                      } else {
                        value = [parseInt(e.target.value)];
                      }

                      const temp: FbCampaignAdSetForm = {
                        ...adSetTemplate,
                      };
                      temp.targeting.genders = value;

                      setAdSetTemplate(temp);
                    }}
                    sx={{ flexDirection: 'column' }}
                    defaultValue="1,2"
                  >
                    <FormControlLabel
                      value="1,2"
                      control={
                        <Radio
                          size="small"
                          defaultChecked={
                            adSetTemplate?.targeting?.genders?.length === 0 ||
                            adSetTemplate?.targeting?.genders?.length === 2
                          }
                        />
                      }
                      label="All"
                    />

                    <FormControlLabel
                      value="1"
                      control={<Radio size="small" />}
                      label="Male"
                      defaultChecked={
                        !adSetTemplate?.targeting?.genders
                          ? false
                          : adSetTemplate?.targeting?.genders[0] === 1
                      }
                    />

                    <FormControlLabel
                      value="2"
                      control={<Radio size="small" />}
                      label="Female"
                      defaultChecked={
                        !adSetTemplate?.targeting?.genders
                          ? false
                          : adSetTemplate?.targeting?.genders[0] === 2
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          textAlign: 'right',
          flex: '50%',
        }}
      >
        <Box
          component="img"
          src={audienceLogo}
          alt=""
          sx={{ width: '350px', marginTop: '50px' }}
        />
      </Grid>
    </Grid>
  );
};

export default DiyAudienceForm;
