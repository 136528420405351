import { Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import React from 'react';
import CampaignOverview from '../Tables/ReportList/CampaignOverview';
import { AverageResponse, Brand, BrandAnalytics, Report } from '../../types';
import { buildCampaignOverviewRows } from '../../utils/helpers/BrandAnalyticsHelper';
import BrandAnalyticsDetails from '../Details/BrandAnalyticsDetails';
import { humanizeString } from '../../utils/stringModifier';
import CampaignRegionOverview from '../Tables/ReportList/CampaignRegionOverview';
import { BusinessType } from '../../utils/helpers/BrandHelpers';
import { IS_DIY_ADZ } from '../../utils';

interface FacebookCampaignAnalyticsProps {
  campaignReport: Report;
  fromDate: Moment | null;
  toDate: Moment | null;
  analyticsData: BrandAnalytics;
  brand: Brand;
  averageResponseTime: AverageResponse;
  leadInitiativeRate: number;
  completedEngagementRate: number;
  messengerCampaigns: any;
  onGeneratePreview: (adId: string) => void;
  campaignType: string;
}

const FacebookCampaignAnalytics: React.FC<FacebookCampaignAnalyticsProps> = ({
  campaignReport,
  fromDate,
  toDate,
  analyticsData,
  brand,
  averageResponseTime,
  leadInitiativeRate,
  completedEngagementRate,
  messengerCampaigns,
  onGeneratePreview,
  campaignType,
}) => {
  const isMessengerAds = campaignType === 'messenger';
  const isLeads = campaignType === 'lead';
  const isFrandev = brand?.businessType === BusinessType.FRANDEV;

  return (
    <div>
      <Typography variant="h6" fontWeight="bold" textAlign="center" mt={2}>{`${
        brand?.brand
      } ${humanizeString(campaignType)} Campaigns`}</Typography>

      <div>
        <Typography variant="body2" textAlign="center" mb={2}>
          {`${moment(fromDate).format('LL')} - ${moment(toDate).format('LL')}`}
        </Typography>
      </div>

      {isMessengerAds ? (
        <BrandAnalyticsDetails
          averageResponseTime={averageResponseTime}
          analyticsData={analyticsData}
          leadInitiativeRate={leadInitiativeRate}
          completedEngagementRate={completedEngagementRate}
          messengerCampaigns={messengerCampaigns}
        />
      ) : null}

      <div>
        <CampaignOverview
          rows={buildCampaignOverviewRows(
            'Campaign',
            campaignType,
            isFrandev && IS_DIY_ADZ,
          )}
          campaigns={campaignReport?.campaigns || []}
          lead={analyticsData?.leadCount || 0}
          isCampaign
          showQL={isLeads && isFrandev && IS_DIY_ADZ}
        />
      </div>

      <div>
        <Typography variant="h6" fontWeight="bold" textAlign="center" my={2}>
          {`${brand?.brand} ${humanizeString(campaignType)} Ad Sets`}
        </Typography>
      </div>

      <div>
        <CampaignOverview
          rows={buildCampaignOverviewRows(
            'Ad Set',
            campaignType,
            isFrandev && IS_DIY_ADZ,
          )}
          adsets={campaignReport?.adsets || []}
          isAdset
          lead={analyticsData?.leadCount || 0}
          showQL={isLeads && isFrandev && IS_DIY_ADZ}
        />
      </div>

      <div>
        <Typography variant="h6" fontWeight="bold" textAlign="center" my={2}>
          {`${brand?.brand} ${humanizeString(campaignType)} Ads`}
        </Typography>
      </div>

      <div>
        <CampaignOverview
          rows={buildCampaignOverviewRows(
            'Ad',
            campaignType,
            isFrandev && IS_DIY_ADZ,
          )}
          ads={campaignReport?.ads || []}
          isAds
          onGeneratePreview={onGeneratePreview}
          showQL={isLeads && isFrandev && IS_DIY_ADZ}
        />
      </div>

      <div>
        <Typography variant="h6" fontWeight="bold" textAlign="center" my={2}>
          {`${brand?.brand} Platform Breakdown`}
        </Typography>
      </div>

      <div>
        <CampaignRegionOverview
          data={campaignReport?.breakdowns}
          campaignType={campaignType}
          breakdownType="platform"
        />
      </div>

      <div>
        <Typography variant="h6" fontWeight="bold" textAlign="center" my={2}>
          {`${brand?.brand} Region Breakdown`}
        </Typography>
      </div>

      <div>
        <CampaignRegionOverview
          data={campaignReport?.breakdowns}
          campaignType={campaignType}
          breakdownType="region"
        />
      </div>

      <div>
        <Typography variant="h6" fontWeight="bold" textAlign="center" my={2}>
          {`${brand?.brand} DMA Breakdown`}
        </Typography>
      </div>

      <div>
        <CampaignRegionOverview
          data={campaignReport?.breakdowns}
          campaignType={campaignType}
          breakdownType="dma"
        />
      </div>
    </div>
  );
};

export default FacebookCampaignAnalytics;
