import { GridColDef } from '@mui/x-data-grid';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Tooltip, Typography } from '@mui/material';

export const leadFormColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Form ID',
    width: 150,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      const { formId } = params.row;

      return (
        <div className={styles.name}>
          <Tooltip
            title={<Typography variant="body2">{formId}</Typography>}
            disableInteractive
          >
            {formId}
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      const { name } = params.row;

      return (
        <div className={styles.name}>
          <Tooltip
            title={<Typography variant="body2">{name}</Typography>}
            disableInteractive
          >
            {name}
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      const { description } = params.row;

      return (
        <div className={styles.name}>
          <Tooltip
            title={<Typography variant="body2">{description}</Typography>}
            disableInteractive
          >
            {description}
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    width: 180,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      const { createdAt } = params.row;

      return (
        <div className={styles.name}>
          <Tooltip
            title={<Typography variant="body2">{createdAt}</Typography>}
            disableInteractive
          >
            {createdAt}
          </Tooltip>
        </div>
      );
    },
  },
];
