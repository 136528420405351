import React, { useContext, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Dashboard.module.scss';
import moment, { Moment } from 'moment';
import {
  Brand,
  DashboardData,
  GoogleAdsMetricsData,
  LineChartData,
} from '../../types';
import {
  fetchFacebookCampaignDashboardData,
  fetchFacebookCampaignLineChartData,
  getBrandCampaignAnalytics,
  getGoogleCampaignMetrics,
} from '../../services/report';
import { useSelector } from 'react-redux';
import {
  adsProvider,
  isLeadAds,
  isMessengerAds,
  isSalesAds,
  isTrafficAds,
  leadDescription,
} from '../../utils/constants/facebookAds';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { AuthContext } from '../../context';
import {
  ADMIN,
  AGENCY,
  BRAND_MANAGER,
  IS_DIY_ADZ,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import { buildRefreshToken } from '../../utils/helpers/DefaultTokenBuilder';
import { Box, Card, CardHeader, Grid, Typography } from '@mui/material';
import CountUp from 'react-countup';
import CircularLoading from '../../components/CircularLoading';
import BarChart from '../../components/Dashboard/BarChart';
import LineChart from '../../components/Dashboard/LineChart';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PaymentsIcon from '@mui/icons-material/Payments';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PaidIcon from '@mui/icons-material/Paid';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { XsOnly } from '../../utils/breakpoints';
import { useNavigate } from 'react-router-dom';
import { AnalyticTools } from '../../utils/helpers/BrandHelpers';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const campaignProvider: string = useSelector(
    (state: any) => state?.campaignProvider?.campaignProvider || 'facebook',
  );
  const { state } = useContext(AuthContext);
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAdmin = state.role === ADMIN;
  const isAgency = state.role === AGENCY;
  const isSalesperson = state.role === SALESPERSON;
  const isBrandManager = state.role === BRAND_MANAGER;
  const roleBasedId = state.roleBasedId;
  const refreshToken = state.authUser?.refreshToken;
  const END_DATE = moment().subtract(1, 'day');
  const isFacebook = campaignProvider === adsProvider.FACEBOOK;
  const isGoogle = campaignProvider === adsProvider.GOOGLE;
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultRefreshToken, setDefaultRefreshToken] = useState<string>(null);
  const [dashboardData, setDashboardData] = useState<DashboardData[]>([
    {
      value: 0,
      label: 'Clicks',
      icon: <AdsClickIcon sx={{ fontSize: '30px' }} />,
      isCurrency: false,
    },
    {
      value: 0,
      label: 'Impressions',
      icon: <RemoveRedEyeIcon sx={{ fontSize: '30px' }} />,
      isCurrency: false,
    },
    {
      value: 0,
      label: 'Leads',
      icon: <GroupAddIcon sx={{ fontSize: '30px' }} />,
      isCurrency: false,
    },
    {
      value: 0,
      label: 'CPM',
      icon: <PaymentsIcon sx={{ fontSize: '30px' }} />,
      isCurrency: true,
    },
    {
      value: 0,
      label: 'CPL',
      icon: <CurrencyExchangeIcon sx={{ fontSize: '30px' }} />,
      isCurrency: true,
    },
    {
      value: 0,
      label: 'Spend',
      icon: <PaidIcon sx={{ fontSize: '30px' }} />,
      isCurrency: true,
    },
    {
      value: 0,
      label: 'CPC',
      icon: <PriceCheckIcon sx={{ fontSize: '30px' }} />,
      isCurrency: true,
    },
  ]);
  const [weeklyChartData, setWeeklyChartData] = useState<any>({
    labels: ['Leads'],
    datasets: [
      {
        label: 'Current',
        data: [0],
        backgroundColor: '#749B20',
      },
      {
        label: 'Previous',
        data: [0],
        backgroundColor: '#D87E33',
      },
    ],
  });
  const [monthlyChartData, setMonthlyChartData] = useState<any>({
    labels: ['Leads'],
    datasets: [
      {
        label: 'Current',
        data: [0],
        backgroundColor: '#749B20',
      },
      {
        label: 'Previous',
        data: [0],
        backgroundColor: '#D87E33',
      },
    ],
  });

  const [weeklyTrendData, setWeeklyTrendData] = useState<any>(null);
  const [monthlyTrendData, setMonthlyTrendData] = useState<any>(null);
  const [lineChartData, setLineChartData] = useState<LineChartData>(null);
  const [weeklyLineChartData, setWeeklyLineChartData] = useState<any>({
    labels: Array.from({ length: 7 }, (_, i) => i + 1),
    datasets: [
      {
        label: 'Current',
        data: [],
        backgroundColor: '#749B20',
        borderColor: '#749B20',
      },
      {
        label: 'Previous',
        data: [],
        backgroundColor: '#D87E33',
        borderColor: '#D87E33',
      },
    ],
  });
  const [monthlyLineChartData, setMonthlyLineChartData] = useState<any>({
    labels: Array.from({ length: 28 }, (_, i) => i + 1),
    datasets: [
      {
        label: 'Current',
        data: [],
        backgroundColor: '#749B20',
        borderColor: '#749B20',
      },
      {
        label: 'Previous',
        data: [],
        backgroundColor: '#D87E33',
        borderColor: '#D87E33',
      },
    ],
  });

  useEffect(() => {
    handleBuildProviderTokens();
  }, []);

  useEffect(() => {
    if ((location || brand) && IS_DIY_ADZ) {
      if (!(location || brand)?.analyticTools?.includes(AnalyticTools.TRENDZ)) {
        if (
          (location || brand)?.analyticTools?.includes(AnalyticTools.SCORECARDZ)
        ) {
          navigate('/scorecardz');
        } else {
          navigate('/reportz');
        }
      }
    }
  }, [location, brand, IS_DIY_ADZ]);

  useEffect(() => {
    if ((location || brand) && campaignProvider) {
      const startDate = moment().subtract(29, 'days');

      if (defaultRefreshToken) {
        fetchCampaignTotalBreakdown(
          startDate,
          END_DATE,
          !isFacebook ? defaultRefreshToken : null,
        );
        fetchCampaignDashboardData(defaultRefreshToken);
        fetchCampaignDashboardLineData(defaultRefreshToken);

        setWeeklyTrendData(null);
        setMonthlyTrendData(null);
        setLineChartData(null);
      }
    }
  }, [location, brand, campaignProvider, defaultRefreshToken]);

  useEffect(() => {
    if ((location || brand) && campaignProvider) {
      setWeeklyChartData(buildChartData(weeklyTrendData));
      setMonthlyChartData(buildChartData(monthlyTrendData));
    }
  }, [location, brand, campaignProvider, weeklyTrendData, monthlyTrendData]);

  useEffect(() => {
    if ((location || brand) && campaignProvider) {
      setWeeklyLineChartData(buildWeeklyLineChartData(lineChartData?.weekly));
      setMonthlyLineChartData(
        buildMonthlyLineChartData(lineChartData?.monthly),
      );
    }
  }, [location, brand, campaignProvider, lineChartData]);

  const fetchCampaignDashboardData = async (refreshToken: string) => {
    setLoading(true);
    try {
      const response = await fetchFacebookCampaignDashboardData(
        (location || brand)?._id,
        campaignProvider,
        refreshToken,
      );

      setWeeklyTrendData(response.data.weekly);
      setMonthlyTrendData(response.data.monthly);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaignDashboardLineData = async (refreshToken: string) => {
    setLoading(true);
    try {
      const response = await fetchFacebookCampaignLineChartData(
        (location || brand)?._id,
        campaignProvider,
        refreshToken,
      );

      setLineChartData(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaignTotalBreakdown = async (
    startDate: Moment | null,
    endDate: Moment | null,
    refreshToken?: string,
  ) => {
    try {
      setLoading(true);

      if (isFacebook) {
        const response = await getBrandCampaignAnalytics(
          (location || brand)?._id,
          startDate,
          endDate,
        );

        const { leadAds, messengerAds, trafficAds, salesAds } = response;

        buildFacebookDashboardItems(
          leadAds,
          messengerAds,
          trafficAds,
          salesAds,
        );
      }

      if (isGoogle) {
        const data = await getGoogleCampaignMetrics(
          (location || brand)?._id,
          refreshToken,
          startDate,
          endDate,
        );

        buildGoogleDashboardItems(data.data);
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBuildProviderTokens = () => {
    buildRefreshToken(
      isSuperAdmin || isAdmin,
      isAgency,
      isBrandManager || isSalesperson,
      refreshToken,
      roleBasedId,
      setDefaultRefreshToken,
    );
  };

  const buildFacebookDashboardItems = (
    leadAds?: any,
    messengerAds?: any,
    trafficAds?: any,
    salesAds?: any,
  ) => {
    let temp: DashboardData[] = [];
    let clicks = 0;
    let impressions = 0;
    let leadCount = 0;
    let spend = 0;

    if (leadAds && !!leadAds?.campaigns) {
      leadAds.campaigns.forEach((ad: any) => {
        clicks += parseInt(ad?.clicks || 0);
        impressions += parseInt(ad?.impressions || 0);
        spend += parseFloat(ad?.spend || 0);
        if (isLeadAds(ad?.objective)) {
          if (ad?.actions) {
            const leadAction = ad?.actions.find((action: any) => {
              return action.action_type === leadDescription(ad?.objective);
            });

            leadCount += parseInt(leadAction?.value || 0);
          }
        }
      });
    }

    if (messengerAds && !!messengerAds?.campaigns) {
      messengerAds.campaigns.forEach((ad: any) => {
        clicks += parseInt(ad?.clicks || 0);
        impressions += parseInt(ad?.impressions || 0);
        spend += parseFloat(ad?.spend || 0);
        if (isMessengerAds(ad?.objective)) {
          if (ad?.actions) {
            const leadAction = ad?.actions.find((action: any) => {
              return action.action_type === leadDescription(ad?.objective);
            });

            leadCount += parseInt(leadAction?.value || 0);
          }
        }
      });
    }

    if (trafficAds && !!trafficAds?.campaigns) {
      trafficAds.campaigns.forEach((ad: any) => {
        clicks += parseInt(ad?.clicks || 0);
        impressions += parseInt(ad?.impressions || 0);
        spend += parseFloat(ad?.spend || 0);
        if (isTrafficAds(ad?.objective)) {
          if (ad?.actions) {
            const leadAction = ad?.actions.find((action: any) => {
              return action.action_type === leadDescription(ad?.objective);
            });

            leadCount += parseInt(leadAction?.value || 0);
          }
        }
      });
    }

    if (salesAds && !!salesAds?.campaigns) {
      salesAds.campaigns.forEach((ad: any) => {
        clicks += parseInt(ad?.clicks || 0);
        impressions += parseInt(ad?.impressions || 0);
        spend += parseFloat(ad?.spend || 0);
        if (isSalesAds(ad?.objective)) {
          if (ad?.actions) {
            const leadAction = ad?.actions.find((action: any) => {
              return action.action_type === leadDescription(ad?.objective);
            });

            leadCount += parseInt(leadAction?.value || 0);
          }
        }
      });
    }

    temp = [
      {
        value: clicks,
        label: 'Clicks',
        icon: <AdsClickIcon sx={{ fontSize: '30px' }} />,
        isCurrency: false,
      },
      {
        value: impressions,
        label: 'Impressions',
        icon: <RemoveRedEyeIcon sx={{ fontSize: '30px' }} />,
        isCurrency: false,
      },
      {
        value: leadCount,
        label: 'Leads',
        icon: <GroupAddIcon sx={{ fontSize: '30px' }} />,
        isCurrency: false,
      },
      {
        value: impressions > 0 ? (spend / impressions) * 1000 : 0,
        label: 'CPM',
        icon: <PaymentsIcon sx={{ fontSize: '30px' }} />,
        isCurrency: true,
      },
      {
        value: leadCount > 0 ? spend / leadCount : 0,
        label: 'CPL',
        icon: <CurrencyExchangeIcon sx={{ fontSize: '30px' }} />,
        isCurrency: true,
      },
      {
        value: spend,
        label: 'Spend',
        icon: <PaidIcon sx={{ fontSize: '30px' }} />,
        isCurrency: true,
      },
      {
        value: clicks > 0 ? spend / clicks : 0,
        label: 'CPC',
        icon: <PriceCheckIcon sx={{ fontSize: '30px' }} />,
        isCurrency: true,
      },
    ];

    setDashboardData(temp);
  };

  const buildGoogleDashboardItems = (metrics: GoogleAdsMetricsData[]) => {
    let temp: DashboardData[] = [];
    let clicks = 0;
    let impressions = 0;
    let cpm = 0;
    let conversions = 0;
    let cost = 0;
    let cpc = 0;

    metrics.forEach((data: GoogleAdsMetricsData) => {
      const { campaigns } = data;
      clicks += campaigns.clicks;
      impressions += campaigns.impressions;
      cpm += campaigns.cpm;
      conversions += campaigns.conversions;
      cost += campaigns.spend;
      cpc += campaigns.cpc;
    });

    temp = [
      {
        value: clicks,
        label: 'Clicks',
        icon: <AdsClickIcon sx={{ fontSize: '30px' }} />,
        isCurrency: false,
      },
      {
        value: impressions,
        label: 'Impressions',
        icon: <RemoveRedEyeIcon sx={{ fontSize: '30px' }} />,
        isCurrency: false,
      },
      {
        value: conversions,
        label: 'Conversions',
        icon: <GroupAddIcon sx={{ fontSize: '30px' }} />,
        isCurrency: false,
      },
      {
        value: cpm,
        label: 'CPM',
        icon: <PaymentsIcon sx={{ fontSize: '30px' }} />,
        isCurrency: true,
      },
      {
        value: conversions > 0 ? cost / conversions : 0,
        label: 'CPL',
        icon: <CurrencyExchangeIcon sx={{ fontSize: '30px' }} />,
        isCurrency: true,
      },
      {
        value: cost,
        label: 'Spend',
        icon: <PaidIcon />,
        isCurrency: true,
      },
      {
        value: cpc,
        label: 'CPC',
        icon: <PriceCheckIcon sx={{ fontSize: '30px' }} />,
        isCurrency: true,
      },
    ];

    setDashboardData(temp);
  };

  const buildChartData = (data: any) => {
    return {
      labels: ['Leads'],
      datasets: [
        {
          label: 'Current',
          data: [data?.current || 0],
          backgroundColor: '#749B20',
        },
        {
          label: 'Previous',
          data: [data?.previous || 0],
          backgroundColor: '#D87E33',
        },
      ],
    };
  };

  const dataSorter = (data: any[], key: string) => {
    return data?.sort((a: any, b: any) => {
      if (a[key] < b[key]) {
        return -1;
      }

      return 1;
    });
  };

  const buildMonthlyLineChartData = (data: any) => {
    const currentValues = dataSorter(data?.current, 'label')?.map((x: any) => {
      return x.value;
    });
    const previousValues = dataSorter(data?.previous, 'label')?.map(
      (x: any) => {
        return x.value;
      },
    );

    const labels = Array.from({ length: 28 }, (_, i) => i + 1);

    return {
      labels,
      datasets: [
        {
          label: 'Current',
          data: currentValues,
          backgroundColor: '#749B20',
          borderColor: '#749B20',
        },
        {
          label: 'Previous',
          data: previousValues,
          backgroundColor: '#D87E33',
          borderColor: '#D87E33',
        },
      ],
    };
  };

  const buildWeeklyLineChartData = (data: any) => {
    const currentValues = dataSorter(data?.current, 'label')?.map((x: any) => {
      return x.value;
    });
    const previousValues = dataSorter(data?.previous, 'label')?.map(
      (x: any) => {
        return x.value;
      },
    );

    const labels = Array.from({ length: 7 }, (_, i) => i + 1);

    return {
      labels,
      datasets: [
        {
          label: 'Current',
          data: currentValues,
          backgroundColor: '#749B20',
          borderColor: '#749B20',
        },
        {
          label: 'Previous',
          data: previousValues,
          backgroundColor: '#D87E33',
          borderColor: '#D87E33',
        },
      ],
    };
  };

  return (
    <Box
      sx={{
        minHeight: 'calc(100vh - 100px)',
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#F1F0F0',
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },

        '&::-webkit-scrollbar-thumb': {
          background: '#096f4d',
          borderRadius: '18px',

          '&:hover': {
            background: '#096f4d',
          },
        },
      }}
    >
      <div className={styles.dashboard}>
        <CircularLoading loading={loading} />

        <Grid container spacing={1} justifyContent="center" columns={14}>
          <Grid item xs={14} justifyContent="center">
            <Typography variant="body2" fontWeight="bold" textAlign="center">
              {`${moment()
                .subtract(29, 'days')
                .format('LL')} - ${END_DATE.format('LL')}`}
            </Typography>
          </Grid>

          {dashboardData.map((data: DashboardData, index: number) => {
            return (
              <Grid item xs={7} sm={'auto'} key={index}>
                <Card sx={{ width: { xs: '100%', sm: '170px' } }}>
                  <CardHeader
                    avatar={data.icon}
                    title={data.label}
                    subheader={
                      <CountUp
                        end={data.value}
                        duration={3}
                        separator=","
                        prefix={data.isCurrency ? '$' : ''}
                        decimals={data.isCurrency ? 2 : 0}
                      />
                    }
                    titleTypographyProps={{
                      sx: {
                        color: '#096F4D',
                        fontSize: '12px',
                        paddingLeft: '0',
                      },
                    }}
                    subheaderTypographyProps={{
                      sx: {
                        color: '#096F4D',
                        fontWeight: 'bold',
                        fontSize: XsOnly() ? '14px' : '16px',
                      },
                    }}
                    sx={{ padding: '10px' }}
                  />
                </Card>
              </Grid>
            );
          })}
        </Grid>

        <Grid container spacing={2} justifyContent="center" mt={1}>
          <Grid item xs={12} sm={6}>
            <BarChart trend="7" chartData={weeklyChartData} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <BarChart trend="28" chartData={monthlyChartData} />
          </Grid>
        </Grid>

        {weeklyChartData && monthlyChartData ? (
          <Grid container spacing={2} justifyContent="center" mt={1}>
            <Grid item xs={12}>
              <LineChart trend="7" chartData={weeklyLineChartData} />
            </Grid>

            <Grid item xs={12}>
              <LineChart trend="28" chartData={monthlyLineChartData} />
            </Grid>
          </Grid>
        ) : null}
      </div>
    </Box>
  );
};

export default Dashboard;
