import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { columns } from '../Tables/BudgetList/BudgetTableColumn';
import { MenuItem, TextField, Typography } from '@mui/material';
import styles from '../../assets/styles/pages/Budgets.module.scss';
import {
  Agency,
  BrandBudget,
  BudgetFormFields,
  BudgetSyncFields,
} from '../../types';
import { ChangeEventType } from '../../types';
import moment from 'moment';
import { SmOnly, XsOnly } from '../../utils/breakpoints';
import { PrimaryButton } from '../Buttons';
import { humanizeString } from '../../utils/stringModifier';
import { CustomLoadingOverlay } from '../Loading';
import { adsProvider } from '../../utils/constants/facebookAds';
import { AuthContext } from '../../context';
import {
  ADMIN,
  AGENCY,
  BRAND_MANAGER,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import {
  createBudget,
  fetchAllBudgets,
  fetchAllBudgetsByAgency,
  fetchAllBudgetsByBrandManager,
  fetchAllBudgetsBySalesperson,
  syncBudget,
} from '../../services/budget';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAlert } from '../../redux/actions';
import PageHeader from '../PageHeader';
import CircularLoading from '../CircularLoading';

const dataGridStyling = () => {
  if (!XsOnly()) return;

  return {
    maxWidth: '330px',
    minWidth: '330px',
    overflow: 'auto',
  };
};

const AddBudgetForm: React.FC = () => {
  const campaignProvider = useSelector(
    (state: any) => state?.budgetReportProvider?.provider,
  );
  const { state } = useContext(AuthContext);
  const dispatch = useDispatch();
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAdmin = state.role === ADMIN;
  const isAgency = state.role === AGENCY;
  const isBrandManager = state.role === BRAND_MANAGER;
  const isSalesperson = state.role === SALESPERSON;
  const roleBasedId = state.roleBasedId;
  const [formValues, setFormValues] = useState<BudgetFormFields>({
    brand: '',
    year: null,
    budgets: null,
    provider: campaignProvider,
  });
  const [years, setYears] = useState([]);
  const [budgets, setBudgets] = useState<BrandBudget[]>([]);
  const [year, setYear] = useState<string>(
    moment().utc().local().year().toString(),
  );
  const [budgetRows, setBudgetRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedColumn, setSelectedColumn] = useState<string>('');
  const agency: Agency = useSelector((state: any) => state?.agency?.agency);

  useEffect(() => {
    getBrandBudgets();
    buildYears();
    setSelectedColumn('');
  }, [agency, campaignProvider]);

  useEffect(() => {
    budgetRowData(year);
  }, [budgets]);

  const getBrandBudgets = async () => {
    try {
      setLoading(true);
      if (isAgency) {
        const response = await fetchAllBudgetsByAgency(roleBasedId);

        setBudgets(response.data);
      } else if (isSalesperson) {
        const response = await fetchAllBudgetsBySalesperson(roleBasedId);

        setBudgets(response.data);
      } else if (isBrandManager) {
        const response = await fetchAllBudgetsByBrandManager(roleBasedId);

        setBudgets(response.data);
      } else {
        if (agency) {
          const response = await fetchAllBudgetsByAgency(agency?._id);

          setBudgets(response.data);
        } else {
          const response = await fetchAllBudgets();

          setBudgets(response.data);
        }
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      dispatch(
        toggleAlert({ toggle: true, type: 'error', message: error.message }),
      );
    }
  };

  const handleCreateBudget = async (obj: BudgetFormFields) => {
    setLoading(true);

    try {
      const data = await createBudget(obj);

      let dupBudgets: any = budgets;

      const budgetIndex = dupBudgets.findIndex((budget: any) => {
        return budget._id === data.budget.brand;
      });

      const monthlyBudgetIndex = dupBudgets[budgetIndex].budgets.findIndex(
        (monthly: any) => {
          return monthly.year === year &&
            campaignProvider === adsProvider.FACEBOOK
            ? monthly.provider === campaignProvider || !monthly.provider
            : monthly.provider === campaignProvider;
        },
      );

      if (monthlyBudgetIndex < 0) {
        dupBudgets[budgetIndex].budgets = [data.budget];
      } else {
        dupBudgets[budgetIndex].budgets[monthlyBudgetIndex] = data.budget;
      }

      setBudgets(dupBudgets);
      budgetRowData(year);
      setLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Budget Created Successfully',
        }),
      );
    } catch (error: any) {
      setLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.message,
          type: 'error',
        }),
      );
    }
  };

  const handleSyncBudget = async () => {
    setLoading(true);

    try {
      let formFields: BudgetSyncFields = {
        month: selectedColumn,
        year,
        provider: campaignProvider,
      };

      if (isSuperAdmin || isAdmin) {
        formFields = { ...formFields };
      }

      const data = await syncBudget(formFields);

      let dupBudgets: any = budgets;

      data.data.forEach((obj: any) => {
        const budgetIndex = dupBudgets.findIndex((budget: any) => {
          return budget._id === obj.brand;
        });

        const monthlyBudgetIndex = dupBudgets[budgetIndex].budgets.findIndex(
          (monthly: any) => {
            return monthly.year === year;
          },
        );

        if (monthlyBudgetIndex < 0) {
          dupBudgets[budgetIndex].budgets = [
            ...dupBudgets[budgetIndex].budgets,
            obj,
          ];
        } else {
          dupBudgets[budgetIndex].budgets[monthlyBudgetIndex] = obj;
        }
      });

      setBudgets(dupBudgets);
      budgetRowData(year);

      dispatch(
        toggleAlert({
          toggle: true,
          message: data.message,
        }),
      );

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.message,
          type: 'error',
        }),
      );
    }
  };

  const handleSetYear = async (year: string) => {
    setYear(year);
    budgetRowData(year);
    setSelectedColumn('');
  };

  const buildYears = () => {
    const startDate = moment('2022-01-01').utc().local();
    const endDate = moment().utc().local();
    let years: string[] = [];

    do {
      years = [...years, startDate.year().toString()];
    } while (startDate.add(1, 'year') < endDate);

    if (endDate.month() === 11) {
      years = [...years, startDate.year().toString()];
    }

    setYears(years);
  };

  const buildFormValues = (params: any) => {
    const { field, value, id } = params;

    let budgetForm: { [key: string]: number } = {};
    budgetForm[field] = value;
    let dupBudgets: any = budgets;

    const budgetIndex = dupBudgets.findIndex((budget: any) => {
      return budget.brandId === id;
    });

    const monthlyBudgetIndex = dupBudgets[budgetIndex].budgets.findIndex(
      (budget: any) => {
        return budget.year === year && campaignProvider === adsProvider.FACEBOOK
          ? budget.provider === campaignProvider || !budget.provider
          : budget.provider === campaignProvider;
      },
    );

    setFormValues({
      brand: dupBudgets[budgetIndex]._id,
      year,
      budgets:
        monthlyBudgetIndex < 0
          ? budgetForm
          : {
              ...dupBudgets[budgetIndex].budgets[monthlyBudgetIndex].budgets,
              ...budgetForm,
            },
      provider: campaignProvider,
    });
  };

  const budgetRowData = (setYear: string) => {
    const rows = budgets.map((row: any) => {
      const { brand, brandId, _id, budgets, paused } = row;
      const budget = budgets.find((x: any) => {
        return x.year === setYear && campaignProvider === x.provider;
      });

      return {
        _id,
        id: brandId,
        brandName: brand,
        january: budget?.budgets?.january,
        february: budget?.budgets?.february,
        march: budget?.budgets?.march,
        april: budget?.budgets?.april,
        may: budget?.budgets?.may,
        june: budget?.budgets?.june,
        july: budget?.budgets?.july,
        august: budget?.budgets?.august,
        september: budget?.budgets?.september,
        october: budget?.budgets?.october,
        november: budget?.budgets?.november,
        december: budget?.budgets?.december,
        active: !paused,
      };
    });

    setBudgetRows(rows);
  };

  return (
    <div className={styles.budgets}>
      <div className={styles.base}>
        <CircularLoading loading={loading} />

        <div className={styles.header}>
          <PageHeader
            title={`${humanizeString(campaignProvider)} Ads Budget Set Up`}
          />

          <div className={styles.controls}>
            <div className={styles.sync}>
              {selectedColumn ? (
                <>
                  <div>
                    <Typography variant="caption">
                      Populate all current month budget based on previous month
                      budget
                    </Typography>
                  </div>

                  <PrimaryButton
                    title={`Sync Budget for ${humanizeString(
                      selectedColumn,
                    )} ${year}`}
                    loading={loading}
                    handleOnClick={handleSyncBudget}
                  />
                </>
              ) : null}
            </div>

            <div>
              <TextField
                id="year"
                name="year"
                select
                sx={{ width: '100%' }}
                label="Year"
                value={year}
                InputLabelProps={{ shrink: true }}
                size="small"
                onChange={(e: ChangeEventType) => {
                  handleSetYear(e.target.value);
                }}
              >
                {years.map((year: number) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>

        <div
          className={`${styles['table']} ${SmOnly() ? styles['-tablet'] : ''} ${
            XsOnly() ? styles['-mobile'] : ''
          }`}
        >
          <Box>
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: 'brand', sort: 'asc' }],
                },
              }}
              rows={budgetRows}
              columns={columns}
              autoHeight
              density="compact"
              hideFooterSelectedRowCount
              components={{
                LoadingOverlay: CustomLoadingOverlay,
              }}
              onCellEditCommit={(params: any) => {
                buildFormValues(params);
              }}
              onCellEditStop={() => {
                handleCreateBudget(formValues);
              }}
              hideFooter
              sx={{
                ...dataGridStyling(),
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                  {
                    display: 'none',
                  },
                '& input[type=number]': {
                  MozAppearance: 'textfield',
                },
              }}
              onCellClick={(params: GridCellParams) => {
                if (params.field === 'brand') return;

                setSelectedColumn(params.field);
              }}
              loading={loading}
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddBudgetForm;
