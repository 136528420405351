import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { AdsTemplate } from '../../../types/IDiy';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { DataGrid } from '@mui/x-data-grid';
import { CustomLoadingOverlay } from '../../Loading';
import { adTemplatesColumns } from './AdsTemplateListTableColumn';
import { styledDataGrid } from '../../../utils/helpers/DataGridHelper';

interface AdsTemplateListTableProps {
  templates: AdsTemplate[];
  loading: boolean;
  onOpenPreview: (template: AdsTemplate) => void;
}

const AdsTemplateListTable: React.FC<AdsTemplateListTableProps> = ({
  templates,
  loading,
  onOpenPreview,
}) => {
  const theme = useTheme();
  const isSmAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  const rows = templates.slice().map((template: AdsTemplate) => {
    return {
      id: template.adsTemplateId,
      name: template.name,
      description: template.description,
      _id: template._id,
      objective: template.objective,
      category: template.category,
      media: template.media,
      template,
      openPreview: onOpenPreview,
    };
  });

  return (
    <Box className={styles.table}>
      <DataGrid
        density="compact"
        rows={rows}
        columns={adTemplatesColumns(isSmAndDown)}
        autoHeight
        hideFooterSelectedRowCount
        loading={loading}
        components={{ LoadingOverlay: CustomLoadingOverlay }}
        sx={styledDataGrid()}
      />
    </Box>
  );
};

export default AdsTemplateListTable;
