import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider, ExpressThemeProvider } from './context';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { Provider as ReduxProvider } from 'react-redux';
import reduxStore from './redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleClientId } from './services/api';
import { createTheme, ThemeProvider } from '@mui/material/styles';

/**
 * React Dev Tools Disabler
 * @description Disable React Dev Tools on Staging and Production Environment
 * @author Angelo David <angelod@codev.com>
 * @reference https://www.npmjs.com/package/@fvilers/disable-react-devtools
 */
if (process.env.NODE_ENV !== 'development') {
  disableReactDevTools();
}

const theme = createTheme({
  typography: {
    fontFamily: `'Poppins' san-serif`,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ReduxProvider store={reduxStore}>
        <AuthProvider>
          <ExpressThemeProvider>
            <BrowserRouter>
              <GoogleOAuthProvider clientId={googleClientId}>
                <App />
              </GoogleOAuthProvider>
            </BrowserRouter>
          </ExpressThemeProvider>
        </AuthProvider>
      </ReduxProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
