import React from 'react';
import { Typography } from '@mui/material';

interface FormHeaderProps {
  title: string;
  my?: number;
}

const FormHeader: React.FC<FormHeaderProps> = ({ title, my = 0 }) => {
  return (
    <Typography
      variant="h6"
      sx={{ textAlign: 'center', fontWeight: 'bold', color: '#096f4d' }}
      my={my}
    >
      {title}
    </Typography>
  );
};

export default FormHeader;
