import React, { useContext, useEffect, useState } from 'react';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AppRoutes from './routes';
import { ThemeProvider, useTheme } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { AuthContext, ExpressThemeContext } from './context';
import LinearProgressBar from './components/LinearProgressBar';
import { getAppTheme } from './services/theme';
import { AUTH_TOKEN, GET_THEME, IS_DIY_ADZ, LOGGED_IN_USER } from './utils';
import chatzAppFavicon from './assets/images/favicon.ico';
import aiadzFavicon from './assets/images/aiadz-favicon.ico';
import { isExpired, resetSession } from './utils/tokenValidator';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setAgencies,
  setAgency,
  setBrand,
  setBrandFilter,
  setBrands,
  setBudgetReportProvider,
  setCampaignProvider,
  setLocation,
  setLocations,
  toggleAlert,
} from './redux/actions';

const App: React.FC = () => {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const theme = useTheme();
  const { loading: authLoading } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { dispatch } = useContext(ExpressThemeContext);
  const [currentPath, setCurrentPath] = useState<string>(null);
  const location = useLocation();

  useEffect(() => {
    let link: any = document.querySelector(`link[rel~='icon']`);
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = IS_DIY_ADZ ? aiadzFavicon : chatzAppFavicon;
    document.title = IS_DIY_ADZ ? 'aiADZ' : "Let's Chatz";
  }, [IS_DIY_ADZ]);

  useEffect(() => {
    const pathname = location.pathname.split('/').filter((x) => x);
    if (pathname[0] !== 'login') {
      setCurrentPath(pathname[0]);
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getAppTheme(window.location.hostname.split('.')[0]);
        if (res && res !== null) {
          dispatch({
            type: GET_THEME,
            payload: { theme: res.theme, properties: res.properties },
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (authToken) {
      const interval = setInterval(() => {
        if (isExpired(authToken)) {
          handleLogout();
        }
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [authToken]);

  const handleLogout = () => {
    resetSession();
    resetReduxSession();
    navigate(`/login${currentPath ? `?redirectUrl=${currentPath}` : ''}`);
    reduxDispatch(
      toggleAlert({
        toggle: true,
        message: 'Your session has been expired.',
        type: 'error',
        horizontal: 'center',
        vertical: 'top',
      }),
    );
  };

  const resetReduxSession = () => {
    dispatch({
      type: LOGGED_IN_USER,
      payload: {
        token: null,
        role: null,
        roleBasedId: null,
        user: null,
        capabilities: null,
      },
    });
    reduxDispatch(setBrands([]));
    reduxDispatch(setBrand(null));
    reduxDispatch(setAgencies([]));
    reduxDispatch(setAgency(null));
    reduxDispatch(setBrandFilter(null));
    reduxDispatch(setCampaignProvider(null));
    reduxDispatch(setBudgetReportProvider(null));
    reduxDispatch(setLocations([]));
    reduxDispatch(setLocation(null));
  };

  if (authLoading || loading) return <LinearProgressBar />;

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <ToastContainer />
        <AppRoutes />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
