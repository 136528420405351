import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { PrimaryButton } from '../../Buttons';
import ModalHeader from '../../Modal/ModalHeader';
import CircularLoading from '../../CircularLoading';
import { Brand, ChangeEventType, FbAssetFeedSpec } from '../../../types';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import { generateAdCopyTemplates } from '../../../services/ads';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../redux/actions';

interface AdCopySuggestionFormProps {
  setFormValues: Dispatch<SetStateAction<FbAssetFeedSpec>>;
  formValues: FbAssetFeedSpec;
  onClose: () => void;
  modal?: boolean;
  pageId?: string;
  brand: Brand;
}

const AdCopySuggestionForm: React.FC<AdCopySuggestionFormProps> = ({
  setFormValues,
  formValues,
  onClose,
  modal = false,
  pageId,
  brand,
}) => {
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [suggestionForm, setSuggestionForm] = useState<any>({
    tone: '',
    emoji: 'yes',
    purpose: '',
    characters: 300,
    bulletPoints: 'yes',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [showEmojis, setShowEmojis] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({
    tone: '',
    purpose: '',
    emoji: '',
    characters: '',
    bulletPoints: '',
  });

  const handleOnSubmit = async () => {
    setLoading(true);
    try {
      const response = await generateAdCopyTemplates(
        brand?._id,
        suggestionForm?.emoji,
        suggestionForm?.purpose,
        suggestionForm?.characters,
        suggestionForm?.bulletPoints,
      );

      if (modal) {
        setSuggestions(response.data);
      } else {
        handleOnSelect(response.data[0]);
      }
    } catch (error: any) {
      const err = error.response.data;
      if (err.message) {
        dispatch(
          toggleAlert({
            toggle: true,
            message: err?.message || 'Oops! Something went wrong.',
            type: 'error',
          }),
        );
        console.log(err.message);
      } else {
        setErrors({
          ...errors,
          purpose: err?.purpose,
          emoji: err?.emoji,
          characters: err?.characters,
          bulletPoints: err?.bulletPoints,
        });
      }

      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = (e: ChangeEventType, index: number) => {
    let temp: string[] = [...suggestions];

    temp[index] = e.target.value;

    setSuggestions(temp);
  };

  const handleOnSelect = (value: string) => {
    let temp: FbAssetFeedSpec = { ...formValues };
    temp.bodies = [{ text: value }];

    setFormValues(temp);
    if (modal) onClose();
  };

  const handleOnChangeSuggestion = (e: ChangeEventType) => {
    const name = e.target.name;

    setSuggestionForm({
      ...suggestionForm,
      [name]: name === 'characters' ? parseInt(e.target.value) : e.target.value,
    });
  };

  const handleAddEmoji = (e: any, obj: any, index: number) => {
    let temp: string[] = [...suggestions];

    temp[index] = temp[index] + obj.emoji;

    setSuggestions(temp);
    setShowEmojis(false);
  };

  return (
    <Grid container spacing={2}>
      <CircularLoading loading={loading} />

      {modal ? (
        <Grid item xs={12}>
          <ModalHeader title="Generate Ad Copy" isCenter />
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          size="small"
          value={suggestionForm?.purpose}
          name="purpose"
          label="Ad Purpose"
          onChange={handleOnChangeSuggestion}
          variant="standard"
          placeholder="Grand Opening / Promos / Reminder"
          InputLabelProps={{ shrink: true }}
          error={errors?.purpose ? true : false}
          helperText={errors?.purpose ? errors?.purpose : ''}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          type="number"
          id="characters"
          name="characters"
          label="Number of Characters"
          onChange={handleOnChangeSuggestion}
          value={suggestionForm?.characters}
          placeholder="0"
          InputLabelProps={{ shrink: true }}
          size="small"
          sx={{
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
              {
                display: 'none',
              },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          }}
          error={errors?.characters ? true : false}
          helperText={errors?.characters ? errors?.characters : ''}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="emoji">Emoji</FormLabel>

          <RadioGroup
            row
            aria-labelledby="emoji"
            name="emoji"
            value={suggestionForm?.emoji}
            onChange={handleOnChangeSuggestion}
          >
            <FormControlLabel
              value="yes"
              control={<Radio size="small" />}
              label="Yes"
            />

            <FormControlLabel
              value="no"
              control={<Radio size="small" />}
              label="No"
            />

            <FormControlLabel
              value="limited"
              control={<Radio size="small" />}
              label="Limited"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="format">Bullet Points</FormLabel>

          <RadioGroup
            row
            aria-labelledby="format"
            name="bulletPoints"
            value={suggestionForm?.bulletPoints}
            onChange={handleOnChangeSuggestion}
          >
            <FormControlLabel
              value="yes"
              control={<Radio size="small" />}
              label="Yes"
            />

            <FormControlLabel
              value="no"
              control={<Radio size="small" />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <PrimaryButton
          title="Generate"
          loading={loading}
          type="button"
          handleOnClick={handleOnSubmit}
          marginRight5
        />

        {!modal ? (
          <PrimaryButton
            title="Cancel"
            loading={loading}
            type="button"
            variant="text"
            handleOnClick={onClose}
          />
        ) : null}
      </Grid>

      {suggestions.map((suggestion: string, index: number) => {
        return (
          <>
            <Grid item xs={12} position="relative">
              <TextField
                fullWidth
                required
                size="small"
                value={suggestion}
                name="suggestion"
                label="Generated Ad Copy"
                onChange={(e: ChangeEventType) => handleOnChange(e, index)}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                multiline
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size="large"
                      edge="end"
                      aria-label="account of current user"
                      aria-controls="chat-settings"
                      aria-haspopup="true"
                      color="inherit"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowEmojis(!showEmojis);
                      }}
                      sx={{
                        width: 0,
                        height: 0,
                        position: 'absolute',
                        top: `calc(100% - 20px)`,
                        right: '50px',
                        padding: 0,
                      }}
                    >
                      <SentimentSatisfiedOutlinedIcon
                        sx={{
                          color: '#096F4D',
                          fontSize: '25px',
                        }}
                      />
                    </IconButton>
                  ),
                }}
              />

              {showEmojis ? (
                <ClickAwayListener
                  onClickAway={() => {
                    setShowEmojis(false);
                  }}
                >
                  <Box sx={{ position: 'absolute', top: 300, right: 0 }}>
                    <Picker
                      onEmojiClick={(e: any, obj: any) =>
                        handleAddEmoji(e, obj, index)
                      }
                      disableAutoFocus={true}
                      skinTone={SKIN_TONE_MEDIUM_DARK}
                      groupNames={{ smileys_people: 'PEOPLE' }}
                      native
                    />
                  </Box>
                </ClickAwayListener>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              <PrimaryButton
                title="Select"
                marginRight10
                theme="primary"
                type="button"
                handleOnClick={() => handleOnSelect(suggestion)}
              />
            </Grid>
          </>
        );
      })}
    </Grid>
  );
};

export default AdCopySuggestionForm;
