import { GridColDef } from '@mui/x-data-grid';
import { toCurrency } from '../../../utils/numberFormatter';
import {
  constantStringToHumanized,
  humanizeString,
} from '../../../utils/stringModifier';

const defaultProps = (isMobile: boolean, width: number, isName?: boolean) => {
  if (isName) return { width: 150 };

  if (isMobile) return { width };

  return { flex: 1 };
};

export const leadColumns = (breakdownType: string, isMobile: boolean) => {
  const isRegion = breakdownType === 'region';
  const isDma = breakdownType === 'dma';
  const columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 150, true),
      field: breakdownType,
      headerName: isDma ? 'DMA' : humanizeString(breakdownType),
      renderCell: (params) => {
        const { region, platform, dma } = params.row;

        return (
          <div>
            {isRegion
              ? region
              : isDma
              ? dma
              : constantStringToHumanized(platform)}
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'clicks',
      headerName: 'Clicks',
      valueGetter: (params) => parseInt(params.row.clicks),
      renderCell: (params) => {
        const { clicks } = params.row;

        return <div>{clicks}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpc',
      headerName: 'CPC',
      valueGetter: (params) => params.row.cpc,
      renderCell: (params) => {
        const { cpc } = params.row;

        return <div>{toCurrency('USD', cpc)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 120),
      field: 'impressions',
      headerName: 'Impressions',
      valueGetter: (params) => parseInt(params.row.impressions),
      renderCell: (params) => {
        const { impressions } = params.row;

        return <div>{impressions}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpm',
      headerName: 'CPM',
      valueGetter: (params) => params.row.cpm,
      renderCell: (params) => {
        const { cpm } = params.row;

        return <div>{toCurrency('USD', cpm)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'leads',
      headerName: 'Leads',
      renderCell: (params) => {
        const { leads } = params.row;

        return <div>{leads}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpl',
      headerName: 'CPL',
      valueGetter: (params) => params.row.cpl,
      renderCell: (params) => {
        const { cpl } = params.row;

        return <div>{toCurrency('USD', cpl)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'cost',
      headerName: 'Cost',
      valueGetter: (params) => params.row.spend,
      renderCell: (params) => {
        const { spend } = params.row;

        return <div>{toCurrency('USD', spend)}</div>;
      },
    },
  ];

  return columns;
};

export const messengerAdsColumns = (
  breakdownType: string,
  isMobile: boolean,
) => {
  const isRegion = breakdownType === 'region';
  const isDma = breakdownType === 'dma';
  const columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 150, true),
      field: breakdownType,
      headerName: isDma ? 'DMA' : humanizeString(breakdownType),
      renderCell: (params) => {
        const { region, platform, dma } = params.row;

        return (
          <div>
            {isRegion
              ? region
              : isDma
              ? dma
              : constantStringToHumanized(platform)}
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'clicks',
      headerName: 'Clicks',
      valueGetter: (params) => parseInt(params.row.clicks),
      renderCell: (params) => {
        const { clicks } = params.row;

        return <div>{clicks}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpc',
      headerName: 'CPC',
      valueGetter: (params) => params.row.cpc,
      renderCell: (params) => {
        const { cpc } = params.row;

        return <div>{toCurrency('USD', cpc)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 120),
      field: 'impressions',
      headerName: 'Impressions',
      valueGetter: (params) => parseInt(params.row.impressions),
      renderCell: (params) => {
        const { impressions } = params.row;

        return <div>{impressions}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpm',
      headerName: 'CPM',
      valueGetter: (params) => params.row.cpm,
      renderCell: (params) => {
        const { cpm } = params.row;

        return <div>{toCurrency('USD', cpm)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'leads',
      headerName: 'Opt-Ins',
      renderCell: (params) => {
        const { leads } = params.row;

        return <div>{leads}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpoi',
      headerName: 'CPOI',
      valueGetter: (params) => params.row.cpl,
      renderCell: (params) => {
        const { cpl } = params.row;

        return <div>{toCurrency('USD', cpl)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'cost',
      headerName: 'Cost',
      valueGetter: (params) => params.row.spend,
      renderCell: (params) => {
        const { spend } = params.row;

        return <div>{toCurrency('USD', spend)}</div>;
      },
    },
  ];

  return columns;
};

export const trafficColumns = (breakdownType: string, isMobile: boolean) => {
  const isRegion = breakdownType === 'region';
  const isDma = breakdownType === 'dma';
  const columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 150, true),
      field: breakdownType,
      headerName: isDma ? 'DMA' : humanizeString(breakdownType),
      renderCell: (params) => {
        const { region, platform, dma } = params.row;

        return (
          <div>
            {isRegion
              ? region
              : isDma
              ? dma
              : constantStringToHumanized(platform)}
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'clicks',
      headerName: 'Clicks',
      valueGetter: (params) => parseInt(params.row.clicks),
      renderCell: (params) => {
        const { clicks } = params.row;

        return <div>{clicks}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpc',
      headerName: 'CPC',
      valueGetter: (params) => params.row.cpc,
      renderCell: (params) => {
        const { cpc } = params.row;

        return <div>{toCurrency('USD', cpc)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 120),
      field: 'impressions',
      headerName: 'Impressions',
      valueGetter: (params) => parseInt(params.row.impressions),
      renderCell: (params) => {
        const { impressions } = params.row;

        return <div>{impressions}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpm',
      headerName: 'CPM',
      valueGetter: (params) => params.row.cpm,
      renderCell: (params) => {
        const { cpm } = params.row;

        return <div>{toCurrency('USD', cpm)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 120),
      field: 'leads',
      headerName: 'Results',
      renderCell: (params) => {
        const { leads } = params.row;

        return <div>{leads}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpl',
      headerName: 'CPL',
      valueGetter: (params) => params.row.cpl,
      renderCell: (params) => {
        const { cpl } = params.row;

        return <div>{toCurrency('USD', cpl)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'cost',
      headerName: 'Cost',
      valueGetter: (params) => params.row.spend,
      renderCell: (params) => {
        const { spend } = params.row;

        return <div>{toCurrency('USD', spend)}</div>;
      },
    },
  ];

  return columns;
};

export const salesColumns = (breakdownType: string, isMobile: boolean) => {
  const isRegion = breakdownType === 'region';
  const isDma = breakdownType === 'dma';
  const columns: GridColDef[] = [
    {
      ...defaultProps(isMobile, 150, true),
      field: breakdownType,
      headerName: isDma ? 'DMA' : humanizeString(breakdownType),
      renderCell: (params) => {
        const { region, platform, dma } = params.row;

        return (
          <div>
            {isRegion
              ? region
              : isDma
              ? dma
              : constantStringToHumanized(platform)}
          </div>
        );
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'clicks',
      headerName: 'Clicks',
      valueGetter: (params) => parseInt(params.row.clicks),
      renderCell: (params) => {
        const { clicks } = params.row;

        return <div>{clicks}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpc',
      headerName: 'CPC',
      valueGetter: (params) => params.row.cpc,
      renderCell: (params) => {
        const { cpc } = params.row;

        return <div>{toCurrency('USD', cpc)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 120),
      field: 'impressions',
      headerName: 'Impressions',
      valueGetter: (params) => parseInt(params.row.impressions),
      renderCell: (params) => {
        const { impressions } = params.row;

        return <div>{impressions}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpm',
      headerName: 'CPM',
      valueGetter: (params) => params.row.cpm,
      renderCell: (params) => {
        const { cpm } = params.row;

        return <div>{toCurrency('USD', cpm)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 120),
      field: 'leads',
      headerName: 'Results',
      renderCell: (params) => {
        const { leads } = params.row;

        return <div>{leads}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 80),
      field: 'cpl',
      headerName: 'CPS',
      valueGetter: (params) => params.row.cpl,
      renderCell: (params) => {
        const { cpl } = params.row;

        return <div>{toCurrency('USD', cpl)}</div>;
      },
    },
    {
      ...defaultProps(isMobile, 100),
      field: 'cost',
      headerName: 'Cost',
      valueGetter: (params) => params.row.spend,
      renderCell: (params) => {
        const { spend } = params.row;

        return <div>{toCurrency('USD', spend)}</div>;
      },
    },
  ];

  return columns;
};
