import { Card } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import TopNavBar from '../components/Navigation/TopNavBar';
import { AuthContext } from '../context';
import {
  ADMIN,
  AGENCY,
  AUTH_TOKEN,
  LOGGED_IN_USER,
  MARKETING_MANAGER,
  SALESPERSON,
  SUPER_ADMIN,
} from '../utils';
import SideNavBar from '../components/Navigation/SideNavBar';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Brand } from '../types';
import CircularLoading from '../components/CircularLoading';
import Toast from '../components/Notification/Toast';
import { isExpired, resetSession } from '../utils/tokenValidator';
import {
  setAgencies,
  setAgency,
  setBrand,
  setBrandFilter,
  setBrands,
  setBudgetReportProvider,
  setCampaignProvider,
  setLocation,
  setLocations,
  toggleAlert,
} from '../redux/actions';
import MobileFilters from '../components/Navigation/MobileFilters';
import { diyAdzDrawers } from '../components/Navigation/Drawers';
import { AnalyticTools } from '../utils/helpers/BrandHelpers';

const DRAWER_WIDTH = 240;

export const DiyMarketingManagerLayout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMobileOptions, setShowMobileOptions] = useState<boolean>(false);
  const pathLocation = useLocation();
  const { state, dispatch: authDispatch } = useContext(AuthContext);
  const isAdmin = state.role === ADMIN;
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isSalesperson = state.role === SALESPERSON;
  const isMarketingManager = state.role === MARKETING_MANAGER;
  const isAgency = state.role === AGENCY;
  const isAuthenticated = state.isAuthenticated;
  const locationBrand = state.authUser?.brand;
  const isFranchisee = locationBrand !== null && locationBrand !== undefined;
  const brand: Brand = useSelector((state: any) => state.brand?.brand);
  const location: Brand = useSelector((state: any) => state.location?.location);
  const authToken = localStorage.getItem(AUTH_TOKEN);

  const pathNames = pathLocation.pathname.split('/');
  const isScoreboard =
    pathNames.includes('scorecardz') || pathNames.includes('trendz');

  useEffect(() => {
    if (brand || location) {
      const pathNames = pathLocation.pathname.split('/');
      const currentPath = pathNames[1];

      const paths = diyAdzDrawers(
        location ? location : brand,
        isMarketingManager,
        isAdmin || isSuperAdmin,
      ).map((path: any) => {
        return path.pathName;
      });

      if (!paths.includes(currentPath)) {
        navigate(
          (location || brand)?.analyticTools?.includes(AnalyticTools.SCORECARDZ)
            ? '/scorecardz'
            : '/trendz',
        );
      }
    }
  }, [brand, location]);

  useEffect(() => {
    if (isExpired(authToken) || !isAuthenticated) {
      navigate('/login');
      resetSession();
      resetReduxSession();
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Your session has been expired.',
          type: 'error',
          horizontal: 'center',
          vertical: 'top',
        }),
      );
    }
  }, [pathLocation]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resetReduxSession = () => {
    authDispatch({
      type: LOGGED_IN_USER,
      payload: {
        token: null,
        role: null,
        roleBasedId: null,
        user: null,
        capabilities: null,
      },
    });
    dispatch(setBrands([]));
    dispatch(setBrand(null));
    dispatch(setAgencies([]));
    dispatch(setAgency(null));
    dispatch(setBrandFilter(null));
    dispatch(setCampaignProvider(null));
    dispatch(setBudgetReportProvider(null));
    dispatch(setLocations([]));
    dispatch(setLocation(null));
  };

  const handleOpenMobileOptions = () => {
    setShowMobileOptions((show) => !show);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CircularLoading loading={loading} />

      <TopNavBar
        drawerWidth={DRAWER_WIDTH}
        onOpenDrawer={handleDrawerToggle}
        setLoading={setLoading}
        onOpenMobileOptions={handleOpenMobileOptions}
      />

      <SideNavBar
        drawerWidth={DRAWER_WIDTH}
        onOpenDrawer={handleDrawerToggle}
        open={mobileOpen}
        isAdmin={isSuperAdmin || isAdmin}
        isAgency={isAgency}
        brand={brand}
        location={location}
        isFranchisee={isFranchisee}
        isMarketingManager={isMarketingManager}
        isSalesperson={isSalesperson}
      />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${isScoreboard ? 240 : DRAWER_WIDTH}px)` },
          marginLeft: { md: `${DRAWER_WIDTH}px` },
          backgroundColor: '#f5f5f5',
          marginTop: '50px',
        }}
      >
        <MobileFilters open={showMobileOptions} setLoading={setLoading} />

        {isScoreboard ? (
          <Box
            sx={{
              minHeight: 'calc(100vh - 100px)',
              maxHeight: 'calc(100vh - 100px)',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#F1F0F0',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
              },

              '&::-webkit-scrollbar-thumb': {
                background: '#096f4d',
                borderRadius: '18px',

                '&:hover': {
                  background: '#096f4d',
                },
              },
            }}
          >
            <Outlet />
          </Box>
        ) : (
          <Card
            sx={{
              minHeight: 'calc(100vh - 100px)',
              maxHeight: 'calc(100vh - 100px)',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-track': {
                // background: "#F1F0F0",
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
              },

              '&::-webkit-scrollbar-thumb': {
                background: '#096f4d',
                borderRadius: '18px',

                '&:hover': {
                  background: '#096f4d',
                },
              },
            }}
          >
            {/*
              * Temporary disable
              * Uncomment if needed
            <CardHeader avatar={<SimpleBreadcrumbs />}></CardHeader>
            */}

            <Outlet />
          </Card>
        )}
      </Box>

      <Toast />

      <footer></footer>
    </Box>
  );
};
