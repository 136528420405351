import { GridColDef } from '@mui/x-data-grid';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import moment from 'moment';
import { Tooltip, Typography } from '@mui/material';

export const leadColumns = (isFrandev: boolean) => {
  let columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Full Name',
      flex: 1,
      sortingOrder: ['desc', 'asc'],
      valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`,
      renderCell: (params) => {
        const { firstName, lastName, lead, onOpen } = params.row;
        const fullName: any = `${firstName} ${lastName}`;

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{fullName}</Typography>}
              disableInteractive
            >
              {fullName}
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      flex: 1,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        const { phoneNumber, lead, onOpen } = params.row;

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{phoneNumber}</Typography>}
              disableInteractive
            >
              {phoneNumber}
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        const { email, lead, onOpen } = params.row;

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{email}</Typography>}
              disableInteractive
            >
              {email}
            </Tooltip>
          </div>
        );
      },
    },
  ];

  if (isFrandev) {
    columns = [
      ...columns,
      {
        field: 'investmentQualified',
        headerName: 'FQL',
        description: 'Financial Qualified Lead',
        width: 80,
        disableColumnMenu: true,
        sortingOrder: ['desc', 'asc'],
        valueGetter: (params) =>
          params.row.investmentQualified ? 'Yes' : 'No',
        renderCell: (params) => {
          const { investmentQualified, lead, onOpen } = params.row;
          const qualified: any = investmentQualified ? 'Yes' : 'No';

          return (
            <div
              className={`${styles['name']} ${styles['-bold']} ${
                investmentQualified
                  ? `${styles['-green']}`
                  : `${styles['-red']}`
              }`}
              onClick={() => onOpen(lead)}
            >
              <Tooltip
                title={<Typography variant="body2">{qualified}</Typography>}
                disableInteractive
              >
                {qualified}
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: 'backgroundQualified',
        headerName: 'BQL',
        width: 80,
        sortingOrder: ['desc', 'asc'],
        disableColumnMenu: true,
        description: 'Background Qualified Lead',
        valueGetter: (params) =>
          params.row.backgroundQualified ? 'Yes' : 'No',
        renderCell: (params) => {
          const { backgroundQualified, lead, onOpen } = params.row;
          const qualified: any = backgroundQualified ? 'Yes' : 'No';

          return (
            <div
              className={`${styles['name']} ${styles['-bold']} ${
                backgroundQualified
                  ? `${styles['-green']}`
                  : `${styles['-red']}`
              }`}
              onClick={() => onOpen(lead)}
            >
              <Tooltip
                title={<Typography variant="body2">{qualified}</Typography>}
                disableInteractive
              >
                {qualified}
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: 'iqlBql',
        headerName: 'QL',
        width: 80,
        disableColumnMenu: true,
        description: 'Qualified Lead',
        sortingOrder: ['desc', 'asc'],
        valueGetter: (params) =>
          params.row.backgroundQualified && params.row.investmentQualified
            ? 'Yes'
            : 'No',
        renderCell: (params) => {
          const { backgroundQualified, investmentQualified, lead, onOpen } =
            params.row;
          const isQualified = backgroundQualified && investmentQualified;
          const qualified: any = isQualified ? 'Yes' : 'No';

          return (
            <div
              className={`${styles['name']} ${styles['-bold']} ${
                isQualified ? `${styles['-green']}` : `${styles['-red']}`
              }`}
              onClick={() => onOpen(lead)}
            >
              <Tooltip
                title={<Typography variant="body2">{qualified}</Typography>}
                disableInteractive
              >
                {qualified}
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: 'background',
        headerName: 'Background',
        flex: 1,
        sortingOrder: ['desc', 'asc'],
        renderCell: (params) => {
          const { background, lead, onOpen } = params.row;

          return (
            <div className={styles.name} onClick={() => onOpen(lead)}>
              <Tooltip
                title={<Typography variant="body2">{background}</Typography>}
                disableInteractive
              >
                {background}
              </Tooltip>
            </div>
          );
        },
      },
    ];
  }

  columns = [
    ...columns,
    {
      field: 'adName',
      headerName: 'Ad Name',
      flex: 1,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        const { adName, lead, onOpen } = params.row;

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{adName}</Typography>}
              disableInteractive
            >
              {adName}
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      flex: 1,
      sortingOrder: ['desc', 'asc'],
      valueGetter: (params) => params.row.createdAt,
      renderCell: (params) => {
        const { createdAt, lead, onOpen } = params.row;
        const formattedDate: any = moment
          .unix(createdAt)
          .utc()
          .format('MM/DD/YYYY hh:mma Z');

        return (
          <div className={styles.name} onClick={() => onOpen(lead)}>
            <Tooltip
              title={<Typography variant="body2">{formattedDate}</Typography>}
              disableInteractive
            >
              {formattedDate}
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return columns;
};
