import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Brand,
  ChangeEventType,
  Fee,
  UpdateAdBudgetForm,
} from '../../../types';
import { FeeType } from '../../../utils/helpers/stripeHelper';
import { PrimaryButton } from '../../Buttons';
import PopupModal from '../../Modal';
import { createOrUpdateFee, updateAdBudget } from '../../../services/fee';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../redux/actions';

interface BrandFeeSetupFormProps {
  brandFees: Fee[];
  onChange: (e: ChangeEventType, index: number, entity: string) => void;
  onAllowBudget: (feeType: string, allowed: boolean, entity: string) => void;
  entity: string;
  subscription?: any;
  setBrandFeesForm?: Dispatch<SetStateAction<Fee[]>>;
  brand: Brand;
}

const BrandFeeSetupForm: React.FC<BrandFeeSetupFormProps> = ({
  brandFees,
  onChange,
  onAllowBudget,
  entity,
  subscription,
  setBrandFeesForm,
  brand,
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const disable = subscription !== null && entity === 'brand';

    setDisabled(disable);
  }, [subscription, entity]);

  const handleClickEdit = () => {
    setDisabled((prev) => !prev);
  };

  const handleOpenConfirm = () => {
    setOpen((open) => !open);
  };

  const handleCreateOrUpdateFees = async (entity: string, payload: Fee[]) => {
    try {
      const IS_BRAND = entity === 'brand';

      const response = await createOrUpdateFee(payload);

      if (IS_BRAND) {
        setBrandFeesForm(response?.data || []);
      }
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.message,
          type: 'error',
        }),
      );
    }
  };

  const handleUpdateAdBudget = async (budget: Fee) => {
    setLoading(true);
    try {
      let params: UpdateAdBudgetForm = {
        scheduleType: 'immediately',
        adBudgetId: budget?._id,
        subscription: subscription.id,
        paymentMethodId: subscription.default_payment_method,
        amount: budget.amount,
      };

      const response = await updateAdBudget(brand?._id, params);

      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Ad Budget Updated Successfully',
        }),
      );
    } catch (error: any) {
      console.log(error.message);
      const err = error.response.data;
      if (err?.message) {
        dispatch(
          toggleAlert({
            toggle: true,
            message: err?.message,
            type: 'error',
          }),
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOnSubmit = () => {
    setLoading(true);
    try {
      let brandFeePayload: Fee[] = [];

      brandFees.forEach((fee: Fee) => {
        if (fee.type === FeeType.AdBudget) return;

        brandFeePayload = [...brandFeePayload, fee];
      });

      const adBudget = brandFees.find((fee: Fee) => {
        return fee.type === FeeType.AdBudget;
      });

      handleCreateOrUpdateFees('brand', brandFeePayload);
      handleUpdateAdBudget(adBudget);
      setOpen(false);
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.message,
          type: 'error',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Grid container spacing={2} mt={1} mb={1}>
        {brandFees.map((fee: Fee, index: number) => {
          if (fee.type === FeeType.AdBudget) {
            return (
              <Grid item xs={12} key={index}>
                <TextField
                  variant="standard"
                  type="number"
                  id={`amount-${index}`}
                  name={`amount-${index}`}
                  label={`${fee.name}`}
                  onChange={(e: any) => {
                    onChange(e, index, entity);
                  }}
                  value={fee.amount}
                  placeholder="81"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={{
                    marginRight: '10px',
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none',
                      },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                  }}
                  disabled={!fee.enabled || disabled}
                />

                <FormControl>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={fee.enabled}
                        name="chargeMediaFee"
                        onChange={(e: any) => {
                          onAllowBudget(fee.type, e.target.checked, entity);
                        }}
                        disabled={disabled}
                      />
                    }
                    label={
                      fee.enabled ? 'Ad Budget Enabled' : 'Ad Budget Disabled'
                    }
                  />
                </FormControl>
              </Grid>
            );
          }

          return (
            <Grid key={index} item xs={12} sm={4} md={3}>
              <TextField
                fullWidth
                variant="standard"
                type="number"
                id={`amount-${index}`}
                name={`amount-${index}`}
                label={`${fee.name} Fee`}
                onChange={(e: any) => {
                  onChange(e, index, entity);
                }}
                value={fee.amount}
                placeholder="81"
                InputLabelProps={{ shrink: true }}
                size="small"
                disabled={disabled}
                sx={{
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                }}
              />
            </Grid>
          );
        })}

        {subscription && disabled ? (
          <Grid item xs={12}>
            <PrimaryButton
              title="Edit"
              type="button"
              handleOnClick={handleClickEdit}
            />
          </Grid>
        ) : null}

        {subscription && !disabled ? (
          <Grid item xs={12}>
            <PrimaryButton
              title="Save"
              type="button"
              handleOnClick={handleOpenConfirm}
              marginRight5
              loading={loading}
            />

            <PrimaryButton
              title="Cancel"
              type="button"
              variant="text"
              handleOnClick={handleClickEdit}
            />
          </Grid>
        ) : null}
      </Grid>

      <PopupModal open={open} handleClose={handleOpenConfirm}>
        <Box sx={{ width: '330px' }}>
          <Grid container spacing={2} sx={{ textAlign: 'center' }}>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                Are you sure you want to change your subscription fees?
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">
                You currently have active subscription. Updating these fees will
                reflect to your active subscription.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <PrimaryButton
                title="Confirm"
                type="button"
                marginRight5
                handleOnClick={handleOnSubmit}
                loading={loading}
              />

              <PrimaryButton
                title="Cancel"
                type="button"
                marginRight5
                variant="text"
                handleOnClick={handleOpenConfirm}
              />
            </Grid>
          </Grid>
        </Box>
      </PopupModal>
    </div>
  );
};

export default BrandFeeSetupForm;
