import axios from 'axios';
import { StripeCustomerForm } from '../../types';
import { apiBaseUrl } from '../api';

export const createOrUpdateStripeCustomer = async (
  params: StripeCustomerForm,
  userId: string,
  role: string,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/customer?userId=${userId}&role=${role}`,
    params,
  );

  return data?.data;
};

export const fetchSingleStripeCustomer = async (
  userId: string,
  role: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/payment/stripe/customer/${userId}?role=${role}`,
  );

  return data?.data;
};

export const generatedStripePortalLink = async (
  userId: string,
  role: string,
  returnUrl: string,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/customer/generate-portal`,
    {
      userId,
      role,
      returnUrl,
    },
  );

  return data?.data;
};

export const getCustomerPaymentMethods = async (brandId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/payment/stripe/customer/${brandId}/payment-methods`,
  );

  return data?.data;
};

export const setDefaultPaymentMethod = async (
  userId: string,
  paymentMethodId: string,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/customer/default-payment-method`,
    { userId, paymentMethodId },
  );

  return data?.data;
};

export const getCustomerInvoices = async (brandId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/payment/stripe/customer/${brandId}/invoices`,
  );

  return data?.data;
};

export const getCustomerSubscriptions = async (brandId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/payment/stripe/customer/${brandId}/subscriptions`,
  );

  return data?.data;
};

export const getCustomerCreditBalances = async (brandId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/payment/stripe/customer/${brandId}/credits`,
  );

  return data?.data;
};
