import { Dispatch, SetStateAction } from 'react';
import { fetchAdminData } from '../../services/admin';
import { getUserByRoleId } from '../../services/user';
import { adsProvider } from '../constants/facebookAds';
import { IS_DIY_ADZ } from '../constants';

export const buildAccessToken = async (
  isAdmin: boolean,
  isAgency: boolean,
  isBrandEntity: boolean,
  accessToken: string,
  roleBasedId: string,
  setDefaultAccessToken: Dispatch<SetStateAction<string>>,
  brand?: any,
) => {
  try {
    /**
     * TODO: Remove if Role Based Facebook Access Token is implemented
     */
    if (IS_DIY_ADZ) {
      const response = await fetchAdminData();

      setDefaultAccessToken(response?.data.defaultAccessToken);
    } else {
      if (accessToken) {
        setDefaultAccessToken(accessToken);
      } else {
        const response = await fetchAdminData();

        setDefaultAccessToken(response?.data.defaultAccessToken);
      }
    }

    /**
     * Temporary Disable Role Based Facebook Access Token
     * All is based on the Super-Admin default Facebook access token
    if (isAdmin) {
      if (accessToken) {
        setDefaultAccessToken(accessToken);
      } else {
        const response = await fetchAdminData();

        setDefaultAccessToken(response?.data.defaultAccessToken);
      }
    } else if (isAgency) {
      if (accessToken) {
        setDefaultAccessToken(accessToken);
      } else {
        await getAgencyProviderToken(
          roleBasedId,
          adsProvider.FACEBOOK,
          setDefaultAccessToken,
          null,
        );
      }
    } else if (isBrandEntity) {
      await getAgencyProviderToken(
        brand?.agency?._id,
        adsProvider.FACEBOOK,
        setDefaultAccessToken,
        null,
      );
    }
    */
  } catch (error: any) {
    console.log(error.message);
  }
};

export const buildRefreshToken = async (
  isAdmin: boolean,
  isAgency: boolean,
  isBrandEntity: boolean,
  refreshToken: string,
  roleBasedId: string,
  setDefaultRefreshToken: Dispatch<SetStateAction<string>>,
  brand?: any,
) => {
  try {
    /**
     * TODO: Remove if Role Based Google Refresh Token is implemented
     */
    if (refreshToken) {
      setDefaultRefreshToken(refreshToken);
    } else {
      const response = await fetchAdminData();

      setDefaultRefreshToken(response?.data.defaultRefreshToken);
    }
    /**
     * Temporary Disable Role Based Google Refresh Token
     * All is based on the Super-Admin default Google refresh token
    if (isAdmin) {
      if (refreshToken) {
        setDefaultRefreshToken(refreshToken);
      } else {
        const response = await fetchAdminData();

        setDefaultRefreshToken(response?.data.defaultRefreshToken);
      }
    } else if (isAgency) {
      if (refreshToken) {
        setDefaultRefreshToken(refreshToken);
      } else {
        await getAgencyProviderToken(
          roleBasedId,
          adsProvider.GOOGLE,
          null,
          setDefaultRefreshToken,
        );
      }
    } else if (isBrandEntity) {
      await getAgencyProviderToken(
        brand?.agency?._id,
        adsProvider.GOOGLE,
        null,
        setDefaultRefreshToken,
      );
    }
    */
  } catch (error: any) {
    console.log(error.message);
  }
};

const getAgencyProviderToken = async (
  roleBasedId: string,
  provider: string,
  setDefaultAccessToken: Dispatch<SetStateAction<string>>,
  setDefaultRefreshToken: Dispatch<SetStateAction<string>>,
) => {
  try {
    const response = await getUserByRoleId(roleBasedId, provider);

    const isFacebook = provider === adsProvider.FACEBOOK;
    const isGoogle = provider === adsProvider.GOOGLE;

    if (isFacebook) setDefaultAccessToken(response?.data?.accessToken);

    if (isGoogle) setDefaultRefreshToken(response?.data?.refreshToken);
  } catch (error: any) {
    console.log(error.message);
  }
};
