import React from 'react';
import { Box, Grid, IconButton, TextField } from '@mui/material';
import { ChangeEventType } from '../../types';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import { PrimaryButton } from '../Buttons';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { FileInput, FileInputError } from '../../types';
import styles from '../../assets/styles/components/Forms/Form.module.scss';

interface SendMessageFormProps {
  handleOnSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
  handleOnChange: (e: ChangeEventType) => void;
  message: string;
  // errors: AgencyErrors
  loading?: boolean;
  handleSendCalendlyUrl: () => void;
  sendCalendlyUrlloading?: boolean;
  showEmojis?: boolean;
  setShowEmojis: React.Dispatch<React.SetStateAction<boolean>>;
  addEmoji: (event: any, emojiObject: any) => void;
  handleCannedResponsesModal: (value: boolean) => void;
  handleFileUpload: (e: any) => void;
  fileDetails: FileInput;
  fileError: FileInputError;
  onRemoveAttachment: () => void;
}

const SendMessageForm: React.FC<SendMessageFormProps> = ({
  handleOnSubmit,
  handleOnChange,
  message,
  loading,
  handleSendCalendlyUrl,
  sendCalendlyUrlloading,
  showEmojis,
  setShowEmojis,
  addEmoji,
  handleCannedResponsesModal,
  handleFileUpload,
  fileDetails,
  fileError,
  onRemoveAttachment,
}) => {
  return (
    <Box
      component="form"
      onSubmit={handleOnSubmit}
      className={`${styles.form} ${styles['-dialog']}`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} position="relative">
          <TextField
            fullWidth
            multiline
            autoComplete="off"
            maxRows={2}
            type="text"
            id="message"
            name="message"
            label="Reply here:"
            InputProps={{
              sx: { paddingRight: 5.5 },
            }}
            onChange={handleOnChange}
            value={message}
            InputLabelProps={{ shrink: true }}
            size="medium"
          />

          <IconButton
            size="large"
            edge="end"
            aria-label="upload picture"
            color="inherit"
            sx={{
              width: '50px',
              height: '50px',
              position: 'absolute',
              top: '19px',
              right: '50px',
            }}
            component="label"
          >
            <input
              id="file-upload"
              hidden
              accept="image/*,video/*,.pdf,.csv"
              type="file"
              onChange={async (e: any) => {
                handleFileUpload(e);
              }}
            />

            <AttachFileOutlinedIcon
              sx={{
                color: '#1976d2',
                fontSize: '30px',
              }}
            ></AttachFileOutlinedIcon>
          </IconButton>

          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls="chat-settings"
            aria-haspopup="true"
            onClick={(e) => {
              e.stopPropagation();
              setShowEmojis(!showEmojis);
            }}
            color="inherit"
            sx={{
              width: '50px',
              height: '50px',
              position: 'absolute',
              top: '19px',
              right: '20px',
            }}
          >
            <SentimentSatisfiedOutlinedIcon
              sx={{
                color: '#1976d2',
                fontSize: '30px',
              }}
            />
          </IconButton>

          {showEmojis ? (
            <Box sx={{ position: 'absolute', top: -300, right: 0 }}>
              <Picker
                onEmojiClick={addEmoji}
                disableAutoFocus={true}
                skinTone={SKIN_TONE_MEDIUM_DARK}
                groupNames={{ smileys_people: 'PEOPLE' }}
                native
              />
            </Box>
          ) : null}
        </Grid>

        <Grid item xs={12} position="relative">
          <p
            className={`${styles['file-details']} ${
              fileError?.error ? styles['-error'] : ''
            }`}
          >
            {fileDetails
              ? `Attachment: ${fileDetails.name}`
              : !fileError?.error
              ? ''
              : fileError?.error}
            {fileDetails ? (
              <span onClick={onRemoveAttachment} className={styles.remove}>
                Remove
              </span>
            ) : null}
          </p>
        </Grid>
      </Grid>

      <div className={`${styles['action']} ${styles['-send-message']}`}>
        <PrimaryButton
          type="button"
          isMr
          title="Canned Responses"
          handleOnClick={() => {
            handleCannedResponsesModal(true);
          }}
          marginRight5
          theme="#096f4d"
        />

        <PrimaryButton title="Send" loading={loading} theme="#096f4d" />
      </div>
    </Box>
  );
};
export default SendMessageForm;
