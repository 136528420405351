import { RiContactsBook2Line, RiBriefcase2Line } from 'react-icons/ri';
import { PiUsersThreeBold } from 'react-icons/pi';
import { FaRegUser } from 'react-icons/fa';
import { MdRadioButtonChecked } from 'react-icons/md';
import { IoTextOutline } from 'react-icons/io5';

export const DEFAULT_INVESTMENT_QUESTION: string =
  'Do you have $100K of Liquid Capital to invest? (from 401K, Retirement, etc.)';
export const DEFAULT_BACKGROUND_QUESTION: string =
  'Tell us about your professional background';
export const DEFAULT_BEST_NUMBER_QUESTION: string = `What's the best number to reach you?`;

export const ThankYouPageButtonType = {
  VIEW_WEBSITE: 'VIEW_WEBSITE',
  CALL_BUSINESS: 'CALL_BUSINESS',
  MESSAGE_BUSINESS: 'MESSAGE_BUSINESS',
  DOWNLOAD: 'DOWNLOAD',
  SCHEDULE_APPOINTMENT: 'SCHEDULE_APPOINTMENT',
  VIEW_ON_FACEBOOK: 'VIEW_ON_FACEBOOK',
  NONE: 'NONE',
  // Custom CTA Type
  SCHEDULE_CALL: 'SCHEDULE_CALL',
};

export const prefilledQuestionIcons = (questionType: string) => {
  let icon: any;
  switch (questionType) {
    case 'contact_information':
      icon = <RiContactsBook2Line size={20} />;
      break;
    case 'demographics':
      icon = <PiUsersThreeBold size={20} />;
      break;
    case 'work_information':
      icon = <RiBriefcase2Line size={20} />;
      break;
    default:
      icon = <FaRegUser size={20} />;
      break;
  }
  return icon;
};

export const prefilledQuestions: any = {
  contact_information: [
    {
      type: 'PHONE',
      label: 'Phone Number',
    },
    { type: 'EMAIL', label: 'Email' },
    {
      type: 'STREET_ADDRESS',
      label: 'Street Address',
    },
    { type: 'CITY', label: 'City' },
    { type: 'STATE', label: 'State' },
    { type: 'PROVINCE', label: 'Province' },
    { type: 'COUNTRY', label: 'Country' },
    { type: 'POST_CODE', label: 'Post Code' },
    { type: 'ZIP', label: 'Zip Code' },
  ],
  user_information: [
    { type: 'FULL_NAME', label: 'Full Name' },
    { type: 'FIRST_NAME', label: 'First Name' },
    { type: 'LAST_NAME', label: 'Last Name' },
  ],
  demographics: [
    { type: 'DOB', label: 'Date of Birth' },
    { type: 'GENDER', label: 'Gender' },
    { type: 'MARITIAL_STATUS', label: 'Maritial Status' },
    { type: 'RELATIONSHIP_STATUS', label: 'Relationship Status' },
    { type: 'MILITARY_STATUS', label: 'Military Status' },
  ],
  work_information: [
    { type: 'JOB_TITLE', label: 'Job Title' },
    { type: 'WORK_PHONE_NUMBER', label: 'Work Phone Number' },
    { type: 'WORK_EMAIL', label: 'Work Email' },
    { type: 'COMPANY_NAME', label: 'Company Name' },
  ],
};

export const leadFormCustomQuestions: any[] = [
  {
    type: 'multiple_choice',
    description: 'Use answer choices to categorize, sort and filter leads.',
    label: 'Multiple Choice',
    icon: <MdRadioButtonChecked />,
  },
  {
    type: 'short_answer',
    description: 'Get additional info in a sentence or two.',
    label: 'Short Answer',
    icon: <IoTextOutline />,
  },
  /**
   * TODO: Re-enable if necessary
   *
  {
    type: 'conditional',
    description:
      'Show only answer choices that apply. Change based on previous answers.',
    label: 'Conditional',
    icon: null,
  },
  {
    type: 'appointment',
    description: 'Let people request a time to meet with you.',
    label: 'Appointment Request',
    icon: null,
  },
  */
];

export const leadFormCtas: any[] = [
  {
    type: 'VIEW_WEBSITE',
    label: 'View Website',
  },
  {
    type: 'CALL_BUSINESS',
    label: 'Call Business',
  },
  {
    type: 'SCHEDULE_CALL',
    label: 'Schedule a Call',
  },
];
