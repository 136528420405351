import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { ChangeEventType } from '../../../types';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import AdapterMoment from '@mui/lab/AdapterMoment';
import momentTz, { Moment } from 'moment-timezone';
import { AdsTemplate } from '../../../types/IDiy';

interface DiyBudgetFormProps {
  template: AdsTemplate;
  onSetEndDate: (newValue: Moment | null) => void;
  onSetStartDate: (newValue: Moment | null) => void;
  onChangeBudget: (e: ChangeEventType) => void;
  schedule: string;
  setSchedule: Dispatch<SetStateAction<string>>;
}

const DiyBudgetForm: React.FC<DiyBudgetFormProps> = ({
  template,
  onSetStartDate,
  onSetEndDate,
  onChangeBudget,
  schedule,
  setSchedule,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1">Start Date/Time</Typography>
      </Grid>

      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            label="Start Date/Time"
            value={momentTz
              .unix(template?.adSetTemplate?.start_time)
              .tz('US/Eastern')}
            onChange={(newValue) => {
              onSetStartDate(newValue);
            }}
            minDate={momentTz.tz('US/Eastern')}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  size="small"
                  variant="standard"
                  helperText="Eastern Time"
                  name="start_date"
                />
              );
            }}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="schedule"
            name="schedule"
            value={schedule}
            onChange={(e: ChangeEventType) => {
              setSchedule(e.target.value);
            }}
            sx={{ flexDirection: 'column' }}
          >
            <FormControlLabel
              value="no"
              control={<Radio size="small" />}
              label="Run campaign every month"
            />

            <FormControlLabel
              value="yes"
              control={<Radio size="small" />}
              label="Stop campaign on"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {schedule === 'yes' ? (
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label="End Date/Time"
              value={momentTz.unix(template?.adSetTemplate?.end_time)}
              onChange={(newValue) => {
                onSetEndDate(newValue);
              }}
              minDate={momentTz
                .unix(template?.adSetTemplate?.start_time)
                .add(1, 'day')}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    size="small"
                    variant="standard"
                    helperText="Eastern Time"
                    name="end_date"
                  />
                );
              }}
              disabled={!template?.adSetTemplate?.start_time}
            />
          </LocalizationProvider>
        </Grid>
      ) : null}

      {schedule ? (
        <Grid item xs={12} sm={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                variant="standard"
                type="number"
                id="brand"
                name={schedule === 'yes' ? 'lifetime_budget' : 'daily_budget'}
                label={`${schedule === 'yes' ? 'Total' : 'Monthly'} Budget`}
                onChange={onChangeBudget}
                value={
                  schedule === 'yes'
                    ? template?.adSetTemplate?.lifetime_budget || ''
                    : template?.adSetTemplate?.daily_budget || ''
                }
                InputLabelProps={{ shrink: true }}
                size="small"
                autoFocus
                sx={{
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default DiyBudgetForm;
