import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Agency,
  Brand,
  ChangeEventType,
  StripePayout,
  StripeUpdateAccountForm as UpdateForm,
} from '../../types';
import styles from '../../assets/styles/components/Forms/Form.module.scss';
import FormHeader from '../Forms/FormHeader';
import { PrimaryButton } from '../Buttons';
import {
  fetchAllStripeAccountPayouts,
  generateStripeAgencyLoginLink,
  generateStripeAgencyOnboardingLink,
  generateStripeBrandLoginLink,
  generateStripeBrandOnboardingLink,
  updateAgencyStripeAccount,
  updateBrandStripeAccount,
} from '../../services/stripe/account';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { humanizeString } from '../../utils/stringModifier';
import StripeUpdateAccountForm from '../Forms/Payment/Onboarding/StripeUpdateAccountForm';
import AccountPayoutListTable from '../Tables/BillingList/AccountPayoutListTable';
import { CreateDepositAccount } from '../Forms/Payment/Onboarding/CreateDepositAccount';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';

interface StripePaymentDetailsProps {
  agency?: Agency;
  brand?: Brand;
  onClickCreate: () => void;
  formValues: UpdateForm;
  setFormValues: Dispatch<SetStateAction<UpdateForm>>;
  account: any;
  setAccount: Dispatch<SetStateAction<any>>;
}

const StripePaymentDetails: React.FC<StripePaymentDetailsProps> = ({
  agency,
  brand,
  onClickCreate,
  formValues,
  setFormValues,
  account,
  setAccount,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [payouts, setPayouts] = useState<StripePayout[]>([]);
  const [showEditStripeAccount, setShowEditStripeAccount] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      brand?.stripe?.accountId !== null &&
      brand?.stripe?.completedOnboarding
    ) {
      getPayouts();
    }
  }, [brand]);

  const isAddress = (field: string) => {
    return [
      'city',
      'country',
      'line1',
      'line2',
      'postal_code',
      'state',
    ].includes(field);
  };

  const getPayouts = async () => {
    try {
      const response = await fetchAllStripeAccountPayouts(brand?._id);

      setPayouts(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleGenerateOnboardingLink = async () => {
    try {
      setLoading(true);
      let response: any;
      if (agency) {
        response = await generateStripeAgencyOnboardingLink(agency?._id);
      } else if (brand) {
        response = await generateStripeBrandOnboardingLink(brand?._id);
      }

      setLoading(false);
      window.open(response.data.url);
    } catch (error: any) {
      console.log(error.message);
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
      setLoading(false);
    }
  };

  const handleGenerateLoginLink = async () => {
    try {
      setLoading(true);
      let response: any;
      if (agency) {
        response = await generateStripeAgencyLoginLink(agency._id);
      } else if (brand) {
        response = await generateStripeBrandLoginLink(brand._id);
      }

      setLoading(false);
      window.open(response.data.url);
    } catch (error: any) {
      console.log(error.message);
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
      setLoading(false);
    }
  };

  const handleChangeNumber = (newValue: string) => {
    setFormValues({
      ...formValues,
      business_profile: {
        ...formValues.business_profile,
        support_phone: newValue.split(' ').join(''),
      },
    });
  };

  const handleUpdateChange = (e: ChangeEventType) => {
    if (isAddress(e.target.name)) {
      setFormValues({
        ...formValues,
        business_profile: {
          ...formValues.business_profile,
          support_address: {
            ...formValues.business_profile.support_address,
            [e.target.name]: e.target.value,
          },
        },
      });
    } else {
      setFormValues({
        ...formValues,
        business_profile: {
          ...formValues.business_profile,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const handleUpdateStripeAccount = async () => {
    try {
      setLoading(true);
      let response: any;

      if (agency) {
        response = await updateAgencyStripeAccount(agency?._id, formValues);
      } else if (brand) {
        response = await updateBrandStripeAccount(brand?._id, formValues);
      }

      setLoading(false);
      setAccount(response?.data);
      setFormValues({
        business_profile: {
          support_email: response?.data?.business_profile?.support_email,
          name: response?.data?.business_profile?.name,
          support_address: response?.data?.business_profile?.support_address,
          support_phone: response?.data?.business_profile?.support_phone,
          support_url: response?.data?.business_profile?.support_url,
          url: response?.data?.business_profile?.url,
        },
      });
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Deposit account updated successfully.',
        }),
      );
      handleCloseAccountForm();
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
    }
  };

  const handleShowAccountForm = () => {
    setShowEditStripeAccount(true);
  };

  const handleCloseAccountForm = () => {
    setShowEditStripeAccount(false);
  };

  return (
    <div className={styles.payment}>
      {!account ? (
        <CreateDepositAccount onClickCreate={onClickCreate} />
      ) : showEditStripeAccount ? (
        <StripeUpdateAccountForm
          onClose={handleCloseAccountForm}
          onChange={handleUpdateChange}
          formValues={formValues}
          onSubmit={handleUpdateStripeAccount}
          loading={loading}
          onChangeNumber={handleChangeNumber}
        />
      ) : (
        <Box
          component="div"
          sx={{
            width: '100%',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <Box component="div" sx={{ marginBottom: '10px' }}>
            <FormHeader title="Deposit Account Details" />

            <Grid container spacing={2} my={1}>
              <Grid item xs={12} sm={6} md={3} sx={{ flexDirection: 'column' }}>
                <Typography variant="body1" fontWeight="bold">
                  Account ID
                </Typography>

                <Typography variant="body1">{account?.id}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={3} sx={{ flexDirection: 'column' }}>
                <Typography variant="body1" fontWeight="bold">
                  Account Type
                </Typography>

                <Typography variant="body1">
                  {humanizeString(account?.type)}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={3} sx={{ flexDirection: 'column' }}>
                <Typography variant="body1" fontWeight="bold">
                  Currency
                </Typography>

                <Typography variant="body1">
                  {humanizeString(account?.default_currency).toUpperCase()}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={3} sx={{ flexDirection: 'column' }}>
                <Typography variant="body1" fontWeight="bold">
                  Status
                </Typography>

                <Typography
                  variant="body1"
                  component="div"
                  onClick={
                    account?.requirements.currently_due.length > 0
                      ? handleGenerateLoginLink
                      : null
                  }
                  sx={{
                    fontWeight:
                      account?.requirements.currently_due.length > 0
                        ? 'bold'
                        : '',
                    color:
                      account?.requirements.currently_due.length > 0
                        ? 'red'
                        : '',
                    cursor:
                      account?.requirements.currently_due.length > 0
                        ? 'pointer'
                        : '',
                  }}
                >
                  {account?.requirements.currently_due.length > 0
                    ? 'Requires Verification'
                    : 'Active'}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <div>
            {((brand ? brand : agency)?.stripe?.completedOnboarding || false) &&
            account?.requirements.currently_due.length > 0 ? (
              <PrimaryButton
                title="Verify Account"
                marginRight5
                loading={loading}
                handleOnClick={handleGenerateLoginLink}
              />
            ) : null}

            {((brand ? brand : agency)?.stripe?.completedOnboarding || false) &&
            account?.requirements.currently_due.length === 0 ? (
              <PrimaryButton
                title="Login"
                marginRight5
                loading={loading}
                handleOnClick={handleGenerateLoginLink}
              />
            ) : null}

            {!(brand ? brand : agency)?.stripe?.completedOnboarding || false ? (
              <PrimaryButton
                title="Complete Onboarding"
                marginRight5
                handleOnClick={handleGenerateOnboardingLink}
                loading={loading}
              />
            ) : null}

            <PrimaryButton
              title="Edit"
              marginRight5
              handleOnClick={handleShowAccountForm}
            />
          </div>

          <Grid container spacing={1} mt={1}>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <FormHeader title="Deposits" />

              <AccountPayoutListTable payouts={payouts} loading={loading} />
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default StripePaymentDetails;
