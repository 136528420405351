import { Grid } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { AdsAccountFormFields, Brand } from '../../../types';
import { adsProvider } from '../../../utils/constants/facebookAds';
import FacebookAdsAccountForm from './FacebookAdsAccountForm';
import GoogleAdsAccountForm from '../GoogleAdsAccountForm';

interface AdAccountFormProps {
  fbAdsAccounts: AdsAccountFormFields[];
  defaultFormValues: AdsAccountFormFields[];
  setDefaultFormValues: Dispatch<SetStateAction<AdsAccountFormFields[]>>;
  onSelectAdsAccount: (
    value: AdsAccountFormFields[],
    brand: Brand,
    selectedId: string,
    reason: string,
    provider: string,
  ) => void;
  brand: Brand;
  connectedAccountsIds: string[];
  handleSync: (value: any[], brand: Brand) => void;
  handleSyncGoogleCustomerIds: (value: string[], brand: Brand) => void;
  googleAdAccounts: AdsAccountFormFields[];
  isFacebook: boolean;
  isGoogle: boolean;
}

const AdAccountForm: React.FC<AdAccountFormProps> = ({
  fbAdsAccounts,
  defaultFormValues,
  setDefaultFormValues,
  onSelectAdsAccount,
  brand,
  connectedAccountsIds,
  handleSync,
  handleSyncGoogleCustomerIds,
  googleAdAccounts,
  isFacebook,
  isGoogle,
}) => {
  return (
    <Grid container spacing={1}>
      {isFacebook ? (
        <Grid item xs={12}>
          <FacebookAdsAccountForm
            brand={brand}
            handleSync={handleSync}
            fbAdsAccounts={fbAdsAccounts}
            defaultFormValues={defaultFormValues}
            connectedAccountsIds={connectedAccountsIds}
            setDefaultFormValues={setDefaultFormValues}
            onSelectAdsAccount={onSelectAdsAccount}
            provider={adsProvider.FACEBOOK}
          />
        </Grid>
      ) : null}

      {isGoogle ? (
        <Grid item xs={12}>
          <GoogleAdsAccountForm
            brand={brand}
            provider={adsProvider.GOOGLE}
            handleSync={handleSyncGoogleCustomerIds}
            googleAdAccounts={googleAdAccounts}
            defaultFormValues={defaultFormValues}
            setDefaultFormValues={setDefaultFormValues}
            onSelectAdsAccount={onSelectAdsAccount}
            connectedAccountsIds={connectedAccountsIds}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default AdAccountForm;
