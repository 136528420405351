import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { FormEvent, Fragment, useEffect, useState } from 'react';
import styles from '../../../assets/styles/components/Forms/Form.module.scss';
import PageHeader from '../../PageHeader';
import logo from '../../../assets/images/aiadz-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  Brand,
  Campaign,
  CampaignAd,
  ChangeEventType,
  FbCampaignAdSetForm,
  StripeCreateSetupIntentForm,
  StripeCustomerBalanceCredit,
  StripeCustomerForm,
} from '../../../types';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchBrandCampaigns } from '../../../services/ads';
import { toCurrency } from '../../../utils/numberFormatter';
import { retriveStripeSubscription } from '../../../services/stripe/subscription';
import moment from 'moment';
import { PrimaryButton } from '../../Buttons';
import LinearProgressBarWithLabel from '../../ProgressBar/LinearProgressBarWithLabel';
import { MuiTelInput } from 'mui-tel-input';
import CountrySelectMenu from '../../Select/CountrySelectMenu';
import AddCardIcon from '@mui/icons-material/AddCard';
import { IconContext } from 'react-icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  buildCardBrand,
  renderCCIcon,
} from '../../Cards/Billing/PaymentMethodHelpers';
import { createStripeSetupIntent } from '../../../services/stripe/paymentIntent';
import {
  createOrUpdateStripeCustomer,
  fetchSingleStripeCustomer,
  getCustomerCreditBalances,
  getCustomerPaymentMethods,
} from '../../../services/stripe/customer';
import StripePaymentTermsModal from '../../Modal/StripePaymentTermsModal';
import { setBrand, setLocation, toggleAlert } from '../../../redux/actions';
import StripePaymentUIModal from '../../Modal/StripePaymentUIModal';
import { DiySettings } from '../../../types/IDiy';
import { getSingleDiySetting, updateAdBudget } from '../../../services/diy';
import CircularLoading from '../../CircularLoading';
import PoweredByStripe from '../../../assets/images/powered-by-stripe.svg';

const DiyAdBudgetForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const adId: string = params.adId;
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [activeStep, setActiveStep] = useState<string>(null);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [changeType, setChangeType] = useState<string>('immediate');
  const [budgetIncrease, setBudgetIncrease] = useState<number>(null);
  const [campaign, setCampaign] = useState<Campaign>(null);
  const [adset, setAdset] = useState<FbCampaignAdSetForm>(null);
  const [ad, setAd] = useState<CampaignAd>(null);
  const [subscription, setSubscription] = useState<any>(null);
  const [progress, setProgress] = useState<number>(0);
  const [totalBudget, setTotalBudget] = useState<number>(0);
  const [paymentMethod, setPaymentMethod] = useState<string>(null);
  const [isDecrease, setIsDecrease] = useState<boolean>(false);
  const [customerForm, setCustomerForm] = useState<StripeCustomerForm>({
    address: {
      city: '',
      country: 'US',
      line1: '',
      line2: '',
      postal_code: '',
      state: '',
    },
    email: '',
    name: '',
    phone: '',
    metadata: {
      user_id: (location || brand)?._id,
      role: 'brand',
    },
  });
  const [customer, setCustomer] = useState<any>(null);
  const [openTermsModal, setOpenTermsModal] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [clientSecret, setClientSecret] = useState<string>(null);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<string>(null);
  const [diySettings, setDiySettings] = useState<DiySettings>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmPayment, setConfirmPayment] = useState<boolean>(false);
  const [creditBalance, setCreditBalance] =
    useState<StripeCustomerBalanceCredit>(null);

  useEffect(() => {
    if (adId && (location || brand)) {
      getCampaign();
      getDiySettings();
      getCustomerCredits();
    }
  }, [adId, brand, location]);

  useEffect(() => {
    if (campaign && brand?.allowSetupPayment && !campaign?.limited)
      getSubscription();
  }, [brand, location, campaign]);

  useEffect(() => {
    buildSteps();
  }, [changeType]);

  useEffect(() => {
    if (customer && (location || brand)) getPaymentMethods(location || brand);
  }, [customer, location, brand, openPaymentModal]);

  useEffect(() => {
    if (buildSteps().length > 0) {
      const step = buildSteps()?.find((value: string, index: number) => {
        return index === activeStepIndex;
      });

      setActiveStep(step);
    }
  }, [activeStepIndex]);

  useEffect(() => {
    if (activeStep === 'Billing Summary' && (location || brand)) {
      getSingleStripeCustomer(location || brand);
    }
  }, [activeStep, location, brand]);

  const getCampaign = async () => {
    setLoading(true);
    try {
      const response = await fetchBrandCampaigns(
        (location || brand)?._id,
        'facebook',
        null,
        adId,
      );

      const adObj = response.data[0].ads.find((ad: CampaignAd) => {
        return ad.id === adId;
      });

      const adsetObj: FbCampaignAdSetForm = response.data[0].adSets.find(
        (adset: FbCampaignAdSetForm) => {
          return adset.id === adObj.adset_id;
        },
      );

      setCampaign(response.data[0]);
      setAd(adObj);
      setAdset(adsetObj);

      if (campaign?.limited) setChangeType('immediate');
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getCampaignNextCycle = (startTime: number) => {
    const startDate = moment.unix(startTime).local();
    const currentDate = moment().local();
    const diffInMonths = currentDate.diff(startDate, 'months');
    const nextBillingCycle = startDate.clone().add(diffInMonths + 1, 'months');

    return nextBillingCycle;
  };

  const getSubscription = async () => {
    try {
      const response = await retriveStripeSubscription(
        brand?._id,
        campaign?._id,
      );

      setSubscription(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getPaymentMethods = async (brand: Brand) => {
    setLoading(true);
    try {
      const response = await getCustomerPaymentMethods(brand?._id);
      let temp: any = [...(response?.data || [])];
      temp = temp.sort((a: any) => {
        if (customer.invoice_settings.default_payment_method === a.id) {
          return -1;
        }

        return 1;
      });

      setPaymentMethods(temp);
      if (temp.length > 0) {
        setPaymentMethod(temp[0]?.id);
      }
    } catch (error: any) {
      setPaymentMethods([]);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getSingleStripeCustomer = async (brand: Brand) => {
    setLoading(true);
    try {
      const { data } = await fetchSingleStripeCustomer(brand?._id, 'brand');

      setCustomer(data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getDiySettings = async () => {
    setLoading(true);
    try {
      const response = await getSingleDiySetting((location || brand)?._id);

      setDiySettings(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getCustomerCredits = async () => {
    setLoading(true);
    try {
      const response = await getCustomerCreditBalances(
        (location || brand)?._id,
      );

      if (response.data.length > 0) {
        setCreditBalance(response.data[0]);
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    try {
      const response = await updateAdBudget(
        ad.id,
        (location || brand)?._id,
        budgetIncrease,
        totalBudget,
        changeType,
        paymentMethod,
      );

      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Ad budget updated successfully!',
        }),
      );

      navigate(
        `/adz?fromUpdateBudget=true${
          isDecrease && response.data.totalCredit > 0
            ? `&creditAmount=${response.data.totalCredit}`
            : ''
        }`,
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const buildSteps = () => {
    let steps: string[] = ['Change Budget'];
    if (changeType === 'immediate' && (location || brand)?.allowSetupPayment) {
      steps = [...steps, 'Billing Summary'];
    }

    return steps;
  };

  const budgetChangeType = (newBudget: number) => {
    if (getCurrentBudget() > newBudget) {
      return 'Decrease';
    }

    return 'Increase';
  };

  const calculateNewBudget = (newBudget: number) => {
    const budget = newBudget > 0 ? newBudget - getCurrentBudget() : 0;
    return budget;
  };

  const handleBack = () => {
    setActiveStepIndex((prev) => prev - 1);

    setProgress(
      (prevProgress) => prevProgress - (1 / buildSteps().length) * 100,
    );
  };

  const handleNext = () => {
    setActiveStepIndex((prev) => prev + 1);
    setProgress(
      (prevProgress) => prevProgress + (1 / buildSteps().length) * 100,
    );
  };

  const isAddress = (field: string) => {
    return [
      'city',
      'country',
      'line1',
      'line2',
      'postal_code',
      'state',
    ].includes(field);
  };

  const handleOnChangeCustomerForm = (e: ChangeEventType) => {
    if (isAddress(e.target.name)) {
      setCustomerForm({
        ...customerForm,
        address: {
          ...customerForm?.address,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      setCustomerForm({
        ...customerForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const buildPageheader = () => {
    let header: string = '';

    switch (activeStep) {
      case 'Remarks':
        header = 'THANK YOU';
        break;
      case 'Billing Summary':
        header = 'BILLING SUMMARY';
        break;
      default:
        header = 'NEW MONTHLY BUDGET';
        break;
    }

    return header;
  };

  const getCurrentBudget = () => {
    return (location || brand)?.allowSetupPayment
      ? adset?.totalBudget /
          (1 - (location || brand)?.budgetPercentageFee / 100)
      : adset?.totalBudget;
  };

  const handleOpenTermsModal = () => {
    setOpenTermsModal(true);
  };

  const handleCloseTermsModal = () => {
    setOpenTermsModal(false);
  };

  const handleOpenPaymentModal = async (
    clientSecret: string,
    transactionType: string,
  ) => {
    setClientSecret(clientSecret);
    setOpenPaymentModal(true);
    setTransactionType(transactionType);
  };

  const handleAddPaymentMethod = async () => {
    setLoading(true);
    try {
      const params: StripeCreateSetupIntentForm = {
        customer: (location || brand)?.stripe?.customerId,
        payment_method_types: ['card'],
        metadata: {
          user_id: (location || brand)?._id,
          role: 'brand',
          action: 'payment_method',
        },
      };

      const setupIntent = await createStripeSetupIntent(
        params,
        (location || brand)?._id,
      );

      handleOpenPaymentModal(setupIntent.data.client_secret, 'setup');
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateCustomer = async (e: FormEvent) => {
    e.preventDefault();
    handleCloseTermsModal();
    setLoading(true);
    try {
      const params: StripeCustomerForm = {
        ...customerForm,
        metadata: {
          user_id: (location || brand)?._id,
          role: 'brand',
        },
      };
      const customerData = await createOrUpdateStripeCustomer(
        params,
        (location || brand)?._id,
        'brand',
      );

      if (customerData?.data) {
        let temp: Brand = {
          ...(location || brand),
          stripe: {
            ...(location || brand)?.stripe,
            customerId: customerData.data.id,
          },
        };

        if (location) {
          dispatch(setLocation(temp));
        } else {
          dispatch(setBrand(temp));
        }

        const params: StripeCreateSetupIntentForm = {
          customer: customerData.data.id,
          payment_method_types: ['card'],
          metadata: {
            user_id: (location || brand)?._id,
            role: 'brand',
            action: 'payment_method',
          },
        };

        const setupIntent = await createStripeSetupIntent(
          params,
          (location || brand)?._id,
        );

        handleOpenPaymentModal(setupIntent.data.client_secret, 'setup');
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
  };

  const buildChargingPlatform = () => {
    let platform: string = '';

    switch (changeType) {
      case 'scheduled':
        platform = 'Charged on ';
        break;
      default:
        if (isDecrease) {
          if (diySettings?.allowSetupOwnAccount) {
            platform = 'Decreased by Facebook';
          } else {
            platform = 'Credit will apply to the next invoice';
          }
        } else {
          if (diySettings?.allowSetupOwnAccount) {
            platform = 'Charged by Facebook';
          } else {
            platform = 'Charged Immediately';
          }
        }

        break;
    }

    return platform;
  };

  const buildPaymentAgreementLabel = () => {
    let amount: number = 0;
    let chargeText: string = '';

    if (calculatedAppliedBalance() > 0) {
      amount = calculatedTotalAmount() - calculatedAppliedBalance();
    } else {
      amount = calculatedTotalAmount();
    }

    if (amount === 0) {
      chargeText = `use my account credit of ${toCurrency(
        'USD',
        calculatedAppliedBalance(),
      )}`;
    } else {
      chargeText = `be charged ${toCurrency('USD', amount)} immediately`;
    }

    return `I confirm that I will ${chargeText} and I authorize this payment. And I authorize the budget ${
      isDecrease ? 'decrease' : 'increase'
    } of the ad campaign on Facebook.`;
  };

  const calculatedTotalAmount = () => {
    let budget: number = 0;
    if (diySettings?.allowSetupOwnAccount) {
      budget = totalBudget * ((location || brand)?.budgetPercentageFee / 100);
    } else {
      budget = totalBudget;
    }

    if (isDecrease) {
      budget = Math.abs(budget);
    }

    return budget;
  };

  const calculatedAppliedBalance = () => {
    let appliedBalance: number = 0;
    if (creditBalance?.ending_balance < 0) {
      if (
        calculatedTotalAmount() > Math.abs(creditBalance?.ending_balance / 100)
      ) {
        appliedBalance = Math.abs(creditBalance?.ending_balance / 100);
      } else {
        return calculatedTotalAmount();
      }
    }

    return appliedBalance;
  };

  const renderContent = () => {
    if (activeStep === 'Change Budget') {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                Please enter your new desired monthly budget
                {/*When do you want to increase the budget?*/}
              </Grid>

              {/**
               * TODO: Re-enable when scheduled increase is implemented
               * <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="changeType"
                    name="changeType"
                    value={changeType}
                    onChange={(e: ChangeEventType) =>
                      setChangeType(e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="immediate"
                      control={<Radio size="small" />}
                      label="Immediate"
                    />

                    {!campaign?.limited ? (
                      <FormControlLabel
                        value="scheduled"
                        control={<Radio size="small" />}
                        label={
                          (location || brand)?.allowSetupPayment
                            ? `Next Billing Cycle (${moment
                                .unix(subscription?.current_period_end)
                                .local()
                                .format('MM/DD/YYYY')})`
                            : `Increase on ${getCampaignNextCycle(
                                adset?.start_time,
                              ).format('MM/DD/YYYY')}`
                        }
                      />
                    ) : null}
                  </RadioGroup>
                </FormControl>
                      </Grid>*/}

              {changeType ? (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    type="number"
                    id="agency"
                    name="agency"
                    label="New Monthly Budget"
                    onChange={(e: ChangeEventType) => {
                      const newBudget = parseFloat(e.target.value);
                      const totalAmount =
                        newBudget > 0 ? newBudget - getCurrentBudget() : 0;
                      setBudgetIncrease(newBudget);
                      setTotalBudget(totalAmount);
                      setIsDecrease(totalAmount < 0);
                    }}
                    value={budgetIncrease}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    error={
                      budgetIncrease === 0 ||
                      budgetIncrease === getCurrentBudget()
                        ? true
                        : false
                    }
                    helperText={
                      budgetIncrease === getCurrentBudget()
                        ? 'You cannot set new budget equal to current budget'
                        : budgetIncrease === 0
                        ? 'Please set your new budget'
                        : ''
                    }
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }}
                    autoFocus
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      Current Monthly Budget
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    {toCurrency('USD', getCurrentBudget())}
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      New Monthly Budget
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    {toCurrency('USD', budgetIncrease || 0)}
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {`Budget ${budgetChangeType(budgetIncrease)}`}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    {toCurrency('USD', calculateNewBudget(budgetIncrease))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      );
    }

    if (activeStep === 'Billing Summary') {
      return (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={isDecrease ? 12 : 6}
            sx={{ justifyContent: 'center', width: '100%' }}
          >
            {!(location || brand)?.stripe?.customerId && !isDecrease ? (
              <Card sx={{ width: '100%' }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'bold', color: '#096F4D' }}
                      >
                        Enter your credit card information
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'bold', color: '#096F4D' }}
                      >
                        Customer Details
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        variant="standard"
                        type="text"
                        id="name"
                        name="name"
                        label="Full Name"
                        onChange={handleOnChangeCustomerForm}
                        value={customerForm?.name}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        autoFocus
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        variant="standard"
                        type="email"
                        id="name"
                        name="email"
                        label="Email"
                        onChange={handleOnChangeCustomerForm}
                        value={customerForm?.email}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <MuiTelInput
                        fullWidth
                        required
                        variant="standard"
                        id="phone"
                        name="phone"
                        label="Phone Number"
                        onChange={(newValue: any) =>
                          setCustomerForm({ ...customerForm, phone: newValue })
                        }
                        value={customerForm?.phone}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        onlyCountries={['US']}
                        defaultCountry="US"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'bold', color: '#096F4D' }}
                      >
                        Address Details
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="standard"
                        type="text"
                        id="line1"
                        name="line1"
                        label="Company Name, Street, PO Box"
                        onChange={handleOnChangeCustomerForm}
                        value={customerForm?.address?.line1}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        autoFocus
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        id="line2"
                        name="line2"
                        label="Apartment, Suite, Unit, Building"
                        onChange={handleOnChangeCustomerForm}
                        value={customerForm?.address?.line2}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="standard"
                        required
                        type="text"
                        id="city"
                        name="city"
                        label="City"
                        onChange={handleOnChangeCustomerForm}
                        value={customerForm?.address?.city}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        variant="standard"
                        type="text"
                        id="state"
                        name="state"
                        label="State"
                        onChange={handleOnChangeCustomerForm}
                        value={customerForm?.address?.state}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CountrySelectMenu
                        value={{
                          code: 'US',
                          label: 'United States',
                          phone: '1',
                          suggested: true,
                        }}
                        disabled
                        shrink
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        variant="standard"
                        type="text"
                        id="postalCode"
                        name="postal_code"
                        label="Postal Code"
                        onChange={handleOnChangeCustomerForm}
                        value={customerForm?.address?.postal_code}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                      }}
                    >
                      <PrimaryButton
                        title="Add Credit Card"
                        type="button"
                        startIcon={<AddCardIcon />}
                        handleOnClick={handleOpenTermsModal}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ) : null}

            {(location || brand)?.stripe?.customerId && !isDecrease ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: '#096F4D' }}
                  >
                    Select the payment method
                  </Typography>
                </Grid>
                {paymentMethods.map((payment: any, index: number) => {
                  const selected = payment.id === paymentMethod;

                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      key={`payment-card-${index}`}
                      sx={{
                        position: 'relative',
                      }}
                    >
                      {selected ? (
                        <Box
                          component="div"
                          sx={{
                            position: 'absolute',
                            top: '6px',
                            zIndex: 1,
                            right: '-12px',
                            float: 'right',
                            margin: '1px',
                          }}
                        >
                          <CheckCircleIcon
                            sx={{
                              color: '#096f4d',
                              bgcolor: 'InfoBackground',
                              borderRadius: '50%',
                            }}
                          />
                        </Box>
                      ) : null}

                      <Card
                        sx={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                          border: selected ? 'solid' : '',
                          borderColor: selected ? '#096f4d' : '',
                          borderWidth: 'medium',
                          zIndex: 0,
                        }}
                        onClick={() => setPaymentMethod(payment.id)}
                      >
                        <CardHeader
                          avatar={
                            <IconContext.Provider value={{ size: '40px' }}>
                              {renderCCIcon(payment.card.brand)}
                            </IconContext.Provider>
                          }
                          title={`${buildCardBrand(payment.card)} ●●●● ${
                            payment.card.last4
                          }`}
                          subheader={`Expires ${payment.card.exp_month}/${payment.card.exp_year}`}
                          titleTypographyProps={{ fontWeight: 'bold' }}
                        />
                      </Card>
                    </Grid>
                  );
                })}

                <Grid
                  item
                  xs={12}
                  sx={{
                    justifyContent: 'center',
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <PrimaryButton
                    title="Add Payment Method"
                    type="button"
                    handleOnClick={handleAddPaymentMethod}
                    loading={loading}
                    startIcon={<AddCardIcon />}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card sx={{ width: '100%' }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {`Media Budget ${!isDecrease ? 'Increase' : 'Decrease'}`}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ justifyContent: 'center', display: 'flex' }}
                  >
                    {toCurrency(
                      'USD',
                      isDecrease
                        ? Math.abs(
                            totalBudget -
                              totalBudget *
                                ((location || brand)?.budgetPercentageFee /
                                  100),
                          )
                        : totalBudget -
                            totalBudget *
                              ((location || brand)?.budgetPercentageFee / 100),
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight:
                          buildChargingPlatform() === 'Charged Immediately'
                            ? 'bold'
                            : '',
                        color:
                          buildChargingPlatform() === 'Charged Immediately'
                            ? 'red'
                            : '',
                      }}
                    >
                      {`(${buildChargingPlatform()})`}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {`Subscription Fee ${
                        !isDecrease ? 'Increase' : 'Decrease'
                      }`}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ justifyContent: 'center', display: 'flex' }}
                  >
                    {toCurrency(
                      'USD',
                      isDecrease
                        ? Math.abs(
                            totalBudget *
                              ((location || brand)?.budgetPercentageFee / 100),
                          )
                        : totalBudget *
                            ((location || brand)?.budgetPercentageFee / 100),
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: isDecrease ? '' : 'bold',
                        color: isDecrease ? '' : 'red',
                      }}
                    >
                      {isDecrease
                        ? '(Credit will apply to the next invoice)'
                        : '(Charged Immediately)'}
                    </Typography>
                  </Grid>

                  {!isDecrease && creditBalance?.ending_balance < 0 ? (
                    <>
                      <Grid item xs={4}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          Account Credit
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sx={{ justifyContent: 'center', display: 'flex' }}
                      >
                        <Typography variant="body1">
                          {toCurrency('USD', calculatedAppliedBalance())}
                        </Typography>
                      </Grid>

                      <Grid item xs={4} />
                    </>
                  ) : null}

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {`Total${isDecrease ? ' Decrease' : ''}`}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ justifyContent: 'center', display: 'flex' }}
                  >
                    <Typography variant="body1">
                      {toCurrency('USD', Math.abs(totalBudget))}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} />

                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Box
                      component="img"
                      src={PoweredByStripe}
                      alt=""
                      width={130}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {!isDecrease ? (
              <FormControlLabel
                control={<Checkbox size="small" />}
                label={
                  <Fragment>
                    <Typography
                      variant="body2"
                      sx={{ color: 'red', fontWeight: 600 }}
                    >
                      {buildPaymentAgreementLabel()}
                    </Typography>
                  </Fragment>
                }
                value={confirmPayment}
                onChange={(e: any) => {
                  setConfirmPayment(e.target.checked);
                }}
                sx={{ mt: 2 }}
              />
            ) : null}
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Box className={`${styles.form} ${xsOnly ? `${styles['-mobile']}` : ''}`}>
      <Box
        component="div"
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          margin: 0,
        }}
      >
        <CircularLoading loading={loading} />

        <Box component="div" sx={{ paddingBottom: 0 }}>
          <PageHeader
            title={buildPageheader()}
            textAlign="left"
            marginBottom="0"
          />
        </Box>

        <div>
          <Box component="img" src={logo} alt="" sx={{ width: '90px' }} />
        </div>
      </Box>

      <Grid container spacing={12}>
        <Grid item xs={12} sx={{ minHeight: 'calc(100vh - 222px)' }}>
          {renderContent()}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        mt={4}
        sx={{
          position: 'sticky',
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: '#FFF',
          paddingBottom: '20px',
          zIndex: 999,
          alignItems: 'flex-end',
        }}
      >
        <Grid item xs={12} sm={6}>
          <LinearProgressBarWithLabel value={progress} />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            justifyContent: 'flex-end',
            display: 'flex',
            paddingY: '20px',
          }}
        >
          {activeStepIndex > 0 ? (
            <PrimaryButton
              title="Back"
              type="button"
              handleOnClick={handleBack}
              marginRight5
              variant="text"
              color="red"
            />
          ) : null}

          {(activeStepIndex + 1 === buildSteps().length &&
            activeStep === 'Billing Summary' &&
            !isDecrease &&
            (!paymentMethod || !confirmPayment)) ||
          (activeStep === 'Change Budget' &&
            (!budgetIncrease || totalBudget === 0)) ? null : (
            <PrimaryButton
              title={
                activeStepIndex + 1 === buildSteps().length
                  ? activeStep === 'Billing Summary'
                    ? 'Update Budget'
                    : 'Finish'
                  : 'Next'
              }
              type="button"
              handleOnClick={
                activeStepIndex + 1 === buildSteps().length
                  ? handleOnSubmit
                  : handleNext
              }
              loading={loading}
            />
          )}
        </Grid>
      </Grid>

      <StripePaymentTermsModal
        open={openTermsModal}
        onClose={handleCloseTermsModal}
        onAgree={handleCreateCustomer}
        title="Advertising Services Agreement"
      />

      <StripePaymentUIModal
        open={openPaymentModal}
        onClose={handleClosePaymentModal}
        clientSecret={clientSecret}
        transactionType={transactionType}
        allowRedirect={false}
      />
    </Box>
  );
};

export default DiyAdBudgetForm;
