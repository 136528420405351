import React, { useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Reports.module.scss';
import CircularLoading from '../../components/CircularLoading';
import PageHeader from '../../components/PageHeader';
import DateRangePicker from '../../components/DateAndTimePickers/DateRangePicker';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setEndRange, setStartRange } from '../../redux/actions';
import {
  Autocomplete,
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AIReport,
  AIReportInsights,
  Brand,
  Campaign,
  CampaignAd,
} from '../../types';
import { getAiReportData } from '../../services/report';
import { camelCaseToHumanized } from '../../utils/stringModifier';
import { NoData } from '../NoContent';
import CountUp from 'react-countup';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { fetchBrandCampaigns } from '../../services/ads';
import parse from 'html-react-parser';

const AiReport: React.FC = () => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const dispatch = useDispatch();
  const startRange = useSelector((state: any) => state.startRange.date);
  const endRange = useSelector((state: any) => state.endRange.date);
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );

  const [report, setReport] = useState<AIReport>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [insightData, setInsightData] = useState<AIReportInsights[]>([]);
  const [ads, setAds] = useState<CampaignAd[]>([]);
  const [selectedAd, setSelectedAd] = useState<CampaignAd>(null);

  useEffect(() => {
    getCampaigns();
  }, [location, brand]);

  useEffect(() => {
    if ((location || brand) && startRange && endRange && selectedAd) {
      getReport();
    }
  }, [location, brand, startRange, endRange, selectedAd]);

  useEffect(() => {
    buildAiReportInsightItems();
  }, [report]);

  const getReport = async () => {
    setLoading(true);
    try {
      const response = await getAiReportData(
        (location || brand)?._id,
        startRange,
        endRange,
        'facebook',
        selectedAd?.id,
      );

      setReport(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getCampaigns = async () => {
    try {
      const campaigns = await fetchBrandCampaigns(
        (location || brand)?._id,
        'facebook',
      );

      let data: CampaignAd[] = [];
      campaigns?.data?.forEach((campaign: Campaign) => {
        campaign?.ads?.forEach((ad: CampaignAd) => {
          if (ad?.status === 'ACTIVE' || ad?.status === 'PAUSED')
            data = [...data, ad];
        });
      });

      if (data?.length > 0) {
        setSelectedAd(data[0]);
      }

      setAds(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const buildAiReportInsightItems = async () => {
    let data: AIReportInsights[] = [];

    Object.keys(report || {}).forEach((key: string) => {
      if (key === 'summary') return;

      const params: AIReportInsights = {
        value: report[key],
        label: key,
        isCurrency: false,
        isPercentage: false,
        icon: <AssessmentOutlinedIcon />,
      };

      data = [...data, params];
    });

    // Always put Performance Score first on the list
    const index = data.findIndex(
      (item: AIReportInsights) => item.label === 'performanceScore',
    );

    if (0 >= data.length) {
      var k = 0 - data.length;
      while (k-- + 1) {
        data.push(undefined);
      }
    }

    data.splice(0, 0, data.splice(index, 1)[0]);

    setInsightData(data);
  };

  const handleChangeDateRange = (dates: Moment[] | []) => {
    dispatch(setStartRange(dates[0]));
    dispatch(setEndRange(dates[1]));
  };

  const buildSummaryHeader = (key: string) => {
    let header: string = '';

    switch (key) {
      case 'cpcScore':
        header = 'Attention Score';
        break;
      case 'ctrScore':
        header = 'CTR Score';
        break;
      case 'clickToLeadScore':
        header = 'Click-To-Lead Score';
        break;
      case 'conversionRateScore':
        header = 'Conversion Score';
        break;
      default:
        header = camelCaseToHumanized(key);
        break;
    }

    return header;
  };

  const isCurrency = (key: string) => {
    return ['spend', 'cpc', 'cplc', 'cpl', 'cpcCplc'].includes(key);
  };

  const isPercentage = (key: string) => {
    return ['ctr', 'ctrLinkClicks', 'conversionRate'].includes(key);
  };

  const buildInsightItemLabel = (key: string) => {
    let label: string = '';

    switch (key) {
      case 'cpl':
      case 'cplc':
      case 'cpc':
      case 'ctr':
        label = key.toUpperCase();
        break;
      case 'ctrLinkClicks':
        label = 'CTR Link Clicks';
        break;
      case 'cplScore':
        label = 'CPL Score';
        break;
      case 'ctrScore':
        label = 'CTR Score';
        break;
      case 'cpcScore':
        label = 'CPC Score';
        break;
      case 'cpcCplc':
        label = 'CPC/CPLC';
        break;
      case 'impressionsToLead':
        label = 'Impressions-To-Lead';
        break;
      case 'clicksToLeads':
        label = 'Clicks-To-Lead';
        break;
      case 'ctrCpc':
        label = 'CTR/CPC';
        break;
      case 'cpqlScore':
        label = 'CPQL Score';
        break;
      default:
        label = camelCaseToHumanized(key);
        break;
    }

    return label;
  };

  return (
    <div className={styles.report}>
      <CircularLoading loading={loading} />

      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="AI Analyz" />

          <div className={styles.controls}>
            <DateRangePicker
              toDate={endRange}
              fromDate={startRange}
              onChange={handleChangeDateRange}
              maxDate={moment()}
              separator="-"
            />
          </div>
        </div>

        {!loading ? (
          <Grid container spacing={1} sx={{ flexGrow: 1, mb: 2 }}>
            {/**
              * TODO: Show after AI report setting was implemented
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <PrimaryButton
                title="Settings"
                startIcon={<SettingsOutlinedIcon />}
                type="button"
                handleOnClick={() => navigate('/ai/reportz/settings')}
              />
            </Grid>
            */}

            <Grid
              item
              xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Autocomplete
                id="ads"
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {`${option.alias} (${option.name})`}
                    </li>
                  );
                }}
                disableClearable
                options={ads?.sort((a, b) => -b.alias?.localeCompare(a.alias))}
                getOptionLabel={(option) => `${option.alias} (${option.name})`}
                value={selectedAd}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Ads"
                    placeholder="Please select an ad"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="small"
                  />
                )}
                onChange={(e: any, value: any) => {
                  setReport(null);
                  setInsightData([]);
                  setSelectedAd(value);
                }}
                sx={{ width: { xs: '100%', sm: '500px' } }}
              />
            </Grid>
          </Grid>
        ) : null}

        {!loading && report ? (
          <Grid
            container
            spacing={1}
            justifyContent="center"
            columns={14}
            mb={2}
          >
            {insightData.map((data: AIReportInsights, index: number) => {
              if (
                ![
                  'performanceScore',
                  'interestScore',
                  'cplScore',
                  'cpcScore',
                  'cpqlScore',
                ].includes(data?.label)
              )
                return <></>;

              return (
                <Grid item xs={7} sm={'auto'} key={`insights-${index + 1}`}>
                  <Card
                    sx={{
                      width: { xs: '100%', sm: '200px' },
                      minHeight: '100%',
                    }}
                  >
                    <CardHeader
                      avatar={data?.icon}
                      title={buildInsightItemLabel(data?.label)}
                      subheader={
                        <CountUp
                          end={
                            isPercentage(data?.label)
                              ? (data?.value || 0) * 100
                              : data?.value || 0
                          }
                          duration={3}
                          separator=","
                          prefix={isCurrency(data?.label) ? '$' : ''}
                          decimals={
                            isCurrency(data?.label) || isPercentage(data?.label)
                              ? 2
                              : 1
                          }
                          suffix={isPercentage(data?.label) ? '%' : ''}
                        />
                      }
                      titleTypographyProps={{
                        sx: {
                          color: '#096F4D',
                          fontSize: '12px',
                          paddingLeft: '0',
                        },
                      }}
                      subheaderTypographyProps={{
                        sx: {
                          color: '#096F4D',
                          fontWeight: 'bold',
                          fontSize: xsOnly ? '14px' : '16px',
                        },
                      }}
                      sx={{ padding: '10px' }}
                    />
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : !report ? (
          <NoData />
        ) : null}

        <div>
          {Object.keys(report?.summary || {}).map((key: string) => {
            if (['ctrScore', 'generalAnalysis'].includes(key)) {
              return <></>;
            }

            return (
              <Box component="div" sx={{ mt: 1 }}>
                {key !== 'additionalInsightsText' ? (
                  <Typography variant="h6" fontWeight="bold">
                    {buildSummaryHeader(key)}
                  </Typography>
                ) : null}

                <Typography variant="body2">
                  {parse(report?.summary[key]?.text)}
                </Typography>

                {!(key === 'additionalInsightsText') ? (
                  <Divider sx={{ mt: 2 }} />
                ) : null}
              </Box>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AiReport;
