import React, { SyntheticEvent, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Pages.module.scss';
import PageHeader from '../../components/PageHeader';
import { useSelector } from 'react-redux';
import { Brand, LeadFormData } from '../../types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Grid, Tab } from '@mui/material';
import { getBrandLeadForms } from '../../services/lead';
import LeadFormListTable from '../../components/Tables/LeadList/LeadFormListTable';
import { PrimaryButton } from '../../components/Buttons';
import { useNavigate } from 'react-router-dom';

const TABS: string[] = ['Lead Forms'];

const Assetz: React.FC = () => {
  const navigate = useNavigate();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );

  const [tabValue, setTabValue] = useState<string>('1');
  const [leadForms, setLeadForms] = useState<LeadFormData[]>([]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);

  useEffect(() => {
    if (location || brand) getLeadForms();
  }, [brand, location]);

  const getLeadForms = async () => {
    setFetchLoading(true);
    try {
      const response = await getBrandLeadForms((location || brand)?._id);

      setLeadForms(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleChangeTabValue = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const renderTabContent = (tab: string) => {
    if (tab === 'Lead Forms') {
      return (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: 'flex-end', display: 'flex' }}
          >
            <PrimaryButton
              title="Create Form"
              type="button"
              handleOnClick={() => navigate('/assetz/form/create')}
            />
          </Grid>

          <Grid item xs={12}>
            <LeadFormListTable leadForms={leadForms} loading={fetchLoading} />
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Assetz" />
        </div>

        <div>
          <TabContext value={tabValue}>
            <TabList
              onChange={handleChangeTabValue}
              variant="scrollable"
              allowScrollButtonsMobile
              scrollButtons
              textColor="secondary"
              TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
              TabScrollButtonProps={{
                style: { backgroundColor: '#096f4d', color: 'white' },
              }}
              sx={{
                backgroundColor: '#096f4d',
                '.MuiTab-root': {
                  top: 5,
                  color: 'white',
                },
                '.Mui-selected': {
                  color: '#096f4d !important',
                  backgroundColor: 'white',
                  top: 5,
                  borderRadius: 2,
                },
              }}
            >
              {TABS?.map((tab: string, index: number) => (
                <Tab
                  label={tab}
                  value={`${index + 1}`}
                  key={`assetz-tab-header-${index + 1}`}
                />
              ))}
            </TabList>

            {TABS?.map((tab: string, index: number) => {
              return (
                <TabPanel
                  value={`${index + 1}`}
                  sx={{
                    backgroundColor: '#d9d9d933',
                    minHeight: 'calc(100vh - 310px)',
                  }}
                  key={`assetz-content-${index + 1}`}
                >
                  {renderTabContent(tab)}
                </TabPanel>
              );
            })}
          </TabContext>
        </div>
      </div>
    </div>
  );
};

export default Assetz;
