import React, { useContext, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Pages.module.scss';
import PageHeader from '../../components/PageHeader';
import { PrimaryButton } from '../../components/Buttons';
import { useNavigate } from 'react-router-dom';
import { AdsTemplate } from '../../types/IDiy';
import { Brand } from '../../types';
import { useSelector } from 'react-redux';
import {
  archiveCampaignTemplate,
  fetchCampaignTemplates,
} from '../../services/diy';
import { adsProvider } from '../../utils/constants/facebookAds';
import AdsTemplateListTable from '../../components/Tables/TemplateList/AdsTemplateListTable';
import { DrawerMenu } from '../../components/Menu';
import TemplatePreview from '../../components/Details/TemplatePreview';
import { buildAccessToken } from '../../utils/helpers/DefaultTokenBuilder';
import {
  ADMIN,
  AGENCY,
  BRAND_MANAGER,
  MARKETING_MANAGER,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import { AuthContext } from '../../context';
import CircularLoading from '../../components/CircularLoading';

const Templates: React.FC = () => {
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);
  const accessToken = state.authUser?.accessToken;
  const isAdmin = state.role === ADMIN;
  const isAgency = state.role === AGENCY;
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isSalesperson = state.role === SALESPERSON;
  const isBrandManager = state.role === BRAND_MANAGER;
  const isMarketingManager = state.role === MARKETING_MANAGER;
  const roleBasedId = state.roleBasedId;

  const [templates, setTemplates] = useState<AdsTemplate[]>([]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<AdsTemplate>(null);
  const [defaultAccessToken, setDefaultAccessToken] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    handleBuildProviderTokens();
  }, []);

  useEffect(() => {
    if (brand)
      getTemplates(brand?.__type === 'location' ? brand?.franchisor : brand);
  }, [brand]);

  const getTemplates = async (brand: Brand) => {
    setFetchLoading(true);
    try {
      const response = await fetchCampaignTemplates(
        brand?._id,
        adsProvider.FACEBOOK,
        true,
      );

      setTemplates(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleOpenPreview = (template: AdsTemplate) => {
    setSelectedTemplate(template);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleBuildProviderTokens = () => {
    buildAccessToken(
      isSuperAdmin || isAdmin,
      isAgency,
      isBrandManager || isSalesperson || isMarketingManager,
      accessToken,
      roleBasedId,
      setDefaultAccessToken,
    );
  };

  const handleArchiveTemplate = async () => {
    setLoading(true);
    try {
      const response = await archiveCampaignTemplate(selectedTemplate?._id);

      const temp = templates?.filter(
        (template: AdsTemplate) => template?._id !== response?.data?._id,
      );

      setTemplates(temp);
      handleClosePreview();
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.page}>
      <CircularLoading loading={loading} />

      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Templatez" />

          <div className={styles.controls}>
            <div className={styles.buttons}>
              <PrimaryButton
                title="Create Template"
                type="button"
                handleOnClick={() => navigate('/templatez/create')}
              />
            </div>
          </div>
        </div>

        <div>
          <AdsTemplateListTable
            templates={templates}
            loading={fetchLoading}
            onOpenPreview={handleOpenPreview}
          />
        </div>
      </div>

      <DrawerMenu
        anchor="right"
        open={openPreview}
        onClose={handleClosePreview}
      >
        <TemplatePreview
          onClose={handleClosePreview}
          templateId={selectedTemplate?._id}
          brand={brand?.__type === 'location' ? brand?.franchisor : brand}
          onArchive={handleArchiveTemplate}
          archiveLoading={loading}
        />
      </DrawerMenu>
    </div>
  );
};

export default Templates;
