import { DatePicker } from '@mui/x-date-pickers';
import React, { Dispatch, SetStateAction } from 'react';
import moment, { Moment } from 'moment';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

interface MonthAndYearPickerProps {
  value: Moment | null;
  setValue: Dispatch<SetStateAction<Moment | null>>;
  handleChangeDate: (date: Moment | null) => void;
  endDay: string;
}

const MonthAndYearPicker: React.FC<MonthAndYearPickerProps> = ({
  value,
  setValue,
  handleChangeDate,
  endDay,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        views={['month', 'year']}
        label="Select Month and Year"
        minDate={moment('2022-01-01').utc().local()}
        maxDate={moment().utc().local().subtract(1, 'day')}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          handleChangeDate(newValue);
        }}
        renderInput={(params) => {
          const valueArr = params.inputProps.value.split(' ');
          const month = valueArr[0];
          const year = valueArr[1];
          params.inputProps.value = `${month} ${
            ['0', '1'].includes(endDay) ? '1' : `1 - ${endDay}`
          }, ${year}`;
          return <TextField {...params} size="small" variant="standard" />;
        }}
      />
    </LocalizationProvider>
  );
};

export default MonthAndYearPicker;
