import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Salesperson } from '../../../types';
import { humanizeString } from '../../../utils/stringModifier';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';

const defaultProps = (isMobile: boolean, width: number) => {
  if (isMobile) return { width };

  return { flex: 1 };
};

export const mobileColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      disableColumnMenu: true,
      sortingOrder: ['desc', 'asc'],
    },
    {
      ...defaultProps(isMobile, 180),
      field: 'name',
      headerName: 'Name',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        const { name } = params.row;

        return <div className={styles.name}>{name}</div>;
      },
    },
    {
      field: 'invite',
      headerName: 'Invite',
      hideSortIcons: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const { handleOnClickInviteBtn, name, email, _id, role, withUser } =
          params.row;

        return (
          <span
            className={`${styles.invite} ${
              withUser ? `${styles['-disabled']}` : ''
            }`}
            onClick={(e) => {
              if (withUser) return;

              e.stopPropagation();
              handleOnClickInviteBtn({ name, email, _id, role });
            }}
          >
            Invite
          </span>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      hideSortIcons: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const { handleOnClickEdit, handleOnClickDelete } = params.row;

        const salesperson: Salesperson = {
          name: params.row.name,
          firstname: params.row.firstname,
          lastname: params.row.lastname,
          email: params.row.email,
          cell: params.row.cell,
          broker: params.row.broker,
          investment: params.row.investment,
          role: params.row.role,
          user: params.row.user,
          _id: params.row._id,
          active: params.row.active,
        };

        return (
          <div>
            <IconButton
              onClick={() => handleOnClickEdit(salesperson)}
              size="small"
            >
              <ModeEditOutlineOutlinedIcon
                sx={{ fontSize: '18px', color: 'green' }}
              />
            </IconButton>

            {/**
           * Temporary Removed: Unused Feature
           *
            <IconButton
              size="small"
              onClick={() => {
                if (salesperson.user?.banned) {
                  handleUnbanSalesPerson(params.row._id);
                } else {
                  handleBanSalesPerson(params.row._id);
                }
              }}
            >
              {salesperson.user?.banned ? (
                <CheckCircleOutlinedIcon
                  sx={{ fontSize: '18px', color: 'green' }}
                />
              ) : (
                <BlockIcon sx={{ fontSize: '18px', color: 'red' }} />
              )}
            </IconButton>
           */}

            <IconButton
              size="small"
              onClick={() => {
                handleOnClickDelete(params.row._id);
              }}
            >
              <DeleteOutlineOutlinedIcon
                sx={{ fontSize: '18px', color: 'red' }}
              />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return columns;
};

export const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 50,
    disableColumnMenu: true,
    sortingOrder: ['desc', 'asc'],
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      const { name } = params.row;

      return <div className={styles.name}>{name}</div>;
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      const { email } = params.row;

      return <div className={styles.name}>{email}</div>;
    },
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      const { role } = params.row;

      return <div className={styles.name}>{humanizeString(role)}</div>;
    },
  },
  {
    field: 'invite',
    headerName: 'Invite',
    hideSortIcons: true,
    disableColumnMenu: true,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
      const { handleOnClickInviteBtn, name, email, _id, role, withUser } =
        params.row;

      return (
        <span
          className={`${styles.invite} ${
            withUser ? `${styles['-disabled']}` : ''
          }`}
          onClick={(e) => {
            if (withUser) return;

            e.stopPropagation();
            handleOnClickInviteBtn({ name, email, _id, role });
          }}
        >
          Invite
        </span>
      );
    },
  },
  {
    field: 'action',
    headerName: 'Action',
    hideSortIcons: true,
    disableColumnMenu: true,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
      const { handleOnClickEdit, handleOnClickDelete } = params.row;

      const salesperson: Salesperson = {
        name: params.row.name,
        firstname: params.row.firstname,
        lastname: params.row.lastname,
        email: params.row.email,
        cell: params.row.cell,
        broker: params.row.broker,
        investment: params.row.investment,
        role: params.row.role,
        user: params.row.user,
        _id: params.row._id,
        active: params.row.active,
      };

      return (
        <div>
          <IconButton
            onClick={() => handleOnClickEdit(salesperson)}
            size="small"
          >
            <ModeEditOutlineOutlinedIcon
              sx={{ fontSize: '18px', color: 'green' }}
            />
          </IconButton>

          {/**
           * Temporary Removed: Unused Feature
            <IconButton
              size="small"
              onClick={() => {
                if (salesperson.user?.banned) {
                  handleUnbanSalesPerson(params.row._id);
                } else {
                  handleBanSalesPerson(params.row._id);
                }
              }}
            >
              {salesperson.user?.banned ? (
                <CheckCircleOutlinedIcon
                  sx={{ fontSize: '18px', color: 'green' }}
                />
              ) : (
                <BlockIcon sx={{ fontSize: '18px', color: 'red' }} />
              )}
            </IconButton>
           */}

          <IconButton
            size="small"
            onClick={() => {
              handleOnClickDelete(params.row._id);
            }}
          >
            <DeleteOutlineOutlinedIcon
              sx={{ fontSize: '18px', color: 'red' }}
            />
          </IconButton>
        </div>
      );
    },
  },
];
