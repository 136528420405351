import { Box, Link, Typography } from '@mui/material';
import React, {
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { blueGrey } from '@mui/material/colors';
import { AccountCircle } from '@mui/icons-material';
import {
  getSingleChat,
  releaseThreadControl,
  sendMessage,
  setLastSeen,
  takeThreadControl,
} from '../../services/chats';
import { useSearchParams } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { ChatUser, ChangeEventType } from '../../types';
import { webSocketUrl, fbPageId } from '../../services/api';
import SendMessageForm from '../../components/Forms/SendMessageForm';
import { io } from 'socket.io-client';
import moment from 'moment';
import _ from 'lodash';
import { AuthContext } from '../../context';
import { AGENCY, SALESPERSON } from '../../utils';
import { getMinMaxMinutes } from '../../services/min-max-minutes';
import DialogHeader from './DialogHeader';
import ScrollButton from './ScrollButton';
import CannedResponsesModal from '../../components/Modal/CannedResponsesModal';
import { humanizeString } from '../../utils/stringModifier';
import { Attachment, FileInput, FileInputError } from '../../types';
import styles from '../../assets/styles/pages/ChatDialog.module.scss';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';

const ChatDialog: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const chatId: string = searchParams.get('chat');
  const [chat, setChat] = useState<ChatUser>();
  const [loading, setLoading] = useState<boolean>(false);
  const [sendMsgloading, setSendMsgLoading] = useState<boolean>(false);
  const [sendCalendlyUrlloading, setSendCalendlyUrlLoading] =
    useState<boolean>(false);
  const [threadControlTaken, setThreadControlTaken] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [chatMessages, setChatMessages] = useState(null);
  const [messagesToShow, setMessagesToShow] = useState(null);
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showEmojis, setShowEmojis] = useState<boolean>(false);
  const [showScrollBtn, setShowScrollBtn] = useState<boolean>(false);
  const [openCannedResponsesModal, setOpenCannedResponsesModal] =
    useState<boolean>(false);
  const [fileError, setFileError] = useState<FileInputError>(null);
  const [file, setFile] = useState<Attachment>(null);
  const [fileDetails, setFileDetails] = useState<FileInput>(null);

  const scrollRef: any = useRef();
  const boxRef: any = useRef();
  const socket: any = useRef();
  const mountedRef: any = useRef(true);

  const { state } = useContext(AuthContext);
  const isAgency = state.role === AGENCY;
  const isSalesperson = state.role === SALESPERSON;

  useEffect(() => {
    (async () => {
      if (isSalesperson) {
        const seenAt = new Date().toISOString();
        try {
          await setLastSeen(chatId, seenAt);
        } catch (error: any) {
          console.log(error.message);
        }
      }
    })();
  }, []);

  useEffect(() => {
    socket.current = io(webSocketUrl);
    socket.current.on('sayHi', (data: string) => {
      console.log('data', data);
    });
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setChat(await getSingleChat(chatId));
      setLoading(false);
    })();
  }, [chatId]);

  useEffect(() => {
    const threadControl = localStorage.getItem('threadControl');
    if (threadControl) {
      setThreadControlTaken(true);
    }
  }, []);

  useEffect(() => {
    getMinMax();
    return () => {
      mountedRef.current = false; // clean up function
    };
  }, []);

  const getMinMax = async () => {
    const { min, max, message } = await getMinMaxMinutes();
    setMin(min);
    setMax(max);
    setErrorMessage(message);
  };

  useEffect(() => {
    if (chat) {
      const interval = setInterval(() => {
        if (chat._id === chatId) {
          socket.current.emit('callApi', chat.chatuserId, chat.brand.brandId);
          socket.current.on('getApi', (message: any) => {
            if (
              message.user === chat.chatuserId &&
              parseInt(message.brandId) === chat.brand.brandId
            ) {
              setChatMessages(message);
            }
          });
        }
      }, 500);
      return () => {
        clearInterval(interval);
      };
    }
  }, [chat, chatId]);

  useEffect(() => {
    if (chatMessages) {
      const temp = _.groupBy(chatMessages.messages, (m) =>
        moment(m.timestamp).format('LL'),
      );
      setMessagesToShow(temp);
    }
  }, [chatMessages]);

  const handleSendMessage = async (e: FormEvent) => {
    const psId = chat.chatuserId;
    e.preventDefault();

    setSendMsgLoading(true);

    if ((sendMsgloading || message === '') && !file && fileError?.error) {
      setSendMsgLoading(false);
      return;
    }

    try {
      if (chat) {
        const psId = chat.chatuserId;
        await takeThreadControl(psId, chat?.page?._id);
        setThreadControlTaken(true);
      }

      const obj = {
        text: message,
        chatId: chat._id,
        brandId: chat.brand.brandId,
        pageId: chat?.page?._id,
        attachment: file,
      };

      const nowTime = Date.now();
      const fromTime = chatMessages?.userLastInteraction;
      const lastInteraction = Math.floor((nowTime - fromTime) / 60000);

      if (lastInteraction < min || lastInteraction > max) {
        setSendMsgLoading(false);
        dispatch(
          toggleAlert({
            toggle: true,
            message: errorMessage,
            type: 'error',
          }),
        );
        return true;
      }

      const res = await sendMessage(psId, obj);

      if (res) {
        const { chat, attachment } = res;

        if (chat) {
          const tempObj = {
            sender: { id: fbPageId },
            recipient: { id: res?.recipient_id },
            message: { text: message, mid: res?.message_id },
            timestamp: new Date(),
          };
          setChatMessages({
            ...chatMessages,
            messages: [...chatMessages.messages, tempObj],
          });
          setMessage('');
        }

        if (attachment) {
          setFile(null);
          setFileDetails(null);
          setFileError(null);
        }

        setShowScrollBtn(true);
      }

      await releaseThreadControl(psId, chat?.page?._id);
    } catch (error: any) {
      const err = error.response.data;
      dispatch(
        toggleAlert({
          toggle: true,
          message: err.message,
          type: 'error',
        }),
      );
      setSendMsgLoading(false);
    } finally {
      setSendMsgLoading(false);
    }
  };

  const handleSendCalendlyUrl = async () => {
    try {
      setSendCalendlyUrlLoading(true);

      if (chat && localStorage.getItem('threadControl') !== 'yes') {
        const psId = chat.chatuserId;
        await takeThreadControl(psId, chat?.page?._id);
        localStorage.setItem('threadControl', 'yes');
        setThreadControlTaken(true);
        setSendCalendlyUrlLoading(false);
      }

      const psId = chat.chatuserId;
      const obj = {
        text: chat?.brand?.calendly,
        chatId: chat._id,
        brandId: chat.brand.brandId,
        pageId: chat?.page?._id,
      };

      const fromTime = Math.floor(
        moment(chatMessages?.userLastInteraction).valueOf() / 60000,
      );
      const nowTime = Math.floor(moment(Date.now()).valueOf() / 60000);
      const lastInteraction = nowTime - fromTime;

      if (lastInteraction < min || lastInteraction > max) {
        setSendCalendlyUrlLoading(false);
        dispatch(
          toggleAlert({
            toggle: true,
            message: errorMessage,
            type: 'error',
          }),
        );
        return true;
      }
      const res = await sendMessage(psId, obj);
      if (res) {
        const tempObj = {
          sender: { id: fbPageId },
          recipient: { id: res?.recipient_id },
          message: { text: chat?.brand?.calendly, mid: res?.message_id },
          timestamp: new Date(),
        };
        setChatMessages({
          ...chatMessages,
          messages: [...chatMessages.messages, tempObj],
        });
        setMessage('');
        setSendCalendlyUrlLoading(false);
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error: any) {
      setSendCalendlyUrlLoading(false);
      const err = error.response.data;
      dispatch(
        toggleAlert({
          toggle: true,
          message: err?.message,
          type: 'error',
        }),
      );
    }
  };

  const handleOnChange = (e: ChangeEventType) => {
    setMessage(e.target.value);
  };
  const addEmoji = (event: any, obj: any) => {
    setMessage(message + obj.emoji);
    setShowEmojis(false);
  };

  const handleScrollDown = () => {
    boxRef.current.scrollTo({
      top: scrollRef.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    const scrollData = scrollHeight - scrollTop - clientHeight;

    if (Math.abs(scrollData) < 2) {
      setShowScrollBtn(false);
    } else {
      setShowScrollBtn(true);
    }
  };

  const setResponseMessage = (message: string) => {
    setMessage(message);
    handleCannedResponsesModal(false);
  };

  const handleCannedResponsesModal = (value: boolean) => {
    setOpenCannedResponsesModal(value);
  };

  const handleFileUpload = (e: any) => {
    setFileDetails(null);
    setFileError(null);
    setFile(null);
    const file = e.target.files[0];
    if (file.size / (1024 * 1024) > 25) {
      setFileError({
        error: 'Oops! The size limit for files is 25 MB.',
      });
    } else {
      setFileDetails(file);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFile({ file: reader.result, type: file.type, name: file.name });
      };
    }
  };

  const onRemoveAttachment = () => {
    setFile(null);
    setFileDetails(null);
    setFileError(null);
  };

  const [called, setCalled] = useState(false);
  useEffect(() => {
    if (messagesToShow !== null && !called) {
      handleScrollDown();
      setCalled(true);
    }
  }, [messagesToShow]);

  const messageContent = (
    isOwnMessage: boolean,
    message: any,
    timestamp: any,
  ) => {
    if (message.text) {
      return (
        <div
          className={`${styles.message} ${isOwnMessage ? styles['-own'] : ''}`}
        >
          <div
            className={`${styles.content} ${
              isOwnMessage ? styles['-own'] : ''
            }`}
          >
            <Typography
              component="p"
              sx={{
                color: isOwnMessage ? '#FFFFFF' : '#484848',
                // mx: 1,
                pt: 1,
                // px: 1.5,
                fontSize: '12px',
              }}
            >
              {message.text}
            </Typography>

            <Typography
              component="span"
              sx={{
                color: isOwnMessage ? '#FFFFFF' : '#484848',
                // mx: 1,
                // py: 1,
                // px: 1.5,
                padding: '0 0 12px',
                fontSize: '8px',
                textAlign: 'right',
              }}
            >
              {moment(timestamp).format('LT')}
            </Typography>
            {/* </div> */}
          </div>
        </div>
      );
    } else if (message.attachments) {
      if (message.attachments[0].type === 'image') {
        const url = message.attachments[0].payload.url;
        const sticker = message.attachments[0].payload.sticker_id;

        return (
          <div
            className={`${styles['attachment']} ${styles['-image']} ${
              isOwnMessage ? styles['-own'] : ''
            }`}
          >
            <img
              width={`${sticker ? '50%' : '100%'}`}
              height="100%"
              className={styles['image-content']}
              src={url}
              alt=""
            />
          </div>
        );
      } else if (
        ['file', 'video', 'audio'].includes(message.attachments[0].type)
      ) {
        const type = message.attachments[0].type;
        return (
          <div
            className={`${styles['attachment']} ${styles['-file']} ${
              isOwnMessage ? styles['-own'] : ''
            }`}
          >
            <Link
              href={`${message.attachments[0].payload.url}`}
              target="_blank"
            >
              {`${humanizeString(type)} Attachement`}
            </Link>
          </div>
        );
      }
    }
  };

  return (
    <Box
      component="div"
      sx={{
        maxWidth: '700px',
        mx: 'auto',
      }}
      className={styles.chat}
    >
      <Box
        component="div"
        mt={3}
        mb={1}
        sx={{
          border: `1px solid ${blueGrey[300]}`,
          borderRadius: '5px',
          position: 'relative',
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              my: 5,
            }}
          >
            <Spinner />
          </Box>
        ) : (
          <Box>
            <DialogHeader chat={chat} />

            {messagesToShow !== null ? (
              <Box
                ref={boxRef}
                onScroll={handleScroll}
                sx={{
                  minHeight: 'calc(100vh - 410px)',
                  maxHeight: 'calc(100vh - 410px)',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  '&::-webkit-scrollbar': {
                    width: '10px',
                  },
                  '&::-webkit-scrollbar-track': {
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  },

                  '&::-webkit-scrollbar-thumb': {
                    background: blueGrey[300],
                    borderRadius: '18px',

                    '&:hover': {
                      background: blueGrey[300],
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '10px',
                    left: '50%',
                    zIndex: 100,
                  }}
                >
                  {showScrollBtn && (
                    <ScrollButton handleScrollDown={handleScrollDown} />
                  )}
                </Box>

                {Object.keys(messagesToShow).length !== 0
                  ? Object.keys(messagesToShow).map((key) => (
                      <div key={key}>
                        <Typography
                          component="p"
                          sx={{
                            width: 'fit-content',
                            background: '#e8e8e0',
                            color: '#096f4d',
                            py: 0.8,
                            px: 3,
                            margin: '12px auto',
                            fontSize: '14px',
                            borderRadius: '18px',
                          }}
                        >
                          {key}
                        </Typography>

                        {messagesToShow[key].map(
                          (message: any, index: number) => {
                            const isOwnMessage =
                              message?.sender.id === chat?.page?.fbPageId;
                            return (
                              <Box
                                component="div"
                                py={1.5}
                                px={2}
                                display="flex"
                                alignItems="center"
                                justifyContent={
                                  isOwnMessage ? 'flex-end' : 'flex-start'
                                }
                                key={index}
                                ref={scrollRef}
                              >
                                {!isOwnMessage ? (
                                  <>
                                    {chat?.profilePic ? (
                                      <img
                                        className={styles.avatar}
                                        src={chat?.profilePic}
                                        alt=""
                                      />
                                    ) : (
                                      <AccountCircle
                                        sx={{
                                          width: '40px',
                                          height: '40px',
                                          marginRight: '8px',
                                        }}
                                      />
                                    )}
                                  </>
                                ) : null}

                                {messageContent(
                                  isOwnMessage,
                                  message.message,
                                  message.timestamp,
                                )}
                              </Box>
                            );
                          },
                        )}
                      </div>
                    ))
                  : null}
              </Box>
            ) : null}
          </Box>
        )}
      </Box>

      {isAgency ? null : (
        <SendMessageForm
          handleOnSubmit={handleSendMessage}
          handleOnChange={handleOnChange}
          message={message}
          loading={sendMsgloading}
          handleSendCalendlyUrl={handleSendCalendlyUrl}
          sendCalendlyUrlloading={sendCalendlyUrlloading}
          showEmojis={showEmojis}
          setShowEmojis={setShowEmojis}
          addEmoji={addEmoji}
          handleCannedResponsesModal={handleCannedResponsesModal}
          handleFileUpload={handleFileUpload}
          fileDetails={fileDetails}
          fileError={fileError}
          onRemoveAttachment={onRemoveAttachment}
        />
      )}

      <CannedResponsesModal
        open={openCannedResponsesModal}
        handleCannedResponsesModal={handleCannedResponsesModal}
        chat={chat}
        setMessage={setResponseMessage}
        message={message}
      />
    </Box>
  );
};
export default ChatDialog;
