import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { AdsAccountFormFields, Brand } from '../../types';
import styles from '../../assets/styles/components/Modal/CampaignModal.module.scss';
import { humanizeString } from '../../utils/stringModifier';
import FormHeader from './FormHeader';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';

interface GoogleAdsAccountFormProps {
  brand: Brand;
  handleSync: (value: string[], brand: Brand) => void;
  defaultFormValues: AdsAccountFormFields[];
  connectedAccountsIds: string[];
  setDefaultFormValues: Dispatch<SetStateAction<AdsAccountFormFields[]>>;
  onSelectAdsAccount: (
    value: AdsAccountFormFields[],
    brand: Brand,
    selectedId: string,
    reason: string,
    provider: string,
  ) => void;
  provider: string;
  googleAdAccounts: AdsAccountFormFields[];
}

const GoogleAdsAccountForm: React.FC<GoogleAdsAccountFormProps> = ({
  brand,
  handleSync,
  defaultFormValues,
  setDefaultFormValues,
  onSelectAdsAccount,
  provider,
  googleAdAccounts,
  connectedAccountsIds,
}) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.accounts}>
      <FormHeader title={`Connect ${humanizeString(provider)} Ad Accounts`} />

      <Grid
        container
        rowSpacing={2}
        sx={{
          maxHeight: '50vh',
          overflow: 'hidden',
        }}
      >
        <Grid item xs={12}>
          <Autocomplete
            id="googleCustomersIds"
            multiple
            disableClearable
            freeSolo
            options={[]}
            value={brand?.googleCustomerIds}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Google Customer ID"
                placeholder="Select Your Google Customer ID"
              />
            )}
            onChange={(e: any, value: any[]) => {
              handleSync(value, brand);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            id="googleAdAccount"
            multiple
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.accountId}>
                  {option.name}
                </li>
              );
            }}
            options={googleAdAccounts.sort(
              (a, b) => -b.name?.localeCompare(a.name),
            )}
            getOptionDisabled={(option) =>
              !!defaultFormValues.find(
                (element: any) =>
                  element.accountId === option.accountId && !element.deleted,
              )
            }
            value={defaultFormValues}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Ad Accounts"
                placeholder="Select Your Ads Accounts"
              />
            )}
            disableClearable
            onChange={async (e: any, value: any[], reason, detail) => {
              if (
                reason === 'removeOption' &&
                connectedAccountsIds.includes(detail.option.accountId)
              ) {
                dispatch(
                  toggleAlert({
                    toggle: true,
                    message: `Cannot remove ${detail.option.name} Ad Account. Kindly disconnect the connected ${detail.option.name}'s campaigns first.`,
                    type: 'error',
                  }),
                );

                return;
              }
              setDefaultFormValues(value);
              onSelectAdsAccount(
                value,
                brand,
                detail.option.accountId,
                reason,
                provider,
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            {defaultFormValues.map((adsAccount: AdsAccountFormFields) => {
              return adsAccount.deleted ? null : (
                <Grid item xs={12} sm={4} lg={3} key={adsAccount.accountId}>
                  <Card sx={{ height: '100%' }}>
                    <CardHeader
                      subheader={adsAccount.name}
                      subheaderTypographyProps={{
                        sx: {
                          fontWeight: 'bold',
                          color: 'black',
                          textAlign: 'center',
                        },
                      }}
                    />

                    <CardContent sx={{ paddingTop: 0 }}>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        component="div"
                      >
                        Account ID:
                        <Typography variant="body2" display="inline" ml={1}>
                          {adsAccount.accountId}
                        </Typography>
                      </Typography>

                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        component="div"
                      >
                        Name:
                        <Typography variant="body2" display="inline" ml={1}>
                          {adsAccount.name}
                        </Typography>
                      </Typography>

                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        component="div"
                      >
                        Currency:
                        <Typography variant="body2" display="inline" ml={1}>
                          {adsAccount.currency}
                        </Typography>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default GoogleAdsAccountForm;
