import { TreeItem, TreeView } from '@mui/lab';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { constantStringToHumanized } from '../../../utils/stringModifier';
import { blueGrey } from '@mui/material/colors';
import { TargetSearchKey } from '../../../types';

interface TargetBrowseFormProps {
  data: any;
  index?: number;
  type: string;
  onChange: (
    value: TargetSearchKey,
    type: string,
    flexibleIndex?: number,
  ) => void;
  flexibleIndex?: number;
  disableOptions: (
    option: any,
    type: string,
    browse?: boolean,
    flexibleIndex?: number,
  ) => boolean;
}

const TargetBrowseForm: React.FC<TargetBrowseFormProps> = ({
  data,
  index = 0,
  type,
  flexibleIndex,
  onChange,
  disableOptions,
}) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  const renderTree = (node: any, path?: string[]) => {
    return Object.entries(node).map(([key, value]) => {
      const currentPath = [...(path || []), key];

      if (Array.isArray(value)) {
        return (
          <TreeItem
            key={currentPath.join('.')}
            nodeId={currentPath.join('.')}
            label={key}
          >
            {value.map((item) => (
              <TreeItem
                key={item.id}
                nodeId={item.id?.toString()}
                label={`${item.name} (${constantStringToHumanized(
                  item.type || '',
                )})`}
                sx={{
                  color: disableOptions(item, type, true, flexibleIndex)
                    ? 'lightgrey'
                    : '',
                }}
                onClick={() =>
                  disableOptions(item, type, true, flexibleIndex)
                    ? null
                    : onChange(item, type, flexibleIndex)
                }
              />
            ))}
          </TreeItem>
        );
      }

      return (
        <TreeItem
          key={currentPath.join('.')}
          nodeId={currentPath.join('.')}
          label={key}
        >
          {renderTree(value, currentPath)}
        </TreeItem>
      );
    });
  };

  return (
    <TreeView
      aria-label="file system navigator"
      expanded={expanded}
      selected={selected}
      multiSelect
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{
        height: '150px',
        flexGrow: 1,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          // background: "#F1F0F0",
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: blueGrey[300],
          borderRadius: '18px',

          '&:hover': {
            background: blueGrey[300],
          },
        },
      }}
    >
      {renderTree(data)}
    </TreeView>
  );
};

export default TargetBrowseForm;
