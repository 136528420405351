import React from 'react';
import styles from '../../assets/styles/components/Modal/ModalHeader.module.scss';

interface ModalHeaderProps {
  title: string;
  isCenter?: boolean;
  noBottomMargin?: boolean;
  maxWidth500?: boolean;
  isLarge?: boolean;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  isCenter = false,
  noBottomMargin = false,
  maxWidth500 = false,
  isLarge = false,
}) => {
  const styling = () => {
    return `${styles.header} ${isCenter ? styles['-center'] : ''} ${
      noBottomMargin ? styles['-no-bottom-margin'] : ''
    } ${maxWidth500 ? styles['-max-width-500'] : ''} ${
      isLarge ? styles['-large'] : ''
    }`;
  };
  return <h3 className={styling()}>{title}</h3>;
};

export default ModalHeader;
