import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Brand,
  ChangeEventType,
  FBCarouselTemplate,
  FbAssetFeedSpec,
  FbAssetFeedSpecMedia,
  FbCampaignForm,
} from '../../../types';
import { PrimaryButton } from '../../Buttons';
import LeadGen from './LeadGen';
import PopupModal from '../../Modal';
import AdMediaForm from './AdMediaForm';
import AdCopySuggestionForm from './AdCopySuggestionForm';
import {
  constantStringToHumanized,
  humanizeString,
} from '../../../utils/stringModifier';
import { buildCallToActions } from '../../../utils/helpers/facebookAdsHelper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { GiArtificialIntelligence } from 'react-icons/gi';
import { HtmlTooltip } from '../../Tooltip';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { AdsPreviewSquare } from '../Diy/AdsPreview';
import {
  AdsTemplate,
  DiySettings,
  MediaVariant,
  TemplateError,
} from '../../../types/IDiy';
import { CUSTOM_FIELDS, CUSTOM_FIELD_REGEX } from '../../../utils';
import { FaRegImage, FaVideo } from 'react-icons/fa6';
import { Objectives } from '../../../utils/constants/facebookAds';
import { fetchSingleFbAdAccountVideo } from '../../../services/ads';

// Creative best practices for text in ads
// https://www.facebook.com/business/help/223409425500940?id=271710926837064
const HEADLINE_CHAR_LIMIT: number = 40;
const DESCRIPTION_CHAR_LIMIT: number = 25;

interface CreativeFormProps {
  brand: Brand;
  accountId: string;
  isTraffic: boolean;
  isLead: boolean;
  isMessenger: boolean;
  objective: string;
  conversionLocation: string;
  format: string;
  setFormat: Dispatch<SetStateAction<string>>;
  carouselData?: FBCarouselTemplate[];
  setCarouselData?: Dispatch<SetStateAction<FBCarouselTemplate[]>>;
  mediaType: string;
  setMediaType: Dispatch<SetStateAction<string>>;
  assetFeedSpec: FbAssetFeedSpec;
  setAssetFeedSpec: Dispatch<SetStateAction<FbAssetFeedSpec>>;
  template: AdsTemplate;
  setTemplate: Dispatch<SetStateAction<AdsTemplate>>;
  campaignTemplate: FbCampaignForm;
  setCampaignTemplate: Dispatch<SetStateAction<FbCampaignForm>>;
  diySettings: DiySettings;
  error: TemplateError;
  isEdit: boolean;
  isEmployment: boolean;
}

const CreativeForm: React.FC<CreativeFormProps> = ({
  brand,
  accountId,
  isTraffic,
  isLead,
  isMessenger,
  objective,
  conversionLocation,
  format,
  setFormat,
  carouselData = [],
  setCarouselData,
  mediaType,
  setMediaType,
  assetFeedSpec,
  setAssetFeedSpec,
  template,
  setTemplate,
  campaignTemplate,
  setCampaignTemplate,
  diySettings,
  error,
  isEdit,
  isEmployment,
}) => {
  const inputRef = useRef();

  const [openMediaForm, setOpenMediaForm] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [selectedVideos, setSelectedVideos] = useState<string[]>([]);
  const [openSuggestions, setOpenSuggestions] = useState<boolean>(false);
  const [editRatio, setEditRatio] = useState<string>(null);
  const [editMedia, setEditMedia] = useState<boolean>(false);
  const [bodyCursorPosition, setBodyCursorPosition] = useState<number>(0);
  const [focusSelection, setFocusSelection] = useState<any>(null);
  const [squareVideoUrl, setSquareVideoUrl] = useState<string>(null);
  const [verticalVideoUrl, setVerticalVideoUrl] = useState<string>(null);
  const [newVideoIds, setNewVideoIds] = useState<string[]>([]);

  useEffect(() => {
    if (focusSelection) {
      const { start, end } = focusSelection;

      (inputRef.current as any).focus();
      (inputRef.current as any).setSelectionRange(start, end);
    }
  }, [focusSelection]);

  useEffect(() => {
    if (isEdit && mediaType === 'video') {
      getAdVideo();
    }
  }, [isEdit, assetFeedSpec?.videos]);

  const handleOnChange = (e: ChangeEventType) => {
    let temp: FbAssetFeedSpec = { ...assetFeedSpec };
    switch (e.target.name) {
      case 'body':
        temp.bodies = [{ text: e.target.value }];
        break;
      case 'title':
        temp.titles = [{ text: e.target.value }];
        break;
      case 'description':
        temp.descriptions = [{ text: e.target.value }];
        break;
      case 'link':
        temp.link_urls = [
          { website_url: e.target.value, display_url: e.target.value },
        ];

        temp.call_to_actions = [
          {
            ...temp.call_to_actions[0],
            value: { link: e.target.value },
          },
        ];
        break;
      default:
        break;
    }

    setAssetFeedSpec(temp);
  };

  const getAdVideo = async () => {
    try {
      const data = assetFeedSpec?.videos?.map((video: FbAssetFeedSpecMedia) => {
        return {
          id: video?.video_id,
          ratio: video?.adlabels[0]?.name.split('_')[1],
        };
      });

      let temp: AdsTemplate = { ...template };

      data?.forEach(async (v: any) => {
        const response = await fetchSingleFbAdAccountVideo(brand?._id, v.id);

        if (v.ratio === 'square' && data.length === 1) {
          setSquareVideoUrl(response.data.source);
          setVerticalVideoUrl(response.data.source);
        } else if (v.ratio === 'square' && data.length > 1) {
          setSquareVideoUrl(response.data.source);
        } else {
          setVerticalVideoUrl(response.data.source);
        }

        setTemplate(temp);
      });
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleOnSelectCTA = (value: string) => {
    let temp: FbAssetFeedSpec = { ...assetFeedSpec };
    temp.call_to_action_types = [value];
    temp.call_to_actions[0] = {
      ...temp.call_to_actions[0],
      type: value,
      adlabels: [{ name: 'cta_1' }],
    };

    setAssetFeedSpec(temp);
  };

  const handleOpenMediaForm = (
    mediaType?: string,
    edit?: boolean,
    ratio?: string,
  ) => {
    switch (mediaType) {
      case 'image':
      case 'video':
        setMediaType(mediaType);
        setFormat('media');
        setEditMedia(edit);
        setEditRatio(ratio);
        setTemplate({ ...template, media: mediaType });

        break;
      default:
        setMediaType('image');
        setFormat('carousel');
        break;
    }

    setOpenMediaForm((prev) => !prev);
  };

  const handleCloseMediaForm = () => {
    setOpenMediaForm((prev) => !prev);
  };

  const handleOnChangeCarouselData = (e: ChangeEventType, index: number) => {
    const name = e.target.name;
    let carouselTemp: FBCarouselTemplate[] = [...carouselData];
    if (name === 'link') {
      carouselTemp[index].link = e.target.value;
      carouselTemp[index].call_to_action.value.link = e.target.value;

      setCarouselData(carouselTemp);
    } else {
      carouselTemp[index][name] = e.target.value;

      setCarouselData(carouselTemp);
    }
  };

  const handleOpenSuggestions = () => {
    setOpenSuggestions(true);
  };

  const handleCloseSuggestions = () => {
    setOpenSuggestions(false);
  };

  const handleAddCustomFields = (field: string) => {
    let temp: FbAssetFeedSpec = { ...assetFeedSpec };
    let customField: string = `{{${field}}}`;

    const matches = temp.bodies[0].text.match(CUSTOM_FIELD_REGEX);

    if (matches?.length > 0 && field === 'custom') {
      const customs = matches.filter((match: string) => {
        return match.includes('{{custom');
      });

      if (customs?.length > 0) {
        customField = `{{custom${customs.length + 1}}}`;
      } else {
        customField = `{{custom1}}`;
      }
    }

    const textBeforeCursor = temp.bodies[0].text.substring(
      0,
      bodyCursorPosition,
    );
    const textAfterCursor = temp.bodies[0].text.substring(
      bodyCursorPosition,
      temp.bodies[0].text.length,
    );
    temp.bodies = [
      { text: `${textBeforeCursor} ${customField} ${textAfterCursor}` },
    ];

    const focusValue = `${textBeforeCursor} ${customField}`.length;
    const afterFocusValue =
      `${textBeforeCursor} ${customField} ${textAfterCursor}`.length -
      textAfterCursor.length;

    setFocusSelection({ start: focusValue, end: afterFocusValue });
    setAssetFeedSpec(temp);
  };

  const handleSelectLeadAdTool = (e: ChangeEventType) => {
    let campaignTemp: FbCampaignForm = { ...campaignTemplate };
    let temp: AdsTemplate = { ...template, [e.target.name]: e.target.value };
    switch (e.target.value) {
      case 'conversion_api':
        campaignTemp = {
          ...campaignTemp,
          objective: Objectives.OUTCOME_TRAFFIC,
        };

        temp = { ...temp, objective: 'Get More Traffic' };
        break;
      default:
        campaignTemp = {
          ...campaignTemp,
          objective: Objectives.OUTCOME_LEADS,
        };

        temp = { ...temp, objective: 'Get More Leads' };
        break;
    }

    setTemplate(temp);
    setCampaignTemplate(campaignTemp);
  };

  const buildVideoPreview = (template: AdsTemplate, ratio: string) => {
    if (isEdit && ratio === 'square') {
      return squareVideoUrl;
    }

    if (isEdit && ratio === 'vertical') {
      return verticalVideoUrl;
    }

    return template?.videos[ratio as keyof MediaVariant];
  };

  const renderCarouselForm = (
    carouselForm: FBCarouselTemplate,
    index: number,
  ) => {
    return (
      <>
        <Grid item xs={12}>
          <img src={carouselForm.picture} alt="" width="150px" />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" fontWeight="bold">{`Carousel #${
            index + 1
          }`}</Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            size="small"
            value={carouselForm?.name}
            name="name"
            label="Headline"
            onChange={(e: ChangeEventType) => {
              handleOnChangeCarouselData(e, index);
            }}
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            size="small"
            value={carouselForm?.description}
            name="description"
            label="Description"
            onChange={(e: ChangeEventType) => {
              handleOnChangeCarouselData(e, index);
            }}
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        {isTraffic ? (
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              size="small"
              value={carouselForm?.link}
              name="link"
              label="Website URL"
              onChange={(e: ChangeEventType) => {
                handleOnChangeCarouselData(e, index);
              }}
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        ) : null}
      </>
    );
  };

  return (
    <Grid container spacing={1} mb={2}>
      <Grid item xs={12} sm={8}>
        <Grid container spacing={1} mb={2}>
          {error?.mediaTypeError || error?.imagesError || error?.videosError ? (
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ color: 'red' }}>
                {error?.mediaTypeError ||
                  error?.imagesError ||
                  error?.videosError}
              </Typography>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {['image', 'video'].map((media: string, index: number) => {
                const description =
                  media === 'image'
                    ? 'Single Image'
                    : media === 'video'
                    ? 'Single Video'
                    : '2 or more scrollable images or videos';
                const logo =
                  media === 'image' ? (
                    <FaRegImage size={60} color="white" />
                  ) : (
                    <FaVideo size={60} color="white" />
                  );
                const selected = mediaType === media;

                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    xl={3}
                    key={`format-${index + 1}`}
                    sx={{
                      position: 'relative',
                    }}
                  >
                    {selected ? (
                      <Box
                        component="div"
                        sx={{
                          position: 'absolute',
                          top: '8px',
                          zIndex: 1,
                          right: '-10px',
                          float: 'right',
                          margin: '1px',
                        }}
                      >
                        <CheckCircleIcon
                          sx={{
                            color: '#84D1B7',
                            bgcolor: 'InfoBackground',
                            borderRadius: '50%',
                          }}
                        />
                      </Box>
                    ) : null}

                    <Card
                      sx={{
                        backgroundColor: '#096f4d',
                        height: '100%',
                        cursor: 'pointer',
                        borderRadius: '8px',
                        border: selected ? 'solid' : '',
                        borderColor: selected ? '#84D1B7' : '',
                      }}
                      onClick={() => {
                        setSelectedImages([]);
                        setSelectedVideos([]);
                        handleOpenMediaForm(media, false, null);
                      }}
                    >
                      <CardHeader
                        sx={{
                          textAlign: 'center',
                          '.css-1ssile9-MuiCardHeader-avatar': {
                            height: '100%',
                          },
                        }}
                        avatar={<Box>{logo}</Box>}
                        title={
                          <Typography
                            variant="body2"
                            sx={{ color: '#FFF', fontWeight: 'bold' }}
                            component="div"
                          >
                            {humanizeString(media)}
                          </Typography>
                        }
                        subheader={
                          <Typography
                            variant="caption"
                            sx={{ color: '#FFF' }}
                            component="div"
                            mt={2}
                          >
                            {description}
                          </Typography>
                        }
                      />
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          width: '100% !important',
          padding: '50px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {format === 'media' ? (
              <Grid container spacing={2}>
                {mediaType === 'image'
                  ? template?.images
                    ? ['square', 'vertical'].map((ratio: string) => {
                        return (
                          <Grid item xs={12}>
                            <Card>
                              <CardContent>
                                <Box
                                  component="div"
                                  sx={{
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Box
                                    component="div"
                                    sx={{
                                      flexDirection: 'row',
                                      width: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Box
                                      component="img"
                                      src={
                                        template?.images[
                                          ratio as keyof MediaVariant
                                        ]
                                      }
                                      alt=""
                                      sx={{
                                        width: '50px',
                                        marginRight: '20px',
                                      }}
                                    />

                                    <Typography variant="body1">
                                      {humanizeString(ratio)}
                                    </Typography>
                                  </Box>

                                  <Box
                                    component="div"
                                    sx={{
                                      justifyContent: 'flex-end',
                                    }}
                                  >
                                    <PrimaryButton
                                      title="Edit Media"
                                      type="button"
                                      width="120px"
                                      handleOnClick={() =>
                                        handleOpenMediaForm(
                                          mediaType,
                                          true,
                                          ratio,
                                        )
                                      }
                                    />
                                  </Box>
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      })
                    : null
                  : template?.videos
                  ? ['square', 'vertical'].map((ratio: string) => {
                      return (
                        <Grid item xs={12}>
                          <Card>
                            <CardContent sx={{ paddingBottom: 0 }}>
                              <Box
                                component="div"
                                sx={{
                                  justifyContent: 'space-between',
                                  display: 'flex',
                                  width: '100%',
                                  alignItems: 'center',
                                }}
                              >
                                <Box
                                  component="div"
                                  sx={{
                                    flexDirection: 'row',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Box
                                    component="video"
                                    src={buildVideoPreview(template, ratio)}
                                    sx={{
                                      width: '100px',
                                      marginRight: '20px',
                                      objectFit: 'contain',
                                    }}
                                  />

                                  <Typography variant="body1">
                                    {humanizeString(ratio)}
                                  </Typography>
                                </Box>

                                <Box
                                  component="div"
                                  sx={{
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <PrimaryButton
                                    title="Edit Media"
                                    type="button"
                                    width="120px"
                                    handleOnClick={() =>
                                      handleOpenMediaForm(
                                        mediaType,
                                        true,
                                        ratio,
                                      )
                                    }
                                  />
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })
                  : null}

                {brand?.__type === 'brand' && brand?.allowLocations ? (
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="allLeads">
                        Allow location to edit media?
                      </FormLabel>

                      <RadioGroup
                        row
                        aria-labelledby="mediaEditable"
                        name="mediaEditable"
                        value={template?.mediaEditable ? 'yes' : 'no'}
                        onChange={(e: ChangeEventType) => {
                          setTemplate({
                            ...template,
                            mediaEditable: e.target.value === 'yes',
                          });
                        }}
                        defaultValue="No"
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" />}
                          label="Yes"
                        />

                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                ) : null}

                <Grid
                  item
                  xs={12}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: 0,
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="body1" fontWeight="bold" component="div">
                    Ad Copy
                  </Typography>

                  {!openSuggestions ? (
                    <Box
                      component="div"
                      sx={{ alignItems: 'center', margin: 0, display: 'flex' }}
                    >
                      <PrimaryButton
                        title="AI Assist"
                        marginRight10
                        type="button"
                        handleOnClick={handleOpenSuggestions}
                        startIcon={<GiArtificialIntelligence width={20} />}
                        endIcon={
                          <HtmlTooltip
                            title={
                              <Fragment>
                                <Box
                                  component="div"
                                  sx={{
                                    fontWeight: 'bold',
                                  }}
                                >
                                  AI Assist
                                </Box>

                                <div>
                                  Generating ad copy with AI Assist is like
                                  having a smart sidekick for your marketing.
                                  It's all about using AI-powered tools that
                                  help you create awesome ad copy effortlessly.
                                  These tools analyze data, suggest keywords,
                                  and even personalize content based on your
                                  Facebook page and website. With AI Assist,
                                  you'll save time, write better ads, and boost
                                  your marketing success by getting handy
                                  suggestions and automating tasks. It's like
                                  having a helpful friend to make your ad copy
                                  shine!
                                </div>
                              </Fragment>
                            }
                          >
                            <InfoTwoToneIcon sx={{ fontSize: '18px' }} />
                          </HtmlTooltip>
                        }
                      />
                    </Box>
                  ) : null}
                </Grid>

                {openSuggestions ? (
                  <Grid item xs={12}>
                    <AdCopySuggestionForm
                      setFormValues={setAssetFeedSpec}
                      formValues={assetFeedSpec}
                      onClose={handleCloseSuggestions}
                      pageId={diySettings?.facebookPage?.fbPageId}
                      brand={brand}
                    />
                  </Grid>
                ) : null}

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {brand?.__type === 'brand' && brand?.allowLocations ? (
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={1}>
                          {CUSTOM_FIELDS.map((fields: any) => {
                            return (
                              <Chip
                                key={fields}
                                label={
                                  <Fragment>
                                    <Typography
                                      variant="caption"
                                      fontWeight="bold"
                                    >
                                      {fields}
                                    </Typography>
                                  </Fragment>
                                }
                                onClick={() => handleAddCustomFields(fields)}
                                sx={{ cursor: 'pointer' }}
                              />
                            );
                          })}
                        </Stack>
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <TextField
                        inputRef={inputRef}
                        fullWidth
                        required
                        size="small"
                        value={assetFeedSpec?.bodies[0]?.text}
                        name="body"
                        onChange={handleOnChange}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        label="Body"
                        minRows={4}
                        maxRows={100}
                        onClick={(e: any) =>
                          setBodyCursorPosition(e.target.selectionStart)
                        }
                        onKeyDown={(e: any) =>
                          setBodyCursorPosition(e.target.selectionStart)
                        }
                        error={error?.bodyError ? true : false}
                        helperText={error?.bodyError ? error?.bodyError : ''}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    value={assetFeedSpec?.titles[0]?.text}
                    name="title"
                    label="Headline"
                    onChange={handleOnChange}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="caption">
                            {`${
                              assetFeedSpec?.titles[0]?.text?.length || 0
                            }/${HEADLINE_CHAR_LIMIT}`}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: HEADLINE_CHAR_LIMIT }}
                    error={error?.headlineError ? true : false}
                    helperText={
                      error?.headlineError ? error?.headlineError : ''
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    value={assetFeedSpec?.descriptions[0]?.text}
                    name="description"
                    label="Description"
                    onChange={handleOnChange}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="caption">
                            {`${
                              assetFeedSpec?.descriptions[0]?.text?.length || 0
                            }/${DESCRIPTION_CHAR_LIMIT}`}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: DESCRIPTION_CHAR_LIMIT }}
                  />
                </Grid>

                {!isLead && !template?.leadAdTool ? (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      value={assetFeedSpec?.link_urls[0]?.website_url}
                      name="link"
                      label="Website URL"
                      onChange={handleOnChange}
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      error={error?.websiteUrlError ? true : false}
                      helperText={
                        error?.websiteUrlError ? error?.websiteUrlError : ''
                      }
                    />
                  </Grid>
                ) : null}

                {brand?.__type === 'brand' && brand?.allowLocations ? (
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="allLeads">
                        Dynamically change Website URL per location?
                      </FormLabel>

                      <RadioGroup
                        row
                        aria-labelledby="urlLocalizable"
                        name="urlLocalizable"
                        value={template?.urlLocalizable ? 'yes' : 'no'}
                        onChange={(e: ChangeEventType) => {
                          setTemplate({
                            ...template,
                            urlLocalizable: e.target.value === 'yes',
                          });
                        }}
                        defaultValue="yes"
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" />}
                          label="Yes"
                        />

                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                ) : null}

                <Grid item xs={12}>
                  {buildCallToActions(objective, conversionLocation).length >
                  0 ? (
                    <Autocomplete
                      id="callToAction"
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option}>
                            {constantStringToHumanized(option)}
                          </li>
                        );
                      }}
                      options={buildCallToActions(
                        objective,
                        conversionLocation,
                      )?.sort((a, b) => -b.localeCompare(a))}
                      value={assetFeedSpec?.call_to_action_types[0]}
                      getOptionLabel={(option) =>
                        constantStringToHumanized(option)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Call-to-Action"
                          fullWidth
                          size="small"
                          placeholder="Select a call to action"
                          required
                          InputLabelProps={{ shrink: true }}
                          //error={errors?.salesperson ? true : false}
                          //helperText={errors?.salesperson ? errors?.salesperson : ''}
                        />
                      )}
                      disableClearable
                      onChange={(e: any, value: any) =>
                        handleOnSelectCTA(value)
                      }
                    />
                  ) : null}
                </Grid>

                {isLead || template?.leadAdTool ? (
                  <>
                    <Grid item xs={12}>
                      <FormControl>
                        <Typography variant="body2">
                          What tools you want to use to gather leads?
                        </Typography>

                        <RadioGroup
                          row
                          aria-labelledby="leadAdTool"
                          name="leadAdTool"
                          value={template?.leadAdTool}
                          onChange={handleSelectLeadAdTool}
                        >
                          <FormControlLabel
                            value={'instant_form'}
                            control={<Radio size="small" />}
                            label="Instant Form"
                          />

                          <FormControlLabel
                            value={'conversion_api'}
                            control={<Radio size="small" />}
                            label="Website"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    {template?.leadAdTool === 'instant_form' ? (
                      <Grid item xs={12}>
                        <LeadGen
                          brand={brand}
                          accountId={accountId}
                          assetFeedSpec={assetFeedSpec}
                          setAssetFeedSpec={setAssetFeedSpec}
                          error={error}
                        />
                      </Grid>
                    ) : null}

                    {template?.leadAdTool === 'conversion_api' ? (
                      <>
                        {/**
                         * TODO: Re-enable if Conversion API was implemented
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            size="small"
                            value={''}
                            name="converstionApiId"
                            label="Conversion API ID"
                            onChange={handleOnChange}
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid> */}

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required
                            size="small"
                            value={assetFeedSpec?.link_urls[0]?.website_url}
                            name="link"
                            label="Website URL"
                            onChange={handleOnChange}
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            error={error?.websiteUrlError ? true : false}
                            helperText={
                              error?.websiteUrlError
                                ? error?.websiteUrlError
                                : ''
                            }
                          />
                        </Grid>
                      </>
                    ) : null}
                  </>
                ) : null}
              </Grid>
            ) : null}

            {format === 'carousel' ? (
              <>
                {carouselData?.map(
                  (carousel: FBCarouselTemplate, index: number) => {
                    return renderCarouselForm(carousel, index);
                  },
                )}
              </>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  component="div"
                  sx={{ marginBottom: '20px', textAlign: 'center' }}
                >
                  Ad Preview
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  justifyContent: 'center',
                  width: '100%',
                  display: 'flex',
                }}
              >
                <div>
                  <AdsPreviewSquare
                    page={diySettings?.facebookPage}
                    body={assetFeedSpec?.bodies[0]?.text}
                    isImage={mediaType === 'image'}
                    imageUrl={template?.images?.square}
                    websiteUrl={assetFeedSpec?.link_urls[0]?.website_url}
                    headline={assetFeedSpec?.titles[0]?.text}
                    description={assetFeedSpec?.descriptions[0]?.text}
                    cta={assetFeedSpec?.call_to_action_types[0]}
                    videoUrl={
                      isEdit ? squareVideoUrl : template?.videos?.square
                    }
                    objective={campaignTemplate?.objective}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <PopupModal open={openMediaForm} handleClose={handleCloseMediaForm}>
        <AdMediaForm
          open={openMediaForm}
          brand={brand}
          accountId={accountId}
          format={format}
          mediaType={mediaType}
          setAssetFeedSpec={setAssetFeedSpec}
          onClose={handleCloseMediaForm}
          template={template}
          setTemplate={setTemplate}
          assetFeedSpec={assetFeedSpec}
          edit={editMedia}
          ratio={editRatio}
          diySettings={diySettings}
          objective={campaignTemplate?.objective}
          setNewVideoIds={setNewVideoIds}
          newVideoIds={newVideoIds}
          isEmployment={isEmployment}
        />
      </PopupModal>
    </Grid>
  );
};

export default CreativeForm;
