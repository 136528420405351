import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import {
  Brand,
  ChangeEventType,
  StripeCustomerBalanceCredit,
  StripeCustomerForm,
} from '../../../types';
import { MuiTelInput } from 'mui-tel-input';
import CountrySelectMenu from '../../Select/CountrySelectMenu';
import { PrimaryButton } from '../../Buttons';
import AddCardIcon from '@mui/icons-material/AddCard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  buildCardBrand,
  renderCCIcon,
} from '../../Cards/Billing/PaymentMethodHelpers';
import { IconContext } from 'react-icons';
import { DiySettings } from '../../../types/IDiy';
import { toCurrency } from '../../../utils/numberFormatter';
import PoweredByStripe from '../../../assets/images/powered-by-stripe.svg';

interface DiyBillingSummaryProps {
  brand: Brand;
  onChange: (e: ChangeEventType) => void;
  formValues: StripeCustomerForm;
  setFormValues: Dispatch<SetStateAction<StripeCustomerForm>>;
  onOpenTerms: () => void;
  paymentMethods: any[];
  paymentMethodId: string;
  setPaymentMethodId: Dispatch<SetStateAction<string>>;
  loading: boolean;
  onAddPaymentMethod: () => void;
  diySettings: DiySettings;
  isDecrease: boolean;
  changeType: string;
  totalCharge: number;
  creditBalance: StripeCustomerBalanceCredit;
}

const DiyBillingSummary: React.FC<DiyBillingSummaryProps> = ({
  brand,
  onChange,
  formValues,
  setFormValues,
  onOpenTerms,
  paymentMethods,
  paymentMethodId,
  setPaymentMethodId,
  loading,
  onAddPaymentMethod,
  diySettings,
  isDecrease,
  changeType,
  totalCharge,
  creditBalance,
}) => {
  const [confirmPayment, setConfirmPayment] = useState<boolean>(false);

  const buildChargingPlatform = () => {
    let platform: string = '';

    switch (changeType) {
      case 'scheduled':
        platform = 'Charged on ';
        break;
      default:
        if (isDecrease) {
          if (diySettings?.allowSetupOwnAccount) {
            platform = 'Decreased by Facebook';
          } else {
            platform = 'Credit will apply to the next invoice';
          }
        } else {
          if (diySettings?.allowSetupOwnAccount) {
            platform = 'Charged by Facebook';
          } else {
            platform = 'Charged Immediately';
          }
        }

        break;
    }

    return platform;
  };

  const calculatedTotalAmount = () => {
    let budget: number = 0;
    if (diySettings?.allowSetupOwnAccount) {
      budget = totalCharge * (brand?.budgetPercentageFee / 100);
    } else {
      budget = totalCharge;
    }

    if (isDecrease) {
      budget = Math.abs(budget);
    }

    return budget;
  };

  const calculatedAppliedBalance = () => {
    let appliedBalance: number = 0;
    if (creditBalance?.ending_balance < 0) {
      if (
        calculatedTotalAmount() > Math.abs(creditBalance?.ending_balance / 100)
      ) {
        appliedBalance = Math.abs(creditBalance?.ending_balance / 100);
      } else {
        return calculatedTotalAmount();
      }
    }

    return appliedBalance;
  };

  const buildPaymentAgreementLabel = () => {
    let amount: number = 0;
    let chargeText: string = '';

    if (calculatedAppliedBalance() > 0) {
      amount = calculatedTotalAmount() - calculatedAppliedBalance();
    } else {
      amount = calculatedTotalAmount();
    }

    if (amount === 0) {
      chargeText = `use my account credit of ${toCurrency(
        'USD',
        calculatedAppliedBalance(),
      )}`;
    } else {
      chargeText = `be charged ${toCurrency('USD', amount)} immediately`;
    }

    return `I confirm that I will ${chargeText} and I authorize this payment. And I authorize the budget ${
      isDecrease ? 'decrease' : 'increase'
    } of the ad campaign on Facebook.`;
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ justifyContent: 'center', width: '100%' }}
      >
        {!brand?.stripe?.customerId ? (
          <Card sx={{ width: '100%' }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: '#096F4D' }}
                  >
                    Enter your credit card information
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: '#096F4D' }}
                  >
                    Customer Details
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    type="text"
                    id="name"
                    name="name"
                    label="Full Name"
                    onChange={onChange}
                    value={formValues?.name}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    type="email"
                    id="name"
                    name="email"
                    label="Email"
                    onChange={onChange}
                    value={formValues?.email}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MuiTelInput
                    fullWidth
                    required
                    variant="standard"
                    id="phone"
                    name="phone"
                    label="Phone Number"
                    onChange={(newValue: any) =>
                      setFormValues({ ...formValues, phone: newValue })
                    }
                    value={formValues?.phone}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    onlyCountries={['US']}
                    defaultCountry="US"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: '#096F4D' }}
                  >
                    Address Details
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    id="line1"
                    name="line1"
                    label="Company Name, Street, PO Box"
                    onChange={onChange}
                    value={formValues?.address?.line1}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    fullWidth
                    type="text"
                    id="line2"
                    name="line2"
                    label="Apartment, Suite, Unit, Building"
                    onChange={onChange}
                    value={formValues?.address?.line2}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="standard"
                    required
                    type="text"
                    id="city"
                    name="city"
                    label="City"
                    onChange={onChange}
                    value={formValues?.address?.city}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    type="text"
                    id="state"
                    name="state"
                    label="State"
                    onChange={onChange}
                    value={formValues?.address?.state}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CountrySelectMenu
                    value={{
                      code: 'US',
                      label: 'United States',
                      phone: '1',
                      suggested: true,
                    }}
                    disabled
                    shrink
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    type="text"
                    id="postalCode"
                    name="postal_code"
                    label="Postal Code"
                    onChange={onChange}
                    value={formValues?.address?.postal_code}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    justifyContent: 'center',
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <PrimaryButton
                    title="Add Credit Card"
                    type="button"
                    startIcon={<AddCardIcon />}
                    handleOnClick={onOpenTerms}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ) : null}

        {brand?.stripe?.customerId ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{ fontWeight: 'bold', color: '#096F4D' }}
              >
                Select the payment method
              </Typography>
            </Grid>
            {paymentMethods.map((payment: any, index: number) => {
              const selected = payment.id === paymentMethodId;

              return (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={`payment-card-${index}`}
                    sx={{
                      position: 'relative',
                    }}
                  >
                    {selected ? (
                      <Box
                        component="div"
                        sx={{
                          position: 'absolute',
                          top: '6px',
                          zIndex: 1,
                          right: '-12px',
                          float: 'right',
                          margin: '1px',
                        }}
                      >
                        <CheckCircleIcon
                          sx={{
                            color: '#096f4d',
                            bgcolor: 'InfoBackground',
                            borderRadius: '50%',
                          }}
                        />
                      </Box>
                    ) : null}

                    <Card
                      sx={{
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                        border: selected ? 'solid' : '',
                        borderColor: selected ? '#096f4d' : '',
                        borderWidth: 'medium',
                        zIndex: 0,
                      }}
                      onClick={() => setPaymentMethodId(payment.id)}
                    >
                      <CardHeader
                        avatar={
                          <IconContext.Provider value={{ size: '40px' }}>
                            {renderCCIcon(payment.card.brand)}
                          </IconContext.Provider>
                        }
                        title={`${buildCardBrand(payment.card)} ●●●● ${
                          payment.card.last4
                        }`}
                        subheader={`Expires ${payment.card.exp_month}/${payment.card.exp_year}`}
                        titleTypographyProps={{ fontWeight: 'bold' }}
                      />
                    </Card>
                  </Grid>
                </>
              );
            })}

            <Grid
              item
              xs={12}
              sx={{
                justifyContent: 'center',
                width: '100%',
                display: 'flex',
              }}
            >
              <PrimaryButton
                title="Add Payment Method"
                type="button"
                handleOnClick={onAddPaymentMethod}
                loading={loading}
                startIcon={<AddCardIcon />}
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      <Grid item xs={12} sm={6}>
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {`Media Budget ${!isDecrease ? 'Increase' : 'Decrease'}`}
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                sx={{ justifyContent: 'center', display: 'flex' }}
              >
                {toCurrency(
                  'USD',
                  isDecrease
                    ? Math.abs(
                        totalCharge -
                          totalCharge * (brand?.budgetPercentageFee / 100),
                      )
                    : totalCharge -
                        totalCharge * (brand?.budgetPercentageFee / 100),
                )}
              </Grid>

              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight:
                      buildChargingPlatform() === 'Charged Immediately'
                        ? 'bold'
                        : '',
                    color:
                      buildChargingPlatform() === 'Charged Immediately'
                        ? 'red'
                        : '',
                  }}
                >
                  {`(${buildChargingPlatform()})`}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {`Subscription Fee ${!isDecrease ? 'Increase' : 'Decrease'}`}
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                sx={{ justifyContent: 'center', display: 'flex' }}
              >
                {toCurrency(
                  'USD',
                  isDecrease
                    ? Math.abs(totalCharge * (brand?.budgetPercentageFee / 100))
                    : totalCharge * (brand?.budgetPercentageFee / 100),
                )}
              </Grid>

              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: isDecrease ? '' : 'bold',
                    color: isDecrease ? '' : 'red',
                  }}
                >
                  {isDecrease
                    ? '(Credit will apply to the next invoice)'
                    : '(Charged Immediately)'}
                </Typography>
              </Grid>

              {!isDecrease && creditBalance?.ending_balance < 0 ? (
                <>
                  <Grid item xs={4}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      Account Credit
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ justifyContent: 'center', display: 'flex' }}
                  >
                    <Typography variant="body1">
                      {toCurrency('USD', calculatedAppliedBalance())}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} />
                </>
              ) : null}

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {`Total${isDecrease ? ' Decrease' : ''}`}
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                sx={{ justifyContent: 'center', display: 'flex' }}
              >
                <Typography variant="body1">
                  {toCurrency('USD', Math.abs(totalCharge))}
                </Typography>
              </Grid>

              <Grid item xs={4} />

              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <Box component="img" src={PoweredByStripe} alt="" width={130} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {!isDecrease ? (
          <FormControlLabel
            control={<Checkbox size="small" />}
            label={
              <Fragment>
                <Typography
                  variant="body2"
                  sx={{ color: 'red', fontWeight: 600 }}
                >
                  {buildPaymentAgreementLabel()}
                </Typography>
              </Fragment>
            }
            value={confirmPayment}
            onChange={(e: any) => {
              setConfirmPayment(e.target.checked);
            }}
            sx={{ mt: 2 }}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default DiyBillingSummary;
