import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DeleteModal } from '../../components/Modal';
import BrandListTable from '../../components/Tables/BrandList/BrandListTable';
import { Brand, ChangeEventType } from '../../types';
import {
  deleteBrand,
  getBrands,
  getBrandsAssociatedWithAgency,
  getBrandsAssociatedWithManager,
  getBrandsAssociatedWithSalesperson,
} from '../../services/brand';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context';
import {
  ADMIN,
  AGENCY,
  BRAND_MANAGER,
  IS_DIY_ADZ,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import { PrimaryButton } from '../../components/Buttons';
import styles from '../../assets/styles/pages/Brands.module.scss';
import PageHeader from '../../components/PageHeader';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';

const Brands: React.FC = () => {
  const dispatch = useDispatch();
  const { state } = useContext(AuthContext);
  const mountedRef = useRef(true);
  const [searchParams] = useSearchParams();
  const agencyId: string = searchParams.get('agencyId');
  const agencyName: string = searchParams.get('agency');
  const [rowData, setRowData] = useState<number>(0);
  const isAdmin = state.role === ADMIN;
  const isSalesperson = state.role === SALESPERSON;
  const isAgency = state.role === AGENCY;
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isBrandManager = state.role === BRAND_MANAGER;
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [brandToDeletetId, setBrandToDeletetId] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentLimit, setCurrentLimit] = useState<number>(25);
  const [page, setPage] = useState(0);
  const [brandFilter, setBrandFilter] = useState<string>('active');
  const [sortedFields, setSortedField] = useState<string>('brand');
  const [sortType, setSortType] = useState<string>('asc');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setBrands([]);
    getAllBrands(
      currentPage,
      currentLimit,
      brandFilter,
      sortedFields,
      sortType,
      false,
    );

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const resetBrands = async (
    response: any,
    page: number,
    limit: number,
    filter: string,
    fromSort: boolean,
  ) => {
    if (currentLimit !== limit || brandFilter !== filter || fromSort) {
      setBrands(response.data);
    } else {
      if (currentPage === page) {
        setBrands(response.data);
      } else {
        setBrands([...brands, ...response.data]);
      }
    }

    setRowData(response.count);
  };

  const getAllBrands = async (
    pageNum: number,
    limit: number,
    filter: string,
    sortedField: string,
    sortType: string,
    fromSort: boolean,
  ) => {
    setLoading(true);

    if (isAgency) {
      const response = await getBrandsAssociatedWithAgency(
        state.roleBasedId,
        pageNum,
        limit,
        filter,
        sortedField,
        sortType,
        IS_DIY_ADZ,
      );

      resetBrands(response, pageNum, limit, filter, fromSort);

      setLoading(false);
    } else if (isBrandManager) {
      const response = await getBrandsAssociatedWithManager(
        state.roleBasedId,
        pageNum,
        limit,
        filter,
        false,
        sortedField,
        sortType,
        IS_DIY_ADZ,
      );

      resetBrands(response, pageNum, limit, filter, fromSort);

      setLoading(false);
    } else if (isSalesperson) {
      const response = await getBrandsAssociatedWithSalesperson(
        state.roleBasedId,
        pageNum,
        limit,
        filter,
        false,
        sortedField,
        sortType,
        IS_DIY_ADZ,
      );

      resetBrands(response, pageNum, limit, filter, fromSort);

      setLoading(false);
    } else {
      if (agencyId !== null) {
        const response = await getBrandsAssociatedWithAgency(
          agencyId,
          pageNum,
          limit,
          filter,
          sortedField,
          sortType,
        );

        resetBrands(response, pageNum, limit, filter, fromSort);
      } else {
        const response = await getBrands(
          pageNum,
          limit,
          filter,
          sortedField,
          sortType,
          fromSort,
        );

        resetBrands(response, pageNum, limit, filter, fromSort);
      }
    }

    setLoading(false);
  };

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleOnClickDelete = (id: string) => {
    setOpenDeleteModal(true);
    setBrandToDeletetId(id);
  };

  const handleDeleteBrand = async () => {
    setDeleteLoading(true);
    const { id } = await deleteBrand(brandToDeletetId);
    if (id) {
      setBrands(brands.filter((s) => s._id !== id));
      setOpenDeleteModal(false);
      setDeleteLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Brand deleted successfully',
        }),
      );
    }
  };

  const setBrandPage = async (page: any, limit: any) => {
    if (page > currentPage) setCurrentPage(page);
    if (currentPage < page) {
      await getAllBrands(
        page,
        limit,
        brandFilter,
        sortedFields,
        sortType,
        false,
      );
    }
  };

  const setBrandSize = async (pageNum: any, limit: any) => {
    setPage(0);
    setCurrentPage(1);
    await getAllBrands(
      pageNum,
      limit,
      brandFilter,
      sortedFields,
      sortType,
      false,
    );

    setCurrentLimit(limit);
  };

  const setFilter = async (filter: string) => {
    setBrandFilter(filter);
    setPage(0);
    setCurrentPage(1);
    setCurrentLimit(25);
    await getAllBrands(1, 25, filter, sortedFields, sortType, false);
  };

  const setSort = async (sortedField: string, sortType: string) => {
    setSortedField(sortedField);
    setSortType(sortType);
    await getAllBrands(
      currentPage,
      currentLimit,
      brandFilter,
      sortedField,
      sortType,
      true,
    );
  };

  return (
    <div className={styles.brand}>
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Brands" />

          <div className={styles.controls}>
            <RadioGroup
              row
              value={brandFilter}
              onChange={(e: ChangeEventType) => setFilter(e.target.value)}
            >
              <FormControlLabel
                value="active"
                control={<Radio size="small" />}
                label="Active"
              />

              <FormControlLabel
                value="inactive"
                control={<Radio size="small" />}
                label="Inactive"
              />
            </RadioGroup>

            <div
              className={`${
                !isSuperAdmin && !isAdmin ? styles['buttons'] : ''
              }`}
            >
              <PrimaryButton
                type="button"
                handleOnClick={() =>
                  navigate(`/${IS_DIY_ADZ ? 'brandz' : 'brands'}/create`)
                }
                title="Add New Brand"
                startIcon={<AddIcon />}
              />
            </div>
          </div>
        </div>

        <div>
          <BrandListTable
            brands={brands}
            isAdmin={isAdmin || isSuperAdmin}
            isAgency={isAgency}
            isSalesperson={isSalesperson}
            isBrandManager={isBrandManager}
            handleOnClickDelete={handleOnClickDelete}
            loading={loading}
            page={page}
            setPage={setPage}
            rowData={rowData}
            setBrandPage={setBrandPage}
            currentLimit={currentLimit}
            pageSize={currentLimit}
            setBrandSize={setBrandSize}
            onSort={setSort}
            agencyId={agencyId}
            agencyName={agencyName}
          />
        </div>

        <DeleteModal
          title="Delete Brand"
          subtitle="Are you want to delete this brand, It'll remove all data belongs to it permanently!"
          open={openDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={handleDeleteBrand}
          loading={deleteLoading}
        />
      </div>
    </div>
  );
};

export default Brands;
