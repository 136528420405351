import {
  Card,
  CardActions,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { buildAddress, locationTypes } from '../../../utils/helpers/DiyHelpers';
import { Brand, BrandErrors, ChangeEventType } from '../../../types';
import { humanizeString } from '../../../utils/stringModifier';
import GeolocationForm from '../Ads/GeolocationForm';
import { PrimaryButton } from '../../Buttons';
import Maps from '../Maps';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { DiySettings } from '../../../types/IDiy';
import CountrySelectMenu from '../../Select/CountrySelectMenu';

interface DiyGeolocationFormProps {
  formValues: Brand;
  setFormValues: Dispatch<SetStateAction<Brand>>;
  accessToken: string;
  isAudience?: boolean;
  brand: Brand;
  isAdmin?: boolean;
  diySettings: DiySettings;
  setDiySettings: Dispatch<SetStateAction<DiySettings>>;
  loading: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  errors: BrandErrors;
}

const DiyGeolocationForm: React.FC<DiyGeolocationFormProps> = ({
  formValues,
  setFormValues,
  accessToken,
  isAudience = false,
  brand,
  isAdmin = false,
  diySettings,
  setDiySettings,
  loading,
  setIsDirty,
  errors,
}) => {
  const [markers, setMarkers] = useState<any[]>([]);
  const [center, setCenter] = useState<any>(null);
  const [map, setMap] = useState<any>(null);
  const [searchError, setSearchError] = useState<string>(null);

  const getCoordinates = async (address: string) => {
    const request = {
      query: address,
      fields: ['ALL'],
    };

    let service = new google.maps.places.PlacesService(map);

    return new Promise((resolve, reject) => {
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const coordinates = {
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
            placeId: results[0].place_id,
          };
          resolve(coordinates);
        } else {
          reject(new Error(`Place query failed with status: ${status}`));
        }
      });
    });
  };

  const handleSelectLocation = async (
    values: any[],
    field: string,
    selectedValue: any,
    exclude: boolean,
  ) => {
    getCoordinates(buildAddress(selectedValue, true))
      .then((coordinates: any) => {
        let temp: any[] = [];

        const data = () => {
          if (exclude) {
            return diySettings?.excludedGeolocations;
          }

          return diySettings?.geolocation;
        };

        switch (field) {
          case 'cities':
            temp = [...(data()?.cities || [])];
            break;
          case 'zips':
            temp = [...(data()?.zips || [])];
            break;
          case 'regions':
            temp = [...(data()?.regions || [])];
            break;
          case 'geo_markets':
            temp = [...(data()?.geo_markets || [])];
            break;
          default:
            temp = [...(data()?.countries || [])];
            break;
        }

        let params: any = { ...selectedValue, ...coordinates };

        if (selectedValue.type === 'city') {
          params = { ...params, radius: diySettings?.maxCityRadius };
        }

        temp = [...temp, params];

        if (exclude) {
          setDiySettings({
            ...diySettings,
            excludedGeolocations: {
              ...diySettings.excludedGeolocations,
              [field]: temp,
            },
          });
        } else {
          setDiySettings({
            ...diySettings,
            geolocation: {
              ...diySettings.geolocation,
              [field]: temp,
            },
          });
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const selectedLocations = (type: string, exclude: boolean) => {
    let temp: any[] = [];

    const diySettingsObj = () => {
      if (exclude) {
        return diySettings?.excludedGeolocations;
      }

      return diySettings?.geolocation;
    };

    switch (type) {
      case 'city':
        diySettingsObj()?.cities?.forEach((city: any) => {
          if (
            [
              type,
              'subcity',
              'neighborhood',
              'subneighborhood',
              'metro_area',
            ].includes(city.type)
          ) {
            temp = [...temp, city];
          }
        });
        break;
      case 'county':
        diySettingsObj()?.cities?.forEach((city: any) => {
          if (city.type === 'medium_geo_area') {
            temp = [...temp, city];
          }
        });
        break;
      case 'zip':
        temp = [...temp, ...(diySettingsObj()?.zips || [])];
        break;
      case 'state':
        temp = [...temp, ...(diySettingsObj()?.regions || [])];
        break;
      case 'geo_market':
        temp = [...temp, ...(diySettingsObj()?.geo_markets || [])];
        break;
      default:
        temp = [...temp, ...(diySettingsObj()?.countries || [])];
        break;
    }

    return temp;
  };

  const handleRemoveLocation = async (
    value: any,
    type: string,
    field: string,
    exclude: boolean,
  ) => {
    let temp: any[] = [];
    let data: any[] = [];

    const diySettingsObj = () => {
      if (exclude) {
        return diySettings?.excludedGeolocations;
      }

      return diySettings?.geolocation;
    };

    switch (field) {
      case 'cities':
        data = [...(diySettingsObj()?.cities || [])];
        break;
      case 'zips':
        data = [...(diySettingsObj()?.zips || [])];
        break;
      case 'regions':
        data = [...(diySettingsObj()?.regions || [])];
        break;
      case 'geo_markets':
        data = [...(diySettingsObj()?.geo_markets || [])];
        break;
      default:
        data = [...(diySettingsObj()?.countries || [])];
        break;
    }

    data.forEach((location: any) => {
      if (location.key !== value.key) {
        temp = [...temp, location];
      }
    });

    if (exclude) {
      setDiySettings({
        ...diySettings,
        excludedGeolocations: {
          ...diySettings.excludedGeolocations,
          [field]: temp,
        },
      });
    } else {
      setDiySettings({
        ...diySettings,
        geolocation: {
          ...diySettings.geolocation,
          [field]: temp,
        },
      });
    }
  };

  const handleSearchAddress = () => {
    setSearchError(null);
    const { line1, city, state } = formValues?.address;

    const address = `${line1 ? `${line1}, ` : ''}${city ? `${city}, ` : ''}${
      state ? `${state} ` : ''
    }`;
    const request = {
      query: address,
      fields: ['ALL'],
    };

    let service = new google.maps.places.PlacesService(map);

    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        let center: any = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        let markers: any[] = [{ ...center }];
        setCenter(center);

        setMarkers(markers);
        setFormValues({
          ...formValues,
          address: {
            ...formValues?.address,
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          },
        });
      } else if (
        status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
      ) {
        setSearchError(
          'We cannot find your place. Use the map below to search and pin for your location',
        );
        setMarkers([]);
        setCenter(null);
        setFormValues({
          ...formValues,
          address: {
            ...formValues?.address,
            lat: null,
            lng: null,
          },
        });
      }
    });
  };

  const handleOnSelectCountries = (e: ChangeEventType, value: any) => {
    setDiySettings({
      ...diySettings,
      targetCountries: value,
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">Target Locations</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">
            Filter target geolocation based on these countries
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <CountrySelectMenu
            value={
              diySettings?.targetCountries || [
                {
                  code: 'US',
                  label: 'United States',
                  phone: '1',
                },
              ]
            }
            onChange={handleOnSelectCountries}
            shrink
            multiple
          />
        </Grid>

        {isAdmin && !loading ? (
          <Grid item xs={12}>
            <Typography variant="body2">
              Select the location types you want to target
            </Typography>

            <FormGroup sx={{ flexDirection: 'row' }}>
              {!loading
                ? locationTypes.map((type: string, index: number) => {
                    const selected = diySettings?.locationTypes?.includes(type);

                    return (
                      <FormControlLabel
                        key={`location-type-${type}`}
                        control={
                          <Checkbox size="small" defaultChecked={selected} />
                        }
                        label={
                          type === 'geo_market' ? 'DMA' : humanizeString(type)
                        }
                        value={selected}
                        onChange={(e: any) => {
                          setIsDirty(true);
                          let temp: string[] = [];
                          if (e.target.checked) {
                            temp = [
                              ...(diySettings?.locationTypes || []),
                              type,
                            ];
                          } else {
                            diySettings?.locationTypes?.forEach(
                              (locationType: string) => {
                                if (locationType !== type) {
                                  temp = [...temp, locationType];
                                }
                              },
                            );
                          }

                          setDiySettings({
                            ...diySettings,
                            locationTypes: temp,
                          });
                        }}
                      />
                    );
                  })
                : null}
            </FormGroup>
          </Grid>
        ) : null}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {diySettings?.locationTypes?.map((type: string) => {
            if (type === 'address') {
              return (
                <Grid container spacing={2} my={1}>
                  <Grid item xs={12} sm={4}>
                    <Card>
                      <CardHeader
                        subheader={`${humanizeString(type)} Max Radius`}
                        subheaderTypographyProps={{
                          color: '#096F4D',
                          fontWeight: 'bold',
                        }}
                      />

                      <CardActions
                        sx={{
                          paddingTop: 0,
                          flexDirection: 'column',
                          paddingX: '20px',
                        }}
                      >
                        <Typography variant="caption">{`${diySettings?.maxAddressRadius} miles`}</Typography>

                        <Slider
                          defaultValue={1}
                          min={1}
                          max={50}
                          valueLabelDisplay="auto"
                          value={diySettings?.maxAddressRadius}
                          onChange={(e: any) => {
                            setIsDirty(true);
                            setDiySettings({
                              ...diySettings,
                              maxAddressRadius: parseInt(e.target.value),
                            });
                          }}
                        />
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              );
            }

            return (
              <GeolocationForm
                type={type}
                onSelectLocation={handleSelectLocation}
                geolocations={selectedLocations(type, false)}
                onRemoveLocation={handleRemoveLocation}
                isAudience={isAudience}
                disabled={!isAdmin}
                formValues={formValues}
                diySettings={diySettings}
                setDiySettings={setDiySettings}
                setIsDirty={setIsDirty}
                isBrandForm
              />
            );
          })}
        </Grid>

        {!loading ? (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  defaultChecked={diySettings?.excludeGeolocation}
                />
              }
              name="excludeGeolocation"
              label="Do you want to exclude targeted territories?"
              value={diySettings?.excludeGeolocation}
              onChange={(e: any) => {
                setIsDirty(true);
                setDiySettings({
                  ...diySettings,
                  [e.target.name]: e.target.checked,
                });
              }}
            />
          </Grid>
        ) : null}

        {diySettings?.excludeGeolocation && !loading ? (
          <>
            <Grid item xs={12}>
              <Typography variant="body2">
                Select the excluded location types
              </Typography>

              <FormGroup sx={{ flexDirection: 'row' }}>
                {diySettings?.excludedLocationTypes
                  ? locationTypes.map((type: string, index: number) => {
                      if (type === 'address' || type === 'country') {
                        return <></>;
                      }

                      return (
                        <FormControlLabel
                          key={`location-type-${type}`}
                          control={
                            <Checkbox
                              size="small"
                              defaultChecked={diySettings?.excludedLocationTypes?.includes(
                                type,
                              )}
                            />
                          }
                          label={
                            type === 'geo_market' ? 'DMA' : humanizeString(type)
                          }
                          value={diySettings?.excludedLocationTypes?.includes(
                            type,
                          )}
                          onChange={(e: any) => {
                            setIsDirty(true);
                            let temp: string[] = [];
                            if (e.target.checked) {
                              temp = [
                                ...(diySettings?.excludedLocationTypes || []),
                                type,
                              ];
                            } else {
                              diySettings?.excludedLocationTypes?.forEach(
                                (locationType: string) => {
                                  if (locationType !== type) {
                                    temp = [...temp, locationType];
                                  }
                                },
                              );
                            }

                            setDiySettings({
                              ...diySettings,
                              excludedLocationTypes: temp,
                            });
                          }}
                        />
                      );
                    })
                  : null}
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              {diySettings?.excludedLocationTypes?.map((type: string) => {
                return (
                  <GeolocationForm
                    type={type}
                    onSelectLocation={handleSelectLocation}
                    geolocations={selectedLocations(type, true)}
                    onRemoveLocation={handleRemoveLocation}
                    isAudience={isAudience}
                    disabled={!isAdmin}
                    formValues={formValues}
                    diySettings={diySettings}
                    setDiySettings={setDiySettings}
                    exclude
                    setIsDirty={setIsDirty}
                    isBrandForm
                  />
                );
              })}
            </Grid>
          </>
        ) : null}

        <Grid item xs={12} sm={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                type="text"
                id="line1"
                name="line1"
                autoComplete="new-password"
                label="Address Line 1"
                onChange={(e: ChangeEventType) => {
                  setIsDirty(true);
                  setFormValues({
                    ...formValues,
                    address: {
                      ...formValues.address,
                      [e.target.name]: e.target.value,
                    },
                  });
                }}
                value={formValues?.address?.line1}
                placeholder="Address Line 1"
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                type="text"
                id="city"
                name="city"
                label="City"
                autoComplete="new-password"
                onChange={(e: ChangeEventType) => {
                  setIsDirty(true);
                  setFormValues({
                    ...formValues,
                    address: {
                      ...formValues.address,
                      [e.target.name]: e.target.value,
                    },
                  });
                }}
                value={formValues?.address?.city}
                placeholder="City"
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                type="text"
                id="state"
                name="state"
                label="State"
                autoComplete="new-password"
                onChange={(e: ChangeEventType) => {
                  setIsDirty(true);
                  setFormValues({
                    ...formValues,
                    address: {
                      ...formValues.address,
                      [e.target.name]: e.target.value,
                    },
                  });
                }}
                value={formValues?.address?.state}
                placeholder="State"
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                type="text"
                id="zipcode"
                name="zipcode"
                label="Zip Code"
                autoComplete="new-password"
                onChange={(e: ChangeEventType) => {
                  setIsDirty(true);
                  setFormValues({
                    ...formValues,
                    address: {
                      ...formValues.address,
                      [e.target.name]: e.target.value,
                    },
                  });
                }}
                value={formValues?.address?.zipcode}
                placeholder="Zip Code"
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                type="text"
                id="lat"
                name="lat"
                disabled
                label="Lat"
                onChange={(e: ChangeEventType) => {
                  setIsDirty(true);
                  setFormValues({
                    ...formValues,
                    address: {
                      ...formValues.address,
                      [e.target.name]: parseFloat(e.target.value),
                    },
                  });
                }}
                value={formValues?.address?.lat || ''}
                placeholder="Zip Code"
                InputLabelProps={{ shrink: true }}
                size="small"
                error={errors?.lat ? true : false}
                helperText={errors?.lat ? errors?.lat : ''}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                type="text"
                id="lng"
                disabled
                name="lng"
                label="Lng"
                onChange={(e: ChangeEventType) => {
                  setIsDirty(true);
                  setFormValues({
                    ...formValues,
                    address: {
                      ...formValues.address,
                      [e.target.name]: parseFloat(e.target.value),
                    },
                  });
                }}
                value={formValues?.address?.lng || ''}
                placeholder="Lng"
                InputLabelProps={{ shrink: true }}
                size="small"
                error={errors?.lng ? true : false}
                helperText={errors?.lng ? errors?.lng : ''}
              />
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <PrimaryButton
                title="Find your Address"
                size="small"
                startIcon={<MyLocationIcon />}
                type="button"
                handleOnClick={handleSearchAddress}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={8}>
          {searchError ? (
            <Typography variant="body1" sx={{ color: 'red' }}>
              {searchError}
            </Typography>
          ) : null}

          <Maps
            brand={formValues}
            setBrand={setFormValues}
            markers={markers}
            setMarkers={setMarkers}
            center={center}
            setCenter={setCenter}
            setParentMap={setMap}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DiyGeolocationForm;
