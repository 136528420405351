import {
  Box,
  Button,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { stringToSnakeCase } from '../../../../utils/stringModifier';
import { PrimaryButton } from '../../../Buttons';
import {
  ChangeEventType,
  FBLeadGenForm,
  FBLeadGenQuestionForm,
} from '../../../../types';
import { leadFormCustomQuestions } from '../../../../utils/helpers/LeadHelpers';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PopupModal from '../../../Modal';

interface LeadFormCustomQuestionFormProps {
  customQuestions: any[];
  prefilledQuestions: any[];
  formValues: FBLeadGenForm;
  setFormValues: Dispatch<SetStateAction<FBLeadGenForm>>;
  register: any;
  unregister: any;
  errors: any;
  clearErrors: any;
  onValidated: any;
}

const LeadFormCustomQuestionForm: React.FC<LeadFormCustomQuestionFormProps> = ({
  customQuestions,
  prefilledQuestions,
  formValues,
  setFormValues,
  register,
  unregister,
  errors,
  clearErrors,
  onValidated,
}) => {
  console.log(errors);
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openEditLabel, setOpenEditLabel] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] =
    useState<FBLeadGenQuestionForm>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const open = Boolean(anchorEl);

  const handleOpenAddCustom = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAddCustom = () => {
    setAnchorEl(null);
  };

  const handleRemoveQuestion = (type: string, index?: number) => {
    let tempQuestions: FBLeadGenQuestionForm[] = [];

    customQuestions.forEach(
      (question: FBLeadGenQuestionForm, questionIndex: number) => {
        if (index !== questionIndex) {
          tempQuestions = [...tempQuestions, question];
        }
      },
    );

    setFormValues({
      ...formValues,
      questions: [...tempQuestions, ...prefilledQuestions],
    });
  };

  const handleRemoveOption = (questionIndex: number, optionIndex: number) => {
    let tempQuestions: FBLeadGenQuestionForm[] = [];

    customQuestions.forEach(
      (question: FBLeadGenQuestionForm, qindex: number) => {
        if (qindex === questionIndex && question?.options?.length > 0) {
          let optionsTemp: any[] = [];
          question?.options?.forEach((option: any, oIndex: Number) => {
            if (oIndex !== optionIndex) {
              optionsTemp = [...optionsTemp, option];
            }
          });

          question.options = optionsTemp;
        }

        tempQuestions = [...tempQuestions, question];
      },
    );

    setFormValues({
      ...formValues,
      questions: [...tempQuestions, ...prefilledQuestions],
    });
  };

  const handleOnSelectCustomQuestion = (question: any) => {
    console.log(question);
    setFormValues({
      ...formValues,
      questions: [
        ...formValues?.questions,
        {
          type: 'CUSTOM',
          options:
            question === 'multiple_choice'
              ? [
                  { value: '', key: '' },
                  { value: '', key: '' },
                ]
              : [],
        },
      ],
    });

    handleCloseAddCustom();
  };

  const handleOnChangeCustomQuestion = (e: ChangeEventType, index: number) => {
    let temp: FBLeadGenQuestionForm[] = [...customQuestions];

    const isPrefilledKey = ['Investment', 'Background', 'BestNumber'].includes(
      temp[index].key,
    );

    temp[index].label = e.target.value;

    if (!isPrefilledKey) {
      temp[index].key = stringToSnakeCase(e.target.value, true);
    }

    setFormValues({
      ...formValues,
      questions: [...temp, ...prefilledQuestions],
    });
  };

  const handleOnChangeOption = (
    e: ChangeEventType,
    questionIndex: number,
    optionIndex: number,
  ) => {
    let temp: FBLeadGenQuestionForm[] = [...customQuestions];

    temp[questionIndex].options[optionIndex].key = stringToSnakeCase(
      e.target.value,
      true,
    );
    temp[questionIndex].options[optionIndex].value = e.target.value;

    setFormValues({
      ...formValues,
      questions: [...temp, ...prefilledQuestions],
    });
  };

  const handleOnAddOption = (questionIndex: number) => {
    let temp: FBLeadGenQuestionForm[] = [...customQuestions];

    temp[questionIndex].options = [
      ...temp[questionIndex].options,
      { value: '', key: '' },
    ];

    setFormValues({
      ...formValues,
      questions: [...temp, ...prefilledQuestions],
    });
  };

  const handleOnChangeQuestionLabel = (e: ChangeEventType, index: number) => {
    let temp: FBLeadGenQuestionForm[] = [...customQuestions];

    temp[index].key = e.target.value;

    setFormValues({
      ...formValues,
      questions: [...temp, ...prefilledQuestions],
    });
  };

  const handleOpenEditLabel = (
    question: FBLeadGenQuestionForm,
    index: number,
  ) => {
    setSelectedQuestion(question);
    setSelectedIndex(index);
    setOpenEditLabel(true);
  };

  const handleCloseEditLabel = () => {
    setOpenEditLabel(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Custom Questions
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2">
          Get the info your business needs.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              id="custom-questions-button"
              aria-controls={open ? 'custom-questions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleOpenAddCustom}
              sx={{
                backgroundColor: '#096f4d',
                '&:hover': {
                  backgroundColor: '#096f4d',
                },
                color: '#FFFFFF',
              }}
              startIcon={<AddIcon />}
            >
              Add Custom Question
            </Button>

            <Menu
              id="custom-questions-menu"
              MenuListProps={{
                'aria-labelledby': 'custom-questions-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseAddCustom}
              TransitionComponent={Fade}
            >
              {leadFormCustomQuestions.map((question: any, index: number) => {
                return (
                  <MenuItem
                    key={`custom-question-option-${index + 1}`}
                    onClick={() => handleOnSelectCustomQuestion(question.type)}
                  >
                    <ListItemIcon>{question.icon}</ListItemIcon>
                    <ListItemText>{question.label}</ListItemText>
                  </MenuItem>
                );
              })}
            </Menu>
          </Grid>

          {customQuestions.map(
            (question: FBLeadGenQuestionForm, index: number) => {
              console.log(question);
              return (
                <>
                  <Grid item xs={12} key={`custom-question-${index + 1}`}>
                    <TextField
                      {...register(`custom-question-${index + 1}`, {
                        required: true,
                      })}
                      fullWidth
                      required
                      variant="standard"
                      type="text"
                      id={`custom-question-${index + 1}`}
                      name={`custom-question-${index + 1}`}
                      label={
                        <Fragment>
                          <Box>
                            {`${`Custom Question #${index + 1}${
                              question.key ? `: ${question.key}` : ''
                            }`}`}{' '}
                            <Box component="span">
                              <PrimaryButton
                                type="button"
                                variant="text"
                                title="Edit Label"
                                startIcon={<EditOutlinedIcon />}
                                theme="blue"
                                handleOnClick={() =>
                                  handleOpenEditLabel(question, index)
                                }
                              />
                            </Box>
                          </Box>
                        </Fragment>
                      }
                      onChange={(e: ChangeEventType) => {
                        clearErrors(`custom-question-${index + 1}`);
                        handleOnChangeCustomQuestion(e, index);
                      }}
                      value={question?.label}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      helperText={
                        errors[`custom-question-${index + 1}`] &&
                        `Custom question #${index + 1} is required`
                      }
                      error={!!errors[`custom-question-${index + 1}`]}
                      autoFocus
                      InputProps={{
                        sx: { borderRadius: '49px' },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                unregister(`custom-question-${index + 1}`);
                                handleRemoveQuestion(question.type, index);
                              }}
                              edge="end"
                            >
                              <DeleteIcon sx={{ color: 'red' }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  {question?.options?.length > 0 ? (
                    <Grid item xs={12} sm={6} ml={4}>
                      <Grid container spacing={2}>
                        {question?.options.map(
                          (option: any, optionIndex: number) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                key={`option-${index + 1}-${optionIndex + 1}`}
                              >
                                <TextField
                                  {...register(
                                    `option-${index + 1}-${optionIndex + 1}`,
                                    { required: true },
                                  )}
                                  fullWidth
                                  required
                                  variant="standard"
                                  type="text"
                                  id={`option-${index + 1}-${optionIndex + 1}`}
                                  name={`option-${index + 1}-${
                                    optionIndex + 1
                                  }`}
                                  label={`${`Option #${optionIndex + 1}${
                                    option.key ? `: ${option.key}` : ''
                                  }`}`}
                                  onChange={(e: ChangeEventType) => {
                                    clearErrors(
                                      `option-${index + 1}-${optionIndex + 1}`,
                                    );
                                    handleOnChangeOption(e, index, optionIndex);
                                  }}
                                  value={option.value}
                                  InputLabelProps={{ shrink: true }}
                                  size="small"
                                  error={
                                    !!errors[
                                      `option-${index + 1}-${optionIndex + 1}`
                                    ]
                                  }
                                  helperText={
                                    !!errors[
                                      `option-${index + 1}-${optionIndex + 1}`
                                    ] &&
                                    `Option #${optionIndex + 1} is required`
                                  }
                                  autoFocus
                                  InputProps={{
                                    sx: { borderRadius: '49px' },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => {
                                            unregister(
                                              `option-${index + 1}-${
                                                optionIndex + 1
                                              }`,
                                            );
                                            handleRemoveOption(
                                              index,
                                              optionIndex,
                                            );
                                          }}
                                          edge="end"
                                          disabled={
                                            question?.options?.length === 1
                                          }
                                        >
                                          <DeleteIcon
                                            sx={{
                                              color:
                                                question?.options?.length === 1
                                                  ? '#EBEBE4'
                                                  : 'red',
                                            }}
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                            );
                          },
                        )}

                        <Grid item xs={12}>
                          <PrimaryButton
                            type="button"
                            title="Add Option"
                            handleOnClick={() => handleOnAddOption(index)}
                            startIcon={<AddIcon />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </>
              );
            },
          )}
        </Grid>
      </Grid>

      <PopupModal open={openEditLabel} handleClose={null}>
        <Grid
          container
          spacing={2}
          sx={{ width: xsOnly ? '100%' : '400px', textAlign: 'center' }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', color: '#096f4d' }}
            >
              Edit Question Label
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Change how your field names appear when you export files.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...(openEditLabel ? register('key', { required: true }) : {})}
              fullWidth
              required
              variant="standard"
              type="text"
              name="key"
              label="Question Label"
              onChange={(e: ChangeEventType) => {
                clearErrors('key');
                handleOnChangeQuestionLabel(e, selectedIndex);
              }}
              value={selectedQuestion?.key}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={!!errors.key}
              helperText={errors.key && 'Question label is required'}
            />
          </Grid>

          <Grid item xs={12}>
            <PrimaryButton
              title="Confirm"
              type="button"
              handleOnClick={onValidated(handleCloseEditLabel)}
            />
          </Grid>
        </Grid>
      </PopupModal>
    </Grid>
  );
};

export default LeadFormCustomQuestionForm;
