import axios from 'axios';
import { apiBaseUrl } from './api';
import { Moment } from 'moment';
import { FBLeadGenForm } from '../types';

export const getLeadsByBrand = async (
  brandId: string,
  startDate: Moment | null,
  endDate: Moment | null,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/leads?startDate=${startDate}&endDate=${endDate}`,
    {
      params: { brandId },
    },
  );

  return data?.data;
};

export const getLeadsByAdId = async (
  brandId: string,
  adId: string,
  startDate: Moment | null,
  endDate: Moment | null,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/leads?startDate=${startDate}&endDate=${endDate}`,
    {
      params: { brandId, adId },
    },
  );

  return data?.data;
};

export const syncFbLeads = async (brandId: string, adId: string) => {
  const data = await axios.post(
    `${apiBaseUrl}/leads/facebook/sync`,
    {},
    {
      params: { brandId, adId },
    },
  );

  return data?.data;
};

export const getBrandLeadForms = async (brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/leads/facebook/forms/${brandId}`);

  return data?.data;
};

export const createLeadForms = async (params: FBLeadGenForm) => {
  const data = await axios.post(
    `${apiBaseUrl}/leads/facebook/lead-form`,
    params,
  );

  return data?.data;
};
