import {
  Autocomplete,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { Brand, ChangeEventType, FBLeadGenForm } from '../../../../types';
import { leadFormCtas } from '../../../../utils/helpers/LeadHelpers';
import CountrySelectMenu from '../../../Select/CountrySelectMenu';
import { DiySettings } from '../../../../types/IDiy';
import {
  rawPhoneNumberPattern,
  securedUrlPattern,
} from '../../../../utils/validation';
import { phoneNoSpaceFormat } from '../../../../utils/phoneNumberFormatter';

interface LeadFormThankYouPageFormProps {
  formValues: FBLeadGenForm;
  setFormValues: Dispatch<SetStateAction<FBLeadGenForm>>;
  selectedCountry: any;
  setSelectedCountry: Dispatch<SetStateAction<any>>;
  diySettings: DiySettings;
  register: any;
  unregister: any;
  errors: any;
  clearErrors: any;
  brand: Brand;
}

const LeadFormThankYouPageForm: React.FC<LeadFormThankYouPageFormProps> = ({
  formValues,
  setFormValues,
  selectedCountry,
  setSelectedCountry,
  diySettings,
  register,
  unregister,
  errors,
  clearErrors,
  brand,
}) => {
  const handleOnSelectCountry = (e: any, value: any) => {
    setFormValues({
      ...formValues,
      thank_you_page: {
        ...formValues.thank_you_page,
        country_code: `+${value.phone}`,
      },
    });

    setSelectedCountry(value);
  };

  const handleOnSelectCTA = (cta: string) => {
    let temp: FBLeadGenForm = {
      ...formValues,
      thank_you_page: {
        ...formValues?.thank_you_page,
        button_type: cta,
      },
    };

    switch (cta) {
      case 'SCHEDULE_CALL':
        unregister('business_phone_number');
        temp = {
          ...temp,
          thank_you_page: {
            ...temp?.thank_you_page,
            website_url: null,
            business_phone_number: null,
            country_code: '+1',
            button_text: 'Schedule Call',
            body: `The quickest way to get started with owning a ${
              brand?.__type === 'location'
                ? brand?.franchisor?.brand
                : brand?.brand
            } is to schedule a call.`,
            title: 'Schedule a Call to Learn More',
          },
        };
        break;
      case 'CALL_BUSINESS':
        unregister('website_url');
        temp = {
          ...temp,
          thank_you_page: {
            ...temp?.thank_you_page,
            website_url: null,
            business_phone_number: phoneNoSpaceFormat(brand?.phoneNumber),
            country_code: '+1',
            button_text: 'Call Now',
          },
        };
        setSelectedCountry({
          code: 'US',
          label: 'United States',
          phone: '1',
          suggested: true,
        });
        break;
      default:
        unregister('business_phone_number');
        temp = {
          ...temp,
          thank_you_page: {
            ...temp?.thank_you_page,
            website_url: diySettings?.defaultUrl,
            business_phone_number: null,
            country_code: '+1',
            button_text: 'View Website',
            title: `Thanks, you're all set.`,
            body: 'You can visit our website or exit the form now.',
          },
        };
        break;
    }

    setFormValues(temp);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">
          Give leads who see this page a call to action. Send them to a webpage,
          or have them call you
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              {...register('title', { required: true })}
              fullWidth
              required
              variant="standard"
              type="text"
              name="title"
              label="Headline"
              onChange={(e: ChangeEventType) => {
                clearErrors('title');
                setFormValues({
                  ...formValues,
                  thank_you_page: {
                    ...formValues?.thank_you_page,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
              value={formValues?.thank_you_page?.title}
              InputLabelProps={{ shrink: true }}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">
                      {`${formValues?.thank_you_page?.title?.length || 0}/60`}
                    </Typography>
                  </InputAdornment>
                ),
              }}
              inputProps={{ maxLength: 60 }}
              error={!!errors.title}
              helperText={errors?.title && 'Headline is required'}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...register('body', { required: true })}
              fullWidth
              required
              type="text"
              name="body"
              label="Description"
              onChange={(e: ChangeEventType) => {
                clearErrors('body');
                setFormValues({
                  ...formValues,
                  thank_you_page: {
                    ...formValues?.thank_you_page,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
              value={formValues?.thank_you_page?.body}
              InputLabelProps={{ shrink: true }}
              size="small"
              multiline
              minRows={4}
              maxRows={100}
              error={!!errors.body}
              helperText={errors?.body && 'Description is required'}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              id="cta"
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.type}>
                    {option.label}
                  </li>
                );
              }}
              options={leadFormCtas}
              value={
                leadFormCtas?.find(
                  (cta: any) =>
                    cta.type === formValues?.thank_you_page?.button_type,
                ) || null
              }
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Call-to-Action Type"
                  placeholder="Select a Call-to-Action type"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  //error={errors?.users && i === 0 ? true : false}
                  //helperText={errors?.users && i === 0 ? errors?.users : ''}
                />
              )}
              disableClearable
              onChange={async (e: any, value: any) => {
                handleOnSelectCTA(value.type);
              }}
            />
          </Grid>

          {['VIEW_WEBSITE', 'SCHEDULE_CALL'].includes(
            formValues?.thank_you_page?.button_type,
          ) ? (
            <Grid item xs={12}>
              <TextField
                {...register('website_url', {
                  required: true,
                  pattern: securedUrlPattern,
                })}
                fullWidth
                required
                variant="standard"
                type="text"
                name="website_url"
                label={
                  formValues?.thank_you_page?.button_type === 'VIEW_WEBSITE'
                    ? 'Website URL'
                    : 'Calendar URL'
                }
                onChange={(e: ChangeEventType) => {
                  clearErrors('website_url');
                  setFormValues({
                    ...formValues,
                    thank_you_page: {
                      ...formValues?.thank_you_page,
                      [e.target.name]: e.target.value,
                    },
                  });
                }}
                value={formValues?.thank_you_page?.website_url}
                InputLabelProps={{ shrink: true }}
                size="small"
                error={!!errors.website_url}
                helperText={
                  errors?.website_url?.message ||
                  (errors?.website_url && 'Website URL is required')
                }
              />
            </Grid>
          ) : null}

          {formValues?.thank_you_page?.button_type === 'CALL_BUSINESS' ? (
            <>
              <Grid item xs={12} sm={6}>
                <CountrySelectMenu
                  value={selectedCountry}
                  onChange={handleOnSelectCountry}
                  withCode
                  label="Country Code"
                  shrink
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('business_phone_number', {
                    required: true,
                    pattern: rawPhoneNumberPattern,
                  })}
                  fullWidth
                  required
                  variant="standard"
                  type="text"
                  name="business_phone_number"
                  label="Business Phone Number"
                  onChange={(e: ChangeEventType) => {
                    clearErrors('business_phone_number');
                    setFormValues({
                      ...formValues,
                      thank_you_page: {
                        ...formValues?.thank_you_page,
                        [e.target.name]: e.target.value,
                      },
                    });
                  }}
                  value={formValues?.thank_you_page?.business_phone_number}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  error={!!errors?.business_phone_number}
                  helperText={
                    errors.business_phone_number?.message ||
                    (errors.business_phone_number &&
                      'Business phone number is required')
                  }
                />
              </Grid>
            </>
          ) : null}

          {formValues?.thank_you_page?.button_type ? (
            <Grid item xs={12}>
              <TextField
                {...register('button_text', { required: true })}
                fullWidth
                required
                variant="standard"
                type="text"
                name="button_text"
                label="Call-to-Action Text"
                onChange={(e: ChangeEventType) => {
                  clearErrors('button_text');
                  setFormValues({
                    ...formValues,
                    thank_you_page: {
                      ...formValues?.thank_you_page,
                      [e.target.name]: e.target.value,
                    },
                  });
                }}
                value={formValues?.thank_you_page?.button_text}
                InputLabelProps={{ shrink: true }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="body2">
                        {`${
                          formValues?.thank_you_page?.button_text?.length || 0
                        }/60`}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 60 }}
                error={!!errors.button_text}
                helperText={
                  errors?.button_text && 'Call-to-Action text is required'
                }
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LeadFormThankYouPageForm;
