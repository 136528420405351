import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import {
  ChangeEventType,
  FBLeadGenForm,
  FBLeadGenQuestionForm,
} from '../../../../types';
import { constantStringToHumanized } from '../../../../utils/stringModifier';
import AddIcon from '@mui/icons-material/Add';
import {
  prefilledQuestionIcons,
  prefilledQuestions,
} from '../../../../utils/helpers/LeadHelpers';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { NestedMenuItem } from 'mui-nested-menu';

interface LeadFormPrefillQuestionFormProps {
  prefillQuestions: FBLeadGenQuestionForm[];
  customQuestions: FBLeadGenQuestionForm[];
  formValues: FBLeadGenForm;
  setFormValues: Dispatch<SetStateAction<FBLeadGenForm>>;
  register: any;
  errors: any;
  clearErrors: any;
}

const LeadFormPrefillQuestionForm: React.FC<
  LeadFormPrefillQuestionFormProps
> = ({
  prefillQuestions,
  customQuestions,
  formValues,
  setFormValues,
  register,
  errors,
  clearErrors,
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnSelectPrefillQuestions = (question: any) => {
    setFormValues({
      ...formValues,
      questions: [...formValues?.questions, { type: question.type }],
    });
    handleClose();
  };

  const handleRemoveQuestion = (type: string) => {
    let tempQuestions: FBLeadGenQuestionForm[] = [];

    prefillQuestions.forEach((question: FBLeadGenQuestionForm) => {
      if (question.type !== type) {
        tempQuestions = [...tempQuestions, question];
      }
    });

    setFormValues({
      ...formValues,
      questions: [...customQuestions, ...tempQuestions],
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Prefill Questions
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              Ask for user information. This will be prefilled from their
              Facebook account.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...register('question_page_custom_headline', { required: true })}
              fullWidth
              required
              variant="standard"
              type="text"
              name="question_page_custom_headline"
              label="Prefill Questions Headline"
              onChange={(e: ChangeEventType) => {
                clearErrors('question_page_custom_headline');
                setFormValues({
                  ...formValues,
                  [e.target.name]: e.target.value,
                });
              }}
              value={formValues?.question_page_custom_headline}
              InputLabelProps={{ shrink: true }}
              size="small"
              helperText={
                (errors.question_page_custom_headline &&
                  `Question headline is required`) || (
                  <Fragment>
                    <Box>
                      Let people know how the information they give you will be
                      used or shared.{' '}
                      <Box component="span">
                        <a
                          href="https://www.facebook.com/business/help/1582353715248057?id=735435806665862"
                          target="_blank"
                          rel="noreferrer"
                        >
                          See Examples
                        </a>
                      </Box>
                    </Box>
                  </Fragment>
                )
              }
              error={!!errors.question_page_custom_headline}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ justifyContent: 'flex-start', display: 'flex' }}
          >
            <>
              <Button
                onClick={handleClick}
                startIcon={<AddIcon />}
                sx={{
                  backgroundColor: '#096f4d',
                  '&:hover': {
                    backgroundColor: '#096f4d',
                  },
                  color: '#FFFFFF',
                }}
              >
                Add Prefill Question
              </Button>

              <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
                {Object.keys(prefilledQuestions).map((key: string) => {
                  return (
                    <NestedMenuItem
                      key={key}
                      label={constantStringToHumanized(key)}
                      parentMenuOpen={open}
                      onClick={handleClose}
                      rightIcon={<ChevronRightOutlinedIcon />}
                      leftIcon={prefilledQuestionIcons(key)}
                    >
                      {prefilledQuestions[key].map((question: any) => {
                        return (
                          <MenuItem
                            onClick={() =>
                              handleOnSelectPrefillQuestions(question)
                            }
                            disabled={prefillQuestions
                              .map((prefill: any) => prefill.type)
                              .includes(question.type)}
                          >
                            {question.label}
                          </MenuItem>
                        );
                      })}
                    </NestedMenuItem>
                  );
                })}
              </Menu>
            </>
          </Grid>

          {prefillQuestions?.length > 0 ? (
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                {prefillQuestions.map((question: FBLeadGenQuestionForm) => {
                  return (
                    <Grid item xs={12}>
                      <Card>
                        <CardHeader
                          subheader={
                            <Fragment>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                }}
                              >
                                {constantStringToHumanized(question.type)}

                                <Box>
                                  <IconButton
                                    onClick={() =>
                                      handleRemoveQuestion(question.type)
                                    }
                                    edge="end"
                                  >
                                    <DeleteIcon sx={{ color: 'red' }} />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Fragment>
                          }
                          subheaderTypographyProps={{
                            color: '#096F4D',
                            fontWeight: 'bold',
                          }}
                        />
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LeadFormPrefillQuestionForm;
