import { Box } from '@mui/material';
import React from 'react';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { columns } from './SubscriptionsListTableColumns';
import { CustomLoadingOverlay } from '../../Loading';

interface SubscriptionsListTableProps {
  subscriptions: any[];
  loading: boolean;
  onOpenPayment?: (clientSecret: string, transactionType: string) => void;
  onOpenCancel: (subscriptionId: string) => void;
  onOpenRenew?: (subscriptionId: string) => void;
}

const SubscriptionsListTable: React.FC<SubscriptionsListTableProps> = ({
  subscriptions,
  loading,
  onOpenPayment,
  onOpenCancel,
  onOpenRenew,
}) => {
  const rows = subscriptions.map((subscription) => {
    const {
      id,
      status,
      created,
      current_period_end,
      current_period_start,
      cancel_at,
    } = subscription;

    return {
      id,
      status,
      startDate: moment.unix(created).local().format('LL'),
      periodStart: moment.unix(current_period_start).local().format('LL'),
      periodEnd: moment.unix(current_period_end).local().format('LL'),
      clientSecret: subscription.latest_invoice?.payment_intent?.client_secret,
      paymentMethod: subscription?.default_payment_method,
      onOpenPayment,
      onOpenCancel,
      cancelAt: cancel_at,
      formattedCancelAt: cancel_at
        ? moment.unix(cancel_at).local().format('MMM DD')
        : null,
      onOpenRenew,
      paymentIntent: subscription.latest_invoice?.payment_intent,
      invoicePageUrl: subscription.latest_invoice?.hosted_invoice_url,
    };
  });

  return (
    <Box className={styles.table}>
      <DataGrid
        density="compact"
        rows={rows}
        columns={columns}
        autoHeight
        hideFooterSelectedRowCount
        components={{ LoadingOverlay: CustomLoadingOverlay }}
        loading={loading}
      />
    </Box>
  );
};

export default SubscriptionsListTable;
