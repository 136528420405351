import axios from 'axios';
import moment, { Moment } from 'moment-timezone';
import { BudgetFormFields, BudgetSyncFields } from '../types';
import { apiBaseUrl } from './api';

export const createBudget = async (obj: BudgetFormFields) => {
  const data = await axios.post(`${apiBaseUrl}/budget/create`, obj);

  return data?.data;
};

export const fetchAllBudgetsByPage = async (pageId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/budget?pageId=${pageId}&filter=active`,
  );

  return data?.data;
};

export const fetchAllBudgets = async () => {
  const data = await axios.get(`${apiBaseUrl}/budget?filter=active`);

  return data?.data;
};

export const fetchAllBudgetsByAgency = async (agencyId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/budget?agencyId=${agencyId}&filter=active`,
  );

  return data?.data;
};

export const fetchAllBudgetsBySalesperson = async (
  salespersonId: string,
  isBrand?: boolean,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/budget?salespersonId=${salespersonId}&filter=active${
      isBrand ? '&type=location' : ''
    }`,
  );

  return data?.data;
};

export const fetchAllBudgetsByBrandManager = async (
  managerId: string,
  isBrand?: boolean,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/budget?managerId=${managerId}&filter=active${
      isBrand ? '&type=location' : ''
    }`,
  );

  return data?.data;
};

export const fetchAllBudgetsByLocations = async (franchisorId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/budget?franchisorId=${franchisorId}&filter=active`,
  );

  return data?.data;
};

export const getBrandBudget = async (brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/budget/${brandId}`);

  return data?.data;
};

export const getBudgetReportByPage = async (
  pageId: string,
  month: string,
  year: string,
  accessToken: string,
) => {
  const timezone = moment.tz.guess();

  const data = await axios.get(
    `${apiBaseUrl}/budget/report?pageId=${pageId}&month=${month}&year=${year}&token=${accessToken}&timezone=${timezone}&filter=active`,
  );

  return data?.data;
};

export const getBudgetReport = async (
  month: string,
  year: string,
  refreshToken: string,
  provider: string,
  isReport: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
) => {
  const timezone = moment.tz.guess();

  const data = await axios.get(
    `${apiBaseUrl}/budget/report?startDate=${startDate}&endDate=${endDate}`,
    {
      params: {
        month,
        year,
        timezone,
        filter: 'active',
        refreshToken,
        provider,
        isReport,
      },
    },
  );

  return data?.data;
};

export const getBudgetReportBySalesperson = async (
  salespersonId: string,
  month: string,
  year: string,
  refreshToken: string,
  provider: string,
  isReport: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  isBrand?: boolean,
) => {
  const timezone = moment.tz.guess();

  const data = await axios.get(
    `${apiBaseUrl}/budget/report?startDate=${startDate}&endDate=${endDate}${
      isBrand ? '&type=location' : ''
    }`,
    {
      params: {
        salespersonId,
        month,
        year,
        timezone,
        refreshToken,
        filter: 'active',
        provider,
        isReport,
      },
    },
  );

  return data?.data;
};

export const getBudgetReportByAgency = async (
  agencyId: string,
  month: string,
  year: string,
  refreshToken: string,
  provider: string,
  isReport: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
) => {
  const timezone = moment.tz.guess();

  const data = await axios.get(
    `${apiBaseUrl}/budget/report?startDate=${startDate}&endDate=${endDate}`,
    {
      params: {
        agencyId,
        month,
        year,
        timezone,
        filter: 'active',
        refreshToken,
        provider,
        isReport,
      },
    },
  );

  return data?.data;
};

export const getBudgetReportByManager = async (
  managerId: string,
  month: string,
  year: string,
  refreshToken: string,
  provider: string,
  isReport: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  isBrand?: boolean,
) => {
  const timezone = moment.tz.guess();

  const data = await axios.get(
    `${apiBaseUrl}/budget/report?startDate=${startDate}&endDate=${endDate}${
      isBrand ? '&type=location' : ''
    }`,
    {
      params: {
        managerId,
        month,
        year,
        refreshToken,
        timezone,
        filter: 'active',
        provider,
        isReport,
      },
    },
  );

  return data?.data;
};

export const getBudgetReportByLocation = async (
  franchisorId: string,
  month: string,
  year: string,
  refreshToken: string,
  provider: string,
  isReport: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
) => {
  const timezone = moment.tz.guess();

  const data = await axios.get(
    `${apiBaseUrl}/budget/report?startDate=${startDate}&endDate=${endDate}`,
    {
      params: {
        franchisorId,
        month,
        year,
        timezone,
        filter: 'active',
        refreshToken,
        provider,
        isReport,
      },
    },
  );

  return data?.data;
};

export const syncBudget = async (obj: BudgetSyncFields) => {
  const data = await axios.post(`${apiBaseUrl}/budget/sync`, obj);

  return data?.data;
};
