import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UserListTable from '../../components/Tables/UserList/UserListTable';
import { DeleteModal } from '../../components/Modal';
import InviteModal from '../../components/Modal/InviteModal';
import {
  Brand,
  ChangeEventType,
  InvitationErrors,
  InviteInfo,
  Salesperson,
} from '../../types';
import {
  deleteSalesperson,
  getAllSalesperson,
  getAllSalespersonByBrand,
} from '../../services/salesperson';
import { inviteSalesperson } from '../../services/invitation';
import { banUser, unbanUser } from '../../services/user';
import { toast } from 'react-toastify';
import { PrimaryButton } from '../../components/Buttons';
import { useNavigate } from 'react-router-dom';
import { humanizeString } from '../../utils/stringModifier';
import styles from '../../assets/styles/pages/Users.module.scss';
import PageHeader from '../../components/PageHeader';
import {
  BRAND_MANAGER,
  IS_DIY_ADZ,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import { XsOnly } from '../../utils/breakpoints';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAlert } from '../../redux/actions';
import { AuthContext } from '../../context';

interface UsersProps {
  brandId?: string;
  setBrandSalespersons?: Dispatch<SetStateAction<Salesperson[]>>;
  setBrandManagers?: Dispatch<SetStateAction<Salesperson[]>>;
}

const Users: React.FC<UsersProps> = ({
  brandId,
  setBrandSalespersons,
  setBrandManagers,
}) => {
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const dispatch = useDispatch();
  const { state } = useContext(AuthContext);
  const role = state.role;
  const isSuperAdmin = role === SUPER_ADMIN;
  const isBrand = brandId !== undefined;
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [banLoading, setBanLoading] = useState<boolean>(false);
  const [invitationLoading, setInvitationLoading] = useState<boolean>(false);
  const [salespersons, setSalespersons] = useState<Salesperson[]>([]);
  const [salespersonsToDeleteId, setSalespersonsToDeleteId] =
    useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [rowData, setRowData] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentLimit, setCurrentLimit] = useState<number>(25);
  const [page, setPage] = useState(0);
  const [invitationInfo, setInvitationInfo] = useState<InviteInfo>({
    name: '',
    email: '',
    id: '',
    role: '',
  });
  const [invitationErrors, setInvitationErrors] = useState<InvitationErrors>({
    email: '',
  });
  const [userFilter, setUserFilter] = useState<string>('active');
  const [sortedField, setSortedField] = useState<string>('name');
  const [sortType, setSortType] = useState<string>('asc');

  const navigate = useNavigate();

  useEffect(() => {
    allSalesperson(
      currentPage,
      currentLimit,
      userFilter,
      sortedField,
      sortType,
      false,
    );
  }, []);

  const resetUsers = async (
    response: any,
    page: number,
    limit: number,
    fromSort: boolean,
  ) => {
    if (currentLimit !== limit || fromSort) {
      setSalespersons(response.data);
    } else {
      if (currentPage === page) {
        setSalespersons(response.data);
      } else {
        setSalespersons([...salespersons, ...response.data]);
      }
    }
    setRowData(response.count);
  };

  const allSalesperson = async (
    pageNum: number,
    limit: number,
    filter: string,
    sortedField: string,
    sortType: string,
    fromSort: boolean,
  ) => {
    let response: any;
    setLoading(true);

    if (IS_DIY_ADZ) {
      if (isSuperAdmin) {
        response = await getAllSalesperson(
          pageNum,
          limit,
          filter,
          sortedField,
          sortType,
          fromSort,
          true,
          IS_DIY_ADZ,
        );
      } else {
        response = await getAllSalespersonByBrand(
          brand?._id,
          pageNum,
          limit,
          filter,
          sortedField,
          sortType,
          fromSort,
          IS_DIY_ADZ,
        );
      }
    } else {
      response = await getAllSalesperson(
        pageNum,
        limit,
        filter,
        sortedField,
        sortType,
        fromSort,
        true,
        IS_DIY_ADZ,
      );
    }

    await resetUsers(response, pageNum, limit, fromSort);

    setLoading(false);
  };

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleCloseInviteModal = () => {
    setInvitationErrors({
      ...invitationErrors,
      email: '',
    });
    setOpenInviteModal(false);
  };

  const handleOnClickEdit = (salesperson: Salesperson) => {
    navigate(
      `/${IS_DIY_ADZ ? 'userz' : 'users'}/edit?salespersonId=${
        salesperson._id
      }`,
    );
  };

  const handleOnClickDelete = (id: string) => {
    setOpenDeleteModal(true);
    setSalespersonsToDeleteId(id);
  };

  const handleOnClickInviteBtn = (info: InviteInfo) => {
    setOpenInviteModal(true);
    setInvitationInfo({
      ...invitationInfo,
      name: info.name,
      email: info.email,
      id: info.id,
      role: info.role,
    });
  };

  const handleInviteSalesperson = async (email: string) => {
    try {
      setInvitationLoading(true);
      const response = await inviteSalesperson({
        email,
        clientUri: window.location.host,
        diy: IS_DIY_ADZ,
      });
      setOpenInviteModal(false);

      dispatch(
        toggleAlert({
          toggle: true,
          message: response?.message,
        }),
      );
    } catch (error: any) {
      const err = error.response.data;

      setInvitationErrors({
        ...invitationErrors,
        email: err.email,
      });
    } finally {
      setInvitationLoading(false);
    }
  };

  const handleDeleteSalesPerson = async () => {
    setDeleteLoading(true);
    const { id, message } = await deleteSalesperson(salespersonsToDeleteId);
    if (id) {
      const newSalespersonsLists = salespersons.filter((s) => s._id !== id);
      setSalespersons(newSalespersonsLists);
      if (isBrand) {
        setBrandSalespersons(
          newSalespersonsLists.filter((s) => s.role === SALESPERSON),
        );
        setBrandManagers(
          newSalespersonsLists.filter((s) => s.role === BRAND_MANAGER),
        );
      }

      setOpenDeleteModal(false);
      setDeleteLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message,
        }),
      );
    }
  };

  const handleBanSalesPerson = async (id: string) => {
    setBanLoading(true);
    await banUser(id);
    let temp: Salesperson[] = [];
    salespersons.forEach((s) => {
      if (s._id === id) s.user.banned = true;
      temp.push(s);
    });
    setSalespersons(temp);
    if (isBrand) {
      setBrandSalespersons(
        temp.filter((obj: Salesperson) => obj.role === SALESPERSON),
      );
      setBrandManagers(
        temp.filter((obj: Salesperson) => obj.role === BRAND_MANAGER),
      );
    }
    toast.warning('User has been banned');
    setBanLoading(false);
  };

  const handleUnbanSalesPerson = async (id: string) => {
    setBanLoading(true);
    await unbanUser(id);
    let temp: Salesperson[] = [];
    salespersons.forEach((s) => {
      if (s._id === id) s.user.banned = false;
      temp.push(s);
    });
    setSalespersons(temp);
    if (isBrand) {
      setBrandSalespersons(
        temp.filter((obj: Salesperson) => obj.role === SALESPERSON),
      );
      setBrandManagers(
        temp.filter((obj: Salesperson) => obj.role === BRAND_MANAGER),
      );
    }
    toast.warning('User has  benn unbanned');
    setBanLoading(false);
  };

  const setUserPage = async (page: any, limit: any) => {
    if (page > currentPage) setCurrentPage(page);
    if (currentPage < page) {
      await allSalesperson(
        page,
        limit,
        userFilter,
        sortedField,
        sortType,
        false,
      );
    }
  };

  const setUserSize = async (pageNum: any, limit: any) => {
    setPage(0);
    setCurrentPage(1);
    await allSalesperson(
      pageNum,
      limit,
      userFilter,
      sortedField,
      sortType,
      false,
    );
    setCurrentLimit(limit);
  };

  const setFilter = async (filter: string) => {
    setUserFilter(filter);
    setPage(0);
    setCurrentPage(1);
    setCurrentLimit(25);
    await allSalesperson(1, 25, filter, sortedField, sortType, false);
  };

  const handleOnSort = async (sortedField: string, sortType: string) => {
    setSortType(sortType);
    setSortedField(sortedField);
    await allSalesperson(
      currentPage,
      currentLimit,
      userFilter,
      sortedField,
      sortType,
      true,
    );
  };

  return (
    <div className={styles.users}>
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title={IS_DIY_ADZ ? 'Userz' : 'Users'} />

          <Grid container className={styles.controls}>
            <Grid
              item
              xs={12}
              sm={6}
              order={{ xs: 2, sm: 1 }}
              sx={{
                justifyContent: 'flex-start',
                display: 'flex',
                width: '100%',
              }}
            >
              <RadioGroup
                row
                value={userFilter}
                onChange={(e: ChangeEventType) => setFilter(e.target.value)}
              >
                <FormControlLabel
                  value="active"
                  control={<Radio size="small" />}
                  label={
                    <Typography variant={XsOnly() ? 'body2' : 'body1'}>
                      Active
                    </Typography>
                  }
                />

                <FormControlLabel
                  value="inactive"
                  control={<Radio size="small" />}
                  label={
                    <Typography variant={XsOnly() ? 'body2' : 'body1'}>
                      Inactive
                    </Typography>
                  }
                />
              </RadioGroup>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              order={{ xs: 1, sm: 2 }}
              sx={{
                justifyContent: 'flex-end',
                display: 'flex',
                width: '100%',
              }}
            >
              <PrimaryButton
                type="button"
                handleOnClick={() =>
                  navigate(`/${IS_DIY_ADZ ? 'userz' : 'users'}/create`)
                }
                title="Add New User"
                startIcon={<AddIcon />}
              />
            </Grid>
          </Grid>
        </div>

        <div>
          <UserListTable
            salespersons={salespersons}
            handleOnClickEdit={handleOnClickEdit}
            handleOnClickDelete={handleOnClickDelete}
            handleOnClickInviteBtn={handleOnClickInviteBtn}
            loading={loading}
            handleBanSalesPerson={handleBanSalesPerson}
            handleUnbanSalesPerson={handleUnbanSalesPerson}
            page={page}
            setPage={setPage}
            rowData={rowData}
            setUserPage={setUserPage}
            currentLimit={currentLimit}
            pageSize={currentLimit}
            setUserSize={setUserSize}
            onSort={handleOnSort}
          />
        </div>
      </div>

      <DeleteModal
        title="Delete Salesperson"
        subtitle="Are you want to delete this salesperson, it will remove each and every data belonging to this salesperson permanently!"
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDeleteSalesPerson}
        loading={deleteLoading}
      />

      <InviteModal
        title={`Invite as ${humanizeString(invitationInfo.role)}`}
        subtitle={`Are you sure you want to invite ${
          invitationInfo.name
        } as ${humanizeString(invitationInfo.role)}?`}
        open={openInviteModal}
        handleClose={handleCloseInviteModal}
        invitationInfo={invitationInfo}
        handleInvite={handleInviteSalesperson}
        loading={invitationLoading}
        errors={invitationErrors}
      />
    </div>
  );
};

export default Users;
