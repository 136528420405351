import {
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Brand, ChangeEventType } from '../../../types';
import { toCurrency } from '../../../utils/numberFormatter';
import { AdsTemplate } from '../../../types/IDiy';

interface DiyUpdateAdBudgetFormProps {
  brand: Brand;
  currentBudget: number; // adset?.totalBudget
  newMonthlyBudget: number;
  setNewMonthlyBudget: Dispatch<SetStateAction<number>>;
  setTotalCharge: Dispatch<SetStateAction<number>>;
  isDecrease: boolean;
  setIsDecrease: Dispatch<SetStateAction<boolean>>;
  setIsBudgetChange: Dispatch<SetStateAction<boolean>>;
  template: AdsTemplate;
  setTemplate: Dispatch<SetStateAction<AdsTemplate>>;
  register: any;
  errors: any;
  unregister: any;
  clearErrors: any;
}

const DiyUpdateAdBudgetForm: React.FC<DiyUpdateAdBudgetFormProps> = ({
  brand,
  currentBudget,
  newMonthlyBudget,
  setNewMonthlyBudget,
  setTotalCharge,
  isDecrease,
  setIsDecrease,
  setIsBudgetChange,
  template,
  setTemplate,
  register,
  errors,
  unregister,
  clearErrors,
}) => {
  const [changeType, setChangeType] = useState<string>('immediate');

  const getCurrentBudget = () => {
    return brand?.allowSetupPayment
      ? currentBudget / (1 - brand?.budgetPercentageFee / 100)
      : currentBudget;
  };

  const calculateNewBudget = (newBudget: number) => {
    const budget = newBudget > 0 ? newBudget - getCurrentBudget() : 0;
    return budget;
  };

  const budgetChangeType = () => {
    if (isDecrease) {
      return 'Decrease';
    }

    return 'Increase';
  };

  const handleOnChange = (e: ChangeEventType) => {
    clearErrors('newMonthlyBudget');
    const newBudget = parseFloat(e.target.value);
    const totalAmount = newBudget > 0 ? newBudget - getCurrentBudget() : 0;
    const isBudgetChanged = newBudget > 0 && newBudget !== getCurrentBudget();
    setNewMonthlyBudget(newBudget);
    setTotalCharge(totalAmount);
    setTemplate({
      ...template,
      adSetTemplate: { ...template?.adSetTemplate, totalCharge: totalAmount },
    });
    setIsDecrease(totalAmount < 0);
    setIsBudgetChange(isBudgetChanged);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl>
          <Typography variant="body2">
            Do you want to change your budget?
          </Typography>

          <RadioGroup
            row
            aria-labelledby="allLeads"
            name="isBudgetChanged"
            value={template?.isBudgetChanged ? 'yes' : 'no'}
            onChange={(e: ChangeEventType) => {
              if (e.target.value === 'no') {
                unregister('newMonthlyBudget');
              }

              setTemplate({
                ...template,
                [e.target.name]: e.target.value === 'yes',
              });
            }}
            defaultValue="no"
          >
            <FormControlLabel
              value="yes"
              control={<Radio size="small" />}
              label="Yes"
            />

            <FormControlLabel
              value="no"
              control={<Radio size="small" />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {template?.isBudgetChanged ? (
        <>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                Please enter your new desired monthly budget
              </Grid>

              {changeType ? (
                <Grid item xs={6}>
                  <TextField
                    {...register('newMonthlyBudget', {
                      required: 'Please set your new budget',
                      validate: {
                        mustBeZero: (value: string) =>
                          parseFloat(value) !== 0 ||
                          'You cannot set the new monthly budget to zero (0)',
                        shouldNotEqualToCurrent: (value: string) =>
                          parseFloat(value) !== getCurrentBudget() ||
                          'You cannot set new budget equal to current budget',
                      },
                    })}
                    fullWidth
                    required
                    variant="standard"
                    type="number"
                    id="newMonthlyBudget"
                    name="newMonthlyBudget"
                    label="New Monthly Budget"
                    onChange={handleOnChange}
                    value={newMonthlyBudget}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    error={!!errors.newMonthlyBudget}
                    helperText={
                      errors.newMonthlyBudget
                        ? errors.newMonthlyBudget.message
                        : ''
                    }
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }}
                    autoFocus
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      Current Monthly Budget
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    {toCurrency('USD', getCurrentBudget())}
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      New Monthly Budget
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    {toCurrency('USD', newMonthlyBudget || 0)}
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {`Budget ${budgetChangeType()}`}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    {toCurrency('USD', calculateNewBudget(newMonthlyBudget))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default DiyUpdateAdBudgetForm;
