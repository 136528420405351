import React from 'react';
import { Box, Button, styled, Grid, Typography } from '@mui/material';
import { AgencyErrors } from '../types';
import CancelIcon from '@mui/icons-material/Cancel';
import { red } from '@mui/material/colors';
import { Logo } from '../types';
import Spinner from './Spinner';
import styles from '../assets/styles/components/AgencyLogoField.module.scss';

interface AgencyLogoFieldProps {
  logo: Logo;
  handleUploadImage: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveLogo: (public_id: string) => void;
  errors: AgencyErrors;
  uploadLoading?: boolean;
}

const Input = styled('input')({
  display: 'none',
  '&:disabled': {
    cursor: 'not-allowed',
  },
});

const AgencyLogoField: React.FC<AgencyLogoFieldProps> = ({
  logo,
  handleUploadImage,
  handleRemoveLogo,
  errors,
  uploadLoading,
}) => {
  return (
    <>
      {logo.url !== '' ? (
        <Box
          component="div"
          position="relative"
          pt={2}
          pl={2}
          className={styles.logo}
        >
          <div className={styles.image}>
            <img width="100%" height="100%" src={logo.url} alt="" />
          </div>

          <CancelIcon
            onClick={() => handleRemoveLogo(logo.public_id)}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              color: red[500],
              cursor: 'pointer',
            }}
          />
        </Box>
      ) : null}

      <Grid item xs={12}>
        <label htmlFor="agency-logo">
          <Input
            disabled={logo.url !== ''}
            onChange={handleUploadImage}
            accept="image/*"
            id="agency-logo"
            multiple
            type="file"
          />

          <Button
            fullWidth
            sx={{
              color: errors.logo ? red[500] : 'rgba(2, 136, 209, 0.5)',
              borderStyle: 'dashed',
              borderColor: errors.logo ? red[500] : 'rgba(2, 136, 209, 0.5)',
              '&:hover': {
                borderStyle: 'dashed',
              },
              '&.Mui-disabled': {
                borderStyle: 'dashed',
                cursor: 'not-allowed',
              },
            }}
            variant="outlined"
            component="span"
            color="info"
            disabled={logo.url !== ''}
          >
            {uploadLoading ? <Spinner /> : 'Upload Logo'}
          </Button>
        </label>

        {errors.logo ? (
          <Typography
            component="small"
            sx={{
              fontWeight: 400,
              fontSize: '0.75rem',
              lineHeight: '1.66',
              letterSpacing: '0.03333em',
              textAlign: 'left',
              marginTop: '4px',
              margiRight: '14px',
              marginBottom: 0,
              marginLeft: '14px',
              color: red[500],
            }}
          >
            {errors.logo}
          </Typography>
        ) : null}
      </Grid>
    </>
  );
};

export default AgencyLogoField;
