import React, { useContext } from 'react';
import chatzAppLogo from '../../assets/images/logo.png';
import { ExpressThemeContext } from '../../context';
import styles from '../../assets/styles/components/Navbar/Navbar.module.scss';
import { IS_DIY_ADZ } from '../../utils';
import diyAdzLogo from '../../assets/images/aiadz-logo.png';

const PublicNavbar: React.FC = () => {
  const {
    state: { expressTheme },
  } = useContext(ExpressThemeContext);

  const logo = () => {
    if (IS_DIY_ADZ) return diyAdzLogo;

    return expressTheme.properties.logo?.url
      ? expressTheme.properties.logo?.url
      : chatzAppLogo;
  };

  const logoSize = () => {
    if (IS_DIY_ADZ) return '100px';

    return '281px';
  };

  return (
    <div className={`${styles.navbar} ${styles['-public']}`}>
      <div className={`${styles.logo} ${styles['-public']}`}>
        <img width={logoSize()} src={logo()} alt="" />
      </div>
    </div>
  );
};

export default PublicNavbar;
