import { Avatar, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Brand } from '../../../types';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

const buildBrandParams = (params: any) => {
  const brand: Brand = {
    brand: params.row.brand,
    agency: params.row.agency,
    investmentRequirement: params.row.investmentRequirement,
    allLeads: params.row.allLeads,
    zipcodereq: params.row.zipcodereq,
    financialreq: params.row.financialreq,
    redirect: params.row.redirect,
    redirectlink: params.row.redirectlink,
    redirecttext: params.row.redirecttext,
    industryRequirement: params.row.industryRequirement,
    industryQuestion: params.row.industryQuestion,
    schedulecall: params.row.schedulecall,
    calendly: params.row.calendly,
    salesperson: params.row.salesperson,
    salespersonTitle: params.row.salespersonTitle,
    manager: params.row.manager,
    _id: params.row._id,
    phoneNumReq: params.row.phoneNumReq,
    budgetOffset: params.row.budgetOffset,
    clientTetherConfig: params.row.clientTetherConfig,
    cannedResponses: params.row.cannedResponses,
    active: params.row.active,
    paused: params.row.paused,
    lastPausedAt: params.row.lastPausedAt,
    page: params.row.page,
    facebookBusinessIds: params.row.facebookBusinessIds,
    googleCustomerIds: params.row.googleCustomerIds,
    allowSetupDepositAccount: params.row.allowSetupDepositAccount,
    allowSetupPayment: params.row.allowSetupPayment,
    allowLocations: params.row.allowLocations,
    __type: params.row.__type,
    stripe: params.row.stripe,
  };

  return brand;
};

export const locationMobileColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 50,
    disableColumnMenu: true,
    sortingOrder: ['desc', 'asc'],
  },
  {
    field: 'brand',
    headerName: 'Location',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      const brand = buildBrandParams(params);

      return <div className={styles.name}>{brand?.brand}</div>;
    },
  },
  {
    field: 'salesperson',
    sortingOrder: ['desc', 'asc'],
    valueGetter: (params) => params.row.salesperson?.name,
    headerName: 'Sales Person',
    flex: 1,
    renderCell: (params) => {
      const { salesperson } = params.row;

      return <div className={styles.name}>{salesperson?.name}</div>;
    },
  },
];

export const locationAdminColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 1,
    disableColumnMenu: true,
    sortingOrder: ['desc', 'asc'],
  },
  {
    field: 'brand',
    headerName: 'Location',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      const brand = buildBrandParams(params);

      return <div className={styles.name}>{brand?.brand}</div>;
    },
  },
  {
    field: 'page',
    headerName: 'Facebook Page',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      const { page } = params.row;

      if (!page?.fbPageId) return <div></div>;

      return (
        <div className={styles.name}>
          <span>
            <Avatar
              sx={{
                width: 30,
                height: 30,
                marginRight: '5px',
              }}
              src={page?.imageUrl}
              alt={page?.name}
            />
          </span>
          {page?.name}
        </div>
      );
    },
  },
  {
    field: 'agency',
    valueGetter: (params) => params.row.agency?.agency,
    headerName: 'Franchisor',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      const { franchisor } = params.row;

      return <div className={styles.name}>{franchisor?.brand}</div>;
    },
  },
  {
    field: 'salesperson',
    valueGetter: (params) => params.row.salesperson?.name,
    headerName: 'Salesperson',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      const { salesperson } = params.row;

      return <div className={styles.name}>{salesperson?.name}</div>;
    },
  },
  {
    field: 'manager',
    valueGetter: (params) => params.row.salesperson?.name,
    headerName: 'Manager',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      const { manager } = params.row;

      return <div className={styles.name}>{manager?.name}</div>;
    },
  },
];

export const locationColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 50,
    disableColumnMenu: true,
    sortingOrder: ['desc', 'asc'],
  },
  {
    field: 'brand',
    headerName: 'Location',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      const brand = buildBrandParams(params);

      return <div className={styles.name}>{brand?.brand}</div>;
    },
  },
  {
    field: 'salesperson',
    sortingOrder: ['desc', 'asc'],
    valueGetter: (params) => params.row.salesperson?.name,
    headerName: 'Sales Person',
    flex: 1,
    renderCell: (params) => {
      const { salesperson } = params.row;

      return <div className={styles.name}>{salesperson?.name}</div>;
    },
  },
  {
    field: 'manager',
    sortingOrder: ['desc', 'asc'],
    valueGetter: (params) => params.row.manager?.name,
    headerName: 'Manager',
    flex: 1,
    renderCell: (params) => {
      const { manager } = params.row;

      return <div className={styles.name}>{manager?.name}</div>;
    },
  },
];
