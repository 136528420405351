import React, { Fragment, useEffect, useState } from 'react';
import { Fee } from '../../../types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Typography,
} from '@mui/material';
import { toCurrency } from '../../../utils/numberFormatter';
import { FeeType } from '../../../utils/helpers/stripeHelper';
import { PrimaryButton } from '../../Buttons';
import FormHeader from '../FormHeader';
import logo from '../../../assets/images/subscribe.svg';
import { SmAndDown } from '../../../utils/breakpoints';
import { getArrayTotalByObjKeys } from '../../../utils/arrayFormatter';
import moment from 'moment';
import { renderCCIcon } from '../../Cards/Billing/PaymentMethodHelpers';
import { snakeCaseToHumanized } from '../../../utils/stringModifier';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useNavigate } from 'react-router-dom';
import { IS_DIY_ADZ } from '../../../utils';

interface SubscriptionFeesProps {
  brandFees: Fee[];
  total: number;
  onClickSubscribe?: () => void;
  subscription?: boolean;
  subscriptions?: any[];
  onOpenPayment?: (clientSecret: string, transactionType: string) => void;
  onOpenCancel?: (subscriptionId: string) => void;
  onOpenRenew?: (subscriptionId: string) => void;
  isAdmin?: boolean;
  onOpenAdBudget?: () => void;
}

const SubscriptionFees: React.FC<SubscriptionFeesProps> = ({
  brandFees,
  total,
  onClickSubscribe,
  subscription = false,
  subscriptions = [],
  onOpenPayment,
  onOpenCancel,
  onOpenRenew,
  isAdmin = false,
  onOpenAdBudget,
}) => {
  const navigate = useNavigate();
  const [totalFees, setTotalFees] = useState<number>(0);
  const [showFees, setShowFees] = useState<boolean>(false);
  const withSubscription = subscriptions.length > 0;
  const brandFee = brandFees.filter((fee: Fee) => {
    return fee.amount > 0 && fee.type !== FeeType.Setup;
  });
  const isSingleFee = brandFee.length === 1;
  const isPastDue =
    subscriptions[0]?.status === 'past_due' &&
    subscriptions[0]?.latest_invoice?.payment_intent?.status ===
      'requires_action';
  const needPaymentMethod = [
    'requires_payment_method',
    'incomplete',
    'incomplete_expired',
    'past_due',
  ].includes(subscriptions[0]?.status);
  const isRenew = subscriptions[0]?.cancel_at > 0;

  useEffect(() => {
    setShowFees(false);
    if (brandFee.length > 0) {
      const total = getArrayTotalByObjKeys(brandFee, 'amount');
      if (total > 0) {
        setShowFees(true);
      } else {
        setShowFees(false);
      }

      setTotalFees(total);
    }
  }, [withSubscription, brandFee]);

  const renderButtonAction = () => {
    if (isPastDue) {
      return window.open(
        subscriptions[0]?.latest_invoice?.hosted_invoice_url,
        '_blank',
      );
    }

    if (needPaymentMethod) {
      return onOpenPayment(
        subscriptions[0]?.latest_invoice?.payment_intent?.client_secret,
        'payment',
      );
    }

    if (isRenew) {
      return onOpenRenew(subscriptions[0]?.id);
    }

    return onOpenCancel(subscriptions[0]?.id);
  };

  const renderButtonText = () => {
    if (isPastDue) {
      return 'Verify Payment';
    }

    if (needPaymentMethod) {
      return 'Change Payment Method';
    }

    if (isRenew) {
      return 'Renew Subscription';
    }

    return 'Cancel Subscription';
  };

  const renderButtonIcon = () => {
    if (isPastDue) {
      return <CreditScoreIcon />;
    }

    if (needPaymentMethod) {
      return <AddCardOutlinedIcon />;
    }

    if (isRenew) {
      return <ReplayOutlinedIcon />;
    }

    return <DoDisturbAltOutlinedIcon />;
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        {!subscription ? (
          <Grid item xs={12}>
            <FormHeader
              title={
                withSubscription
                  ? 'Subscription Details'
                  : 'Subscribe to Chatz.App'
              }
            />
          </Grid>
        ) : null}

        {withSubscription ? (
          <Grid container spacing={2} justifyContent="center" mt={1}>
            <Grid item xs={12} sm={3} flexDirection="column">
              <Typography variant="body1" fontWeight="bold" textAlign="center">
                Start Date
              </Typography>

              <Typography variant="body1" textAlign="center">
                {moment.unix(subscriptions[0]?.created).utc().format('LL')}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3} flexDirection="column">
              <Typography variant="body1" fontWeight="bold" textAlign="center">
                Subscription Period
              </Typography>

              <Typography variant="body1" textAlign="center">
                {`${moment
                  .unix(subscriptions[0]?.current_period_start)
                  .utc()
                  .format('MMM DD, YYYY')} - ${moment
                  .unix(subscriptions[0]?.current_period_end)
                  .utc()
                  .format('MMM DD, YYYY')}`}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3} flexDirection="column">
              <Typography variant="body1" fontWeight="bold" textAlign="center">
                Payment Method
              </Typography>

              <Typography variant="body1" textAlign="center">
                {subscriptions[0]?.default_payment_method?.card ? (
                  <>
                    {renderCCIcon(
                      subscriptions[0]?.default_payment_method?.card?.brand,
                      20,
                    )}{' '}
                    {`${
                      subscriptions[0]?.default_payment_method?.card?.last4
                        ? `●●●● ${subscriptions[0]?.default_payment_method?.card?.last4}`
                        : ''
                    }`}
                  </>
                ) : (
                  'N/A'
                )}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3} flexDirection="column" textAlign="center">
              <Typography variant="body1" fontWeight="bold">
                Status
              </Typography>

              <Typography variant="body1" textAlign="center">
                {subscriptions[0]?.latest_invoice?.payment_intent?.status ===
                'requires_action'
                  ? 'Requires Authentication'
                  : `${
                      subscriptions[0]?.status === 'trialing'
                        ? 'Pending'
                        : snakeCaseToHumanized(subscriptions[0]?.status)
                    }`}
                {subscriptions[0]?.cancel_at ? (
                  <Fragment>
                    <Chip
                      label={`Cancels ${moment
                        .unix(subscriptions[0]?.cancel_at)
                        .utc()
                        .format('MMM DD')}`}
                      size="small"
                      color="warning"
                      sx={{ marginLeft: '5px' }}
                    />
                  </Fragment>
                ) : null}
              </Typography>
            </Grid>
          </Grid>
        ) : null}

        <Grid container spacing={2} justifyContent="center" mt={1}>
          {!subscription ? (
            <Grid
              item
              xs={12}
              md={showFees ? 6 : 12}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                margin: 0,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                component="img"
                src={logo}
                alt=""
                sx={{ width: '400px', height: '400px' }}
              />

              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <PrimaryButton
                    title={withSubscription ? renderButtonText() : 'Subscribe'}
                    theme="#096f4d"
                    type="button"
                    handleOnClick={
                      withSubscription ? renderButtonAction : onClickSubscribe
                    }
                    rounded
                    width={withSubscription ? '100%' : '200px'}
                    size="large"
                    startIcon={withSubscription ? renderButtonIcon() : null}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          <Grid
            item
            xs={12}
            md={subscription ? 12 : 6}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              margin: 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid container spacing={2} justifyContent="center" my={2}>
              {SmAndDown() ? null : <Grid item xs={12} sm={6} md={3} />}

              {!showFees ? null : (
                <Grid item xs={12} sm={12} md={6}>
                  <Card sx={{ borderRadius: 3 }}>
                    <CardHeader
                      subheader={
                        withSubscription
                          ? 'Total Monthly Fees'
                          : `${
                              isSingleFee
                                ? `${
                                    brandFee[0]?.type === FeeType.AdBudget
                                      ? brandFee[0]?.name
                                      : `${brandFee[0]?.name} Fee`
                                  }`
                                : 'Total'
                            }`
                      }
                      sx={{
                        textAlign: 'center',
                        backgroundColor: '#096f4d',
                        paddingBottom: '10px',
                      }}
                      subheaderTypographyProps={{
                        sx: { fontWeight: 'bold', color: 'white' },
                      }}
                    />

                    <CardContent sx={{ textAlign: 'center' }}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 'bold', color: '#096f4d' }}
                      >
                        {toCurrency(
                          'USD',
                          withSubscription ? totalFees || 0 : total || 0,
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              {SmAndDown() ? null : <Grid item xs={12} sm={6} md={3} />}
            </Grid>

            {isSingleFee ? null : (
              <Grid container spacing={2} justifyContent="center">
                {brandFees.map((fee: Fee, index: number) => {
                  if (
                    fee.amount === 0 ||
                    !fee.enabled ||
                    (withSubscription && fee.type === FeeType.Setup)
                  )
                    return <></>;

                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={4}
                      key={`grid-${index}`}
                      flexDirection="column"
                    >
                      <Card
                        key={`card-${index}`}
                        sx={{
                          borderRadius: 3,
                          minHeight: '100%',
                        }}
                      >
                        <CardHeader
                          subheader={`${fee?.name} ${
                            fee?.type === FeeType.AdBudget ? '' : 'Fee'
                          }`}
                          sx={{
                            textAlign: 'center',
                            backgroundColor: '#096f4d',
                          }}
                          subheaderTypographyProps={{
                            sx: { fontWeight: 'bold', color: 'white' },
                          }}
                        />

                        <CardContent sx={{ textAlign: 'center', py: 1 }}>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 'bold', color: '#096f4d' }}
                          >
                            {toCurrency('USD', fee.amount || 0)}
                          </Typography>

                          {fee?.oneTime ? (
                            <div>
                              <Typography
                                color="text.secondary"
                                variant="caption"
                                letterSpacing="0"
                              >
                                (One-time Fee)
                              </Typography>
                            </div>
                          ) : (
                            <div>
                              <Typography
                                color="text.secondary"
                                variant="caption"
                                letterSpacing="0"
                              >
                                (Monthly)
                              </Typography>
                            </div>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            )}

            {withSubscription && subscriptions[0].status === 'active' ? (
              <Grid container spacing={2} justifyContent="center" mt={2}>
                <Grid item>
                  <PrimaryButton
                    title="Update Ad Budget"
                    theme="#096f4d"
                    type="button"
                    handleOnClick={() =>
                      navigate(
                        `/${
                          IS_DIY_ADZ ? 'billingz' : 'billings'
                        }/ad-budget/update`,
                      )
                    }
                    rounded
                    width="100%"
                    size="large"
                    startIcon={<AttachMoneyIcon />}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SubscriptionFees;
