import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { Brand, CampaignFormFields } from '../../../types';
import {
  humanizeString,
  snakeCaseToHumanized,
} from '../../../utils/stringModifier';
import styles from '../../../assets/styles/components/Modal/CampaignModal.module.scss';
import FormHeader from '../FormHeader';

interface CampaignFormProps {
  campaigns: CampaignFormFields[];
  campaignFormValues: CampaignFormFields[];
  defaultFormValues: CampaignFormFields[];
  setDefaultValue: Dispatch<SetStateAction<CampaignFormFields[]>>;
  brand: Brand;
  onSelectCampaign: (
    campaignAds: any[],
    brand: Brand,
    selectedCampaign: string,
    reason: string,
  ) => void;
  provider: string;
}

const CampaignForm: React.FC<CampaignFormProps> = ({
  campaigns,
  campaignFormValues,
  defaultFormValues,
  setDefaultValue,
  brand,
  onSelectCampaign,
  provider,
}) => {
  const selectedOption = (o: any) => {
    return !!campaignFormValues.find((e: any) => e.id === o.id && !e.deleted);
  };

  return (
    <div className={styles.campaigns}>
      <FormHeader title={`Select ${humanizeString(provider)} Campaigns`} />

      <Autocomplete
        id="campaigns"
        multiple
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        options={campaigns.sort((a, b) => -b.name?.localeCompare(a.name))}
        getOptionDisabled={(option) => selectedOption(option)}
        value={defaultFormValues}
        getOptionLabel={(option) => option?.name}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Campaigns"
            placeholder="Select your campaigns"
          />
        )}
        disableClearable
        onChange={async (e: any, value: any[], reason, detail) => {
          await setDefaultValue(value);
          await onSelectCampaign(value, brand, detail.option.id, reason);
        }}
      />

      <Grid container spacing={1} mt={1}>
        {defaultFormValues?.map((campaign: CampaignFormFields) => {
          return campaign.deleted ? null : (
            <Grid item xs={12} sm={6} lg={3} key={campaign.id}>
              <Card sx={{ height: '100%' }}>
                <CardHeader
                  subheader={campaign.name}
                  subheaderTypographyProps={{
                    sx: {
                      fontWeight: 'bold',
                      color: 'black',
                      textAlign: 'center',
                    },
                  }}
                />

                <CardContent sx={{ paddingTop: 0 }}>
                  <Typography variant="body2" fontWeight="bold" component="div">
                    ID:
                    <Typography variant="body2" display="inline" ml={1}>
                      {campaign.id}
                    </Typography>
                  </Typography>

                  <Typography variant="body2" fontWeight="bold" component="div">
                    Status:
                    <Typography variant="body2" display="inline" ml={1}>
                      {humanizeString(campaign.status)}
                    </Typography>
                  </Typography>

                  <Typography variant="body2" fontWeight="bold" component="div">
                    Objective:
                    <Typography variant="body2" display="inline" ml={1}>
                      {humanizeString(snakeCaseToHumanized(campaign.objective))}
                    </Typography>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default CampaignForm;
